import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	clearRequiredErrors,
	dataToServer,
	findFirstError,
	onSubmission,
} from './workerCardEditor.utils';
import { FAB } from 'react-native-paper';
import {
	View,
	Image,
	StyleSheet,
	useWindowDimensions,
	Platform,
} from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigationState, useRoute } from '@react-navigation/core';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import CarouselContainer from '../../../components/shared/Carousel/Carousel.container';
import CaruselItem from '../../../components/shared/Carousel/CaruselItem.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import Wrapper from './Wrapper/Wrapper.component';
import colors from '../../../config/colors';
import { i18nService } from '../../../services/i18n.service';
import ActionButtons from './ActionButtons/ActionButtons';
import TopDrawer from '../../../components/shared/Drawers/TopDrawer';
import CommentsAndErrors from './CommentsAndErrors/CommentsAndErrors';
import httpService from '../../../services/http.service';
import TabWrapper from './TabWrapper/TabWrapper';
import icons from '../../../config/icons';
import { SCREEN_SIZES } from '../../../constants';
import HideOnKeyboardOpen from '../../../components/shared/HideOnKeyboardOpen/HideOnKeyboardOpen.component';
import { WorkerCardModel } from '../../../models/workerCard.model';
import { modalService } from '../../../services/modal.service';
import ModalConfirmActions from '../../../modals/ModalConfirmActions/ModalConfirmActions.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import { getTabsMap } from './WorkerCardTabs';
import { validationSchema } from './WorkerCardValidation';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import { getDocumentTitle } from '../../../utils/globals.utils';
import { dispatch } from '../../../store/store';
import {
	setIsInWorkerCard,
	setWorkerCardAlertOnChangedEmp,
} from '../../../store/actions/login.actions';
import EmployeeCommentModal from '../../../modals/CommentModal/EmployeeCommentModal.component';
import {
	fieldsMap,
	getCommentsForLeftPanel,
	getFancyValue,
	getFieldComment,
	getMissingFields,
	getTabsWithCommentsList,
} from './WorkerCardViewer.utils';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ScrollView } from 'react-native-gesture-handler';
import {
	EventName,
	logEvent,
} from '../../../services/analyticsAndLogs.service';

const bigCaruselaWidth = 1500;
const smallCaruselaWidth = 1019;

const TabBar = ({ navigation, navigate }: any) => {
	const platform = useDevicePlatform();
	useEffect(() => {
		navigate(navigation.getState().index || 1);
	}, []);

	return null;
};

const Tab = createBottomTabNavigator();

const WorkerCardEditor = ({ navigation }: any) => {
	const platform = useDevicePlatform();
	const dimensions = useWindowDimensions();
	const isMobile = platform !== DeviceType.WEB;
	const isBrowser = platform !== DeviceType.MOBILE;

	const [currentPage, setCurrentPage] = useState<number>();
	const [isDrawerOpen, setIsDrawerOpen] = useState(!isMobile);
	const [goToPosition, setGoToPosition] = useState<Function>();
	const [originalValues, setOriginalValues] = useState<any>();
	const [initialValues, setInitialValues] = useState<any>();
	const [errorToFocus, setErrorToFocus] = useState<any>();
	const [duplicate, setDuplicate] = useState(false);
	const [commentsForPanel, setCommentsForPanel] = useState<any[]>([]);
	const [openDrawerFunc, setOpenDrawerFunc] = useState<any>();
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [taxYear, setTaxYear] = useState();
	const [tabsWithCommentsList, setTabsWithCommentsList] = useState<any>(null);
	const [missingFields, setMissingFields] = useState<any[]>([]);
	const [taxCreditAddressList, setTaxCreditAddressList] = useState<any[]>([]);
	const [tabsMap, setTabsMap] = useState<any[]>(getTabsMap([],2024));
	const route = useRoute();
	const navigaionState = useNavigationState(state => state);
	const openDrawerFuncRef = useRef<any>();
	openDrawerFuncRef.current = openDrawerFunc;

	const drawerDataStatus: any = useMemo(() => ({ value: undefined }), []);
	const shouldTriggerValidation: any = useMemo(
		() => ({ value: undefined }),
		[],
	);

	const menuWidth = useMemo(
		() => (dimensions.width >= SCREEN_SIZES.MEDIUM ? 320 : 0),
		[dimensions],
	);

	const urlParams: any = useMemo(
		() => isBrowser && new URL(document.location).searchParams,
		[isBrowser && document.location],
	);

	const isNew101Form = useMemo(() => {
		if (typeof route?.params?.isNew101Form === 'boolean') {
			return route?.params?.isNew101Form;
		}

		if (typeof route?.params?.params?.isNew101Form === 'boolean') {
			return route?.params?.params?.isNew101Form;
		}

		return (
			route?.params?.isNew101Form ||
			route?.params?.params?.isNew101Form ||
			(isBrowser && urlParams?.get('isNew101Form'))
		);
	}, [route?.params?.isNew101Form, route?.params?.params?.isNew101Form]);

	const formId = useMemo(() => {
		return (
			route.params?.formId ||
			route.params?.params?.formId ||
			(isBrowser && urlParams?.get('formId'))
		);
	}, [route.params?.formId, route.params?.params?.formId, urlParams]);

	const originalStatus = useMemo(
		() =>
			route.params?.originalStatus ||
			route.params?.originalStatus?.formId ||
			(isBrowser && urlParams?.get('originalStatus')),
		[
			route.params?.originalStatus,
			route.params?.params?.originalStatus,
			urlParams,
		],
	);

	const isSmallCarusel = useMemo(
		() => bigCaruselaWidth > dimensions.width - 33 - menuWidth,
		[dimensions, menuWidth],
	);

	const shouldDisplayArrows = useMemo(
		() =>
			smallCaruselaWidth > dimensions.width - 33 - menuWidth ||
			(isDrawerOpen &&
				(isSmallCarusel ? smallCaruselaWidth : bigCaruselaWidth) >
					dimensions.width - 240 - menuWidth),
		[dimensions, isSmallCarusel, isDrawerOpen, menuWidth],
	);

	const formMethods = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema()),
		mode: 'onBlur',
	});

	const isEmployee = useMemo(
		() => navigaionState.routeNames[navigaionState.index] === 'editor',
		[navigaionState.index],
	);

	const errorsTabMap = useMemo(
		() =>
			tabsMap.reduce(
				(res, tab) => ({
					...res,
					[tab.pageRoute]: !!_.flattenDeep(tab.fields).find(f =>
						_.get(formMethods.formState.errors, f.name),
					),
				}),
				{},
			),
		[formMethods.formState],
	);

	useEffect(() => {
		if (shouldTriggerValidation.value) {
			shouldTriggerValidation.value = false;
			formMethods.trigger();
		}
	}, [formMethods.formState.errors]);

	useEffect(() => {
		if (!isMobile) {
			if(!openDrawerFuncRef.current){
				openDrawerFuncRef.current?.current();
			}
		}
		if (!drawerDataStatus.value) {
			drawerDataStatus.value = 'first_init';
		} else if (
			drawerDataStatus.value === 'first_init' &&
			openDrawerFuncRef.current
		) {
			drawerDataStatus.value = 'next';
		}
	}, [commentsForPanel, missingFields, openDrawerFuncRef.current]);

	useEffect(() => {
		const subscription = formMethods.watch((value, { name, type }) => {
			if (type === 'change' && !isFormChanged) {
				setIsFormChanged(true);
			}
		});
		return () => subscription.unsubscribe();
	}, [formMethods.watch]);

	useEffect(() => {
		if (isFormChanged) {
			dispatch(setWorkerCardAlertOnChangedEmp(true));
		}
		return () => {
			dispatch(setWorkerCardAlertOnChangedEmp(false));
		};
	}, [isFormChanged]);

	useEffect(() => {
		dispatch(setIsInWorkerCard(true));

		return () => {
			dispatch(setIsInWorkerCard(false));
		};
	}, []);

	useEffect(() => {
		if (errorToFocus?.tab?.pageRoute) {
			const tabId = tabsMap.findIndex(
				(tab: any) => tab.pageRoute === errorToFocus?.tab?.pageRoute,
			);

			setCurrentPage(tabId);

			navigation.navigate('editor', {
				screen: errorToFocus?.tab?.pageRoute,
				params: { focused: errorToFocus.field, isEmployee, formId },
			});

			if (isMobile || shouldDisplayArrows) {
				goToPosition && goToPosition(tabId);
			}

			setErrorToFocus(undefined);
		}
	}, [errorToFocus]);

	useEffect(() => {
		if (originalStatus === 'APPROVED') {
			setDuplicate(true);
		}
	}, [originalStatus]);

	useEffect(() => {
		formMethods.reset(initialValues);
	}, [initialValues]);

	useEffect(() => {
		(async () => {
			try {
				let list = taxCreditAddressList;
				if(!taxCreditAddressList || taxCreditAddressList.length === 0) {
				 	list = await httpService.api({
					type: 'getTaxCreditAddressList',
				});
				setTaxCreditAddressList(list);
			}
				const preparedList = list.map((el: any) => {
					return {
						id: el.addressCode,
						name: el.address,
					};
				});
				if(taxYear){
					setTabsMap(getTabsMap(preparedList,taxYear));
				}

			} catch (e) {
				console.error(e);
			}
		})();
	}, [taxYear]);

	useEffect(() => {
		(async () => {
			try {
				if (taxCreditAddressList?.length) {
					if (formId && (!isNew101Form || isNew101Form === 'false')) {
						const res: any = await httpService.api({
							type: 'getWorkerCardInEdit',
							params: {
								formId,
							},
						});
						populateFormForClient(res);
					} else if (
						(isNew101Form || isNew101Form === 'true') &&
						!route.params?.formData
					) {
						const res: any = await httpService.api({
							type: 'getDataForNew101Report',
						});
						populateFormForClient(res);
					} else if (
						(isNew101Form || isNew101Form === 'true') &&
						route.params?.formData
					) {
						populateFormForClient(route.params?.formData);
					} else {
						setInitialValues(new WorkerCardModel());
					}
				}
			} catch (e) {
				if (
					e.status === 400 &&
					e.data === 'User cant create new form'
				) {
					close();
					await modalService.openModal(
						null,
						{
							submitBtnText: 'general.close',
							iconName: 'attention',
						},
						(props: any) => (
							<AlertModal {...props}>
								<I18n>
									worderCard.editor.error.cantCreateNewReport
								</I18n>
							</AlertModal>
						),
					);
				}
			}
		})();
	}, [formId, isNew101Form, route.params?.formData, taxCreditAddressList]);

	useEffect(() => {
		onMount();
	}, []);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('WorkerCardEditor');
		}
		logEvent({
			eventName: EventName.edit101WorkerCard,
			data: {},
		});
	};

	const populateFormForClient = (res: any) => {
		const data = new WorkerCardModel({ ...res, taxCreditAddressList });
		setOriginalValues(_.cloneDeep(data));
		setInitialValues(data);
		setTabsWithCommentsList(getTabsWithCommentsList(tabsMap, data));
		setCommentsForPanel(getCommentsForLeftPanel(data));
		setTaxYear(res.taxYear);
		setMissingFields(getMissingFields(data));
	};

	const onCommentClick = async (
		fieldLabel: string,
		fieldName: string,
		fieldValue: string,
		isRequired?: boolean,
	) => {
		const comment = getFieldComment(
			initialValues,
			fieldName,
			route.params.screen,
		);

		await modalService.openModal(
			null,
			{
				fieldLabel,
				fieldValue: getFancyValue(fieldName, fieldValue),
				comment: comment.comment,
			},
			EmployeeCommentModal,
		);
	};

	const onNavigate = (tabId: number) => {
		const currentRoute = currentPage && tabsMap[currentPage];
		const route = tabsMap[tabId];

		if (tabId !== currentPage) {
			if (currentRoute) {
				formMethods.trigger(
					_.compact(
						_.flattenDeep(currentRoute.fields).map(
							(filed: any) => filed.name,
						),
					),
				);

				setMissingFields(getMissingFields(formMethods.getValues()));
			}
			setCurrentPage(tabId);

			navigation.navigate('editor', {
				screen: route.pageRoute,
				params: { isEmployee, formId, isNew101Form },
			});

			if (isMobile || shouldDisplayArrows) {
				goToPosition && goToPosition(tabId);
			}
			if(isMobile && isDrawerOpen){
				openDrawerFuncRef.current?.current()
				setIsDrawerOpen(false)
			}
		}
	};

	const onSaveError = (errors: any) => {
		logEvent({
			eventName: EventName.saveWorkerCardFailure,
			data: {
				data: formMethods.getValues(),
				errors: errors,
			},
		});
		const fieldError = findFirstError(errors);
		clearRequiredErrors(errors, formMethods.clearErrors);

		if (!fieldError) {
			save();
		} else {
			setErrorToFocus(fieldError);
		}
	};

	const save = async (values?: any, noClose?: boolean) => {
		let formIdForRequest = formId;

		if (isNew101Form === 'true' || isNew101Form === true) {
			formIdForRequest = 0;
		}

		try {
			const res: any = await httpService.api({
				type: 'updateWorkerCard',
				params: {
					formId: formIdForRequest,
				},
				isFormData: true,
				notGenerateFormData: true,
				data: await dataToServer(
					formMethods.getValues(),
					originalValues,
					duplicate,
				),
			});
			if (
				(Platform.OS !== 'web' && res[0] !== '{') ||
				(Platform.OS === 'web' && typeof res !== 'object')
			) {
				throw { data: res };
			}
			if (!noClose) {
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>workerCard.editor.save.success</I18n>
						</AlertModal>
					),
				);
				close();
			}
			return res;
		} catch (e) {
			logEvent({
				eventName: EventName.saveWorkerCardFailure,
				data: {
					data: await dataToServer(
						formMethods.getValues(),
						originalValues,
						duplicate,
					),
					error: e,
				},
			});
			let errorMessage = 'workerCard.editor.save.failure';
			if (
				typeof e?.data === 'string' &&
				e?.data?.includes('Tax credit address code is not valid')
			) {
				errorMessage = 'workerCard.editor.save.addressCodeNotValid';
			}
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>{errorMessage}</I18n>
					</AlertModal>
				),
			);
		}
	};

	const onSubmmisionError = async (errors: any) => {
		try {
			logEvent({
				eventName: EventName.submitWorkerCardFailure,
				data: {
					data: formMethods.getValues(),
					errors: errors,
				},
			});
			const fieldError = findFirstError(errors, false);
			if (fieldError) {
				setErrorToFocus(fieldError);
				const fieldErrorNonRequired = findFirstError(errors);
				if (
					!fieldErrorNonRequired &&
					JSON.stringify(initialValues) !==
						JSON.stringify(formMethods.getValues())
				) {
					const data = await save(null, true);
					const tempData =
						typeof data === 'string' ? JSON.parse(data) : data;
					const values = new WorkerCardModel({
						...tempData,
						taxCreditAddressList,
					});
					setInitialValues(values);
					formMethods.reset(values);
					shouldTriggerValidation.value = true;
					navigation.setParams({
						formId: tempData.id,
						isNew101Form: 'false',
					});

					setDuplicate(false);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	const submission = async () => {
		onSubmission({
			formId,
			isNew101Form,
			currentValues: formMethods.getValues(),
			initialValues: originalValues,
			duplicate,
			close,
		});
	};

	const close = () => {
		navigation.navigate('form101', {
			screen: '101table',
		});
	};

	const cancel = async () => {
		console.log('cancel is the new create')
		logEvent({
			eventName: EventName.cancelEdit101,
			data: {},
		});
		if (
			JSON.stringify(initialValues) !==
			JSON.stringify(formMethods.getValues())
		) {
			await modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						close();
						callback();
					},
					onSubmit: (callback: Function) => {
						formMethods.handleSubmit(save, onSaveError)();
						callback();
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>general.shouldSaveChanges</I18n>
					</ModalConfirmActions>
				),
			);
		} else {
			close();
		}
	};

	const goNext = () => {
		if (
			currentPage === 4 &&
			formMethods.getValues().employee?.maritalStatus !== 'MARRIED'
		) {
			onNavigate(6);
		} else {
			onNavigate((currentPage || 0) + 1);
		}
	};

	const goBack = () => {
		if (
			currentPage === 6 &&
			formMethods.getValues().employee?.maritalStatus !== 'MARRIED'
		) {
			onNavigate(4);
		} else {
			onNavigate((currentPage || 0) - 1);
		}
	};

	const tabsArrayComponent = useMemo(
		() =>
			tabsMap.map((tab, index) => (
				<>
					{tabsWithCommentsList &&
						tabsWithCommentsList[tab.pageRoute] && (
							<Image
								source={icons.addedCommentIcon}
								fadeDuration={0}
								style={{
									height: 13,
									width: 14,
									marginLeft: 17,
									transform: [{ rotateY: '270deg' }],
									position: 'absolute',
									zIndex: 1,
									top: -3,
								}}
							/>
						)}
					{errorsTabMap && errorsTabMap[tab.pageRoute] ? (
						<Image
							style={{
								position: 'absolute',
								top: -3,
								left: 0,
								zIndex: 100,
								width: 14,
								height: 14,
							}}
							source={icons.errorIndicaror}
						/>
					) : null}
					<CaruselItem
						key={tab.pageRoute}
						selected={index === currentPage}
						title={`workerCard.editor.${tab.pageRoute}`}
						tabId={index}
						width={isMobile || isSmallCarusel ? 86 : 145}
						onPress={onNavigate}
					/>
				</>
			)),
		[errorsTabMap, currentPage, isSmallCarusel, isMobile],
	);

	const goToCommentScreen = async (
		screen: string,
		comment: any,
		name: string,
	) => {
		let fieldValue;
		const values = formMethods.getValues();
		let pageName: string | undefined;
		const tabName = Object.keys(fieldsMap).find(
			(el: string) => fieldsMap[el] === comment.objectName,
		);
		let targetTabIndex;
		if (comment.fieldName === 'ROOT') {
			return;
		}
		if (comment.objectName === 'ROOT') {
			pageName =
				comment.fieldName === 'hasChildren' ? 'children' : 'disclaimer';
			fieldValue = values[comment.fieldName];
			targetTabIndex = comment.fieldName === 'signature' ? 8 : 2;
		} else {
			fieldValue = values[tabName][comment.fieldName];
			targetTabIndex = tabsMap.findIndex(
				(el: any) => el.pageRoute === screen,
			);
			pageName = Object.keys(fieldsMap).find(
				(key: string) => fieldsMap[key] === comment?.objectName,
			);
		}

		if (comment?.objectName === 'ADDITIONAL_INCOME') {
			pageName = 'taxCreditRequest';
		}

		const fieldName =
			comment?.objectName === 'ROOT'
				? comment.fieldName
				: `${pageName}.${comment.fieldName}`;

		let allFields: any[] = [];

		for (const obj of tabsMap) {
			allFields = [...allFields, ..._.flattenDeep(obj.fields)];
		}

		onNavigate(targetTabIndex);
		await modalService.openModal(
			null,
			{
				fieldLabel: name,
				fieldValue: getFancyValue(fieldName, fieldValue),
				comment: comment.comment,
				fromCommentPanel: true,
			},
			EmployeeCommentModal,
		);
	};

	const goToFieldScreen = (screen: string, field: any) => {
		const fieldName = `${field.parentName || ''}${
			field.position || field.position === 0 ? `[${field.position}]` : ''
		}${field.parentName ? '.' : ''}${field.name}`;
		setErrorToFocus({
			field: fieldName,
			name: field.name,
			message: i18nService.translate('general.validation.required'),
			tab: {
				pageRoute: screen,
			},
		});
		
		if(isMobile){
			openDrawerFuncRef.current?.current()
			setIsDrawerOpen(false)
		}
	};

	return (
		<>
			<FormProvider {...formMethods}>
				<Wrapper
					year={taxYear}
					setIsDrawerOpen={setIsDrawerOpen}
					isEmployee={true}
					comments={commentsForPanel}
					isMobile={isMobile}
					missingFields={missingFields}
					goToCommentScreen={goToCommentScreen}
					goToFieldScreen={goToFieldScreen}
					setOpenDrawerFunc={setOpenDrawerFunc}
					icon={
						missingFields.length ? (
							<Image
								style={{
									width: 14,
									height: 14,
								}}
								source={icons.exclamationMarkRed}
							/>
						) : null
					}
				>
					{isMobile ? (
						<View
							style={{
								backgroundColor: colors.white,
								zIndex: 600,
							}}
						>
							<CarouselContainer
								notDisplayHelpers
								shouldDisplayArrows={
									!isMobile && shouldDisplayArrows
								}
								itemMargin={9}
								itemWidth={
									isMobile || isSmallCarusel ? 86 : 145
								}
								setResetCaruselFunc={() => {}}
								setGoToPosition={setGoToPosition}
							>
								{tabsArrayComponent}
							</CarouselContainer>
						</View>
					) : null}
					{isMobile && (
						<TopDrawer
							setOpenDrawerFunc={setOpenDrawerFunc}
							setIsDrawerOpen={setIsDrawerOpen}
							icon={
								missingFields.length ? (
									<Image
										style={{
											width: 14,
											height: 14,
										}}
										source={icons.exclamationMarkRed}
									/>
								) : null
							}
						>
							<ScrollView
								style={{
									flex: 1,
									backgroundColor: colors.white,
								}}
							>
								<CommentsAndErrors
									comments={commentsForPanel}
									isMobile={isMobile}
									removeComment={() => {}}
									isEmployee={true}
									goToCommentScreen={goToCommentScreen}
									missingFields={missingFields}
									goToFieldScreen={goToFieldScreen}
								/>
							</ScrollView>
						</TopDrawer>
					)}
					<View
						style={{
							flex: 1,
							marginLeft: !isMobile ? (isDrawerOpen ? 0 : 5) : 0,
							height:
								!isMobile &&
								dimensions.width < SCREEN_SIZES.MEDIUM
									? dimensions.height - 223
									: 'auto',
							// paddingLeft: !isMobile ? (isDrawerOpen ? 25 : 0) : 0,
							backgroundColor: colors.white,
							width: '100%',
							marginTop:
								Platform.OS === 'ios' ? 49 : isMobile ? 16 : 0,
						}}
					>
						{!isMobile ? (
							<View
								style={{
									// marginLeft: !isMobile ? (isDrawerOpen ? 0 : 5) : 0,
									paddingLeft: !isMobile
										? isDrawerOpen
											? 25
											: 0
										: 0,
									width: '100%',
								}}
							>
								<CarouselContainer
									itemMargin={9}
									notDisplayHelpers
									shouldDisplayArrows={
										!isMobile && shouldDisplayArrows
									}
									itemWidth={
										isMobile || isSmallCarusel ? 86 : 145
									}
									setResetCaruselFunc={() => {}}
									setGoToPosition={setGoToPosition}
									containerMaxWidth={
										isDrawerOpen
											? dimensions.width - 240
											: undefined
									}
								>
									{tabsArrayComponent}
								</CarouselContainer>
							</View>
						) : null}

						<Tab.Navigator
							tabBar={props => (
								<TabBar {...props} navigate={onNavigate} />
							)}
							lazy={true}
							swipeEnabled={false}
						>
							{tabsMap.map(tab => (
								<Tab.Screen
									key={`${tab.pageRoute}`}
									name={tab.pageRoute}
									initialParams={{ isEmployee, formId }}
									options={{
										title: getDocumentTitle(tab.pageRoute),
									}}
									children={() => (
										<TabWrapper
											data={initialValues}
											onCommentClick={onCommentClick}
											tabsMap={tabsMap}
										/>
									)}
								/>
							))}
						</Tab.Navigator>
						<HideOnKeyboardOpen>
							{currentPage !== tabsMap.length - 1 &&
							(!isDrawerOpen || !isMobile) ? (
								<FAB
									style={StyleSheet.compose(
										styles.fab,
										currentPage
											? styles.leftButton
											: undefined,
									)}
									small
									color={colors.lightPrimary}
									icon=''
									label={i18nService.translate(
										'general.next',
									)}
									onPress={goNext}
								/>
							) : null}
						</HideOnKeyboardOpen>
						<HideOnKeyboardOpen>
							{currentPage && (!isDrawerOpen || !isMobile) ? (
								<FAB
									style={styles.fab}
									small
									color={colors.lightPrimary}
									icon=''
									label={i18nService.translate(
										'general.prev',
									)}
									onPress={goBack}
								/>
							) : null}
						</HideOnKeyboardOpen>
						<HideOnKeyboardOpen>
							{!isDrawerOpen || !isMobile ? (
								<ActionButtons
									save={formMethods.handleSubmit(
										save,
										onSaveError,
									)}
									submission={formMethods.handleSubmit(
										submission,
										onSubmmisionError,
									)}
									cancel={cancel}
									isEmployee={isEmployee}
									// readOnly={readOnly}
								/>
							) : null}
						</HideOnKeyboardOpen>
					</View>
				</Wrapper>
			</FormProvider>
		</>
	);
};

const styles = StyleSheet.create({
	fab: {
		position: 'absolute',
		margin: 16,
		right: 0,
		bottom: 15,
		borderColor: colors.lightPrimary,
		backgroundColor: colors.white,
		borderWidth: 1,
	},
	leftButton: {
		right: 92,
	},
});

export default WorkerCardEditor;
