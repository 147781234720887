import { debounce } from 'lodash';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';

import { FilterData } from '../../components/web/Filters/Filters.container';
import httpService from '../../services/http.service';
import { getUserData } from '../../store/selectors/login.selectors';
import {
	fromMonthToMonthList,
	fromMonthToMonthListLike,
	fromMonthToMonthListComprehensive,
} from '../../models/fromMonthToMonth.model';
import {
	createFilterOptionsForTable,
	createHeadersForTable,
	fromMonthToMonthReportsFilters,
	fromMonthToMonthReportsResponce,
	FromMonthToMonthReportsDataType,
	FromMonthToMonthReportsFiltersDataType,
	FromMonthToMonthReportsFiltersType,
	statusFromMonthToMonthReportsMatcher,
	onApproveSelectedButtonHandler,
	onSendReportModal,
	FromMonthToMonthReportsPageFilterSize,
	onDisapproveSelectedButtonHandler,
} from '../FromMonthToMonthReports/FromMonthToMonthReports.utils';
import reducer, {
	initialState,
	FromMonthToMonthReportsRequest,
} from './localStore/FromMonthToMonthReports.reducer';
import {
	filter,
	incrementPage,
	sort,
	search,
	setAllFiltersSavedState,
	resetPage,
	resetFiltersAndSort,
} from './localStore/FromMonthToMonthReports.actions';
import { getDepartmentsFilterList } from '../../store/selectors/reportsFilters.selectors';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../store/actions/reportsFilters.actions';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import I18n from '../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import FromMonthToMonthReportsPage from './userList/FromMonthToMonthReports.component';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import routes from '../../config/routes';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';
import { Privileges } from '../../constants/roleTypes.constant';
import {
	incrementBusyIndicator,
	resetBusyIndicator,
} from '../../store/actions/config.actions';
import { dispatch } from '../../store/store';
import useEffectNotInitial from '../../hooks/useEffectNotInitial';
import { getExcelFileFromResponse } from '../../utils/downloadFile.web.utils';
import { EventName, logEvent } from '../../services/analyticsAndLogs.service';

const FromMonthToMonthReportsEmploeesTableContainer = ({
	navigation,
}: {
	navigation: any;
}) => {
	const platform = useDevicePlatform();

	const [isfirstComponentInit, setIsFirstComponentInit] =
		useState<boolean>(true);
	const [FromMonthToMonthReportsList, setFromMonthToMonthReportsList] =
		useState<fromMonthToMonthListLike>(fromMonthToMonthReportsResponce);
	const [FromMonthToMonthReportsFilters, setFromMonthToMonthReportsFilters] =
		useState<FromMonthToMonthReportsFiltersDataType>(
			fromMonthToMonthReportsFilters,
		);
	const [
		selectedFromMonthToMonthReports,
		setSelectedFromMonthToMonthReports,
	] = useState<FromMonthToMonthReportsDataType | null>(null);
	const selectedRowIndex = useMemo(
		() =>
			FromMonthToMonthReportsList.data.findIndex(
				(report: FromMonthToMonthReportsDataType) =>
					report.id === selectedFromMonthToMonthReports?.id,
			),
		[selectedFromMonthToMonthReports],
	);
	const [isRowSelected, setIsRowSelected] = useState<boolean>(false);
	const [tableState, tableDispatch] = useReducer(reducer, initialState);
	const [activeActionButtonType, setActiveActionButtonType] = useState<boolean | 'approve' | 'disapprove' >(false);
	const [customerDraftData, setCustomerDraftData] = useState({});
	const [displayComponents, setDisplayComponents] = useState([]);
	const [comprehensiveAddAdditional, setComprehensiveAddAdditional] =
		useState(false);
	const [showLastsMonths, setShowLastsMonths] = useState(false);
	const [removeOrAddNewComponent, setRemoveOrAddNewComponent] =
		useState(null);
	const [triggerRemoveOrAddNewComponent, setTriggerRemoveOrAddNewComponent] =
		useState(0);
	const [triggerUndo, setTriggerUndo] = useState(0);

	const [reportNotFound, setReportNotFound] = useState<boolean>(false);
	const [noReports, setNoReports] = useState<boolean>(false);
	const [onLineChange, setOnLineChange] = useState<Array<number>>([]);
	const [customerWageComponents, setCustomerWageComponents] =
		useState<Object>({});

	const [isPdfMode, setIsPdfMode] = useState<boolean>(
		platform === DeviceType.WEB,
	);
	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
		finish_work: [true],
	});
	const [isSelectAllReportsCheckbox, setSelectAllReportsCheckbox] =
		useState<boolean>(false);
	const [checkboxedReports, setCheckboxedReports] = useState<number[]>([]);
	const route = useRoute() as any;
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const userData = useSelector(getUserData);
	const [swithScreenBy, setSwithScreenBy] = useState(1);
	const [maxParameter, setMaxParameter] = useState<number>(500);
	const [undoAction, setUndoAction] = useState({});
	const [undoIds, setUndoIds] = useState([]);
	const [loading, setLoading] = useState({});
	const [selectUnitId, setSelectUnitId] = useState([]);
	const [sortingComprehensiveMode, setSortingComprehensiveMode] =
		useState(false);
	const isSelectAllReportsCheckboxRef = useRef<boolean>();

	isSelectAllReportsCheckboxRef.current = isSelectAllReportsCheckbox;

	useEffect(() => {
		dispatch(incrementBusyIndicator());
		setIsFirstComponentInit(true);
		if (route.params?.screen === 'details') {
			setIsRowSelected(true);
		}
		setSelectedDepartments({
			dep: [],
			finish_work: [true],
		});
		if (selectUnitId.length) {
			tableState.sortBy = '';
			tableState.sortDirection = 'DESC';
			tableState.filter = {};
			setFromMonthToMonthReportsList({
				...FromMonthToMonthReportsList,
				hasNext: false,
			});
			tableDispatch(resetFiltersAndSort());
			dispatch(fetchDepartmentsFilterList(route.name, selectUnitId));
			getCustomerWageComponents(selectUnitId);
			setDisplayComponents([]);
			getDisplayComponents();
		}
	}, [userData, selectUnitId]);

	useEffect(() => {
		const statusToDispatch = activeActionButtonType == 'approve' 
								? 'AWAITING_APPROVAL' 
								: (activeActionButtonType == 'disapprove' 
									? 'APPROVED' 
									: '');
		tableDispatch(filter('status', statusToDispatch, false));
	}, [activeActionButtonType]);

	const disabled = useMemo(
		() =>
			!hasPermissionOnBusinessUnit(
				selectUnitId?.[0],
				Privileges.ROLE_DRAFT_WAGE_REPORT_WRITE,
			),
		[selectUnitId],
	);
	useEffect(() => {
		if (!disabled) {
			getMaxParameter();
		}
	}, [disabled]);

	useEffect(() => {
		let isSavedStateExists = false;
		Object.values(tableState?.filter).forEach((item: any) => {
			if (item.length) {
				isSavedStateExists = true;
			}
		});
		if (isSavedStateExists && isfirstComponentInit) {
			tableDispatch(setAllFiltersSavedState(tableState));
			setIsFirstComponentInit(false);
			return;
		}
		if (isfirstComponentInit && !isSavedStateExists) {
			tableDispatch(filter('status', '' as string, false));
			setIsFirstComponentInit(false);
		}
	}, [tableState]);

	useEffect(() => {
		debouncedUpdateData(tableState, swithScreenBy, selectUnitId);
	}, [tableState, userData, swithScreenBy, selectUnitId]);

	useEffectNotInitial(() => {
		dispatch(resetBusyIndicator());
	}, [customerDraftData]);

	useEffect(() => {
		if (swithScreenBy === 0 && comprehensiveAddAdditional) {
			tableState.page = 0;
			setComprehensiveAddAdditional(false);
			debouncedUpdateData(tableState, swithScreenBy, selectUnitId);
		}
	}, [removeOrAddNewComponent, triggerUndo]);

	useEffect(() => {
		tableState.page = 0;
		if (swithScreenBy === 0) {
			setActiveActionButtonType(false);
		}
		if (sortingComprehensiveMode) {
			tableState.sortBy = '';
			tableState.sortDirection = 'DESC';
			tableState.filter = {};
			tableDispatch(resetFiltersAndSort());
			setSortingComprehensiveMode(false);
		}
	}, [swithScreenBy]);

	useEffect(() => {
		setFromMonthToMonthReportsFilters(state => ({
			...state,
			dep: departmentsFilterList,
		}));
	}, [departmentsFilterList]);

	const disabledLoadMore = useMemo(
		() => FromMonthToMonthReportsList.hasNext,
		[FromMonthToMonthReportsList, tableState.page],
	);

	const onLoadMore = useCallback(() => {
		if (disabledLoadMore) {
			tableDispatch(incrementPage());
		}
	}, [disabledLoadMore]);

	const debouncedUpdateData = useCallback(
		debounce(
			(
				data: FromMonthToMonthReportsRequest,
				swithScreenBy: number,
				selectUnitId: any,
			) => {
				loadFromMonthToMonthReportsList(
					data,
					swithScreenBy,
					selectUnitId,
				);
			},
			500,
		),
		[],
	);

	const getEmployeesFilter = async (
		search: string,
		loadOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: FromMonthToMonthReportsPageFilterSize,
			search,
			filter: selectedDepartments,
			unitIds: selectUnitId,
		});

		const list = res?.data?.map((el: any) => {
			return {
				value: el.employeeId,
				label: el.name + ` (${el.teudatZeut})`,
			};
		});

		return {
			options: list,
			total: res?.total,
			hasMore: res?.hasNext,
			additional: {
				page: page + 1,
			},
		};
	};

	const getDepartmentsFilter = (search: string) => {
		const filter = search
			? FromMonthToMonthReportsFilters.dep.filter(item => {
					return item.name.includes(search);
			  })
			: FromMonthToMonthReportsFilters.dep;

		return {
			options: filter.map((el: any) => {
				return {
					value: el.id,
					label: el.name,
				};
			}),
		};
	};

	const loadFromMonthToMonthReportsList = (
		{
			page,
			pageSize,
			sortBy,
			sortDirection,
			filter,
			search,
		}: FromMonthToMonthReportsRequest,
		swithScreenBy: number,
		selectUnitId: any,
	) => {
		if (!selectUnitId.length) {
			return;
		}
		page === 0 && setLoading(true);
		httpService
			.api<fromMonthToMonthListLike>({
				type:
					swithScreenBy === 0
						? 'getFromMonthToMonthReportsListUpdateMode'
						: 'getFromMonthToMonthReportsList',
				data: {
					p: page,
					ps: pageSize,
					s: sortBy,
					sd: sortDirection,
					f: filter,
					search,
				},
				query: { unitId: selectUnitId?.[0] },
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					const reqData =
						swithScreenBy === 0
							? res.data.map(
									(fw: fromMonthToMonthListComprehensive) =>
										new fromMonthToMonthListComprehensive(
											fw,
										),
							  )
							: res.data.map(
									(fw: fromMonthToMonthList) =>
										new fromMonthToMonthList(fw),
							  );

					if (!res.data[0]) {
						setIsRowSelected(false);
						setReportNotFound(true);
						dispatch(resetBusyIndicator());
						if (page === 0) {
							setSelectedFromMonthToMonthReports(null);
						}
					} else setReportNotFound(false);
					if (page === 0) {
						setFromMonthToMonthReportsList(state => ({
							hasNext: res.data.length < pageSize ? false : true,
							total:
								filter.dep.length || filter.emp.length
									? state.total
									: res.total,
							yearMonth: res.yearMonth,
							comments: res.comments,
							showComponents: res.showComponents,
							data: reqData,
						}));
						if (reqData.length > 0) {
							setSelectedFromMonthToMonthReports(reqData[0]);
						}
					} else {
						setFromMonthToMonthReportsList(state => {
							if (isSelectAllReportsCheckboxRef.current) {
								setAllChecked([...state.data, ...reqData]);
							}
							return {
								hasNext:
									res.data.length < pageSize ? false : true,
								total: state.total,
								yearMonth: res.yearMonth,
								comments: res.comments,
								showComponents: res.showComponents,
								data: [...state.data, ...reqData],
							};
						});
					}
					if (!res.total && page === 0) {
						setNoReports(true);
					} else {
						setNoReports(false);
					}
				}
			})
			.catch(err => {
				setFromMonthToMonthReportsList(fromMonthToMonthReportsResponce);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getCustomerWageComponents = (selectUnitId: any) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getCustomerWageComponents',
				query: { unitId: selectUnitId?.[0] },
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					setCustomerWageComponents(res);
				}
			});
	};

	const getDisplayComponents = () => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getDisplayComponents',
				query: { unitId: selectUnitId?.[0] },
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					setDisplayComponents(res);
				}
			});
	};

	const getMaxParameter = () => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getMaxParameter',
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					setMaxParameter(res);
				}
			});
	};

	const getUndoAction = () => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getUndoAction',
				query: { unitId: selectUnitId?.[0] },
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					setUndoAction(res);
				}
			});
	};

	const makeUndoAction = () => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'makeUndoAction',
				query: { unitId: selectUnitId?.[0] },
				returnAllRes: true,
			})
			.then(async (res: any) => {
				if (res.status === 200) {
					if (!undoAction.componentCode && !undoAction.singleAction) {
						setComprehensiveAddAdditional(true);
					}
					setUndoAction({});
					setTriggerUndo(prev => (prev === 0 ? 1 : 0));
					handleUndoIds(null, true);
					modalService.openModal(
						null,
						{
							submitBtnText: 'general.close',
							iconName: 'attention',
						},
						(props: any) => (
							<AlertModal {...props}>
								<I18n style={{ fontFamily: 'RubikRegular' }}>
									FromMonthToMonthReportsReportPage.undoAction.success
								</I18n>
							</AlertModal>
						),
					);
				}
			});
	};

	const onApproveSelectedButtonHandlerFirst = () => {
		switch (activeActionButtonType) {
			case 'approve':
				onApproveSelectedButtonHandler(
					checkboxedReports,
					tableState,
					isSelectAllReportsCheckbox,
					setCheckboxedReports,
					setActiveActionButtonType,
					selectUnitId,
				);
                break;
            case 'disapprove':
				onDisapproveSelectedButtonHandler(
					checkboxedReports,
					setCheckboxedReports,
					setActiveActionButtonType,
					selectUnitId
				)
                break;
            default:
                break;
		}
	};



	const handleFilter = useCallback((data: FilterData) => {
		setSelectedFromMonthToMonthReports(null);
		if (data.name === 'unitIds') {
			setSelectUnitId(
				Array.isArray(data.value) ? [data.value[0]] : [data.value],
			);
			return;
		}
		if (data.name === 'search') {
			tableDispatch(search(data.value));
			return;
		} else if (Array.isArray(data?.value)) {
			if (data.name === 'status' && data?.value) {
				let mappedArray: any = [];

				data?.value.map(el => {
					const val =
						statusFromMonthToMonthReportsMatcher[el.id]?.resolve;
					mappedArray.push({ id: val });
				});
				tableDispatch(filter(data.name, mappedArray, true));
			} else {
				tableDispatch(
					filter(
						data.name as FromMonthToMonthReportsFiltersType,
						data.value,
						true,
					),
				);
			}
		} else if (data.name === 'status') {
			const mapper =
				statusFromMonthToMonthReportsMatcher[data?.value].resolve;
			tableDispatch(
				filter(
					data.name as FromMonthToMonthReportsFiltersType,
					mapper,
					data.isMulti,
				),
			);
		} else {
			tableDispatch(
				filter(
					data.name as FromMonthToMonthReportsFiltersType,
					data.value,
					data.isMulti,
				),
			);
		}
	}, []);

	const { businessUnitsList } = useBusinessUnitsState({
		privileges: routes.fromMonthToMonthReports.privileges,
		onChangeFilter: handleFilter,
		forceSingle: true,
	});

	const handleSorting = useCallback((field: any, direction: any) => {
		tableDispatch(sort(field, direction));
	}, []);

	const handleSortingComprehensive = useCallback(
		(field: any, direction: any) => {
			tableDispatch(sort(field, direction));
			setSortingComprehensiveMode(true);
		},
		[],
	);

	const selectedRowHandler = (
		rowData: FromMonthToMonthReportsDataType,
		flags: any,
	) => {
		if (isPdfMode && !flags?.forceNavigate) {
			setSelectedFromMonthToMonthReports(rowData);
			return;
		}
		if (rowData.status === 'EXPORTED') {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n style={{ fontFamily: 'RubikRegular' }}>
							modal.reports101.SUBMIT
						</I18n>
					</AlertModal>
				),
			);
		}
	};

	const navigateBack = () => {
		setSelectedFromMonthToMonthReports(null);
		setIsRowSelected(false);
		navigation.navigate('fromMonthToMonthReports', {
			screen: 'workPaperDraft',
		});
	};

	const debouncedAutocompleteSearch = useCallback(
		debounce((query: string) => {
			tableDispatch(search(query));
		}, 300),
		[],
	);

	const setAllChecked = (list?: any) => {
		const data = list || FromMonthToMonthReportsList.data;
		const tempArr: number[] = [];
		data.forEach(el => {
			const properStatus = activeActionButtonType == "approve" ? "AWAITING_APPROVAL" :"APPROVED";
			if (el.status === properStatus) {
				tempArr.push(el.id);
			}
		});

		setCheckboxedReports([...tempArr]);
	};

	const onSelectAllCheckboxHandler = () => {
		if (!isSelectAllReportsCheckbox) {
			setAllChecked();
			setSelectAllReportsCheckbox(true);
		} else onDisselectAllReportsHandler();
	};

	const onDisselectAllReportsHandler = () => {
		setCheckboxedReports([]);
		setSelectAllReportsCheckbox(false);
	};

	const onCheckboxChangeHandler = (row: any) => {
		const selectedId = row?.original?.id || row?.id;
		const includesEl = !!checkboxedReports.length
			? isCheckboxSelected(selectedId)
			: false;

		if (checkboxedReports.length && includesEl) {
			const arr = checkboxedReports.filter(el => el !== selectedId);
			setSelectAllReportsCheckbox(false);
			setCheckboxedReports([...arr]);
		} else setCheckboxedReports(state => [...state, selectedId]);
	};

	const isCheckboxSelected = (id: any) => {
		return checkboxedReports.includes(id);
	};

	const resetTablePage = () => {
		tableDispatch(resetPage());
	};

	const moveToNextEmp = () => {
		const nextEmp = FromMonthToMonthReportsList.data[selectedRowIndex + 1];
		setSelectedFromMonthToMonthReports(nextEmp);
		if (
			FromMonthToMonthReportsList.data.length - selectedRowIndex <= 4 &&
			FromMonthToMonthReportsList.hasNext
		) {
			tableDispatch(incrementPage());
		}
	};

	const moveToPrevEmp = () => {
		const nextEmp = FromMonthToMonthReportsList.data[selectedRowIndex - 1];
		setSelectedFromMonthToMonthReports(nextEmp);
	};

	const onSendReport = async (
		month: string,
		employee: object,
		optionalDeductionsComponent: any,
	) => {
		if (optionalDeductionsComponent.length > 6) {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n style={{ textAlign: 'center' }}>
							modal.FromMonthToMonthReports.selectAllReports.moreThenSix.onlyOneEmp.failure
						</I18n>
					</AlertModal>
				),
			);
		} else {
			onSendReportModal(
				month,
				employee,
				setActiveActionButtonType,
				selectUnitId,
			);
		}
	};

	const onSetIsPdfMode = (value: boolean) => {
		setIsPdfMode(value);
		FromMonthToMonthReportsList?.data &&
			setSelectedFromMonthToMonthReports(
				FromMonthToMonthReportsList?.data[0],
			);
	};

	const handleUndoIds = (id?: any, remove?: boolean) => {
		if (remove) {
			setUndoIds([]);
		} else {
			const newUndoIds = [...undoIds];
			const exists = undoIds.includes(id || userData.id);
			if (!exists) {
				newUndoIds.push(id || userData.id);
			}

			setUndoIds([...newUndoIds]);
		}
	};

	const exportExcel = async () => {
		const res: any = await httpService.api({
			type: 'getFromMonthToMonthReportsExcelFile',
			data: {
				p: tableState.page,
				ps: tableState.pageSize,
				s: tableState.sortBy,
				sd: tableState.sortDirection,
				f: tableState.filter,
				search: tableState.search,
			},
			query: { unitId: selectUnitId?.[0] },
			responseType: 'blob',
			returnAllRes: true,
		});
		getExcelFileFromResponse(res);
		logEvent({
			eventName: EventName.exportFromMothToMonthExcel,
			data: {},
		});
	};

	return (
		<FromMonthToMonthReportsPage
			navigation={navigation}
			data={FromMonthToMonthReportsList.data}
			yearMonth={FromMonthToMonthReportsList.yearMonth}
			total={FromMonthToMonthReportsList.total}
			reportNotFound={reportNotFound}
			headers={createHeadersForTable(
				activeActionButtonType,
				isCheckboxSelected,
				onCheckboxChangeHandler,
				onSelectAllCheckboxHandler,
				isSelectAllReportsCheckbox,
				onLineChange,
			)}
			filtersData={createFilterOptionsForTable(
				FromMonthToMonthReportsFilters,
				tableState,
				getEmployeesFilter,
				businessUnitsList,
				selectUnitId,
			)}
			resetTablePage={resetTablePage}
			onSort={handleSorting}
			onLoadMore={onLoadMore}
			onFilter={handleFilter}
			onRowClick={selectedRowHandler}
			isRowSelected={isRowSelected}
			selectedRowData={selectedFromMonthToMonthReports}
			navigateBack={navigateBack}
			// pageCurrent={currentPageselected}
			onSearchTextChange={(query: any) =>
				debouncedAutocompleteSearch(query)
			}
			onSearchOptionSelected={(query: any) => {
				tableDispatch(filter('emp', query?.value as any));
			}}
			onDisselectAllReports={onDisselectAllReportsHandler}
			onSelectAllReports={onSelectAllCheckboxHandler}
			selectedFilters={tableState.filter}
			pageData={{
				page: tableState.page,
				pageSize: tableState.pageSize,
			}}
			onSelectRowCheckboxPressed={onCheckboxChangeHandler}
			isCheckboxSelected={isCheckboxSelected}
			onApproveSelectedButtonPressed={onApproveSelectedButtonHandlerFirst}
			isSelectAllReportsCheckbox={isSelectAllReportsCheckbox}
			onSelectAllCheckboxReports={onSelectAllCheckboxHandler}
			noReports={noReports}
			isPdfMode={isPdfMode}
			disabled={disabled}
			setShowLastsMonths={setShowLastsMonths}
			showLastsMonths={showLastsMonths}
			customerWageComponents={customerWageComponents}
			setActiveActionButtonType={setActiveActionButtonType}
			activeActionButtonType={activeActionButtonType}
			customerDraftData={customerDraftData}
			setCustomerDraftData={setCustomerDraftData}
			setIsPdfMode={onSetIsPdfMode}
			setOnLineChange={setOnLineChange}
			getEmployeesFilter={getEmployeesFilter}
			getDepartmentsFilter={getDepartmentsFilter}
			swithScreenBy={swithScreenBy}
			setSwithScreenBy={setSwithScreenBy}
			handleSelectLine={setSelectedFromMonthToMonthReports}
			getDisplayComponents={getDisplayComponents}
			displayComponents={displayComponents}
			setDisplayComponents={setDisplayComponents}
			onSortComprehensive={handleSortingComprehensive}
			userData={userData}
			maxParameter={maxParameter}
			getUndoAction={getUndoAction}
			undoAction={undoAction}
			makeUndoAction={makeUndoAction}
			triggerUndo={triggerUndo}
			sortingComprehensiveMode={sortingComprehensiveMode}
			isLoadingData={loading}
			undoIds={undoIds}
			selectUnitId={selectUnitId}
			handleUndoIds={handleUndoIds}
			exportExcel={exportExcel}
			removeOrAddNewComponent={removeOrAddNewComponent}
			setRemoveOrAddNewComponent={setRemoveOrAddNewComponent}
			triggerRemoveOrAddNewComponent={triggerRemoveOrAddNewComponent}
			setTriggerRemoveOrAddNewComponent={
				setTriggerRemoveOrAddNewComponent
			}
			setComprehensiveAddAdditional={setComprehensiveAddAdditional}
			{...{
				moveToNextEmp,
				moveToPrevEmp,
				onSendReport,
				selectedRowIndex,
				disableNext:
					!disabledLoadMore &&
					selectedRowIndex ===
						FromMonthToMonthReportsList.data.length - 1,
			}}
		/>
	);
};
export default FromMonthToMonthReportsEmploeesTableContainer;
