import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LocaleConfig } from 'react-native-calendars';
import _, { AnyKindOfDictionary } from 'lodash';

import { setCalendarLocale } from '../../../config/calendarLocaleConfig';
import { modalService } from '../../../services/modal.service';
import httpService from '../../../services/http.service';
import { fromMonthToMonthListLike } from '../../../models/fromMonthToMonth.model';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import FlexTable from '../../../components/web/FlexTable/FlexTable.container';
import { i18nService } from '../../../services/i18n.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import {
	DataFormatTool,
	handleAddButtonModal,
	indexName,
} from './FromMonthToMonthReportsSalaryDataFormByEmployee.utils';
import FieldBuilder from '../../../components/shared/FieldBuilder/FieldBuilder';
import moment from 'moment';
import useEffectNotInitial from '../../../hooks/useEffectNotInitial';
import { ProgressViewIOS } from 'react-native';

setCalendarLocale(LocaleConfig);

interface FromMonthToMonthReportsPageProps {
	isFullScreen: boolean;
	disableNext: boolean;
	selectedRowIndex: boolean;
	moveToPrevEmp: Function;
	moveToNextEmp: Function;
	disabled: boolean;
	data: Object;
	onSendReport: Function;
	setShowLastsMonths: Function;
	showLastsMonths: boolean;
	customerWageComponents: any;
	customerDraftData: object;
	setCustomerDraftData: Function;
	MonthYearFormat: Function;
	setOnLineChange: Function;
	getUndoAction: Function;
	triggerUndo: number;
	userData: any;
	undoAction: any;
	undoIds: any;
	handleUndoIds: Function;
	selectUnitId: any;
	isSmallDimensions: boolean;
	handleMakeUndoAction: Function;
}
interface FormInputs<T> {
	[key: string]: T;
}

const useStyles = makeStyles((theme: any) => ({
	wrapper: {
		padding: ({ isFullScreen }: any) =>
			isFullScreen ? '13px 40px 50px 0px' : '13px 10px 50px 0px',
		width: ({ isFullScreen, showLastsMonths, customerDraftData }: any) =>
			isFullScreen
				? showLastsMonths && customerDraftData?.paycheck.length > 1
					? '98%'
					: '100%'
				: showLastsMonths && customerDraftData?.paycheck.length > 1
				? '55%'
				: '55%',
		direction: 'rtl',
	},
	header: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignContent: 'start',
		margin: '7px 10px 0 0',
	},
	body: {
		display: 'flex',
		justifyContent: 'space-between',
		alignContent: 'start',
		padding: '0px 5px',
		overflowX: 'auto',
		height: ({ isSmallDimensions }: any) =>
			isSmallDimensions ? 'calc(100% - 87px)' : '91%',
		width: '100%',
		overflow: 'hidden',
	},
	tableCurrentWrraper: {
		display: 'flex',
		width: ({ showLastsMonths, customerDraftData }: any) =>
			showLastsMonths && customerDraftData?.paycheck.length > 1
				? 'auto'
				: '100%',
		flexDirection: 'row',
		height: ({ isSmallDimensions }: any) =>
			isSmallDimensions ? '95%' : '97%',
		overflowY: 'auto',
		minWidth: 405,
		padding: 10,
		backgroundColor: colors.white,
		flex: ({ showLastsMonths, customerDraftData }: any) =>
			showLastsMonths && customerDraftData?.paycheck.length > 1 ? 1 : 2,
	},
	tableRowHeaders: {
		width: '100%',
		marginLeft: '2px',
	},
	tableRowCurrent: {
		width: '100%',
	},
	tableListWrraper: {
		display: 'flex',
		marginRight: '7px',
		flexDirection: 'row',
		width: 'auto',
		height: ({ isSmallDimensions }: any) =>
			isSmallDimensions ? '95%' : '97%',
		overflow: 'auto',
		padding: '10px 5px',
		minHeight: 280,
		minWidth: 260,
		backgroundColor: colors.white,
		flex: ({ showLastsMonths, customerDraftData }: any) =>
			showLastsMonths && customerDraftData?.paycheck.length > 1 ? 2 : 1,
	},
	tableRow: {
		width: '100%',
		margin: '0 5px',
	},
	arrowContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		width: '25px',
		margin: '0px 8px',
	},
	emploBtnsWrap: {
		margin: '10px 0',
		minWidth: '210px',
		display: 'flex',
	},
	btnsEmplo: {
		display: 'flex',
		marginLeft: '20px',
		flexDirection: 'row',
		color: colors.primary,
		background: 'none',
		border: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		padding: '0',
		'&:hover': { opacity: 0.8 },
	},
	emploSentTo: {
		margin: '3px 10px 10px 10px',
		color: colors.darkGrey,
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		minWidth: '120px',
		'&:hover': { opacity: 0.8 },
	},
	emploAfterSent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: '120px',
		height: '100%',
	},
	iconCheckFile: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '10px',
	},
	titleWrap: {
		flex: 1,
		margin: '10px 0',
		minWidth: '200px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	titleName: {
		color: colors.darkGrey,
		fontSize: '18px',
		fontWeight: 600,
		padding: '0 2px',
		fontFamily: 'RubikMedium',
	},
	titleTz: {
		color: colors.darkGrey,
		fontSize: '18px',
		fontWeight: 600,
		padding: '0 2px 0 8px',
		fontFamily: 'RubikMedium',
	},
	titleLastUpdate: {
		color: colors.darkGrey,
		fontSize: '14px',
		fontFamily: 'RubikRegular',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		paddingTop: '3px',
		height: '18px',
	},
	titleLastUpdateData: {
		paddingRight: '3px',
	},
	actions: {
		display:'flex',
		flexDirection: 'row',
	},
	cancelLastAction: {
		textAlign: 'center',
		width: '33%'
	},
	commentsWrap: {
		width: ({ isFullScreen, showLastsMonths, customerDraftData }: any) =>
			isFullScreen
				? showLastsMonths && customerDraftData?.paycheck.length > 1
					? 'calc(33.33% - 9px)'
					: 'calc(33.33% - 20px)'
				: showLastsMonths && customerDraftData?.paycheck.length > 1
				? 'calc(50% - 10px)'
				: 'calc(66.66% - 37px)',
		backgroundColor: colors.white,
		border: `1px solid ${colors.filterBorder}`,
		borderRadius: '5px',
		margin: '12px 5px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
	},
	commentsText: {
		width: ({ isFullScreen, showLastsMonths, customerDraftData }: any) =>
			isFullScreen
				? showLastsMonths && customerDraftData?.paycheck.length > 1
					? '44%'
					: '40%'
				: showLastsMonths && customerDraftData?.paycheck.length > 1
				? '33%'
				: '49%',
		height: 32,
		backgroundColor: colors.primary,
		color: colors.white,
		paddingRight: '10px',
		lineHeight: '32px',
	},
	commentsinput: {
		width: ({ isFullScreen, showLastsMonths, customerDraftData }: any) =>
			isFullScreen
				? showLastsMonths && customerDraftData?.paycheck.length > 1
					? '56%'
					: '60%'
				: showLastsMonths && customerDraftData?.paycheck.length > 1
				? '67%'
				: '51%',
		height: 32,
	},
	prevNextMonth: {
		display: 'flex',
		flexDirection: 'row',
		color: colors.primary,
		background: 'none',
		border: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		padding: 0,
		paddingTop: '10px',
		'&:hover': { opacity: 0.8 },
	},
	noResultsFound: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexDirection: 'column',
		fontFamily: 'RubikRegular',
		minWidth: 160,
		width: 'max-width',
		flex: 1,
		border: `1px solid ${colors.filterBorder}`,
		margin: '0 5px',
	},
	noResultsFoundHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		textAlign: 'center',
		fontFamily: 'RubikMedium',
		padding: '8px 0',
		fontSize: '14px',
		height: '22px',
		width: '100%',
		backgroundColor: colors.tableGrayHeaders,
		color: colors.white,
	},
	noResultsFoundHeaderSpan: {
		padding: '0 8px',
	},
	noResultsFoundBody: {
		color: colors.darkGrey,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		fontFamily: 'RubikMedium',
		padding: '30px 0 12px 0',
		fontSize: '18px',
		fontWeight: 600,
		width: '100%',
	},
}));

const FromMonthToMonthReportsSalaryDataFormByEmployee = ({
	isFullScreen,
	disableNext,
	selectedRowIndex,
	moveToPrevEmp,
	moveToNextEmp,
	disabled,
	data,
	onSendReport,
	setShowLastsMonths,
	showLastsMonths,
	customerWageComponents,
	customerDraftData,
	setCustomerDraftData,
	MonthYearFormat,
	setOnLineChange,
	getUndoAction,
	triggerUndo,
	userData,
	undoAction,
	undoIds,
	handleUndoIds,
	selectUnitId,
	isSmallDimensions,
	handleMakeUndoAction
}: FromMonthToMonthReportsPageProps) => {
	useEffect(() => {
		getCustomerDraftData(data.id, data.draftId);
	}, [data, triggerUndo]);

	useEffectNotInitial(() => {
		if (
			Object.keys(undoAction).length > 0 &&
			undoIds.includes(userData.id)
		) {
			getUndoAction();
		}
	}, [userData]);

	useEffect(() => {
		if (
			Object.keys(undoAction).length > 0 &&
			undoIds.includes(userData.id)
		) {
			getUndoAction();
		}
	}, [selectUnitId]);

	useEffect(() => {
		if (Object.keys(customerDraftData).length > 0) {
			setFlexTableData(DataFormat('getData', customerDraftData));
		}
	}, [customerDraftData]);

	const classes = useStyles({
		isFullScreen,
		showLastsMonths,
		customerDraftData,
		isSmallDimensions,
	});
	const [flexTableData, setFlexTableData] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [changesFields, setChangesFields] = useState({});
	const [limitDate, setLimitDate] = useState('');
	const [lastMonth, setLastMonth] = useState({});
	const firstDivRef = useRef();
	const secondDivRef = useRef();
	const errorLineRef = useRef<boolean>(false);
	const saveNewComponentIdsRef = useRef<any>();

	const handleScrollFirst = scroll => {
		secondDivRef.current.scrollTop = scroll.target.scrollTop;
	};

	const handleScrollSecond = scroll => {
		firstDivRef.current.scrollTop = scroll.target.scrollTop;
	};

	const dinamicSchema = yup.lazy((flexTableData): any => {
		const shapes = {};
		const DATA_OBJ_KEYS = Object.keys(flexTableData);

		DATA_OBJ_KEYS.forEach((parameter): any => {
			if (parameter === 'comments') {
				shapes[parameter] = yup
					.string()
					.nullable()
					.max(
						50,
						i18nService.translate(
							'FromMonthToMonthReports.yup.validations.form.limit.to.50',
						),
					);
			}
		});
		return yup.object().shape(shapes);
	});
	const DataFormat = (
		method: string,
		data: any,
		wage?: string,
		removeValue?: boolean,
	) => {
		return DataFormatTool(
			method,
			data,
			formMethods,
			flexTableData,
			setInitialValues,
			(customerDraftData as any).draft,
			wage,
			false,
			customerWageComponents,
			removeValue,
		);
	};

	const formMethods = useForm<FormInputs<number>>({
		defaultValues: initialValues,
		mode: 'onBlur',
		reValidateMode: 'onChange',
		resolver: yupResolver(dinamicSchema),
	});

	const UpdateTheFormState = async (
		setValue: any,
		getValues: any,
		c: any,
		d: any,
		e: any,
		f: any,
		lineName: string,
	) => {
		const data = getValues();

		// take the "code name" (lineName) of this field.
		const lineNameOnly = lineName.includes('_amount')
			? lineName.replace('_amount', '')
			: lineName.includes('_rate')
			? lineName.replace('_rate', '')
			: lineName.includes('_sum')
			? lineName.replace('_sum', '')
			: lineName;

		const dirty = formMethods.formState.dirtyFields;

		setChangesFields(dirty);
		Object.keys(data).forEach(key => {
			if (!data[key]) {
				delete data[key];
			}
		});
		// chack if amount and rate are set, and user try write something in the sum field, make sum value equal to 0 and return error.
		if (
			(lineName.includes('_amount') && data[lineName]) ||
			(lineName.includes('_rate') && data[lineName])
		) {
			if (
				Object.keys(data).includes(`${lineNameOnly}_sum`) &&
				Number(data[`${lineNameOnly}_sum`]) !== 0
			) {
				setValue(lineName, '');
				if (!errorLineRef.current) {
					errorLineRef.current = true;
					await removeItem(
						'FromMonthToMonthReports.validations.form.amount.rate.msg',
					);
				}
			}
			// chack if sum are set, and user try write something in amount or rate fields, make amout and rate values equal to '' and return error.
		} else if (lineName.includes('_sum') && data[lineName] !== 0) {
			if (
				(Object.keys(data).includes(`${lineNameOnly}_amount`) &&
					Number(data[`${lineNameOnly}_amount`]) !== 0) ||
				(Object.keys(data).includes(`${lineNameOnly}_rate`) &&
					Number(data[`${lineNameOnly}_rate`]) !== 0)
			) {
				setValue(lineName, '');
				if (!errorLineRef.current) {
					errorLineRef.current = true;
					await removeItem(
						'FromMonthToMonthReports.validations.form.sum.msg',
					);
				}
			}
		}
	};

	const removeItem = async (message: any) => {
		await modalService.openModal(
			null,
			{
				onSubmit: async (callback: Function) => {
					callback((errorLineRef.current = false));
				},
				submitBtnText: 'general.close',
				iconName: 'attention',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n style={{ textAlign: 'center' }} size={18}>
						{message}
					</I18n>
				</AlertModal>
			),
		);
	};

	const handleAutoSave = async () => {
		const newData = formMethods.getValues();

		Object.keys(newData).forEach(key => {
			if (!newData[key]) {
				delete newData[key];
			}
		});
		await formMethods.trigger(Object.keys(changesFields));

		if (Object.keys(newData).length === 0) {
			formMethods.clearErrors();
		} else {
			const clearData = formMethods.getValues();
			Object.keys(clearData).forEach(key => {
				if (
					(!key.includes('_amount') && !key.includes('_rate')) ||
					!Number(clearData[key])
				) {
					delete clearData[key];
				}
			});

			const clearErrors = Object.keys(clearData).map(element => {
				return element.includes('_amount')
					? element.replace('_amount', '')
					: element.includes('_rate')
					? element.replace('_rate', '')
					: element;
			});

			const clearTheErrors = clearErrors.filter(
				(a, i, aa) => aa.indexOf(a) === i && aa.lastIndexOf(a) !== i,
			);

			const theLineErrorToClean = clearTheErrors.map(line => {
				return [`${line}_amount`, `${line}_rate`];
			});

			const amountRateFields = Object.keys(changesFields)
				.map((item: any) => {
					if (item.includes('_amount') || item.includes('_rate')) {
						return item;
					}
				})
				.map((element: any) => {
					if (!Number(formMethods.getValues(element)) && element) {
						return element.includes('_amount')
							? element.replace('_amount', '')
							: element.includes('_rate')
							? element.replace('_rate', '')
							: element;
					}
				});

			// if is only one dirty but the other fields value its null, 0 or undefined then bring it too.
			const cleanNullValuesToo = amountRateFields.map(item => {
				if (
					amountRateFields.indexOf(item) ===
					amountRateFields.lastIndexOf(item)
				) {
					return !Number(formMethods.getValues(`${item}_amount`)) &&
						!Number(formMethods.getValues(`${item}_rate`))
						? item
						: undefined;
				}
			});
			cleanNullValuesToo.forEach((item: string, index: number) => {
				if (item === undefined) {
					delete cleanNullValuesToo[index];
				}
			});

			const clearTheErrorsOfNull = amountRateFields.filter(
				(a, i, aa) => aa.indexOf(a) === i && aa.lastIndexOf(a) !== i,
			);

			clearTheErrorsOfNull.forEach((item: string, index: number) => {
				if (!item) {
					delete clearTheErrorsOfNull[index];
				}
			});

			const clearTheErrorsOfNullList = clearTheErrorsOfNull.map(line => {
				return [`${line}_amount`, `${line}_rate`];
			});

			const cleanNullValuesTooList = cleanNullValuesToo.map(line => {
				return [`${line}_amount`, `${line}_rate`];
			});

			const errors = [
				...theLineErrorToClean,
				...clearTheErrorsOfNullList,
				...cleanNullValuesTooList,
			];
			formMethods.clearErrors(errors.flat());
		}

		let relevantData = {};
		let removeValue = false;
		const allData = formMethods.getValues();

		Object.entries(allData).forEach(([key, value]) => {
			Object.entries(initialValues).forEach(([ikey, ivalue]) => {
				if (
					ikey.toString() === key.toString() &&
					value != ivalue &&
					(value || ivalue)
				) {
					relevantData[key] = {
						[key]: value,
					};
					if (ivalue && !value) {
						removeValue = true;
					} else {
						removeValue = false;
					}
				}
			});
		});

		if (Object.keys(relevantData).length !== 0) {
			const sendData = DataFormat(
				'beforeSave',
				relevantData,
				undefined,
				removeValue,
			);
			relevantData = {};
			const readyData = Object.entries(sendData).map(entry => {
				return entry[1];
			});

			readyData.forEach((element: any, index: number) => {
				if (!element.componentId) {
					element.componentId = saveNewComponentIdsRef.current?.[
						element.componentType
					]?.find(
						(item: any) => item.code === element.code,
					)?.componentId;
				}
				saveCustomerDraftData(
					data?.id,
					data?.draftId || customerDraftData?.draft?.id,
					element,
				);
				setOnLineChange((prev: Array<number>) => [...prev, data.id]);
			});
		}
	};

	const checkBeforeSend = async () => {
		if (Object.keys(formMethods.formState.errors).length > 0) {
			await modalService.openModal(
				null,
				{
					onSubmit: async (callback: Function) => {
						callback((errorLineRef.current = false));
					},
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n style={{ textAlign: 'center' }} size={18}>
							{'FromMonthToMonthReports.sendReport.amountRate'}
						</I18n>
					</AlertModal>
				),
			);
		} else {
			const month = MonthYearFormat(
				customerDraftData?.draft?.yearMonth,
				0,
				'month',
			);
			const employee = data;
			employee.draftId = employee.draftId
				? employee.draftId
				: customerDraftData?.draft?.id;
			const optionalValues = Object.entries(initialValues)
				.filter(([key, value]) => key.includes('_optional') && value)
				.map(item => item[0]);
			onSendReport(month, employee, optionalValues);
		}
	};

	const handleLastMonthValuesCheck = (boxNumber: number = 0): boolean => {
		if (customerDraftData?.paycheck) {
			let resuls = false;
			resuls = customerDraftData?.paycheck[boxNumber].comments
				? true
				: false;
			resuls = customerDraftData?.paycheck[boxNumber].id ? true : false;
			resuls = customerDraftData?.paycheck[boxNumber].payworkdays
				? true
				: false;
			resuls = customerDraftData?.paycheck[boxNumber].sickLeaveUsed
				? true
				: false;
			resuls = customerDraftData?.paycheck[boxNumber].vacationUsed
				? true
				: false;
			resuls = customerDraftData?.paycheck[boxNumber].workdays
				? true
				: false;
			resuls = customerDraftData?.paycheck[boxNumber].workingHours
				? true
				: false;
			resuls =
				customerDraftData?.paycheck[boxNumber]
					.optionalDeductionsComponent.length > 0
					? true
					: false;
			resuls =
				customerDraftData?.paycheck[boxNumber].wageComponent.length > 0
					? true
					: false;
			return resuls;
		}
		return false;
	};

	const handleAddNewWage = (value: any, index: any) => {
		try {
			if (value) {
				const newLineObject = value?.map((line: any) => {
					return DataFormat('addNew', line, line.componentType);
				});

				const newData: any = [...flexTableData];

				newLineObject.forEach((element: any) => {
					const sec: any = Object.keys(element)[0];
					const newValue = Object.values(element)[0];
					newData[index][sec] = newValue;
				});
				setFlexTableData([...newData]);
				const saveNewLine = value.reduce((acc, line) => {
					return line.componentType === 'wageComponent'
						? {
								...acc,
								[`${line.code}_amount`]: '',
								[`${line.code}_rate`]: '',
								[`${line.code}_sum`]: '',
						  }
						: { ...acc, [`${line.code}_optional`]: '' };
				}, {});
				value?.map((line: any) => {
					return line.componentType === 'wageComponent'
						? {
								[`${line.code}_amount`]: '',
								[`${line.code}_rate`]: '',
								[`${line.code}_sum`]: '',
						  }
						: {
								[`${line.code}_optional`]: '',
						  };
				});
				
				setInitialValues({ ...initialValues, ...saveNewLine });
				setChangesFields({});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const componentTypes = {
		"wageComponent":1, 
		"optionalDeductionsComponent":2
	}	

	useEffect(() => {
		console.log({initialValues});
	},[initialValues])
	
	const removeWageLine = (value: any, index: any, componentType: string) => {
		const newData = [...flexTableData];
		delete newData[componentTypes[componentType]][value];
		console.log({newData});
		setFlexTableData(newData);
		const newInitialValues = {...initialValues}
		Object.keys(newInitialValues).forEach((key: any) => {
			if (key.includes(value) && newInitialValues.hasOwnProperty(key)) {
				console.log("includes", key, value)
				delete newInitialValues[key];	
			} 
		})
		setInitialValues(newInitialValues);
	}

	const getCustomerDraftData = (id: number, draftId: number | null) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: draftId
					? 'getCustomerDraftDataWithDraftId'
					: 'getCustomerDraftData',
				query: { unitId: selectUnitId?.[0] },
				params: {
					id,
					draftId,
				},
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					const draft: any = { ...res.draft };
					draft.wageComponent = orderResponseData(
						res.draft?.wageComponent,
						'wageComponent',
					);

					draft.optionalDeductionsComponent = orderResponseData(
						res.draft?.optionalDeductionsComponent,
						'optionalDeductionsComponent',
					);
					const paycheck: any = [...res.paycheck];
					paycheck[0].wageComponent = orderResponseData(
						res.paycheck[0]?.wageComponent,
						'wageComponent',
					);

					paycheck[0].optionalDeductionsComponent = orderResponseData(
						res.paycheck[0]?.optionalDeductionsComponent,
						'optionalDeductionsComponent',
					);

					const newRes = {
						...res,
						totalComponents: {
							wageComponent: [
								...draft.wageComponent,
								...paycheck[0].wageComponent,
							],
							optionalDeductionsComponent: [
								...draft.optionalDeductionsComponent,
								...paycheck[0].optionalDeductionsComponent,
							],
						},
						draft: draft,
						paycheck: paycheck,
					};
					data.draftId ? undefined : (data.draftId = res.draft.id);
					setCustomerDraftData(newRes);
					DataFormat('defaultValues', newRes);
					setShowLastsMonths(false);
					setLastMonth(newRes.paycheck[0]);
					setLimitDate(
						`${
							Number(res.paycheck[0].yearMonth.split('-')[0]) - 1
						}-${res.paycheck[0].yearMonth.split('-')[1]}`,
					);
				}
			});
	};

	const orderResponseData = (data: any, wageType: string) => {
		const counter: any = {};
		return data.reduce((obj: any, item: any) => {
			const find = obj.find((i: any) => i.code === item.code);
			counter[item.code] = find ? counter[item.code] + 1 : 0;
			const _d = find
				? {
						newCode: `${item.code}-${counter[item.code]}`,
						...item,
						name: getCustomerWageComponentsName(
							item.code,
							item.name,
							customerWageComponents,
							wageType,
						),
				  }
				: {
						...item,
						name: getCustomerWageComponentsName(
							item.code,
							item.name,
							customerWageComponents,
							wageType,
						),
				  };

			obj.push(_d);
			return obj;
		}, []);
	};

	const getPreviousMonthsList = (id: number) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'getPreviousMonthsList',
				query: { unitId: selectUnitId?.[0] },
				params: {
					id,
				},
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res) {
					// get the last 12 months of the total componenets and merge them into the last and current month componenets (newTotal function).
					// the data we send to the function its first the server response (last months) and then the current state (current month)
					// because its newer.
					// in the newTotal function we also check (in the getCustomerWageComponentsName function) if the code exists
					// in the customerWageComponents state and if so we take the componenets name from this list, if not, the name remain as is.
					// get the last 12 months paycheck list and sort it by year-month.
					// set all this DATA to the flexTableData state.
					// update the initial state of the form.

					// const newTotalState = {
					// 	optionalDeductionsComponent: newTotal(
					// 		data.optionalDeductionsComponent,
					// 		'optionalDeductionsComponent',
					// 	),
					// 	wageComponent: newTotal(
					// 		data.wageComponent,
					// 		'wageComponent',
					// 	),
					// };

					const newRes = checkAndReturnCorrrctData(
						res.paycheck
							.sort(
								(a: any, b: any) =>
									(new Date(b.yearMonth) as any) -
									(new Date(a.yearMonth) as any),
							)
							.slice(0, 13),
					);
					const finalRes = newRes.map((item: any) => {
						return {
							...item,
							optionalDeductionsComponent: orderResponseData(
								item.optionalDeductionsComponent,
								'optionalDeductionsComponent',
							),
							wageComponent: orderResponseData(
								item.wageComponent,
								'wageComponent',
							),
						};
					});

					const newTotaldata = {
						optionalDeductionsComponent: [
							...finalRes
								.map(
									(item: any) =>
										item.optionalDeductionsComponent,
								)
								.flat(),
							...(customerDraftData as any).totalComponents
								.optionalDeductionsComponent,
						],
						wageComponent: [
							...finalRes
								.map((item: any) => item.wageComponent)
								.flat(),
							...(customerDraftData as any).totalComponents
								.wageComponent,
						],
					};

					setCustomerDraftData({
						...customerDraftData,
						totalComponents: newTotaldata,
						paycheck: finalRes,
					});
					setShowLastsMonths(true);
					const formValues = formMethods.getValues();
					setInitialValues({ ...initialValues, ...formValues });
				}
			});
	};

	const checkAndReturnCorrrctData = (res: any) => {
		// if its not a last paychecs at all, just return the last paychecs from the first API call.
		if (!res || res.length === 0) {
			return [lastMonth];
		}

		// chack the dates of the months, if its over 1 year from last month, remove those paychecks from the list.
		// and make Deep check one by one if really have paychecks, if not, remove from the list too.
		const data = res.filter(
			(month: any) =>
				new Date(month.yearMonth) >= new Date(limitDate) &&
				handleValuesCheck(month),
		);

		// after that, if all not have paycheck, return the last month only.
		if (!data || data.length === 0) {
			return [lastMonth];
		}

		// But, if some of theme have paycheck, take the last one and make moths from this month untill now.
		const lastOne = data[data.length - 1];
		const dateStart = moment(lastOne.yearMonth);
		const dateEnd = moment(lastMonth.yearMonth);
		const timeValues = [];

		// Pull all together in one list and overwrite the demo ones in exsist in the original list
		while (
			dateEnd > dateStart ||
			dateStart.format('M') === dateEnd.format('M')
		) {
			const originalValue = data.find(
				(value: any) => value.yearMonth === dateStart.format('YYYY-MM'),
			);
			if (originalValue) {
				timeValues.push(originalValue);
			} else {
				timeValues.push({
					yearMonth: dateStart.format('YYYY-MM'),
					wageComponent: [],
					optionalDeductionsComponent: [],
				});
			}
			dateStart.add(1, 'month');
		}

		return timeValues.sort(
			(a: any, b: any) =>
				(new Date(b.yearMonth) as any) - (new Date(a.yearMonth) as any),
		);
	};
	const handleValuesCheck = (item: any): boolean => {
		if (item) {
			let resuls = false;
			resuls = item.comments ? true : false;
			resuls = item.id ? true : false;
			resuls = item.payworkdays ? true : false;
			resuls = item.sickLeaveUsed ? true : false;
			resuls = item.vacationUsed ? true : false;
			resuls = item.workdays ? true : false;
			resuls = item.workingHours ? true : false;
			resuls = item.optionalDeductionsComponent.length > 0 ? true : false;
			resuls = item.wageComponent.length > 0 ? true : false;
			return resuls;
		}
		return false;
	};

	const getCustomerWageComponentsName = (
		code: any,
		name: any,
		customerWageComponents: any,
		wageType: string,
	) => {
		const result: any = Object.values(
			customerWageComponents[wageType],
		).find((item: any) => item.code === code);

		return result?.name || name;
	};

	const previousMonthsList = () => {
		getPreviousMonthsList(data?.id);
	};

	const previousMonthsListHide = () => {
		getCustomerDraftData(data.id, data.draftId);
	};

	const saveCustomerDraftData = (id: number, draftId: number, data: any) => {
		httpService
			.api<fromMonthToMonthListLike>({
				type: 'saveCustomerDraftData',
				query: { unitId: selectUnitId?.[0] },
				params: {
					id,
					draftId,
				},
				returnAllRes: true,
				data,
				disableBI: true,
			})
			.then(async (res: any) => {
				if (res.status === 200) {
					const formValues = formMethods.getValues();
					setInitialValues({ ...initialValues, ...formValues });
					setChangesFields({});
					getUndoAction();
					handleUndoIds();
					if (res.data && !data.componentId) {
						saveNewComponentIdsRef.current = {
							...saveNewComponentIdsRef.current,
							[data.componentType]: saveNewComponentIdsRef
								.current?.[data.componentType]?.length
								? [
										...saveNewComponentIdsRef.current[
											data.componentType
										],
										{
											...data,
											componentId: res.data,
										},
								  ]
								: [
										{
											...data,
											componentId: res.data,
										},
								  ],
						};
					}
				}
			});
	};

	const handleAddButton = async (name: string) => {
		handleAddButtonModal(
			name,
			customerWageComponents,
			flexTableData,
			handleAddNewWage,
		);
	};

	return (
		<div className={classes.wrapper}>
			<FormProvider {...formMethods}>
				<div className={classes.headerWrap}>
					<div className={classes.header}>
						<div className={classes.emploBtnsWrap}>
							{selectedRowIndex ? (
								<button
									className={classes.btnsEmplo}
									onClick={() => moveToPrevEmp()}
								>
									<ArrowBackIos
										style={{
											transform: 'rotate(180deg)',
											height: 14,
											marginLeft: -6,
										}}
										color='inherit'
									/>
									<I18n
										color={'primary'}
										weight='normal'
										size={14}
									>
										FromMonthToMonthReports.DataFormByEmployee.prevEmp
									</I18n>
								</button>
							) : null}
							{!disableNext && (
								<button
									className={classes.btnsEmplo}
									onClick={() => moveToNextEmp()}
								>
									<I18n
										color={'primary'}
										weight='normal'
										size={14}
									>
										FromMonthToMonthReports.DataFormByEmployee.nextEmp
									</I18n>
									<ArrowBackIos
										color='inherit'
										style={{
											height: 14,
											marginRight: -6,
										}}
									/>
								</button>
							)}
						</div>
						<div className={classes.titleWrap}>
							<span className={classes.titleName}>
								{data.fullName}
							</span>
							<span className={classes.titleTz}>
								{data.teudatZeut}
							</span>
							<span className={classes.titleLastUpdate}>
								<I18n color={'darkGrey'} weight='400' size={14}>
									FromMonthToMonthReports.DataFormByEmployee.lastUpdate
								</I18n>
								<span className={classes.titleLastUpdateData}>
									{data.lastUpdate}
								</span>
							</span>
						</div>
						<div>
							{!disabled && data?.status !== 'APPROVED' && (
								<button
									className={classes.emploSentTo}
									onClick={() => checkBeforeSend()}
								>
									<div className={classes.iconCheckFile}>
										<ImageIcon
											width={16}
											name={'checkFile'}
										/>
									</div>
									<div>
										<I18n
											color={'darkGrey'}
											weight='normal'
											size={12}
										>
											FromMonthToMonthReports.DataFormByEmployee.reportView.action
										</I18n>
									</div>
								</button>
							)}
							{data?.status === 'APPROVED' && (
								<div className={classes.emploAfterSent}>
									<I18n
										color={'green'}
										weight='normal'
										size={12}
									>
										FromMonthToMonthReportsReportPage.status.APPROVED
									</I18n>
								</div>
							)}
						</div>
					</div>
					<div className={classes.actions}>
						<div className={classes.commentsWrap}>
							<div className={classes.commentsText}>
								<I18n color={'white'} weight='normal' size={14}>
									FromMonthToMonthReports.formats.AdditionalData.comments.placeholder
								</I18n>
							</div>
							<div className={classes.commentsinput}>
								<FieldBuilder
									styles={{
										wrapper: {
											width: '100%',
											height: '100%',
											border: 'none',
											fontSize: '14px',
											margin: 0,
											padding: 13,
											paddingVertical: 0,
										},
										labelWrapper: {
											height: 0,
											marginTop: 0,
											marginRight: 0,
											marginLeft: 0,
											marginBottom: 0,
											padding: 0,
										},
										errorCustomStyle: {
											backgroundColor: colors.white,
											position: 'relative',
											maxWidth: 900,
											zIndex: 999,
											whiteSpace: 'nowrap',
											top: -3,
										},
									}}
									backgroundColor={colors.white}
									height={'100%'}
									padding={0}
									disabled={disabled}
									key={'comments'}
									name={'comments'}
									type='input'
									onBlur={handleAutoSave}
									onChange={UpdateTheFormState}
									maxLength={50}
								/>
							</div>
						</div>
						<div className={classes.cancelLastAction}>
							<button
								style={{
									height: '100%',
									marginBottom: 0,
									marginLeft: 12,
									opacity:
										Object.keys(undoAction).length !== 0 &&
										undoIds.includes(userData.id)
											? 1
											: 0.5,
									background: 'none',
									border: 'none',
									cursor:
										Object.keys(undoAction).length !== 0 &&
										undoIds.includes(userData.id)
											? 'pointer'
											: 'auto',
									padding: 0,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								onClick={() =>
									Object.keys(undoAction).length !== 0 &&
									undoIds.includes(userData.id)
										? handleMakeUndoAction()
										: undefined
								}
							>
								<I18n
									size={14}
									weight='normal'
									style={{
										lineHeight: 20,
										paddingHorizontal: 8,
									}}
								>
									FromMonthToMonthReportsReportPage.undoAction.undoBtn
								</I18n>
								<ImageIcon
									name='undo'
									height={16.3}
									width={20}
								/>
							</button>
						</div>

					</div>

				</div>
				<div className={classes.body}>
					<div
						className={classes.tableCurrentWrraper}
						onScroll={handleScrollFirst}
						ref={firstDivRef}
					>
						<div className={classes.tableRowHeaders}>
							{flexTableData &&
								flexTableData.map((item, index) => (
									<FlexTable
										key={`FlexTable-${index}`}
										onRemove={removeWageLine}
										type={'box'}
										index={indexName(index)}
										data={flexTableData[index]}
										tableName={'header'}
										disabled={disabled || false}
										onBlur={handleAutoSave}
										onChange={UpdateTheFormState}
										onPreesAdd={handleAddButton}
										userId={data.id}
									/>
								))}
						</div>
						<div className={classes.tableRowCurrent}>
							{flexTableData &&
								flexTableData.map((item, index) => (
									<FlexTable
										key={`FlexTable-${index}`}
										type={'box'}
										onRemove={removeWageLine}
										index={indexName(index)}
										data={flexTableData[index]}
										tableName={'currentMonth'}
										disabled={disabled || false}
										onBlur={handleAutoSave}
										onChange={UpdateTheFormState}
										onPreesAdd={handleAddButton}
										maxLength={10}
										keyboardType={'numeric'}
										toFixed={2}
										forceMin={true}
									/>
								))}
						</div>
					</div>
					<div
						className={classes.tableListWrraper}
						onScroll={handleScrollSecond}
						ref={secondDivRef}
					>
						{customerDraftData?.paycheck?.map(
							(table: any, boxNumber: number) =>
								handleLastMonthValuesCheck(boxNumber) &&
								flexTableData ? (
									<div className={classes.tableRow}>
										{flexTableData.map((item, index) => (
											<FlexTable
												key={`FlexTable-${index}`}
												type={'box'}
												boxNumber={boxNumber}
												index={indexName(index)}
												data={flexTableData[index]}
												onRemove={removeWageLine}
												tableName={'lastMonth'}
												disabled={true}
												headerBackgroundColor={
													colors.tableGrayHeaders
												}
												onBlur={handleAutoSave}
												onChange={UpdateTheFormState}
												onPreesAdd={handleAddButton}
												maxLength={10}
												userId={data.id}
											/>
										))}
									</div>
								) : (
									customerDraftData?.paycheck && (
										<div className={classes.noResultsFound}>
											<div
												className={
													classes.noResultsFoundHeader
												}
											>
												<span
													className={
														classes.noResultsFoundHeaderSpan
													}
												>
													{MonthYearFormat(
														customerDraftData
															?.paycheck[
															boxNumber
														].yearMonth,
													)}
												</span>
											</div>
											<div
												className={
													classes.noResultsFoundBody
												}
											>
												{i18nService.translate(
													'FromMonthToMonthReports.flexTable.noResultsFound',
												)}
											</div>
										</div>
									)
								),
						)}
					</div>
					<div className={classes.arrowContainer}>
						{!showLastsMonths ? (
							<button
								className={classes.prevNextMonth}
								onClick={previousMonthsList}
							>
								<ArrowBackIos
									style={{
										color: colors.primary,
										height: 14,
										marginLeft: -6,
										marginRight: -10,
									}}
								/>
								<ArrowBackIos
									style={{
										color: colors.primary,
										height: 14,
										marginRight: -10,
										marginLeft: -2,
									}}
								/>
							</button>
						) : (
							<button
								className={classes.prevNextMonth}
								onClick={previousMonthsListHide}
							>
								<ArrowBackIos
									style={{
										transform: 'rotate(180deg)',
										color: colors.primary,
										height: 14,
										marginLeft: -10,
										marginRight: -2,
									}}
								/>
								<ArrowBackIos
									style={{
										transform: 'rotate(180deg)',
										color: colors.primary,
										height: 14,
										marginRight: -6,
										marginLeft: -10,
									}}
								/>
							</button>
						)}
					</div>
				</div>
			</FormProvider>
		</div>
	);
};

export default FromMonthToMonthReportsSalaryDataFormByEmployee;
