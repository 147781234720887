import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { logout, resetPassword } from '../../store/actions/login.actions';
import ResetPassword from './ResetPassword.component';
import { verifyForgetPassword } from '../../store/actions/login.actions';
import routes from '../../config/routes';
import {
	getResetPasswordError,
	getResetPasswordErrorMessage,
} from '../../store/selectors/login.selectors';
import { StackActions } from '@react-navigation/native';
import { getIsMaintenance } from '../../store/selectors/config.selectors';
const ResetPasswordContainer = ({ navigation }: { navigation: any }) => {
	const dispatch = useDispatch();

	const [isResetPassword, setResetPasswordFlag] = useState(false);

	const [newPassword, setNewPassword] = useState('');
	const [shouldShowNewPassword, toggleShowNewPassword] = useState(false);
	const [repeatPassword, setRepeatPassword] = useState('');
	const [shouldShowRepeatPassword, toggleShowRepeatPassword] =
		useState(false);
	const [captchaVerified, setCaptchaVerified] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState(false);
	const recaptchaRef = useRef();
	const resetPasswordError = useSelector(getResetPasswordError);
	const errorMessage = useSelector(getResetPasswordErrorMessage);
	const isMaintenance = useSelector(getIsMaintenance);

	useEffect(() => {
		isMaintenance && navigation.navigate('signIn');
	}, [isMaintenance]);

	useEffect(() => {
		Linking.getInitialURL().then(url => {
			navigateHandler(url);
		});
	}, []);

	const navigateHandler = (url: any) => {
		let key = new URL(url).searchParams.get('key') || '';
		dispatch(verifyForgetPassword({ key, navigation }));
	};

	const resetPasswordHandler = async () => {
		setSubmitted(true);
		if (Platform.OS !== 'web' || captchaVerified) {
			try {
				await dispatch(
					resetPassword(
						{
							password: newPassword,
							password2: repeatPassword,
						},
						setResetPasswordFlag,
					),
				);
			} catch {
				console.log('something went wrong');
			}
		}
	};

	const onCaptchaChange = (value: any) => {
		setCaptchaVerified(true);
	};

	const onExpiredCaptcha = () => {
		setCaptchaVerified(false);
	};
	const onBackToLogin = async () => {
		navigation.dispatch(StackActions.replace(routes.signIn.name));
		await dispatch(logout());
	};
	return (
		<ResetPassword
			isPasswordGotReset={isResetPassword}
			backToLogin={onBackToLogin}
			errorMessage={errorMessage || ''}
			resetPasswordError={resetPasswordError}
			navigation={navigation}
			newPassword={newPassword}
			repeatPassword={repeatPassword}
			shouldShowNewPassword={shouldShowNewPassword}
			shouldShowRepeatPassword={shouldShowRepeatPassword}
			onSetNewPassword={(password: string) => setNewPassword(password)}
			onSetRepeatPassword={(repeatPassword: string) =>
				setRepeatPassword(repeatPassword)
			}
			onToggleShowNewPassword={() =>
				toggleShowNewPassword(!shouldShowNewPassword)
			}
			onToggleShowRepeatPassword={() =>
				toggleShowRepeatPassword(!shouldShowRepeatPassword)
			}
			onResetPassword={() => resetPasswordHandler()}
			onCaptchaChange={onCaptchaChange}
			onExpiredCaptcha={onExpiredCaptcha}
			captchaVerified={captchaVerified}
			submitted={submitted}
			ref={recaptchaRef}
		/>
	);
};

export default ResetPasswordContainer;
