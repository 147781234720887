import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import ButtonsGroupe from '../../components/shared/FieldBuilder/ButtonsGroup/ButtonsGroup.component';
import Multiselect from '../../components/shared/FieldBuilder/MultiSelect/Multiselect.container';
import Select from '../../components/shared/FieldBuilder/Select/ReactSelect.component';
import I18n from '../../components/shared/i18n/I18n.component';
import httpService from '../../services/http.service';
import { i18nService } from '../../services/i18n.service';
import { localUpdateUserData } from '../../store/actions/login.actions';
import { getBusinessUnitsConfig } from '../../store/selectors/login.selectors';
import { getBusinessUnitsList } from '../../store/selectors/reportsFilters.selectors';
import { 
	EventName,
	 logEvent 
} from '../../services/analyticsAndLogs.service';

const BusinessUnitsInfo = ({ isMobileWeb }: any) => {
	const { allowMultipleSelection, defaultUnitIds, leadingUnitId } =
		useSelector(getBusinessUnitsConfig);

	const businessUnitsList = useSelector(getBusinessUnitsList);
	const [showError, setShowError] = useState(false);
	const [selectUnits, setSelectUnits] = useState(defaultUnitIds);
	const dispatch = useDispatch();
	const width = isMobileWeb ? 90 : 200;

	const onChangeBusinessUnits = ({ value, isAllSelected }: any) => {
		if (value.length || isAllSelected) {
			setShowError(false);
			setData(
				'defaultUnitIds',
				isAllSelected
					? businessUnitsList.map((val: any) => val.id)
					: value.map((val: any) => val.id),
			);
			setSelectUnits(
				isAllSelected
					? businessUnitsList.map((val: any) => val.id)
					: value.map((val: any) => val.id),
			);
			logEvent({
				eventName: EventName.personalInformationChange,
				data: {change: 'ChangeBusinessUnits'},
				logDataToAnalytics: true,
			});
		} else {
			setSelectUnits([]);
			setShowError(true);
		}
	};

	useEffect(() => {
		if (allowMultipleSelection === undefined && businessUnitsList.length) {
			setEnableMultiUnits(false);
		}
	}, [allowMultipleSelection, businessUnitsList]);

	const setData = async (key: string, value: any, extra = {}) => {
		const data = { [key]: value, ...extra };
		const res = await httpService.api({
			type: 'updateHpSettings',
			data: {
				allowMultipleSelection,
				defaultUnitIds,
				leadingUnitId,
				...data,
			},
		});

		dispatch(localUpdateUserData(data));
	};

	const setEnableMultiUnits = (val: boolean) => {
		setData('allowMultipleSelection', val, {
			defaultUnitIds: [businessUnitsList[0]?.id],
			leadingUnitId: val ? businessUnitsList[0]?.id : undefined,
		});
		logEvent({
			eventName: EventName.personalInformationChange,
			data: {change: 'allowMultipleSelection'},
			logDataToAnalytics: true,
		});
	};

	return (
		<View style={{ flexDirection: 'column', zIndex: 1 }}>
			<View
				style={{
					flexDirection: 'column',
					alignItems: isMobileWeb ? 'flex-start' : 'flex-end',
					marginVertical: 10,
					width: '100%',
				}}
			>
				<View
					style={{
						flexDirection: isMobileWeb ? 'column' : 'row-reverse',
						alignItems: 'center',
						justifyContent: 'flex-start',
						width: isMobileWeb ? '100%' : 450,
					}}
				>
					<I18n
						size={14}
						weight={isMobileWeb ? 'bold' : 'normal'}
						numberOfLines={isMobileWeb ? 2 : 1}
						style={{
							lineHeight: 20,
							width: isMobileWeb ? '100%' : width * 2.5,
							textAlign: isMobileWeb ? 'center' : 'right',
							paddingBottom: isMobileWeb ? 10 : undefined,
						}}
					>
						personalInformation.multiSelectBusinessUnits
					</I18n>
					<View
						style={{
							marginTop: isMobileWeb ? 5 : 0,
							marginBottom: isMobileWeb ? 10 : 0,
							width: isMobileWeb ? '100%' : undefined,
						}}
					>
						<ButtonsGroupe
							options={[
								{
									value: true,
									label: i18nService.translate(
										'general.true',
									),
								},
								{
									value: false,
									label: i18nService.translate(
										'general.false',
									),
								},
							]}
							onChange={setEnableMultiUnits}
							value={allowMultipleSelection}
						/>
					</View>
				</View>
				<View
					style={{
						flexDirection: isMobileWeb ? 'column' : 'row-reverse',
						alignItems: 'center',
						justifyContent: 'flex-start',
						width: isMobileWeb ? '100%' : 600,
						paddingVertical: 20,
						zIndex: 10,
					}}
				>
					<I18n
						size={14}
						weight={isMobileWeb ? 'bold' : 'normal'}
						numberOfLines={isMobileWeb ? 2 : 1}
						style={{
							lineHeight: 20,
							width: isMobileWeb
								? '100%'
								: allowMultipleSelection
								? 272
								: 500,
							textAlign: isMobileWeb ? 'center' : 'right',
							paddingBottom: isMobileWeb ? 10 : undefined,
						}}
					>
						{`personalInformation.${
							allowMultipleSelection
								? 'defaultBusinessUnits'
								: 'defaultBusinessUnit'
						}`}
					</I18n>
					{allowMultipleSelection ? (
						<View
							style={{
								flex: 1,
								width: isMobileWeb ? '100%' : 'unset',
								zIndex: 12,
								position: 'relative',
							}}
						>
							<Multiselect
								config={{
									options: businessUnitsList,
									name: '',
									value: selectUnits?.map((unit: any) =>
										businessUnitsList.find(
											bunit => bunit.id === unit,
										),
									),
									returnAll: true,
								}}
								min={1}
								onChange={onChangeBusinessUnits}
							/>
							{showError && (
								<I18n
									size={14}
									weight={isMobileWeb ? 'bold' : 'normal'}
									color='red'
								>
									personalInformation.defaultBusinessUnitsError
								</I18n>
							)}
						</View>
					) : (
						<Select
							options={businessUnitsList}
							onChange={(value: any) =>
								setData('defaultUnitIds', [value])
							}
							value={defaultUnitIds?.[0]}
						/>
					)}
				</View>
				{allowMultipleSelection ? (
					<View
						style={{
							flexDirection: isMobileWeb
								? 'column'
								: 'row-reverse',
							alignItems: 'center',
							justifyContent: 'flex-start',
							width: isMobileWeb ? '100%' : 600,
							paddingBottom: 20,
						}}
					>
						<I18n
							size={14}
							weight={isMobileWeb ? 'bold' : 'normal'}
							numberOfLines={isMobileWeb ? 2 : 1}
							style={{
								lineHeight: 20,
								width: isMobileWeb ? '100%' : width * 2.5,
								textAlign: isMobileWeb ? 'center' : 'right',
								paddingBottom: isMobileWeb ? 10 : undefined,
							}}
						>
							personalInformation.leadBusinessUnit
						</I18n>

						<Select
							options={businessUnitsList}
							onChange={(value: any) => {
									setData('leadingUnitId', value)
									logEvent({
										eventName: EventName.personalInformationChange,
										data: {change: 'leadingUnitId'},
										logDataToAnalytics: true,
									});
								}
							}
							value={leadingUnitId}
						/>
					</View>
				) : null}
			</View>
		</View>
	);
};

export default BusinessUnitsInfo;
