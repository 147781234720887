import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import store from '../../../store/store';
import { taxCreditReasonVaidation } from './fields/taxCreditReasonFields';

export const idValidation = function (item?: any) {
	let id = item;
	id = String(id).trim();
	if (!item || item === '') {
		return true;
	}
	if (
		!_.compact([
			'0',
			'2',
			'7',
			'3',
			this.path === 'spouse.idNumber' ? '9' : undefined,
		]).includes(id[0]) ||
		id.length !== 9 ||
		isNaN(Number(id))
	) {
		return false;
	}
	id = id.length < 9 ? ('00000000' + id).slice(-9) : id;
	return (
		Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) %
			10 ===
		0
	);
};

const idSpouseDuplication = function (item?: string) {
	let id = item;
	id = String(id).trim();
	const dataValue = this?.from[1].value;

	if (
		item &&
		item !== '' &&
		(dataValue.employee?.idNumber === id ||
			dataValue.children?.filter((child: any) => child.idNumber === item)
				?.length)
	) {
		return false;
	}
	return true;
};

const idChildDuplication = function (item?: string) {
	let id = item;
	id = String(id).trim();
	const dataValue = this?.from[1].value;

	if (
		item &&
		item !== '' &&
		(dataValue.employee?.idNumber === id ||
			dataValue.spouse?.idNumber === id ||
			dataValue.children.filter((child: any) => child.idNumber === item)
				?.length > 1)
	) {
		return false;
	}
	return true;
};

const isRequired = function (value: any) {
	if (
		this.from[this.from.length - 1].value?.employee?.maritalStatus ===
		'MARRIED'
	) {
		if (!value || !value.length || value === '') {
			return false;
		}
	}
	return true;
};

const validateIncome = function (value: any) {
	if (
		this.from[0].value?.additionalIncomeType?.length ||
		(this.from[0].value?.incomeType &&
			this.from[0].value?.incomeType !== '')
	) {
		return true;
	}
	return false;
};

export const alphaNumeriRegex = /^[\u0590-\u05FF 0-9a-zA-Z]+$/i;
export const numberOnlyRegex = /^[0-9]*$/g;
export const englishName = /^[A-Z\-' ]*$/;
export const nonEmptyString = /^$/;

export const validationSchema = () => {
	const settingsData = store.getState().login.user;

	return yup.object().shape({
		employer: yup.object({
			name: yup.string(),
			address: yup.string(),
			phone: yup.string(),
			deductionFileId: yup.string(),
		}),
		employee: yup.object({
			idNumber: yup.string(),
			passport: yup.string(),
			idFile: !settingsData?.customer_setting?.requestEmployeeIdFile
				? yup.array()
				: yup.array().of(
						yup.object().shape({
							extraData: yup
								.object()
								.nullable()
								.test(
									'idTest',
									'general.validation.required',
									function (value) {
										return (
											this.path !==
												'employee.idFile[0].extraData' ||
											this?.from[0]?.value
										);
									},
								),
						}),
				  ),
			firstName: yup
				.string()
				.when('israeliResident', {
					is: false,
					then: yup
						.string()
						.matches(
							englishName,
							'workerCard.editor.employee.firstName.error',
						),
					otherwise: yup.string(),
				})
				// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
				.required('general.validation.required')
				.nullable(),
			lastName: yup
				.string()
				.when('israeliResident', {
					is: false,
					then: yup
						.string()
						.matches(
							englishName,
							'workerCard.editor.employee.lastName.error',
						),
					otherwise: yup.string(),
				})
				// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
				.required('general.validation.required'),
			birthDate: yup
				.string()
				.test(
					'isYounger',
					'general.validation.tooYoung',
					function (value?: string) {
						if (
							value &&
							moment(value, 'DD/MM/YYYY').isSameOrAfter(
								moment().subtract(14, 'years'),
							)
						) {
							return false;
						}
						return true;
					},
				)
				.required('general.validation.required')
				.nullable(),
			immigrationDate: yup.string().when('israeliResident', {
				is: false,
				then: yup
					.string()
					.matches(
						nonEmptyString,
						'workerCard.editor.employee.immigrationDate.error',
					),
				// .test(
				// 	"",
				// 	"workerCard.editor.employee.immigrationDate.error",
				// 	function (value?: string) {
				// 		return value !== ""
				// 	}
				// ),
				//.equals([""], 'workerCard.editor.employee.immigrationDate.error'),
				otherwise: yup.string(),
			}),
			email: yup
				.string()
				.matches(
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
					'general.validation.invalidValue',
				)
				.nullable(),
			address: yup.object({
				street: yup
					.string()
					// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
					.required('general.validation.required')
					.nullable(),
				houseNumber: yup
					.string()
					.max(5, 'general.validation.onlyFiveChars')
					// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
					.required('general.validation.required')
					.nullable(),
				city: yup
					.string()
					// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
					.required('general.validation.required')
					.nullable(),
				postalCode: yup
					.string()
					.matches(numberOnlyRegex, 'general.validation.invalidValue')
					.nullable(),
			}),
			phone: yup
				.string()
				.matches(numberOnlyRegex, 'general.validation.invalidValue')
				.min(9, 'general.validation.invalidValue')
				.nullable(),
			cellPhone: yup
				.string()
				.matches(numberOnlyRegex, 'general.validation.invalidValue')
				.nullable(),
			cellPrefix: yup
				.string()
				.matches(numberOnlyRegex, 'general.validation.invalidValue')
				.nullable(),
			gender: yup
				.string()
				.required('general.validation.required')
				.nullable(),
			maritalStatus: yup
				.string()
				.required('general.validation.required')
				.nullable(),
			genderFile: yup.array().when('maritalStatus', {
				is: 'SEPARATED',
				then: yup.array().of(
					yup.object().shape({
						extraData: yup
							.object()
							.nullable()
							.test(
								'testName',
								'general.validation.required',
								function (value) {
									return (
										this.path !==
											`employee.genderFile[0].extraData` ||
										this?.from[0]?.value
									);
								},
							),
					}),
				),
			}),
			israeliResident: yup
				.boolean()
				.required('general.validation.required')
				.nullable(),
			kibbutzMember: yup
				.boolean()
				.required('general.validation.required')
				.nullable(),
			revenuesTransferredToKibbutz: yup
				.boolean()
				.when('kibbutzMember', {
					is: true,
					then: yup.boolean().required('general.validation.required'),
					otherwise: yup.bool(),
				})
				.nullable(),
			healthOrgMember: yup
				.boolean()
				.required('general.validation.required')
				.nullable(),
			healthOrgName: yup
				.string()
				.when('healthOrgMember', {
					is: true,
					then: yup.string().required('general.validation.required'),
					otherwise: yup.string(),
				})
				.nullable(),
			bankId: yup
				.string()
				.matches(numberOnlyRegex, 'general.validation.invalidValue')
				.transform(value => (value === '' ? undefined : value))
				.nullable()
				.max(2, 'general.validation.twoDigits'),
			branchId: yup
				.string()
				.matches(numberOnlyRegex, 'general.validation.invalidValue')
				.transform(value => (value === '' ? undefined : value))
				.nullable()
				.max(3, 'general.validation.threeDigits'),
			accountNumber: yup
				.string()
				.matches(numberOnlyRegex, 'general.validation.invalidValue')
				.transform(value => (value === '' ? undefined : value))
				.nullable()
				.max(10, 'general.validation.tenDigits'),
		}),
		hasChildren: yup
			.boolean()
			.required('general.validation.required')
			.nullable(),
		children: yup.array().when('hasChildren', {
			is: true,
			then: yup
				.array()
				.of(
					yup.object().shape({
						name: yup
							.string()
							// .matches(
							// 	/^[\u0590-\u05FF 0-9]+$/i,
							// 	'general.validation.invalidValue',
							// )
							.required('general.validation.required'),
						birthDate: yup
							.string()
							.required('general.validation.required'),
						idNumber: yup
							.string()
							.test(
								'idTest',
								'general.validation.invalidValue',
								idValidation,
							)
							.test(
								'idSame',
								'general.validation.idAlreadyExists',
								idChildDuplication,
							)
							.required('general.validation.required'),
						myCustody: yup
							.boolean()
							.nullable()
							.required('general.validation.required'),
						childAllowance: yup
							.boolean()
							.nullable()
							.required('general.validation.required'),
						singleParent: yup
							.boolean()
							.nullable()
							.required('general.validation.required'),
					}),
				)
				.min(1)
				.max(13),
		}),
		currentEmployerIncome: yup.object({
			startDate: yup.string().required('general.validation.required'),
			additionalIncomeType: yup
				.array(yup.string())
				.test(
					'additionalIncomeTypeTest',
					'general.validation.required',
					validateIncome,
				),
			incomeType: yup
				.string()
				.test(
					'incomeTypeTest',
					'general.validation.required',
					validateIncome,
				)
				.nullable(),
		}),
		otherEmployerIncome: yup.object({
			otherEmployerIncome: yup
				.boolean()
				.required('general.validation.required'),
			incomeType: yup.array(yup.string()).when('otherEmployerIncome', {
				is: true,
				then: yup
					.array(yup.string())
					.min(1, 'general.validation.required')
					.required('general.validation.required'),
				otherwise: yup.array(yup.string()),
			}),
			taxCreditRequest: yup.boolean().when('otherEmployerIncome', {
				is: true,
				then: yup.boolean().required('general.validation.required'),
				otherwise: yup.boolean(),
			}),
			noKerenHishtalmutPayment: yup.boolean(),
			noDisabilityPayment: yup.boolean(),
		}),
		spouse: yup.object().shape({
			identityType: yup
				.string()
				.test('isRequired', 'general.validation.required', isRequired),
			idNumber: yup
				.string()
				.test('isRequired', 'general.validation.required', isRequired)
				.when('identityType', {
					is: 'ID',
					then: yup
						.string()
						.matches(
							numberOnlyRegex,
							'general.validation.invalidValue',
						)
						.max(9, 'general.validation.invalidValue')
						.min(9, 'general.validation.invalidValue')
						.test(
							'idTest',
							'general.validation.invalidValue',
							idValidation,
						)
						.test(
							'idSameSpouse',
							'general.validation.idAlreadyExists',
							idSpouseDuplication,
						)
						.nullable(),
					otherwise: yup.string(),
				})
				.nullable(),
			firstName: yup
				.string()
				// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
				.test('isRequired', 'general.validation.required', isRequired)
				.nullable(),
			lastName: yup
				.string()
				// .matches(alphaNumeriRegex, 'general.validation.invalidValue')
				.test('isRequired', 'general.validation.required', isRequired)
				.nullable(),
			birthDate: yup
				.string()
				.test('isRequired', 'general.validation.required', isRequired)
				.nullable(),
			immigrationDate: yup.string().nullable(),
			hasIncome: yup
				.boolean()
				.test(
					'isRequiredHasIncome',
					'general.validation.required',
					function (value) {
						if (
							this.from &&
							this.from[this.from.length - 1].value?.employee
								?.maritalStatus === 'MARRIED'
						) {
							if (value === undefined || value === null) {
								return false;
							}
						}
						return true;
					},
				)
				.nullable(),
			incomeType: yup
				.array(yup.string())
				.test(
					'isRequired',
					'general.validation.required',
					function (value) {
						if (
							this.parent.hasIncome &&
							this.from[this.from.length - 1].value.employee
								.maritalStatus === 'MARRIED'
						) {
							if (!value || !value.length) {
								return false;
							}
						}
						return true;
					},
				)
				.nullable(),
		}),
		taxCreditReason: taxCreditReasonVaidation,
		taxCreditRequest: yup.object().shape({
			wantTaxCredit: yup
				.boolean()
				.required('general.validation.required')
				.nullable(),
			taxCreditRequestReason: yup.string().when('wantTaxCredit', {
				is: true,
				then: yup
					.string()
					.required('general.validation.required')
					.nullable(),
			}),
			noIncome: yup
				.boolean()
				.when('taxCreditRequestReason', {
					is: 'noIncome',
					then: yup.boolean().required('general.validation.required'),
					otherwise: yup.boolean(),
				})
				.nullable(),
			noIncomeFile: yup.array().when('noIncome', {
				is: true,
				then: yup.array().of(
					yup.object().shape({
						extraData: yup
							.object()
							.nullable()
							.test(
								'testName',
								'general.validation.required',
								function (value) {
									console.log(
										'general.validation.required',
										value,
									);
									return (
										this.path !==
											`taxCreditRequest.noIncomeFile[0].extraData` ||
										this?.from[0]?.value
									);
								},
							),
					}),
				),
			}),
			hasAdditionalIncome: yup
				.boolean()
				.when('taxCreditRequestReason', {
					is: 'additionalIncome',
					then: yup.boolean().required('general.validation.required'),
					otherwise: yup.boolean(),
				})
				.nullable(),
			additionalIncome: yup.array().when('hasAdditionalIncome', {
				is: true,
				then: yup
					.array()
					.of(
						yup.object().shape({
							name: yup
								.string()
								// .matches(
								// 	alphaNumeriRegex,
								// 	'general.validation.invalidValue',
								// )
								.required('general.validation.required')
								.nullable(),
							address: yup
								.string()
								// .matches(
								// 	alphaNumeriRegex,
								// 	'general.validation.invalidValue',
								// )
								.required('general.validation.required')
								.nullable(),
							deductionFileId: yup
								.string()
								.matches(
									numberOnlyRegex,
									'general.validation.invalidValue',
								)
								.required('general.validation.required')
								.nullable(),
							incomeType: yup
								.string()
								.required('general.validation.required')
								.nullable(),
							monthlyIncome: yup
								.string()
								.required('general.validation.required')
								.test(
									'numberValid',
									'general.validation.invalidValue',
									function (value) {
										const tempValue =
											value === '' || !value
												? 0
												: +value.split(',').join('');
										return isNaN(tempValue) ||
											tempValue > 999999.99
											? false
											: true;
									},
								)
								.nullable(),
							taxReduce: yup
								.string()
								.required('general.validation.required')
								.test(
									'numberValid',
									'general.validation.invalidValue',
									function (value) {
										const tempValue =
											value === '' || !value
												? 0
												: +value.split(',').join('');
										return isNaN(tempValue) ||
											tempValue > 999999.99
											? false
											: true;
									},
								)
								.nullable(),
							additionalIncomeFile: yup.array().of(
								yup.object().shape({
									extraData: yup
										.object()
										.nullable()
										.test(
											'testName',
											'general.validation.required',
											function (value) {
												return (
													!this.path.includes(
														'additionalIncomeFile[0].extraData',
													) || this?.from[0]?.value
												);
											},
										),
								}),
							),
						}),
					)
					.min(1)
					.max(3),
			}),

			taxAssessorApproved: yup
				.boolean()
				.when('taxCreditRequestReason', {
					is: 'taxAssessorApproved',
					then: yup.boolean().required('general.validation.required'),
					otherwise: yup.boolean(),
				})
				.nullable(),
			taxAssessorApprovedFile: yup
				.array()
				.when('taxCreditRequestReason', {
					is: 'taxAssessorApproved',
					then: yup.array().of(
						yup.object().shape({
							extraData: yup
								.object()
								.nullable()
								.test(
									'testName',
									'general.validation.required',
									function (value) {
										return (
											this.path !==
												`taxCreditRequest.taxAssessorApprovedFile[0].extraData` ||
											this?.from[0]?.value
										);
									},
								),
						}),
					),
				}),
		}),
		signature: yup
			.string()
			.required('general.validation.required')
			.nullable(),
	});
};
