import { Platform } from 'react-native';

export const isSafari = () => {
	const ua = navigator.userAgent.toLowerCase();
	if (ua.indexOf('safari') != -1) {
		if (ua.indexOf('chrome') > -1) {
			return false;
		} else {
			return true;
		}
	}
	return false;
};

export const tamplate = (
	data: any,
	size: any,
	isMobile?: boolean,
	safariMargin?: number,
	isMobileWeb?: boolean,
) => {
	return `
    <!doctype html>
    
    <html lang="he">
    <head>
      <meta charset="utf-8">
      <link href='https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&display=swap' rel='stylesheet'>
      <script src='https://unpkg.com/panzoom@9.4.0/dist/panzoom.min.js' query='#usePanzoom' name='pz'></script>
      <style>
            * {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                //direction: rtl;
            }
            ::-webkit-scrollbar {
                width: 5px;
                height: 5px;
              }
              
              ::-webkit-scrollbar-button {
                width: 0px;
                height: 0px;
              }
              
              ::-webkit-scrollbar-thumb {
                background: #d0d3d9;
                border: 0px none none;
                border-radius: 51px;
              }
              
              ::-webkit-scrollbar-thumb:hover {
                background: #d0d3d9;
              }
              
              ::-webkit-scrollbar-thumb:active {
                background: #d0d3d9;
              }
              
              ::-webkit-scrollbar-track {
                background: none;
                border: 0px none none;
                border-radius: 50px;
              }
              
              ::-webkit-scrollbar-track:hover {
                background: none;
              }
              
              ::-webkit-scrollbar-track:active {
                background: none;
              }
              
              ::-webkit-scrollbar-corner {
                background: transparent;
              }
            @page{
                margin-left: 0px;
                margin-right: 0px;
                margin-top: 0px;
                margin-bottom: 0px;
            }
            html {
                height: 100%;
                width: 100%;
            }
            body {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;                
            }
            .wrapper {
                width: 794px;
                margin-top: ${safariMargin || 0}px;
                margin-bottom: ${safariMargin || 0}px;                
            }
            .wrapperDownloadSafari {
                width: 794px;
                margin-top: ${safariMargin || 0}px;
                margin-bottom: ${safariMargin || 0}px;                
            }
            div {
                text-align: right;

                letter-spacing: 0.2px;
                white-space: nowrap;
                line-height: unset;
            }
            section {
                display: flex;
                flex-direction: column;
                // width: 794px;

                // border: 1px solid black;
                height: 1123px;
                padding-left: 14px;
                padding-right: 14px;
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 10.7px;
                font-family: 'Assistant';
            }
            .square {
                display: flex;
                flex-direction: column;
                border: 1px solid #8a8b8a;
                direction: rtl;
            }
            .squareBlue {
                display: flex;
                flex-direction: column;
                border: 1px solid rgb(74, 174, 239);
                background-color: rgb(74, 174, 239, 0.1);
                direction: rtl;
            }
            .squareGrey {
                display: flex;
                flex-direction: column;
                direction: rtl;
                border: 1px solid #8a8b8a;
                background-color: #ececeb;
                height: 253px;
                width: 166px
            }
            .padding20 {
                padding: 20px;
            }
            .pt-10 {
                padding-top: 10px;
            }
            .mb-1 {
                margin-bottom: 1px;
            }
            .mb-5 {
                margin-bottom: 5px;
            }
            .mb-3 {
                margin-bottom: 3px;
            }
            .mb-2 {
                margin-bottom: 2px;
            }
            .mt-20 {
                margin-top: 20px;
            }
            .md-15 {
                margin-bottom: 15px;
            }
            .md-10 {
                margin-bottom: 10px;
            }
            .md-25 {
                margin-bottom: 25px;
            }
            .pr-15 {
                padding-right: 15px;
            }
            .pr-5 {
                padding-right: 5px;
            }
            .pl-5 {
                padding-left: 5px;
            }
            .pl-3 {
                padding-left: 3px;
            }
            .pl-1 {
                padding-left: 1px;
            }
            .pb-5 {
                padding-bottom: 5px;
            }
            .p-10 {
                padding: 10px;
            }
            .alignLeft {
                justify-content: flex-end;
            }
            .squareTitle {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 14.7px;
                font-weight: 700;
                border-bottom: 1px solid #8a8b8a;
                padding-right: 10px;
                background-color: #d8d8d8;
            }
            .greySectionTitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 12px;
                border-bottom: solid 1px #8a8b8a;
                margin-bottom: 10px;
            }
            .greySectionBodyWrapper {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
            }
            .greySectionContent {
                padding-right: 12px;
                padding-left: 20px;
            }
            .greySectionContentTotal {
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #8a8b8a;
            }
            .textCenter {
                width: 50%;
                text-align: center;
            }
            .blueTitle {
                color: #0091ff;
                font-weight: 700;
                width: 50%;
                text-align: center;
            }
            .textVertical {
                transform: rotate(270deg);
                transform-origin: top right;
                position: absolute;
                top: 50px;
                right: 0px;
                color: #0091ff;
            }
            .multiInnerSquare {
                display: flex;
                flex-direction: row;
                border: 1px solid #8a8b8a;
                direction: rtl;
                justify-content: space-between;
            }
            .sectionEHeader{
                display: flex;
                direction: rtl;
                background-color: #d8d8d8;
                font-weight: 700;
                color: black;
            }
            .sectionFHeader {
                display: flex;
                direction: rtl;
                background-color: #d8d8d8;
                font-weight: 700;
                color: black;
            }
            .sectionEBottom {
                display: flex;
                border-top: 1px solid #979797;
                height: 37.5px
            }
            .sectionERow {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
            .sectionFRow {
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
            }
            .rowShort {
                display: flex;
                align-items: center;
                direction: rtl;
            }
            .rowLong {
                display: flex;
                justify-content: space-between;
            }
            .first {
                height: 98px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
            .second {
                height: 175px;
                margin-top: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .third {
                height: 400px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
            .fourth {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                height: 305px
            }
            .five {
                height: 55px;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
            #sectionA {
                width: 500px;
                height: 100%;
                align-items: center;
                padding-top: 28px;
                padding-bottom: 29px;
                padding-right: 12.5px;
            }
            #sectionB {
                width: 257px;
                height: 100%;
                padding-top: 28px;
                padding-right: 30px;
                margin-right: 10px;
            }
            #sectionD {
                height: 100%;
                width: 418px;
            }
            #sectionC {
                height: 97px;
                width: 339px;
                padding: 10px;
            }
            #sectionE {
                width: 537px;
                height: 100%;
                position: relative;
            }
            #sectionF {
                width: 221px;
                background-color: #ececeb;
                height: 100%;
            }
            #sectionJ {
                height: 100%;
                width: 221px;
                display: flex;
                padding-top: 15px;
                padding-right: 39px;
                padding-left: 13px;
            }
            #sectionK {
                height: 100%;
                width: 537px;
                padding-top: 8px;
                padding-right: 24px;
            }
            .sectionE-1{
                overflow: hidden;
                width: 25px;
                position: relative;
            }
            .sectionE-2{
                overflow: hidden;
                width: 114px;
                padding-right: 4px;
            }
            .sectionE-3{
                overflow: hidden;
                width: 40px;
                padding-right: 4px;
            }
            .sectionE-4{
                overflow: hidden;
                width: 64px;
                padding-right: 4px;
            }
            .sectionE-5{
                overflow: hidden;
                width: 64px;
                padding-right: 4px;
            }
            .sectionE-6{
                overflow: hidden;
                width: 63px;
                padding-right: 4px;
            }
            .sectionE-7{
                overflow: hidden;
                width: 63.7px;
                padding-right: 4px;
            }
            .sectionE-8{
                overflow: hidden;
                width: 89.2px;
                padding-right: 4px;
            }
            .sectionE-separator {
                position: absolute;
                width: 1px;
                // width: 0.5px;
                height: 345px;
                top: 14px;
                background-color: #979797;
            }
            .E-1-saparator {
                right: 30px; 
            }
            .E-2-saparator {
                right: 144px;
            }
            .E-3-saparator {
                right: 184px;
            }
            .E-4-saparator {
                right: 248px;
            }
            .E-5-saparator {
                right: 312px;
            }
            .E-6-saparator {
                right: 375px;
            }
            .E-7-saparator {
                right: 439px;
            }
            .sectionF-separator {
                position: absolute;
                width: 1px;
                //width: 0.5px;
                height: 347px;
                top: 0;
                background-color: #979797;
            }
            .F-1-saparator {
                right: 32.5px;
            }
            .F-2-saparator {
                right: 159.5px;
            }
            .sectionESumTitle {
                height: 100%;
                width: 375.5px;
                border-left: 1px solid #979797;
                padding-top: 5px;
                padding-right: 33px;
            }
            .sectionESumMas {
                height: 100%;
                width: 64px;
                border-left: 1px solid #979797;
                padding-top: 5px;
                padding-right: 4px;
            }
            .sectionESumPay {
                height: 100%;
                width: 89.2px;
                padding-top: 5px;
                padding-right: 4px;
            }
            .sectionFBottom {
                display: flex;
                border-top: 1px solid #8a8b8a;
                height: 37.5px
            }
            .sectionFSumTitle {
                height: 100%;
                width: 160.5px;
                border-left: 1px solid #8a8b8a;
                padding-top: 5px;
                //padding-right: 34px;
                padding-right: 37px;
            }
            .sectionFSumPay {
                padding-top: 5px;
                padding-right: 4px;
            }
            .insideRight
            .insideLeft
            .insideMiddle {
                display: flex;
                flex-direction: column;
            }
            .emptyRow {
                height: 14px;
            }
            .emptyRowBig {
                height: 14.4px;
            }
            .emptyRowSmall {
                height: 10px;
            }
            .sectionF-1 {
                width: 25.5px;
                overflow: hidden;
                position: relative;
            }
            .sectionF-2 {
                width: 132px;
                padding-right: 6px;
                overflow: hidden;
            }
            .sectionF-3 {
                width: 60px;
                padding-right: 4px;
                overflow: hidden;
            }
            .sectionHI {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 221px;
            }
            #sectionG {
                width: 537px;
                height: 305px;            
                position: relative;
            }
            #sectionH {
                height: 61px;
                width: 221px;
                display: flex;
                justify-content: center;
                align-items: center;
    
            }
            #sectionI {
                width: 221px;
                height: 233px;
                margin-top: 8px;
            }
            .sectionIRow {
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
            }
            .sectionI-1 {
                width: 25.5px;
                position: relative;
                overflow: hidden;
            }
            .sectionI-2 {
                width: 131.5px;
                padding-right: 10px;
                overflow: hidden;
            }
            .sectionI-3 {
                width: 62px;
                padding-right: 4px;
                overflow: hidden;
            }
            .sectionI-separator {
                position: absolute;
                width: 1px;
                //width: 0.5px;
                height: 190px;
                top: 0;
                background-color: #979797;
            }
            .I-1-saparator {
                right: 32.5px
            }
            .I-2-saparator {
                right: 160.5px
            }
            .sectionIHeader {
                display: flex;
                direction: rtl;
                background-color: #d8d8d8;
                font-weight: 700;
                color: black;
            }
            .sectionIBottom {
                display: flex;
                border-top: 1px solid #8a8b8a;
                height: 27.5px
            }
            .sectionISumTitle {
                height: 100%;
                width: 161px;
                border-left: 1px solid #8a8b8a;
                padding-top: 5px;
                padding-right: 37px;  
            }
            .sectionISumPay {
                padding-top: 5px;
                padding-right: 4px;
            }
            .emptySectionG {
                align-items: center;
                justify-content: center;
                display: flex;
                font-weight: 700;
            }
            .sectionGHeader {
                display: flex;
                direction: rtl;
                background-color: #d8d8d8;
                font-weight: 700;
                color: black;
            }
            .sectionGRow {
                display: flex;
                flex-direction: column;
                flex: 1;
                position: relative;
                height:  ${
					Platform.OS === 'ios'
						? 270
						: Platform.OS === 'android'
						? 274
						: 273
				}px;
            }
            .sectionG-1 {
                width: 70px;
                position: relative;
                overflow: hidden;
            }
            .sectionG-2 {
                width: 54px;
                padding-right: 4px;
                overflow: hidden;
            }
            .sectionG-3 {
                width: 86px;
                padding-right: 4px;
            }
            .sectionG-4 {
                width: 55px;
                padding-right: 4px;
                overflow: hidden;
            }
            .sectionG-5 {
                width: 97px;
                padding-right: 4px;
                overflow: hidden;
            }
            .sectionG-6 {
                width: 54px;
                padding-right: 4px;
                overflow: hidden;
            }
            .sectionG-7-8 {
                height: calc(100% - 22px);
                width: 113px;
                display: flex;
                flex-direction: column;
                position: absolute;
                left: 0px;
                top: 22px;
            }
            .sectionG-7 {
                height: 86px;
                position: relative;
                padding-right: 10px;
                padding-left: 8px;
                overflow: visible;
            }
            .G-8-paddings {
                padding-right: 10px;
                padding-left: 8px;
                padding-top: ${Platform.OS === 'ios' ? 1 : 3}px;
            }
            .sectionG8Header {
                display: flex;
                background-color: #d8d8d8;
                font-weight: 700;
                color: black;
                padding-right: 25px;
                margin-right: ${
					Platform.OS === 'ios' || Platform.OS === 'android' ? 0 : 1
				}px;
            }
            .sectionG-8 {
                overflow: hidden;
                height: 95px;
                border-bottom: 1px solid #979797;
                margin-right: 1px;
                position: relative;
            }
            .sectionG-separator{
                position: absolute;
                width: 1px;
                //width: 0.5px;
                height:  ${
					Platform.OS === 'ios'
						? 270
						: Platform.OS === 'android'
						? 274
						: 273
				}px;
                top: 0;
                background-color: #979797;
            }
            .G-1-saparator{
                right: 75px ;
            }
            .G-2-saparator{
                right: 129px;
            }
            .G-3-saparator{
                right: 215px
            }
            .G-4-saparator{
                width: 1.6px;
                right: 270px;
            }
            .G-5-saparator{
                right: 367px;
            }
            .G-6-saparator{
                width: 1.6px;
                right: 420px;
            }
            .G-7-saparator{
                height: 96px;
                right: 74px;
                top: -8px;
            }
            .G-8-saparator{
                right: 74px;
                top: 14px;
            }
            .sectionLHeader {
                display: flex;
                background-color: #d8d8d8;
                font-weight: 700;
                color: black;
                padding-right: 7px;
                margin-left: 3px;
                width: 76px;
            }
            .sectionL {
                display: flex;
                border-top: 1px solid #979797;
                border-left: 1px solid #979797;
                width: 422px;
            }
            .sectionM {
                white-space: initial;
                padding: 3px 8px;
            }
            .textArea{
                // display: -webkit-box;
                // -webkit-line-clamp: 3;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // text-overflow: ellipsis;
            }
            .textArea {
                --max-lines: 3;
                position: relative;
                max-height: calc(var(--lh) * var(--max-lines));
                overflow: hidden;
                padding-left: 1rem; /* space for ellipsis */
                white-space: normal;
            }
            .alignLeft {
                justify-content: flex-start;                
            }
            .numericCell {
                direction: ltr;
                unicode-bidi: bidi-override;
            }
            //   .textArea::before {
            //     position: absolute;
            //     content: "...";
            //     inset-block-end: 0; /* "bottom" */
            //     inset-inline-end: 0; /* "right" */
            //   }
            //   .textArea::after {
            //     content: "";
            //     position: absolute;
            //     inset-inline-end: 0; /* "right" */
            //     width: 1rem;
            //     height: 1rem;
            //     background: white;
            //   }
        </style>
    </head>
    <body>
        <div class='wrapper' id=${
			Platform.OS === 'ios' || Platform.OS === 'android' || !isMobileWeb
				? 'unused'
				: 'usePanzoom'
		}>
            ${Object.keys(data)
				.sort()
				.map(pageNumber => paycheckSection(data[pageNumber]))
				.join('')}	
        </div>
    </body>
    </html>
    `;
};

const sectionERow = (row: any) => `
    <div class='rowShort mb-2'>
        <div class="sectionE-1 numericCell">${row[`1`]?.value || ''}</div>
        <div class="sectionE-2">${row[`2`]?.value || ''}</div>
        <div class='sectionE-3'>${row[`3`]?.value || ''}</div>
        <div class='sectionE-4 numericCell'>${row[`4`]?.value || ''}</div>
        <div class='sectionE-5 numericCell'>${row[`5`]?.value || ''}</div>
        <div class='sectionE-6 numericCell'>${row[`6`]?.value || ''}</div>
        <div class='sectionE-7 numericCell'>${row[`7`]?.value || ''}</div>
        <div class='sectionE-8 numericCell'>${row[`8`]?.value || ''}</div>
    </div>`;

const sectionESumRow = (sumRow: any) => `
        <div class="sectionESumTitle">${sumRow[`1`]?.value || ''}</div>
        <div class="sectionESumMas numericCell">${
			sumRow[`6`]?.value || ''
		}</div>
        <div class="sectionESumPay numericCell">${
			sumRow[`7`]?.value || ''
		}</div>`;

const sectionFRow = (row: any) => `
    <div class='rowShort mb-2'>
        <div class="sectionF-1 numericCell">${row[`1`]?.value || ''}</div>
        <div class="sectionF-2">${row[`2`]?.value || ''}</div>
        <div class='sectionF-3 numericCell'>${row[`3`]?.value || ''}</div>
    </div>`;

const sectionFSumRow = (sumRow: any) => `
    <div class="sectionFSumTitle">${sumRow[`2`]?.value || ''}</div>
    <div class="sectionFSumPay numericCell">${sumRow[`3`]?.value || ''}</div> 
`;

const sectionIRow = (row: any) => `
    <div class='rowShort mb-2'>
        <div class="sectionI-1 numericCell">${row[`1`]?.value || ''}</div>
        <div class="sectionI-2">${row[`2`]?.value || ''}</div>
        <div class='sectionI-3 numericCell'>${row[`3`]?.value || ''}</div>
    </div>
`;
const sectionISumRow = (sumRow: any) => `
    <div class="sectionISumTitle">${sumRow[`2`]?.value || ''}</div>
    <div class="sectionISumPay numericCell">${sumRow[`3`]?.value || ''}</div>
`;

const sectionGRow = (row: any) =>
	`
    <div class='rowShort mb-${Platform.OS === 'ios' ? '0' : '2'}' >
        <div class="sectionG-1">${row[`1`]?.value || ''}</div>
        <div class="sectionG-2">${insertRowValue(row, `2`) || ''}</div>
        <div class='sectionG-3'>${row[`3`]?.value || ''}</div>
        <div class='sectionG-4'>${insertRowValue(row, `4`)}</div>
        <div class='sectionG-5'>${row[`5`]?.value || ''}</div>
        <div class='sectionG-6'>${insertRowValue(row, `6`)}</div>
    </div>
`;

const sectionGRow7 = (row: any) => {
	return `
        <div class="rowLong" style="justify-content: unset;">
            <div style="width: 66px;">${row[`7`]?.value || ''}</div>
            <div class="numericCell" style="margin-right: 4px;">${
				row[`8`]?.value || ''
			}</div>
        </div>
    `;
};
const sectionGRow8 = (row: any) => {
	return `
    <div class='rowLong G-8-paddings' style="justify-content: unset;">
        <div style="width: 66px;">${row[`1`]?.value || ''}</div>
        <div class="numericCell" style="margin-right: 4px;">${
			row[`2`]?.value || ''
		}</div>
    </div>
    `;
};

const filterSpecColumns = (rowData: any, col1: any, col2: any) => {
	if (col1 in rowData || col2 in rowData) {
		return true;
	} else {
		return false;
	}
};

const isNumeric = (num: string) => {
	if (!num) {
		return false;
	}
	return !isNaN(Number(num));
};

const formatValue = (value: string) => {
	let className = '';
	if (isNumeric(value)) {
		className = 'numericCell';
	}
	if (className) {
		return `<span class="${className}">${value}</span>`;
	} else {
		return value;
	}
};

const insertValue = (data: any, section: string, row: string, column: any) => {
	const value = data?.[section]?.[row]?.[column]?.['value'] || '';
	const formattedValue = formatValue(value);
	return formattedValue;
};

const insertRowValue = (row: string, column: any) => {
	const value = row?.[column]?.['value'] || '';
	const formattedValue = formatValue(value);
	return formattedValue;
};

const paycheckSection = (data: any) => {
	let gSaparatorsHeight = Platform.OS === 'ios' ? 288 : 289;
	if (data.l && data.l['1']['1']) {
		gSaparatorsHeight =
			Platform.OS === 'ios' ? 270 : Platform.OS === 'android' ? 274 : 273;
	}
	return `
    <section>
            <div class="first">
                <div id="sectionB" class="squareBlue">
                    <div class="rowShort">
                        <div class="pl-5">${insertValue(
							data,
							'b',
							'1',
							'1',
						)}</div>
                        <div>${insertValue(data, 'b', '1', '2')}</div>
                    </div>
                    <div class="rowShort">
                        <div class="pl-5">${insertValue(
							data,
							'b',
							'2',
							'1',
						)}</div>
                        <div>${insertValue(data, 'b', '2', '2')}</div>
                    </div>
                    <div class="rowShort">
                        <div class="pl-5">${insertValue(
							data,
							'b',
							'3',
							'1',
						)}</div> 
                        <div>${insertValue(data, 'b', '3', '2')}</div> 
                    </div>
                </div>
                <div id="sectionA" class="multiInnerSquare square">
                    <div class="insideRight" style="width: 50%;">
                        <div class="rowShort">
                            <div class="pl-5">${insertValue(
								data,
								'a',
								'1',
								'1',
							)}</div>
                            <div>${insertValue(data, 'a', '1', '2')}</div>
                        </div>
                        <div class="rowShort">
                            <div class="pl-5">${insertValue(
								data,
								'a',
								'2',
								'1',
							)}</div>
                            <div>${insertValue(data, 'a', '2', '2')}</div>
                        </div>
                        <div class="rowShort">
                            <div class="pl-5">${insertValue(
								data,
								'a',
								'3',
								'1',
							)}</div>
                            <div>${insertValue(data, 'a', '3', '2')}</div>
                        </div>
                    </div>
                    <div class="insideLeft" style="margin-left: 54px">
                        <div class="rowShort alignLeft">
                            <div class="pl-5">${insertValue(
								data,
								'a',
								'1',
								'3',
							)}</div>
                            <div>${insertValue(data, 'a', '1', '4')}</div>
                        </div>
                        <div class="rowShort alignLeft">
                            <div class="pl-5">${insertValue(
								data,
								'a',
								'2',
								'3',
							)}</div>
                            <div>${insertValue(data, 'a', '2', '4')}</div>
                        </div>
                        <div class="rowShort alignLeft">
                            <div class="pl-5">${insertValue(
								data,
								'a',
								'3',
								'3',
							)}</div>
                            <div>${insertValue(data, 'a', '3', '4')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="second">
                <div id="sectionD" class="square">
                    <div class='squareTitle'>
                        <span>פרטים אישיים</span>
                    </div>
                    <div class="multiInnerSquare" style="border: none; padding-top: 6.5px; padding-right: 12px; padding-left: 10px;">
                        <div class="insideRight" style="width: 33%">
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'1',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '1', '2')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'2',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '2', '2')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'3',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '3', '2')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'4',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '4', '2')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'5',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '5', '2')}</div>
                            </div>
                            <div class="md-10"></div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'7',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '7', '2')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'8',
									'1',
								)}</div>
                                <div>${insertValue(data, 'd', '8', '2')}</div>
                            </div>
                        </div>
                        <div class="insideMiddle" style="width: 33%">
                            ${
								insertValue(data, 'd', '1', '3') !== ''
									? `<div class="rowShort">
                                <div>${insertValue(data, 'd', '1', '3')}</div>
                            </div>`
									: `<div class="emptyRow"></div>`
							}
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'2',
									'3',
								)}</div>
                                <div>${insertValue(data, 'd', '2', '4')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'3',
									'3',
								)}</div>
                                <div>${insertValue(data, 'd', '3', '4')}</div>
                            </div>
                            <div class="emptyRowBig"></div>
                            <div class="emptyRowBig"></div>
                            <div class="emptyRowBig"></div>
                            <div class="emptyRowSmall"></div>
                            <div class="rowShort">
                                <div class="pl-1">${insertValue(
									data,
									'd',
									'7',
									'3',
								)}</div>
                                <div class="pl-3">${insertValue(
									data,
									'd',
									'7',
									'4',
								)}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'8',
									'3',
								)}</div>
                                <div>${insertValue(data, 'd', '8', '4')}</div>
                            </div>
                        </div>
                        <div class="insideLeft" style="width: 33%">
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'1',
									'5',
								)}</div>
                                <div>${insertValue(data, 'd', '1', '6')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'2',
									'5',
								)}</div>
                                <div>${insertValue(data, 'd', '2', '6')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'3',
									'5',
								)}</div>
                                <div>${insertValue(data, 'd', '3', '6')}</div>
                            </div>
                            <div class="rowShort">
                                <div class="pl-5">${insertValue(
									data,
									'd',
									'4',
									'5',
								)}</div>
                                <div>${insertValue(data, 'd', '4', '6')}</div>
                            </div>
                            <div class="md-25"></div>
                            <div class="rowShort">
                            <div class="pl-3">${insertValue(
								data,
								'd',
								'7',
								'5',
							)}</div>
                            <div>${insertValue(data, 'd', '7', '6')}</div>
                        </div>
                        </div>
                    </div>
                </div>
                <div id="sectionC" class="squareBlue">
                    <div class="rowShort">
                        <div>${insertValue(data, 'c', '1', '1')}</div>
                    </div>
                    <div class="md-10"></div>
                    <div class="rowShort">
                        <div>${insertValue(data, 'c', '2', '1')}</div>
                    </div>
                    <div class="rowShort">
                        <div>${insertValue(data, 'c', '3', '1')}</div>
                    </div>
                    <div class="rowShort">
                        <div>${insertValue(data, 'c', '4', '1')}</div>
                    </div>
                </div>
            </div>
            <div class="third">
                <div id="sectionF" class="squareGrey">
                    <div class='sectionFHeader'>
                        <div class='tableHeader' style='padding-right: 55px; padding-left: 61px;'>ניכויי חובה</div>
                        <div class='tableHeader' style='padding-right: 10px;'>הסכום</div>
                    </div>
                    <div class="sectionFRow pr-5 pt-10">
                        ${
							data.f
								? Object.keys(data.f)
										.sort(
											(a, b) => parseInt(a) - parseInt(b),
										)
										.filter(row => row !== '999')
										.map((row, index) =>
											sectionFRow(data.f[row]),
										)
										.join('')
								: ''
						}
                        <div class="sectionF-separator F-1-saparator"></div>
                        <div class="sectionF-separator F-2-saparator"></div>
                    </div>
                    <div class="sectionFBottom">
                        ${sectionFSumRow(data.f?.['999'] || '')} 
                    </div>
                </div>
                <div id="sectionE" class="squareBlue">
                    <div class='sectionEHeader'>
                        <div class='tableHeader' style='padding-right: 50.5px; padding-left: 95px;'>תאור התשלום</div>
                        <div class='tableHeader' style='padding-left: 38px;'>כמות</div>
                        <div class='tableHeader' style='padding-left: 35px;'>תעריף</div>
                        <div class='tableHeader' style='padding-left: 33px;'>גילום</div>
                        <div class='tableHeader' style='padding-left: 35px;'>שווי למס</div>
                        <div class='tableHeader'>התשלום</div>
                    </div>
                    <div class="sectionERow pr-5 pt-10">
                        <div class="sectionE-separator E-1-saparator"></div>
                        <div class="sectionE-separator E-2-saparator"></div>
                        <div class="sectionE-separator E-3-saparator"></div>
                        <div class="sectionE-separator E-4-saparator"></div>
                        <div class="sectionE-separator E-5-saparator"></div>
                        <div class="sectionE-separator E-6-saparator"></div>
                        <div class="sectionE-separator E-7-saparator"></div>
                        ${
							data.e
								? Object.keys(data.e)
										.sort(
											(a, b) => parseInt(a) - parseInt(b),
										)
										.filter(row => row !== '999')
										.map((row, index) =>
											sectionERow(data.e[row]),
										)
										.join('')
								: ''
						}
                    </div>
                    <div class="sectionEBottom">
                       ${sectionESumRow(data.e?.['999'] || '')} 
                    </div>

                    <div class="textVertical">
                            ${insertValue(data, 'o', '1', '1')}
                        </div>
                </div>
            </div>
            <div class="fourth">
                <div class='sectionHI'>
                    <div id="sectionH" class="squareBlue">
                        <div class="rowLong" style="width: 100%; padding: 23px 37px 23.5px 13.1px">
                            <div>${insertValue(data, 'h', '1', '1')}</div>
                            <div>${insertValue(data, 'h', '1', '2')}</div>
                        </div>
                    </div>
                    <div id="sectionI" class="squareGrey">
                        <div class='sectionIHeader'>
                            <div class='tableHeader' style='width: 162px; display: flex; justify-content: center;'>ניכויי רשות</div>
                            <div class='tableHeader' style='width: 61px; display: flex; justify-content: center;'>הסכום</div>
                        </div>
                       ${
							data.i
								? `<div class="sectionIRow pr-5 pt-10">
                        ${
							data.i
								? Object.keys(data.i)
										.sort(
											(a, b) => parseInt(a) - parseInt(b),
										)
										.filter(row => row !== '999')
										.map((row, index) =>
											sectionIRow(data.i[row]),
										)
										.join('')
								: ''
						}
                            <div class="sectionI-separator I-1-saparator"></div>
                            <div class="sectionI-separator I-2-saparator"></div>
                        </div>`
								: ''
						}
                        ${
							data.i
								? `<div class="sectionIBottom">
                            ${sectionISumRow(data.i?.['999'] || '')}
                        </div>`
								: ''
						}
                    </div>
                </div>
                ${
					data.g
						? `<div id="sectionG" class="square">
                    <div class='sectionGHeader'>
                        <div class='tableHeader' style='width: 271.5px; display: flex; justify-content: center; border-left: 1.5px solid white'>נתונים נוספים</div>
                        <div class='tableHeader' style='width: 150px; display: flex; justify-content: center; border-left: 1.5px solid white''>סכומים מצטברים</div>
                        <div class='tableHeader' style='display: flex; justify-content: center; flex: 1'>חשבון חופשה</div>
                    </div>
                    <div>
                    <div class="sectionGRow pr-5 pt-10">
                        ${
							data.g
								? Object.keys(data.g)
										.sort(
											(a, b) => parseInt(a) - parseInt(b),
										)
										.filter(
											row => row !== '999' && row !== '0',
										)
										.map((row, index) =>
											sectionGRow(data.g[row]),
										)
										.join('')
								: ''
						}
                        <div class="sectionG-separator G-1-saparator" style="height: ${gSaparatorsHeight}px"></div>
                        <div class="sectionG-separator G-2-saparator" style="height: ${gSaparatorsHeight}px"></div>
                        <div class="sectionG-separator G-3-saparator" style="height: ${gSaparatorsHeight}px"></div>
                        <div class="sectionG-separator G-4-saparator" style="height: ${gSaparatorsHeight}px"></div>
                        <div class="sectionG-separator G-5-saparator" style="height: ${gSaparatorsHeight}px"></div>
                        <div class="sectionG-separator G-6-saparator" style="height: ${gSaparatorsHeight}px"></div>
                    </div>
                       ${
							data.l && data.l['1']['1']
								? `<div class="sectionL">
                            <div class='sectionLHeader'>חשבון הבראה</div>
                            <div>${insertValue(data, 'l', '1', '2')}</div>
                        </div>`
								: ''
						}
                    </div>
                    <div class='sectionG-7-8'>
                            <div class="sectionG-7">
                                ${
									data.g
										? Object.keys(data.g)
												.sort(
													(a, b) =>
														parseInt(a) -
														parseInt(b),
												)
												.filter(row => {
													return filterSpecColumns(
														data.g[row],
														'7',
														'8',
													);
												})
												.map((row, index) =>
													sectionGRow7(data.g[row]),
												)
												.join('')
										: ''
								}
                                <div class="sectionG-separator G-7-saparator"></div>
                            </div>
                            <div class="sectionG-8">
                                <div class="sectionG8Header">
                                    חשבון מחלה
                                </div>
                                <div class="sectionG-separator G-8-saparator"></div>
                                ${
									data.n
										? Object.keys(data.n)
												.sort(
													(a, b) =>
														parseInt(a) -
														parseInt(b),
												)
												.filter(row => {
													return row !== '0';
													// return filterSpecColumns(
													// 	data.n[row],
													// 	'1',
													// 	'2',
													// );
												})
												.map((row, index) =>
													sectionGRow8(data.n[row]),
												)
												.join('')
										: data.n
								}
                            </div>
                            <div class="sectionM">
                                ${insertValue(data, 'm', '1', '1')}
                            </div>
                    </div>
                </div>`
						: `<div id="sectionG" class="square emptySectionG"><div>ראה המשך בדף הבא</div></div>`
				}
            </div>
            <div class="five">
                <div id='sectionJ' class="squareBlue">
                    <div class="rowLong">
                        <div>${insertValue(data, 'j', '1', '1')}</div>
                        <div>${insertValue(data, 'j', '1', '2')}</div>
                    </div>
                </div>
                <div id='sectionK' class="square">
                   <div class="textArea">
                    ${insertValue(data, 'k', '1', '1')}
                    </div>
                </div>
            </div>
        </section>`;
};
