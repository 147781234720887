import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import {
	Platform,
	TouchableOpacity,
	useWindowDimensions,
	View,
} from 'react-native';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import Table from '../../../components/web/ReactTable/Table.container';
import colors from '../../../config/colors';
import { dispatch } from '../../../store/store';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../../store/actions/reportsFilters.actions';
import { useRoute } from '@react-navigation/core';
import { useSelector } from 'react-redux';
import {
	getDepartmentsFilterList,
	getEmployeesFilterList,
} from '../../../store/selectors/reportsFilters.selectors';
import {
	Action,
	fetchReports,
	initialState,
	workPaperReducer,
	WorkPaperState,
} from './workPaperReducer';
import FiltersContainer, {
	FilterData,
} from '../../../components/web/Filters/Filters.container';
import { getFiltersConfig } from './WorkPaperPage.utils';
import I18n from '../../../components/shared/i18n/I18n.component';
import httpService from '../../../services/http.service';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { getExcelFileFromResponse } from '../../../utils/downloadFile.web.utils';
import { getUserData } from '../../../store/selectors/login.selectors';
import { i18nService } from '../../../services/i18n.service';
import Typography from '../../../components/shared/Typography/Typography.component';
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import { WorkPaperPdfCreator } from './WorkPaperPdfCreator/WorkPaperPdfCreator';
import { arrangeData } from './WorkPaperPdfCreator/WorkPaperPdfCreator.utils';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../../../store/actions/config.actions';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import { EventName, logEvent } from '../../../services/analyticsAndLogs.service';

const pageFilterSize = 20;

const WorkPaperPage = ({ navigation }: any) => {
	const { height } = useWindowDimensions();
	const [lastMonth, setLastMonth] = useState<any>();
	const [scrollToTop, setScrollToTop] = useState(false);
	const userData = useSelector(getUserData);
	const employeesFilterList = useSelector(getEmployeesFilterList);
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const [currentTableState, setCurrentTableState] = useState<any>({});
	const isFirstInit = useRef(true);

	const route = useRoute();

	const [
		{
			filters,
			columns,
			rows,
			hasMore,
			errorMessage,
			page,
			pageSize,
			totalReports,
			showNumbers,
		},
		localDispatch,
	] = useReducer<React.Reducer<WorkPaperState, Action>>(
		workPaperReducer,
		initialState,
	);

	const handleFilter = useCallback((data: FilterData) => {
		if (data.name === 'unitIds') {
			isFirstInit.current = true;
		}
		setScrollToTop(true);
		localDispatch({
			type: `update_${data.name}`,
			payload: data.name === 'unitIds' ? data : data.value,
		});
	}, []);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.workPaper.privileges,
		onChangeFilter: handleFilter,
	});

	const getLastMonth = async () => {
		const res: { first: string; last: string } = await httpService.api({
			type: 'getLastWorkPaper',
			query: { unitIds: getSelectedUnits(filters.unitIds).join() },
		});
		if (res.last) {
			setLastMonth(
				new Date(+res.last.split('-')[0], +res.last.split('-')[1]),
			);
			localDispatch({
				type: 'inital_date',
				payload: {
					year: +res.last.split('-')[0],
					month: +res.last.split('-')[1],
				},
			});
		} else {
			setLastMonth(null);
		}
	};

	useEffect(() => {
		if (filters !== initialState.filters) {
			localDispatch({ type: 'reset_filters', payload: null });
		}
	}, [userData]);

	useEffect(() => {
		if (filters.unitIds.length || multiValue) {
			getLastMonth();
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(filters.unitIds),
				),
			);
			isFirstInit.current = false;
		}
	}, [filters.unitIds]);

	useEffect(() => {
		filters.from_month.length &&
			filters.to_month.length &&
			!isFirstInit.current &&
			fetchReports({
				page,
				pageSize,
				filter: {
					...filters,
					unitIds: getSelectedUnits(filters?.unitIds),
				},
				dispatch: localDispatch,
			});
	}, [filters, page]);

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res: any = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: pageFilterSize,
			search,
			filter: { dep: filters.dep },
			unitIds: getSelectedUnits(filters.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: pageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};

	const goNext = useCallback(() => {
		setScrollToTop(true);
		localDispatch({ type: `increment_page`, payload: undefined });
	}, []);

	const goPrev = useCallback(() => {
		setScrollToTop(true);
		localDispatch({ type: `decrement_page`, payload: undefined });
	}, []);

	const filtersData = useMemo(
		() =>
			getFiltersConfig(
				departmentsFilterList,
				filters.dep,
				getEmployeesFilter,
				filters,
				lastMonth,
				`${JSON.stringify(userData)}${JSON.stringify(filters.unitIds)}`,
				businessUnitsList,
				isBusinessUnitsMulti,
				filters.unitIds,
			),
		[filters, employeesFilterList, departmentsFilterList, userData],
	);

	const exportExcel = async () => {
		const res: any = await httpService.api({
			type: 'exportWorkPaper',
			data: {
				f: {
					...filters,
					unitIds: getSelectedUnits(filters.unitIds),
				},
			},
			responseType: 'blob',
			returnAllRes: true,
		});
		getExcelFileFromResponse(res);
		logEvent({
			eventName: EventName.exportWorkPaperExcel,
			data: {},
		});
	};

	const exportPdf = async () => {
		try {
			const res: { data: any; totalRow: any } = await httpService.api({
				type: 'getWorkPaper',
				data: {
					p: 0,
					ps: totalReports,
					f: filters,
				},
			});
			dispatch(incrementBusyIndicator());
			const arrangedData = arrangeData(res);
			const blob = await pdf(
				<WorkPaperPdfCreator
					pdfData={arrangedData}
					filters={filters}
				/>,
			).toBlob();
			dispatch(decrementBusyIndicator());

			let elm = document.createElement('a');
			elm.href = URL.createObjectURL(blob);
			elm.setAttribute('download', `SalaryDetail.pdf`);
			elm.click();
		} catch (e) {
			console.log({ e });
		}
	};

	return (
		<ReportsPageWrapper navigation={navigation} title='workPaper.title'>
			<View
				style={{
					maxHeight: height - 241,
					minHeight: height - 241,
				}}
			>
				<View
					style={{
						marginBottom: 31,
						zIndex: 5,
						flexDirection: 'row-reverse',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<FiltersContainer
						config={filtersData}
						onChange={handleFilter}
						customStyles={{
							container: {
								flexDirection: 'row-reverse',
							},
							filterContainer: {
								marginRight: 0,
								marginLeft: 15,
								zIndex: 5,
								flexDirection: 'row-reverse',
								alignItems: 'center',
							},
							label: {
								marginLeft: 10,
							},
						}}
					/>
					<View style={{ flex: 1 }} />
					{totalReports && lastMonth ? (
						<Typography
							color='lightPrimary'
							style={{ marginLeft: 20 }}
						>{`${i18nService.translate('general.employees')} ${
							showNumbers
								? hasMore
									? (page + 1) * pageSize
									: totalReports
								: ''
						} ${i18nService.translate(
							'general.from',
						)} ${totalReports}`}</Typography>
					) : null}
					<TouchableOpacity
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
							...Platform.select({
								web: {
									color: page
										? colors.lightPrimary
										: colors.grey,
								},
							}),
						}}
						onPress={page ? goPrev : undefined}
					>
						<ArrowBackIos
							style={{ transform: 'rotate(180deg)' }}
							color='inherit'
						/>
						<I18n
							color={page ? 'lightPrimary' : 'grey'}
							weight='normal'
						>
							general.prev
						</I18n>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							marginHorizontal: 20,
							flexDirection: 'row-reverse',
							alignItems: 'center',
							...Platform.select({
								web: {
									color: hasMore
										? colors.lightPrimary
										: colors.grey,
								},
							}),
						}}
						onPress={hasMore ? goNext : undefined}
					>
						<I18n
							color={hasMore ? 'lightPrimary' : 'grey'}
							weight='normal'
						>
							general.next
						</I18n>
						<ArrowBackIos color='inherit' />
					</TouchableOpacity>

					<TouchableOpacity
						onPress={rows?.length ? exportExcel : undefined}
					>
						<ImageIcon name='excel' />
					</TouchableOpacity>

					{/* <TouchableOpacity
						onPress={rows?.length ? exportPdf : undefined}
						style={{ marginRight: 10 }}
					>
						<ImageIcon name='downloadPDF' height={21} width={16} />
					</TouchableOpacity> */}
				</View>
				{!lastMonth ? (
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<I18n weight='bold' size={24}>{`${i18nService.translate(
							'salaryReportPage.noWorkPaperData',
						)} `}</I18n>
					</View>
				) : rows?.length || errorMessage ? (
					<Table
						headers={columns}
						data={rows || []}
						keyName={''}
						onSort={() => {}}
						getNextData={() => {}}
						hasFooter={false}
						showExpandHeader
						errorMessage={errorMessage}
						defaultScrollRight
						currentTableState={currentTableState}
						setCurrentTableState={setCurrentTableState}
						scrollToTop={scrollToTop}
						setScrollToTop={setScrollToTop}
					/>
				) : null}
			</View>
		</ReportsPageWrapper>
	);
};

export default WorkPaperPage;
