import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
	TouchableHighlight,
	View,
	StyleSheet,
	TouchableOpacity,
	ScrollView,
} from 'react-native';
import _, { compact } from 'lodash';

import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import Typography from '../../components/shared/Typography/Typography.component';
import colors from '../../config/colors';
import getShadowStyle from '../../config/shadow';
import { Option } from '../../interfaces/option.interface';
import { fromMonthToMonthList } from '../../models/fromMonthToMonth.model';
import { i18nService } from '../../services/i18n.service';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import httpService from '../../services/http.service';
import ModalConfirmActions from '../../modals/ModalConfirmActions/ModalConfirmActions.component';
import Tooltip from 'rc-tooltip';

export type FromMonthToMonthReportsFiltersType = 'emp' | 'dep' | 'status';

export type FromMonthToMonthReportsFiltersDataType = Record<
	'emp' | 'dep' | 'status',
	Option[] | string[] | string
>;

export type FromMonthToMonthReportsDataType = Record<
	keyof fromMonthToMonthList,
	string | number | string
>;

export type FromMonthToMonthReportsRowType =
	Partial<FromMonthToMonthReportsDataType>;

export const FromMonthToMonthReportsPageFilterSize = 20;

const BlueCheckbox = withStyles({
	root: {
		color: 'default',
		'&$checked': {
			color: '#4AAEEF',
		},
	},
	checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

const CheckboxHeader = ({
	onSelectAllCheckButton,
	isCheckboxAllSelected,
}: {
	onSelectAllCheckButton: () => void;
	isCheckboxAllSelected: boolean;
}) => {
	return isCheckboxAllSelected ? (
		<BlueCheckbox
			size={'small'}
			checked={true}
			onChange={(event: any) => {
				onSelectAllCheckButton();
			}}
		/>
	) : (
		<Checkbox onClick={() => onSelectAllCheckButton()} size={'small'} />
	);
};

export const checkIfCheckboxIsDisabled = (
	row: any,
	activeActionButtonType: boolean | string,
) => {
	let disabled;
	if (activeActionButtonType == 'approve') {
		disabled = row.original.status === 'AWAITING_APPROVAL' ? false : true;
	} else if (activeActionButtonType == 'disapprove') {
		disabled = row.original.status === 'APPROVED' ? false : true;
	}
	return disabled;
};

export const createHeadersForTable = (
	activeActionButtonType: boolean,
	checkIfRowSelected: (id: any) => boolean,
	onCheckboxChange: (id: any) => void,
	onSelectAllCheckButton: () => void,
	isCheckboxAllSelected: boolean,
	onLineChange: [],
) => {
	let fieldToLabelMap: FromMonthToMonthReportsRowType = {
		userId: activeActionButtonType ? (
			<CheckboxHeader
				isCheckboxAllSelected={isCheckboxAllSelected}
				onSelectAllCheckButton={onSelectAllCheckButton}
			/>
		) : (
			''
		),
		fullName: i18nService.translate(
			'FromMonthToMonthReportsReportPage.fullName',
		),
		teudatZeut: i18nService.translate(
			'FromMonthToMonthReportsReportPage.teudatZeut',
		),
		// statusCode: i18nService.translate('FromMonthToMonthReportsReportPage.statusCode'),
		status: i18nService.translate(
			'FromMonthToMonthReportsReportPage.status',
		),
		// departmentId: i18nService.translate('FromMonthToMonthReportsReportPage.departmentId'),
		// departmentAid: i18nService.translate('FromMonthToMonthReportsReportPage.departmentAid'),
		department: i18nService.translate(
			'FromMonthToMonthReportsReportPage.departmentName',
		),
		employeeCreateDate: i18nService.translate(
			'FromMonthToMonthReportsReportPage.employeeCreateDate',
		),
		submitDate: i18nService.translate(
			'FromMonthToMonthReportsReportPage.submitDate',
		),
		lastUpdate: i18nService.translate(
			'FromMonthToMonthReportsReportPage.lastUpdate',
		),
		attachedFiles: i18nService.translate(
			'FromMonthToMonthReportsReportPage.attachedFiles',
		),
	};

	fieldToLabelMap = _.pick(fieldToLabelMap, [
		'userId',
		'teudatZeut',
		'fullName',
		'department',
		'status',
	]);
	const FromMonthToMonthReportsHeaders: any = Object.entries(
		fieldToLabelMap,
	).map(([key, value]) => ({
		accessor: key,
		Header: value,
		isSortable: key === 'userId' ? false : true,
		CustomCell:
			key === 'teudatZeut'
				? ({ value, row }: any) => {
						return (
							<View>
								{(row.original?.changed ||
									onLineChange.includes(
										row.original?.id,
									)) && <ChangesIndicator />}
								<Typography
									color='primary'
									weight='bold'
									size={14}
								>
									{value}
								</Typography>
							</View>
						);
				  }
				: key === 'status'
				? ({ value, row }: any) => {
						return <StatusColorMatcher status={value} />;
				  }
				: key === 'attachedFiles'
				? ({ value, row }: any) => {
						return <DownloadSection value={value} row={row} />;
				  }
				: key === 'userId' //&& isSelected
				? ({ value, row }: any) => {
						return activeActionButtonType ? (
							<div
								onClick={(event: any) =>
									event.stopPropagation()
								}
							>
								<BlueCheckbox
									disabled={checkIfCheckboxIsDisabled(
										row,
										activeActionButtonType,
									)}
									size={'small'}
									checked={checkIfRowSelected(
										row.original.id,
									)}
									onChange={(event: any) => {
										onCheckboxChange(row);
									}}
								/>
							</div>
						) : (
							<></>
						);
				  }
				: null,
	}));
	return FromMonthToMonthReportsHeaders;
};

export const createFilterOptionsForTable = (
	filtersData: FromMonthToMonthReportsFiltersDataType,
	state: any,
	getEmployeesFilter: any,
	businessUnitsList: any[],
	selectUnitId: any[],
) => {
	return compact([
		{
			name: 'status',
			options: Object.entries(statusFromMonthToMonthReportsMatcher).map(
				([key, value]) => ({
					id: key,
					name: i18nService.translate(
						`FromMonthToMonthReportsReportPage.status.${value?.resolve}`,
					),
				}),
			),
			value: state.filter?.status?.map((val: StatusCodeToStringKeys) => ({
				id: statusCodeToString[val],
				name: i18nService.translate(
					`FromMonthToMonthReportsReportPage.status.${val}`,
				),
			})),
			placeholder: 'status',
			type: 'multiselect',
			key: `status-${JSON.stringify(filtersData.emp)}`,
			styles: {
				container: { flex: 1, minWidth: 150 },
			},
		},
		filtersData.dep.length > 1 && {
			name: 'dep',
			value: state.filter?.dep?.map((val: string) => ({
				id: val,
				name: (filtersData?.dep as any[]).find(
					(el: any) => String(el.id) === String(val),
				)?.name,
			})),
			options: filtersData.dep,
			type: 'multiselect',
			placeholder: 'departments',
			styles: { container: { flex: 1 } },
			key: `departments-${JSON.stringify(filtersData.emp)}`,
		},
		{
			name: 'emp',
			loadOptions: getEmployeesFilter,
			type: 'asyncmultiselect',
			placeholder: i18nService.translate(
				'usersManagement.employeesShort',
			),
			styles: {
				container: { flex: 1.7 },
				width: '100%',
			},
			key: `user-${JSON.stringify(filtersData.emp)}-${selectUnitId}`,
			alwaysRerender: true,
		},
		businessUnitsList?.length > 1
			? {
					name: 'unitIds',
					type: 'select',
					placeholder: 'businessUnits',
					defaultValue: Array.isArray(selectUnitId)
						? selectUnitId?.[0]
						: selectUnitId,
					options: businessUnitsList,
					hideDefaultOption: true,
					styles: {
						width: filtersData.dep.length > 1 ? 150 : 200,
					},
			  }
			: undefined,
	]);
};

export const fromMonthToMonthReportsResponce = {
	hasNext: false,
	total: 0,
	data: [],
};

export const fromMonthToMonthReportsFilters = {
	dep: [],
	emp: [],
	status: [],
};

type statusFromMonthToMonthReportsMatcherType = Record<
	string,
	Record<string, string>
>;

type StatusCodeToStringKeys = 'AWAITING_APPROVAL' | 'APPROVED' | 'EXPORTED';

const statusCodeToString: Record<StatusCodeToStringKeys, string> = {
	AWAITING_APPROVAL: 'ממתין לאישור',
	APPROVED: 'אושר להעברה לשכר',
	EXPORTED: 'נמשך ע״י מכפל',
};
export const statusFromMonthToMonthReportsMatcher: statusFromMonthToMonthReportsMatcherType =
	{
		'ממתין לאישור': { resolve: 'AWAITING_APPROVAL', color: 'orange' },
		'אושר להעברה לשכר': { resolve: 'APPROVED', color: 'blue' },
		'נמשך ע״י מכפל': {
			resolve: 'EXPORTED',
			color: 'green',
		},
	};

export const statusFromMonthToMonthReportsMatcherByResolve = (
	status: string,
) => {
	return Object.keys(statusFromMonthToMonthReportsMatcher).find(
		key => statusFromMonthToMonthReportsMatcher[key].resolve === status,
	);
};

export const statusFromMonthToMonthReportsMatcherForReadOnly = (
	statusHebr: string,
) => {
	if (
		statusFromMonthToMonthReportsMatcher[statusHebr].resolve === 'APPROVED'
	) {
		return 'true';
	}
	return 'false';
};

const StatusColorMatcher = ({ status }: { status: any }) => {
	return (
		<View style={{ display: 'flex', flexDirection: 'row' }}>
			<View>
				<div
					style={{
						width: 10,
						height: 10,
						backgroundColor: `${statusFromMonthToMonthReportsMatcher[status]?.color}`,
						borderRadius: 50,
						marginLeft: 3,
					}}
				></div>
			</View>
			<View>
				<I18n size={12} weight='normal'>
					{`FromMonthToMonthReportsReportPage.status.${status}`}
				</I18n>
			</View>
		</View>
	);
};

export const SelectReportsButtonMobileContainer = ({
	onDisselectAll,
	onApproveAllSelectedButtonPressed,
	stylesConf,
	setActiveActionButtonType,
	activeActionButtonType,
	disabled,
	onPressButton,
	onPressChangeStatusButton,
}: any) => {
	const selectReportButtonsContainer = StyleSheet.flatten([
		styles.selectReportButton,
		stylesConf?.containerIncludesOneBtn,
	]);

	const manageButtonsContainer = StyleSheet.flatten([
		styles.manageSelectButtonsContainer,
		stylesConf?.containerIncludesTwoBtn,
	]);

	const approveSelectButtonStyle = StyleSheet.flatten([
		styles.approveSelectButton,
		stylesConf?.buttonApprove,
	]);

	const onCancelClickHandler = (actionType: string) => {
		onDisselectAll();
		setActiveActionButtonType(false);
	}

	useEffect(() => {
		console.log({activeActionButtonType});
	}, [activeActionButtonType])
	return (
		<>
			{!activeActionButtonType && (
				<TouchableHighlight
					underlayColor='transparent'
					disabled={disabled}
					style={{
						opacity: disabled ? 0.7 : 1,
					}}
					onPress={() => onPressButton()}
				>
					<View
						style={[
							selectReportButtonsContainer,
							{ width: 'auto' },
						]}
					>
						<I18n
							style={{
								marginLeft: 15,
								marginRight: 15,
							}}
							color={'white'}
							weight='400'
							size={16}
							numberOfLines={1}
						>
							FromMonthToMonthReportsReportPage.reportView.action
						</I18n>
					</View>
				</TouchableHighlight>
			)}

			{activeActionButtonType && (
				<View style={manageButtonsContainer}>
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => onCancelClickHandler(activeActionButtonType)}
					>
						<View style={styles.resetSelectButton}>
							<I18n
								style={{ marginLeft: 20, marginRight: 20 }}
								color={'lightPrimary'}
								weight='400'
								size={16}
							>
								FromMonthToMonthReportsReportPage.back
							</I18n>
						</View>
					</TouchableHighlight>
					{
						<TouchableHighlight
							underlayColor='transparent'
							onPress={() => {
								onApproveAllSelectedButtonPressed();
							}}
						>
							<View style={approveSelectButtonStyle}>
								<I18n
									style={{ marginLeft: 20, marginRight: 20 }}
									color={'white'}
									weight='400'
									size={16}
								>
									FromMonthToMonthReportsReportPage.ok
								</I18n>
							</View>
						</TouchableHighlight>
					}
				</View>
			)}

			
			{!activeActionButtonType && <TouchableHighlight
					underlayColor='transparent'
					disabled={disabled}
					style={{
						opacity: disabled ? 0.7 : 1,
					}}
					onPress={() => onPressChangeStatusButton()}
				>
					<View
						style={[
							selectReportButtonsContainer,
							{ width: 'auto' },
						]}
					>
						<I18n
							style={{
								marginLeft: 15,
								marginRight: 15,
							}}
							color={'white'}
							weight='400'
							size={16}
							numberOfLines={1}
						>
							FromMonthToMonthReportsReportPage.reportView.changeStatus
						</I18n>
					</View>
			</TouchableHighlight>}
		</>
	);
};

export const SubMenuMobileItem = ({
	item,
	filterContentMapperValue,
	chosenRowsValue,
	setChosenRowsValue,
}: {
	item: { id: any; name: any };
	filterContentMapperValue: string;
	chosenRowsValue: string[];
	setChosenRowsValue: (selectedRows: string[]) => void;
}) => {
	const selectItemHandler = () => {
		if (chosenRowsValue?.find(el => el === item.id)) {
			const filteredArray = chosenRowsValue.filter(el => el !== item.id);
			setChosenRowsValue(filteredArray);
		} else {
			const updatedArray = [...chosenRowsValue, item.id];
			setChosenRowsValue(updatedArray);
		}
	};

	const isSelected = chosenRowsValue?.find(el => el === item.id)
		? true
		: false;

	return (
		<View>
			<TouchableHighlight
				underlayColor='transparent'
				onPress={() => selectItemHandler()}
			>
				<View style={styles.itemSubFilterContainer}>
					<View style={styles.itemSubFilterContainerMark}>
						{isSelected ? (
							<ImageIcon
								name={'checkedMark'}
								width={22}
								height={18}
							/>
						) : (
							<></>
						)}
					</View>
					<View style={styles.itemSubFilterContainerOptions}>
						<Typography color='darkGrey' weight='400' size={20}>
							{filterContentMapperValue === 'status'
								? item.id
								: item.name}
						</Typography>
					</View>
				</View>
			</TouchableHighlight>

			<View style={styles.separator}></View>
		</View>
	);
};

export const isFormFromMonthToMonthReportsOutdated = (dateToCheck: string) => {
	const deadline = `${dateToCheck + 1}-01-28`;
	const isOverdue = moment().isAfter(deadline);

	return isOverdue;
};

export const isFilterActive = (
	selectedFilters: FromMonthToMonthReportsFiltersDataType,
) => {
	let isAcive = false;
	Object.values(selectedFilters).forEach((filter: any) => {
		if (filter?.length) {
			isAcive = true;
		}
	});
	return isAcive;
};

export const onDisapproveSelectedButtonHandler = (	
	checkboxedReports: any,
	setCheckboxedReports: Function,
	setActiveActionButtonType: Function,
	selectUnitId: any
) => {
	console.log({checkboxedReports});
		if (checkboxedReports.length) {
			modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						callback();
						setCheckboxedReports([]);
						setActiveActionButtonType(false);
						const res: any = await httpService.api({
							type: 'disapproveMultipleDrafts',
							data: {
								ids:checkboxedReports
							},
							query: { unitId: selectUnitId?.[0] },
						});
					},
					
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>modal.FromMonthToMonthReports.selectAllReports.disapproveModal</I18n>
					</ModalConfirmActions>
				),		
			);
		} else {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>
							modal.FromMonthToMonthReports.selectAllReports.nonSelected
						</I18n>
					</AlertModal>
				),
			);
		}
}

export const onApproveSelectedButtonHandler = (
	checkboxedReports: any,
	tableState: any,
	isSelectAllReportsCheckbox: boolean,
	setCheckboxedReports: Function,
	setActiveActionButtonType: Function,
	selectUnitId: any,
) => {
	if (checkboxedReports.length) {
		modalService.openModal(
			null,
			{
				onCancel: (callback: Function) => {
					callback();
				},
				onSubmit: async (callback: Function) => {
					callback();
					setCheckboxedReports([]);
					setActiveActionButtonType(false);
					const res: any = await httpService.api({
						type: 'approveFromMonthToMonthReportsList',
						query: { unitId: selectUnitId?.[0] },
						data: {
							f: tableState.filter,
							search: tableState.search,
							ids: isSelectAllReportsCheckbox
								? []
								: checkboxedReports,
						},
					});

					const onlyOneEmp = res?.data?.length === 1 ? true : false;
					const errors = res?.data?.filter(
						(item: any) => !item.success,
					);
					const optionalErrors = res?.data?.filter(
						(item: any) =>
							!item.success &&
							item.errorMessage === 'OPTIONAL_DEDUCTION',
					);

					const allDataFailed =
						res?.data?.length === errors?.length ? true : false;
					const optionalFailed =
						errors?.length === optionalErrors?.length
							? true
							: false;

					const idsList = errors
						?.map((id: any) => id.teudatZeut)
						.join(', ');

					if (res.data?.length || res.jobId) {
						modalService.openModal(
							null,
							{
								submitBtnText: 'general.close',
								iconName: 'attention',
							},
							(props: any) => {
								const [isDetailsOpen, setIsDetailsOpen] =
									useState<boolean>(false);
								const [isCopied, setisCopied] =
									useState<boolean>(false);
								return (
									<AlertModal {...props}>
										<I18n style={{ textAlign: 'center' }}>
											{`modal.FromMonthToMonthReports.selectAllReports.${
												res.jobId
													? 'asyncSuccess'
													: !errors.length
													? 'success'
													: optionalFailed
													? onlyOneEmp
														? 'moreThenSix.onlyOneEmp.failure'
														: allDataFailed
														? 'moreThenSix.moreThenOneEmp.all.failure'
														: 'moreThenSix.moreThenOneEmp.some.failure'
													: 'failure'
											}`}
										</I18n>
										{isDetailsOpen && (
											<View style={styles.isDetailsOpen}>
												<View
													style={
														styles.isDetailsOpenTitle
													}
												>
													<I18n
														size={14}
														style={{
															textAlign: 'center',
														}}
													>
														modal.FromMonthToMonthReports.selectAllReports.moreThenSix.onlyOneEmp.failure.details.title
													</I18n>
													<button
														style={{
															background: 'none',
															border: 'none',
															cursor: 'pointer',
															padding: 0,
															margin: 0,
															height: '100%',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
															width: 30,
															flexDirection:
																'column',
														}}
														onClick={() => {
															navigator.clipboard.writeText(
																idsList,
															);
															setisCopied(true);
														}}
													>
														<ImageIcon
															width={
																isCopied
																	? 17.5
																	: 14
															}
															height={
																isCopied
																	? 14
																	: 17.5
															}
															name={
																isCopied
																	? 'checkedMark'
																	: 'multipleFile'
															}
														/>
														<I18n
															size={10}
															weight={
																isCopied
																	? 'normal'
																	: '400'
															}
															style={{
																paddingTop:
																	isCopied
																		? 4
																		: 2,
															}}
														>
															{isCopied
																? 'general.copied'
																: 'general.copy'}
														</I18n>
													</button>
												</View>
												<ScrollView
													style={{ marginTop: 5 }}
												>
													<Typography
														size={14}
														style={{
															textAlign: 'left',
														}}
													>
														{idsList}
													</Typography>
												</ScrollView>
											</View>
										)}
										{!res.jobId &&
											errors.length > 0 &&
											!onlyOneEmp &&
											optionalFailed && (
												<TouchableOpacity
													onPress={() =>
														setIsDetailsOpen(
															!isDetailsOpen,
														)
													}
												>
													<I18n
														size={16}
														color='primary'
														style={{
															marginTop: 10,
														}}
													>
														{`modal.FromMonthToMonthReports.selectAllReports.moreThenSix.onlyOneEmp.failure.${
															isDetailsOpen
																? 'closeDetails'
																: 'details'
														}`}
													</I18n>
												</TouchableOpacity>
											)}
									</AlertModal>
								);
							},
						);
					}
				},
				submitBtnText: 'general.true',
				cancelBtnText: 'general.false',
				iconName: 'question',
			},
			(props: any) => (
				<ModalConfirmActions {...props}>
					<I18n>modal.FromMonthToMonthReports.selectAllReports</I18n>
				</ModalConfirmActions>
			),
		);
	} else {
		modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
				iconName: 'attention',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>
						modal.FromMonthToMonthReports.selectAllReports.nonSelected
					</I18n>
				</AlertModal>
			),
		);
	}
};

export const makeUndoActionModal = (
	undoAction: any,
	makeUndoAction: Function,
) => {
	modalService.openModal(
		null,
		{
			submitBtnText: 'general.true',
			cancelBtnText: 'general.false',
			iconName: 'question',
			onCancel: (callback: Function) => {
				callback();
			},
			onSubmit: (callback: Function) => {
				makeUndoAction();
				callback();
			},
		},
		(props: any) => (
			<ModalConfirmActions {...props}>
				{undoAction.singleAction ? (
					<Typography style={{ textAlign: 'center' }}>
						{`${i18nService.translate(
							'FromMonthToMonthReportsReportPage.undoAction.singleAction.a',
						)} ${
							undoAction.componentCode
								? `${undoAction.componentCode} - ${undoAction.componentName}`
								: `${undoAction.componentName}`
						} ${i18nService.translate(
							'FromMonthToMonthReportsReportPage.undoAction.singleAction.b',
						)}${undoAction.user}?`}
					</Typography>
				) : (
					<Typography>
						{`${i18nService.translate(
							'FromMonthToMonthReportsReportPage.undoAction.comprehensive.a',
						)}${i18nService.translate(
							`FromMonthToMonthReports.comprehensive.editWage.selectAction.${undoAction.action}`,
						)} ${i18nService.translate(
							'FromMonthToMonthReportsReportPage.undoAction.comprehensive.b',
						)} ${
							undoAction.componentCode
								? `${undoAction.componentCode} - ${undoAction.componentName}`
								: `${undoAction.componentName}`
						}?`}
					</Typography>
				)}
			</ModalConfirmActions>
		),
	);
};

export const onSendReportModal = async (
	month: string,
	employee: object,
	setActiveActionButtonType: Function,
	selectUnitId: any,
) => {
	const res: any = await httpService.api({
		type: 'approveCustomerDraftData',
		query: { unitId: selectUnitId?.[0] },
		params: {
			id: employee.id,
			draftId: employee.draftId,
		},
	});
	let msg: string;
	if (res.error) {
		msg = i18nService.translate('FromMonthToMonthReports.sendReport.error');
	} else {
		msg = `${i18nService.translate(
			'FromMonthToMonthReports.sendReport.success.first',
		)} ${month} ${i18nService.translate(
			'FromMonthToMonthReports.sendReport.success.second',
		)} ${employee.fullName} ${i18nService.translate(
			'FromMonthToMonthReports.sendReport.success.third',
		)}`;
		setActiveActionButtonType("approve");
	}

	await modalService.openModal(
		null,
		{
			onSubmit: async (callback: Function) => {
				callback();
				setActiveActionButtonType(false);
			},
			submitBtnText: 'general.close',
			iconName: 'attention',
		},
		(props: any) => (
			<AlertModal {...props}>
				<Typography style={{ textAlign: 'center' }} size={18}>
					{msg}
				</Typography>
			</AlertModal>
		),
	);
};

const ChangesIndicator = () => {
	return (
		<View style={styles.changesIndicator}>
			<I18n size={8} color='white'>
				general.changes
			</I18n>
		</View>
	);
};

const styles = StyleSheet.create({
	btn: {
		height: 20,
		width: 25,
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	btnDisabled: {
		opacity: 0.5,
	},
	downQuant: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	selectReportButton: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	manageSelectButtonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	approveSelectButton: {
		height: 40,
		marginLeft: 18,
		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		justifyContent: 'center',
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	resetSelectButton: {
		height: 40,
		backgroundColor: colors.white,
		borderRadius: 45,
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: '#4aaeef',
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	itemSubFilterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginLeft: 15,
		marginRight: 38,
		height: 65,
	},
	itemSubFilterContainerMark: {
		display: 'flex',
		justifyContent: 'center',
	},
	itemSubFilterContainerOptions: {
		display: 'flex',
		justifyContent: 'center',
	},
	separator: {
		backgroundColor: 'rgb(82, 89, 98)',
		height: 1,
		opacity: 0.2,
	},
	changesIndicator: {
		position: 'absolute',
		backgroundColor: '#eb8b2d',
		padding: 3,
		width: 38,
		top: -17,
		right: -15,
		alignItems: 'center',
		borderBottomRightRadius: 7,
		borderBottomLeftRadius: 7,
	},
	isDetailsOpen: {
		marginTop: 16,
		maxHeight: 120,
		backgroundColor: colors.lightBlue,
		marginHorizontal: 10,
		padding: 10,
		width: '100%',
	},
	isDetailsOpenTitle: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
});
