import ChildRowTitle from '../TabsComponents/ChildRowTitle/ChildRowTitle';

export const childrenFields = {
	pageRoute: 'children',
	fields: [
		[
			{
				name: 'hasChildren',
				label: 'workerCard.editor.children.hasChildren',
				type: 'buttonsGroup',
				options: [
					{ label: 'general.true', value: true },
					{ label: 'general.false', value: false },
				],
				isRequired: true,
				onChange: (
					setValue: Function,
					getValues: Function,
					selectedValue: any,
					prevValue: any,
					trigger: Function,
					reset: Function,
				) => {
					const obj = getValues();

					reset(
						{
							...obj,
							hasChildren: selectedValue,
							children: selectedValue
								? [
										{
											name: '',
											idNumber: '',
											birthDate: '',
											myCustody: null,
											childAllowance: null,
											singleParent:
												getValues().employee
													?.maritalStatus ===
												'MARRIED'
													? false
													: null,
										},
								  ]
								: [],
							taxCreditReason:
								!selectedValue && obj.taxCreditReason
									? {
											...obj.taxCreditReason,
											childrenNotInMyCustodyFile: [],
											singleParentLivingSeparately: false,
											childrenNotInMyCustody: false,
											childrenWithDisability: false,
											numberOfChildrenWithDisability:
												undefined,
											childrenWithDisabilityFile: [],
									  }
									: obj.taxCreditReason,
						},
						{ keepErrors: true, keepDirty: true },
					);
				},
			},
			{
				name: 'children',
				type: 'array',
				maxLength: 13,
				onRemove: (
					setValue: Function,
					getValues: Function,
					reset: Function,
				) => {
					const childrens = getValues?.()?.children;
					if (childrens) {
						if (
							!childrens?.filter(child => !child.myCustody)
								?.length
						) {
							setValue(
								'taxCreditReason.childrenNotInMyCustody',
								false,
							);
							setValue(
								'taxCreditReason.childrenNotInMyCustodyFile',
								[],
							);
						}
					}
				},
				getDefautValue: (getValues: Function) => ({
					name: '',
					idNumber: '',
					birthDate: '',
					myCustody: null,
					childAllowance: null,
					singleParent:
						getValues().employee?.maritalStatus === 'MARRIED'
							? false
							: null,
				}),
				shouldDisplay: () => ({
					watch: ['hasChildren'],
					callback: (values: any) => values[0],
				}),
				fieldWrapperStyle: (isMobile: boolean) => ({
					width: !isMobile ? 'unset' : '100%',
				}),
				arrayWrapperStyle: (isMobile: boolean) => ({
					width: '100%',
				}),
				RowTitle: ChildRowTitle,
				fields: [
					{
						name: 'name',
						type: 'input',
						label: 'workerCard.editor.children.firstName',
						tooltip: 'workerCard.editor.children.firstName.tooltip',
						isRequired: true,
						maxLength: 50,
					},
					{
						name: 'idNumber',
						type: 'input',
						label: 'workerCard.editor.children.id',
						tooltip: 'workerCard.editor.children.id.tooltip',
						isRequired: true,
						maxLength: 9,
						onChange: (
							setValue: Function,
							getValues: Function,
							selectedValue: any,
							prevValue: any,
							trigger: Function,
						) => {
							if (selectedValue) {
								setTimeout(() => {
									trigger([
										...getValues().children.map(
											(child: any, index: number) =>
												`children[${index}].idNumber`,
										),
										getValues().spouse?.idNumber &&
											'spouse.idNumber',
									]);
								}, 500);
							}
						},
					},
					{
						name: 'birthDate',
						type: 'datepicker',
						maxDate: new Date(),
						label: 'workerCard.editor.children.birthDate',
						tooltip: 'workerCard.editor.children.birthDate.tooltip',
						placeholder: 'general.datePickerPlaceholder',
						isRequired: true,
					},
					{
						name: 'myCustody',
						type: 'buttonsGroup',
						options: [
							{ label: 'general.true', value: true },
							{ label: 'general.false', value: false },
						],
						label: 'workerCard.editor.children.myCustody',
						tooltip: 'workerCard.editor.children.myCustody.tooltip',
						isRequired: true,
						onChange: (
							setValue: Function,
							getValues: Function,
							selectedValue: any,
							prevValue: any,
							trigger: Function,
							reset: Function,
						) => {
							const obj = getValues();
							const notInMyCustody =
								(obj.children || []).filter(
									(child: any) => !child.myCustody,
								).length <= 1;

							if (notInMyCustody) {
								setTimeout(() => {
									reset(
										{
											...obj,
											taxCreditReason: obj.taxCreditReason
												? {
														...obj.taxCreditReason,

														...(selectedValue
															? {
																	childrenNotInMyCustody:
																		false,
																	childrenNotInMyCustodyFile:
																		[],
															  }
															: {
																	singleParentLivingSeparately:
																		false,
															  }),
												  }
												: undefined,
										},
										{ keepErrors: true, keepDirty: true },
									);
								}, 500);
							}
						},
					},
					{
						name: 'childAllowance',
						type: 'buttonsGroup',
						options: [
							{ label: 'general.true', value: true },
							{ label: 'general.false', value: false },
						],
						label: 'workerCard.editor.children.childAllowance',
						tooltip:
							'workerCard.editor.children.childAllowance.tooltip',
						isRequired: true,
						onChange: (
							setValue: Function,
							getValues: Function,
							selectedValue: any,
							prevValue: any,
							trigger: Function,
							reset: Function,
						) => {
							const obj = getValues();

							if (
								!selectedValue &&
								(obj.children || []).filter(
									(child: any) => !child.childAllowance,
								).length <= 1
							) {
								setTimeout(() => {
									reset(
										{
											...obj,
											taxCreditReason: obj.taxCreditReason
												? {
														...obj.taxCreditReason,
														singleParentLivingSeparately:
															false,
												  }
												: undefined,
										},
										{ keepErrors: true, keepDirty: true },
									);
								}, 500);
							}
						},
					},
					{
						name: 'singleParent',
						type: 'buttonsGroup',
						options: [
							{ label: 'general.true', value: true },
							{ label: 'general.false', value: false },
						],
						label: 'workerCard.editor.children.singleParent',
						tooltip:
							'workerCard.editor.children.singleParent.tooltip',
						isRequired: true,
						shouldDisabled: () => ({
							watch: ['employee.maritalStatus'],
							callback: (values: any) => values[0] === 'MARRIED',
						}),
					},
				],
			},
		],
	],
};
