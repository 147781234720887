import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	TouchableOpacity,
	Dimensions,
} from 'react-native';
import { useSelector } from 'react-redux';
import colors from '../../../config/colors';
import { Privileges } from '../../../constants/roleTypes.constant';
import { i18nService } from '../../../services/i18n.service';
import { logout } from '../../../store/actions/login.actions';
import { setRepEnterCustomerMode } from '../../../store/actions/repEnterMode.actions';
import {
	getEmployerRolesPrivileges,
	getUserData,
	getUserPrivilegesStatus,
} from '../../../store/selectors/login.selectors';
import {
	getEnterModeName,
	getIsEnterMode,
} from '../../../store/selectors/repEnterMode.selector';
import { dispatch } from '../../../store/store';
import { DoubleButtonContainer } from '../DrawerItems/DoubleButton/DoubleButton.container';
import EmployerName from '../EmployerName/EmployerName';
import I18n from '../i18n/I18n.component';
import ImageIcon from '../Icon/ImageIcon.component';
import Typography from '../Typography/Typography.component';

const WebHeader = ({ navigation }: any) => {
	const userData = useSelector(getUserData);
	const userPrivilegesStatus = useSelector(getUserPrivilegesStatus);
	const employerRolesPrivileges = useSelector(getEmployerRolesPrivileges);
	const isRepEnterMode = useSelector(getIsEnterMode);
	const repEnterModeName = useSelector(getEnterModeName);

	const [selectedUser, setSelectedUser] = useState('');

	return (
		<View style={styles.container} nativeID='innerWebHeader'>
			<View style={styles.rightSide}>
				<TouchableOpacity
					onPress={() => navigation.navigate('personalInformation')}
					disabled={!!isRepEnterMode}
					style={styles.userInfo}
				>
					<Typography size={14} weight='400' color='primary'>
						!
					</Typography>
					<Typography
						style={{ marginRight: 5 }}
						size={14}
						weight='normal'
						color='primary'
					>
						{`${userData?.first_name} ${userData?.last_name}`}
					</Typography>
					<I18n
						size={14}
						style={{ marginRight: 7 }}
						weight='normal'
						color='primary'
					>
						general.hi
					</I18n>
					<ImageIcon name={'profileDefault'} width={20} height={20} />
				</TouchableOpacity>

				<ImageIcon
					name={'michpal'}
					width={56}
					height={40}
					style={{ marginBottom: 10, marginLeft: 10 }}
				/>
			</View>
			{isRepEnterMode && (
				<View style={styles.leftSide}>
					<View style={styles.centerSide}>
						<I18n
							size={18}
							weight='normal'
							color='white'
							style={{ marginLeft: 10 }}
						>
							enterCustomer.detailsWebHeader
						</I18n>
						<Typography
							size={18}
							weight='normal'
							color='white'
							numberOfLines={1}
						>
							{repEnterModeName} ({isRepEnterMode})
						</Typography>
					</View>
				</View>
			)}
			<View style={styles.leftSide}>
				{userPrivilegesStatus && !isRepEnterMode && (
					<View style={{ marginLeft: 10 }}>
						<DoubleButtonContainer
							onSelect={user => setSelectedUser(user)}
							styleConf={{ width: 86, height: 26, textSize: 12 }}
						/>
					</View>
				)}
				{(employerRolesPrivileges?.includes(
					Privileges.ROLE_CUSTOMER_SETTING_WRITE,
				) ||
					employerRolesPrivileges?.includes(
						Privileges.ROLE_CUSTOMER_SETTING_READ,
					)) &&
					(userPrivilegesStatus
						? selectedUser === 'employer'
						: true) && (
						<TouchableOpacity
							onPress={() =>
								navigation.navigate('businessSettings')
							}
							style={{
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<ImageIcon
								name={
									isRepEnterMode
										? 'settingsOrange'
										: 'settings'
								}
								width={20}
								height={20}
								style={{ marginRight: 5, marginLeft: 10 }}
							/>
							<I18n
								size={14}
								style={{ marginRight: 15 }}
								weight='normal'
							>
								businessSettings.title
							</I18n>
						</TouchableOpacity>
					)}
				{!isRepEnterMode && (
					<>
						<View style={styles.employeeNameWrapper}>
							<EmployerName />
						</View>
						<TouchableHighlight
							style={{ padding: 15 }}
							underlayColor='transparent'
							onPress={() => dispatch(logout())}
						>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row-reverse',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<I18n
									size={14}
									weight='normal'
									color='darkGrey'
								>
									header.logout
								</I18n>
								<View style={{}}>
									<ImageIcon
										name={'exit'}
										width={20}
										height={20}
										style={{
											marginRight: 5,
										}}
									/>
								</View>
							</View>
						</TouchableHighlight>
					</>
				)}
				{isRepEnterMode && (
					<TouchableHighlight
						style={{ padding: 15 }}
						underlayColor='transparent'
						onPress={() => dispatch(setRepEnterCustomerMode({}))}
					>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row-reverse',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<I18n size={14} weight='normal' color='darkGrey'>
								enterCustomer.exit
							</I18n>
							<ImageIcon
								name={'enterCustomer'}
								width={20}
								height={20}
								style={{
									marginRight: 5,
									transform: [{ rotateY: '180deg' }],
								}}
							/>
						</View>
					</TouchableHighlight>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: 10,
	},
	leftSide: {
		flexDirection: 'row-reverse',
		justifyContent: 'center',
		alignItems: 'center',
	},
	userInfo: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 9,
		marginRight: 7,
	},
	rightSide: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	centerSide: {
		backgroundColor: colors.warning,
		height: 60,
		paddingHorizontal: 24,
		flexDirection: 'row-reverse',
		borderRadius: 10,
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: Dimensions.get('window').width - 650,
	},
	employeeNameWrapper: {
		// marginLeft: 15,
		// width: 115,
	},
});

export default WebHeader;
