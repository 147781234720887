import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import { FilterData } from '../../../components/web/Filters/Filters.container';
import {
	getDepartmentsFilterList,
	getEmployeesFilterList,
} from '../../../store/selectors/reportsFilters.selectors';
import {
	fetchDepartmentsFilterList,
	fetchEmployeesFilterList,
} from '../../../store/actions/reportsFilters.actions';
import { i18nService } from '../../../services/i18n.service';
import VacationReportPage from './VacationReportPage.component';
import { useDispatch, useSelector } from 'react-redux';
import {
	addCommaSeparatorToNumber,
	getMonthByQuaterNumber,
	parsePeriod,
	prepareRowNameForClient,
} from '../../../utils/reportsPagesHelpers.utils';
import {
	getFiltersConfig,
	initialHeaders,
	maxNumberOfEmployeesForAccordion,
} from './VacationReportPage.utils';
import { getExcelFileFromResponse } from '../../../utils/downloadFile.web.utils';
import {
	vacationReportReducer,
	VacationReportReducerState,
	Action,
	initialState,
	fetchDepartments,
	fetchEmployees,
	fetchVacationReportsExcel,
	fetchReportLastDate,
} from './vacationReportReducer';
import { useRoute } from '@react-navigation/core';
import { getUserData } from '../../../store/selectors/login.selectors';
import useBusinessUnitsState from '../../../hooks/useBusinessUnitsState';
import routes from '../../../config/routes';
import { EventName, logEvent } from '../../../services/analyticsAndLogs.service';



const VacationReportContainer = ({ navigation }: any) => {
	const pageFilterSize = 20;

	const dispatch = useDispatch();
	const [
		{
			filters,
			employees,
			departments,
			totalDepartmentsReports,
			sortEmployeesBy,
			employeesSortDirection,
			sortDepartmentsBy,
			departmentsSortDirection,
			selectedDepartmentForEmployeesFetch,
			hasMoreEmployees,
			totalEmployeesReports,
			employeesPageNumber,
			employeesPageSize,
			lastMonth,
			lastYear,
		},
		localDispatch,
	] = useReducer<React.Reducer<VacationReportReducerState, Action>>(
		vacationReportReducer,
		initialState,
	);

	const [tableData, setTableData] = useState<any>([]);
	const [defaultExpandedRows, setDefaultExpandedRows] = useState<any>({});
	const [currentTableState, setCurrentTableState] = useState<any>({});
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const [periodName, setPeriodName] = useState('');
	const [itIsNotInitialRequest, setItIsNotInitialRequest] =
		useState<boolean>(false);
	const [selectedDepartments, setSelectedDepartments] = useState<any>({
		dep: [],
	});
	const [headers, setHeaders] = useState<any>(initialHeaders);
	const [departmentId, setDepartmentId] = useState('');
	const [modalTitle, setModalTitle] = useState<string>('');
	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const route = useRoute() as any;
	const userData = useSelector(getUserData);

	const handleFilter = useCallback((data: FilterData) => {
		localDispatch({
			type: `update_${data.name}`,
			payload: data.name === 'unitIds' ? data : data.value,
		});
		if (data.name === 'departments') {
			setSelectedDepartments({
				dep: data.value.map((el: any) => el.id),
			});
		}
	}, []);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.vacationReports.privileges,
		onChangeFilter: handleFilter,
	});

	const getEmployeesFilter = async (
		search: string,
		loadedOptions: null,
		{
			page,
		}: {
			page: number;
		},
	) => {
		const res = await fetchEmployeesFilterList(route.name, {
			page,
			pageSize: pageFilterSize,
			search,
			filter: selectedDepartments,
			unitIds: getSelectedUnits(filters.unitIds),
		});

		return {
			options: res?.data?.map((el: any) => {
				return {
					value: el.employeeId,
					label: el.name + ` (${el.teudatZeut})`,
				};
			}),
			hasMore: pageFilterSize === res?.data?.length,
			additional: {
				page: page + 1,
			},
		};
	};
	const [filtersConfig, setFiltersConfig] = useState(
		getFiltersConfig(
			filters.period[0],
			departmentsFilterList,
			selectedDepartments,
			getEmployeesFilter,
			{
				toMonth: filters.to_month[0],
				toYear: filters.to_year[0],
				fromMonth: filters.from_month[0],
				fromYear: filters.from_year[0],
			},
			businessUnitsList,
			isBusinessUnitsMulti,
			filters.unitIds,
			`${JSON.stringify(userData)}${JSON.stringify(filters.unitIds)}`,
		),
	);
	const onModalBtnPressedHandler = () => {
		setModalOpened(false);
	};

	const onDepartmentsSort = useCallback((field, direction) => {
		localDispatch({
			type: 'set_departments_sort',
			payload: { field, direction },
		});
	}, []);

	const onEmployeesSort = useCallback((field, direction) => {
		localDispatch({
			type: 'set_employees_sort',
			payload: { field, direction },
		});
	}, []);

	useEffect(() => {
		setFiltersConfig(
			getFiltersConfig(
				filters.period[0],
				departmentsFilterList,
				selectedDepartments,
				getEmployeesFilter,
				{
					toMonth: filters.to_month[0],
					toYear: filters.to_year[0],
					fromMonth: filters.from_month[0],
					fromYear: filters.from_year[0],
				},
				businessUnitsList,
				isBusinessUnitsMulti,
				filters.unitIds,
				`${JSON.stringify(userData)}${JSON.stringify(filters.unitIds)}`,
			),
		);
	}, [
		filters,
		departmentsFilterList,
		selectedDepartments,
		businessUnitsList,
		isBusinessUnitsMulti,
	]);

	useEffect(() => {
		if (itIsNotInitialRequest && filters.to_month.length) {
			fetchDepartments({
				sortBy: sortDepartmentsBy,
				sortDirection: departmentsSortDirection,
				filter: {
					...filters,
					unitIds: getSelectedUnits(filters.unitIds),
				},
				dispatch: localDispatch,
			});
		}
	}, [filters, sortDepartmentsBy, departmentsSortDirection]);

	useEffect(() => {
		if (
			filters !== initialState.filters ||
			sortDepartmentsBy !== initialState.sortDepartmentsBy ||
			departmentsSortDirection !== initialState.departmentsSortDirection
		) {
			localDispatch({ type: 'reset_filters', payload: null });
			setSelectedDepartments({
				dep: [],
			});
		} else {
			setItIsNotInitialRequest(true);
		}
	}, [userData]);

	useEffect(() => {
		const preparedData = departments.map((item: any) => {
			for (const field in item) {
				if (typeof item[field] === 'number') {
					item[field] = addCommaSeparatorToNumber(item[field]);
				}
			}

			if (item.periods?.length) {
				const periods = item.periods.map((period: any) => {
					const newItem: any = {
						...period,
					};
					for (const field in period) {
						if (typeof period[field] === 'number') {
							newItem[field] = addCommaSeparatorToNumber(
								period[field],
							);
						}
					}
					return {
						...newItem,
						id: item.id,
						departmentName: item.name,
						isClickable: true,
						canHaveSubRows: true,
					};
				});
				const preparedItem = {
					...item,
					subRows: periods,
				};
				delete preparedItem.periods;

				return preparedItem;
			}

			return item;
		});
		setTableData(preparedData);
	}, [departments]);

	useEffect(() => {
		if (!employees.length || !totalEmployeesReports) {
			return;
		}

		if (totalEmployeesReports > maxNumberOfEmployeesForAccordion) {
			setModalOpened(true);
		} else {
			const preparedTableData = tableData.map((row: any, i: number) => {
				if (row.id === departmentId) {
					const newSubRows = row.subRows.map(
						(subRow: any, j: number) => {
							if (subRow.name === periodName) {
								subRow.subRows = employees;

								const expandedRows = {
									[i]: true,
									[`${i}.${j}`]: true,
								};

								setDefaultExpandedRows({
									...currentTableState.expanded,
									...expandedRows,
								});

								return subRow;
							}

							return subRow;
						},
					);

					row.subRows = newSubRows;

					return row;
				}

				return row;
			});
			setTableData(preparedTableData);
		}
	}, [employees, totalEmployeesReports]);

	useEffect(() => {
		if (totalDepartmentsReports) {
			const total: any = {};
			for (const field in totalDepartmentsReports) {
				total[field] = addCommaSeparatorToNumber(
					totalDepartmentsReports[field],
				);
			}
			const headers = initialHeaders.map(header => {
				if (!['expander', 'name'].includes(header.accessor)) {
					return {
						...header,
						Footer: total[header.accessor],
					};
				}

				if (header.accessor === 'name') {
					return {
						...header,
						Footer: i18nService.translate(
							'worklogReportPage.total',
						),
					};
				}

				return header;
			});

			setHeaders(headers);
		}
	}, [totalDepartmentsReports]);

	useEffect(() => {
		if (filters.unitIds.length || multiValue) {
			fetchReportLastDate(
				localDispatch,
				getSelectedUnits(filters.unitIds),
			);
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(filters.unitIds),
				),
			);
			setFiltersConfig(
				getFiltersConfig(
					filters.period[0],
					departmentsFilterList,
					selectedDepartments,
					getEmployeesFilter,
					{
						toMonth: filters.to_month[0],
						toYear: filters.to_year[0],
						fromMonth: filters.from_month[0],
						fromYear: filters.from_year[0],
					},
					businessUnitsList,
					isBusinessUnitsMulti,
					filters.unitIds,
				),
			);
		}
	}, [filters.unitIds]);

	useEffect(() => {
		if (selectedDepartmentForEmployeesFetch.length) {
			const filter = {
				...filters,
				dep: selectedDepartmentForEmployeesFetch,
			};
			getEmployeesList(
				{
					...filter,
				},
				localDispatch,
			);
		}
	}, [employeesSortDirection, sortEmployeesBy]);

	const getExcel = async () => {
		const res = await fetchVacationReportsExcel({
			sortBy: sortEmployeesBy,
			sortDirection: sortDepartmentsBy,
			filter: {
				...filters,
				unitIds: getSelectedUnits(filters.unitIds),
			},
		});
		if (res) {
			getExcelFileFromResponse(res);
			logEvent({
				eventName: EventName.exportVacationReportExcel ,
				data: {},
			});
		}
	};

	const getEmployeesList = (filter: any, dispatch: any) => {
		fetchEmployees({
			page: employeesPageNumber,
			pageSize: employeesPageSize,
			sortBy: sortEmployeesBy,
			sortDirection: employeesSortDirection,
			filter: {
				...filter,
				unitIds: getSelectedUnits(filter.unitIds),
			},
			dispatch,
		});
	};

	const onSubRowClick = useCallback(
		(row: any) => {
			if (!row.departmentName) {
				return;
			}
			if (row.subRows) {
				return;
			}
			const filterParams = parsePeriod(row.name);
			const periodName = prepareRowNameForClient(row.name);
			const filterEmployeeByQuarter =
				filters.period[0] === 'QUARTERLY'
					? filterParams.quarter
						? [getMonthByQuaterNumber(filterParams.quarter[0])]
						: [0]
					: [];
			setModalTitle(`${row.departmentName} - ${periodName}`);
			const filter = {
				...filters,
				dep: [row.id],
			};
			setDepartmentId(row.id);
			localDispatch({ type: 'set_selected_department', payload: row.id });
			setPeriodName(row.name);
			const to_month =
				filters.period[0] === 'MONTH'
					? filterParams.month
					: filters.period[0] === 'QUARTERLY'
					? [filterEmployeeByQuarter[0] + 2]
					: filter.to_month;
			const from_month =
				filters.period[0] === 'MONTH'
					? filterParams.month
					: filters.period[0] === 'QUARTERLY'
					? filterEmployeeByQuarter
					: filter.from_month;
			const to_year =
				filters.period[0] === 'YEAR'
					? filterParams.year
					: filter.to_year;
			const from_year =
				filters.period[0] === 'YEAR'
					? filterParams.year
					: filter.from_year;

			getEmployeesList(
				{ ...filter, to_month, from_month, to_year, from_year },
				localDispatch,
			);
		},
		[filters],
	);

	const getNextData = useCallback(() => {
		hasMoreEmployees && localDispatch({ type: 'increment_page' });
	}, [hasMoreEmployees]);

	const onLeftArrowClick = useCallback(
		(row: any) => {
			onSubRowClick(row);
		},
		[onSubRowClick],
	);

	return (
		<VacationReportPage
			filtersData={filtersConfig}
			onFilter={handleFilter}
			headers={headers}
			data={tableData}
			navigation={navigation}
			onSubRowClick={onSubRowClick}
			modalTitle={modalTitle}
			modalTableRows={employees}
			modalOpened={modalOpened}
			onModalBtnPressedHandler={onModalBtnPressedHandler}
			onSort={onDepartmentsSort}
			getNextData={getNextData}
			onEmployeesSort={onEmployeesSort}
			allEmployeesFetched={hasMoreEmployees}
			getExcel={getExcel}
			defaultExpandedRows={defaultExpandedRows}
			onLeftArrowClick={onLeftArrowClick}
			setCurrentTableState={setCurrentTableState}
		/>
	);
};

export default VacationReportContainer;
