import React, { useEffect, useRef } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native'; // For React Native
import { useSelector } from 'react-redux';
import { getLoginData } from '../../../store/selectors/login.selectors';
import { Platform } from 'react-native';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import { LoginState } from '../../../interfaces/redux.interface';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { getUserData,getUserTypes } from '../../../store/selectors/login.selectors';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';


// Custom hook to get the previous value
const usePrevious = (value:any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// Function to compute deep difference between two objects
const getObjectDiff = (obj1:any, obj2:any) => {
  if (!obj1 || !obj2) return obj2;
  const diff = Object.keys({ ...obj1, ...obj2 }).reduce((result:any, key) => {
    if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      result[key] = obj2[key];
    }
    return result;
  }, {});
  return Object.keys(diff).length ? diff : null;
};



const NavigationStateTracker = () => {
  const isReactNative = typeof useNavigation === 'function';
  // React Native
  const navigation = useNavigation();
  const route = useRoute()
  const {loginData} = useSelector(getLoginData);  
 
  
  const isWeb = useDevicePlatform() === DeviceType.WEB;
	const isMobileWeb = useDevicePlatform() === DeviceType.MOBILE_WEB;
  const userData = useSelector(getUserData);
  const { isEmployer, isEmployee, isRep } = useSelector(getUserTypes);
  const state = navigation.getState();
  
  // Web  
  const prevNavState = usePrevious(navigation.getState());
  const tempPrint = "***";

  useEffect(()=>{
    setAnalyticsOptions(loginData);
    console.log('******Start setAnalyticsOptions******')
		console.log(loginData + tempPrint);
		console.log('*******End setAnalyticsOptions*******')
  },[loginData,userData])

  useEffect(()=>{
		console.log('******Start******')
		console.log(window.location.href + tempPrint);
		console.log('*******End*******')
		const innerState = navigation.getState().routes[state.index].state;
		const currentRoute = innerState?.routes?.[innerState?.index || 0];
		const currentRouteName = currentRoute?.name;	
		console.log(currentRouteName + tempPrint);
		
		logEvent({
			eventName: EventName.screenView,
			data: {
				url: window.location.href,
				route: currentRouteName,
			},
			logDataToAnalytics: true,
		});
	},[window.location.href]);

  useEffect(() => {
    if (isReactNative) {
      const unsubscribe = navigation.addListener('state', (e) => {
        const currentState = e.data.state;
        //console.log(JSON.stringify(prevNavState));
       // console.log(JSON.stringify(currentState));
        const changes = getObjectDiff(prevNavState, currentState);
        console.log('Navigation state changed:', changes);
        console.log('Current route:', route.name);
      });
      return unsubscribe;
    } else {
      const changes = getObjectDiff(prevNavState, location);
      if (changes) {
        console.log('Route changed:', changes);
      }
    }
  }, [navigation, route, location, prevNavState, isReactNative]);

  const setAnalyticsOptions = (user:LoginState)=>{
    if(isWeb){
      if(userData?.id) {
        const analytics = getAnalytics();
        const fullName = `${userData?.first_name} ${userData?.last_name}`
        console.log(userData?.id + tempPrint);
        console.log(fullName + tempPrint);
        console.log("isEmployer: " + isEmployer + ",isEmployee: "+ isEmployee +",isRep: "+ isRep + tempPrint); 
        setUserId(analytics,userData?.id);     
        setUserProperties(analytics,{ isEmployer: isEmployer, isEmployee: isEmployee, isRep: isRep})
      }
    }
  }

  return null; // This component doesn't render anything
};


export default NavigationStateTracker;