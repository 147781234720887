import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import {
	Platform,
	StyleSheet,
	Switch,
	TouchableOpacity,
	View,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import PDFViewer from '../../../components/shared/PDFViewer/PDFViewer.component';
import colors from '../../../config/colors';
import { Privileges } from '../../../constants/roleTypes.constant';
import httpService from '../../../services/http.service';
import { modalService } from '../../../services/modal.service';
import { hasPermissionOnBusinessUnit } from '../../../utils/globals.utils';
import WorkerCardCompareModal from '../../WorkerCard/WorkerCardCompareModal/WorkerCardCompareModal';
import { openApproveModal } from '../../WorkerCard/WorkerCardEditor/WorkerCardViewer.utils';
import {
	downloadFile,
	downloadUrlApprovedManagerFile,
} from '../../WorkerCard/WorkerCardPreview/utils';
import { fillPdf } from '../../WorkerCard/WorkerCardPreview/workerCardPreviewBuilder';
import {
	downloadDesktopManagerAttachmentsHandler,
	getIsModuleActiveByUnitId,
} from '../Report101Page.utils';
import { ActionButton, reject } from './Report101PdfView.utils';

const Report101PdfView = (props: any) => {
	const {
		data,
		onRowClick,
		resetTablePage,
		moveToNextEmp,
		moveToPrevEmp,
		selectedRowIndex,
		disableNext,
	} = props;

	const [pdfUrl, setPdfUrl] = useState<string>();
	const [pdfMarkedFile, setPdfMarkedFile] = useState<string>();
	const [showReportDifference, setShowReportDifference] = useState(true);

	const [pdfOldFile, setPdfOldFile] = useState<string>();
	const [pdfFile, setPdfFile] = useState<string>();
	const [pdfFileName, setPdfFileName] = useState<string>();
	const canDoActions =
		!hasPermissionOnBusinessUnit(
			data.businessUnitId,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
		) || !getIsModuleActiveByUnitId(data.businessUnitId);

	useEffect(() => {
		(async () => {
			try {
				setPdfUrl(undefined);
				setPdfFile(undefined);
				if (
					data.id &&
					['APPROVED', 'PENDING_EMPLOYER'].includes(data.statusCode)
				) {
					const res: any = await httpService.api({
						type: 'getWorkerCardFinalFileManager',
						params: { formId: data.id },
					});

					if (res) {
						setPdfUrl(res);
						data?.changed && (await createOldFile());
					} else {
						const fileData = await fillPdf({
							formId: data.id,
							isEmployee: false,
							showPendingTitle:true
						});

						const file: string = await httpService.api({
							type: 'createWorkerCardFileByManager',
							params: { formId: data.id },
							data: { pdfForm: fileData?.file },
						});

						if (file) {
							setPdfUrl(file);
							data?.changed && (await createOldFile());
						}
					}
				} else {
					throw 'err';
				}
			} catch (e) {
				const fileData = await fillPdf({
					formId: data.id,
					isEmployee: false,
					showPendingTitle:true
				});
				setPdfFileName(fileData?.fileName);
				setPdfFile(fileData?.file);
				data?.changed && (await createOldFile());
			}
		})();
	}, [data?.id]);

	const createOldFile = async () => {
		try {
			const originalPdf: any = await httpService.api({
				type: 'getWorkerCardFinalFileManagerDiff',
				params: { formId: data.id },
			});

			const markedFileData = await fillPdf({
				formId: data.id,
				isEmployee: false,
				showHighligh: true,
				originalPdf,
				showPendingTitle:true
			});

			setPdfMarkedFile(markedFileData?.file);

			const oldFileData = await fillPdf({
				data: originalPdf,
				formId: data.id,
				isEmployee: false,
				showPendingTitle:true
			});

			setPdfFile(oldFileData?.file)

			setPdfOldFile(oldFileData?.file);
		} catch (e) {}
	};

	const onShowDifference = () => {
		setShowReportDifference(!showReportDifference);
	};

	const openCompareModal = async () => {
		await modalService.openModal(null, {}, (props: any) => (
			<WorkerCardCompareModal
				{...props}
				data={data}
				prevPdfFile={pdfOldFile}
				currentPdfFile={pdfMarkedFile}
			/>
		));
	};

	return (
		<View
			style={{
				flex: 1,
				backgroundColor: colors.white,
				width: '100%',
				height: '100%',
			}}
		>
			<View
				style={{
					height: 61,
					backgroundColor: colors.white,
					flexDirection: 'row',
					alignItems: 'center',
					padding: 10,
					paddingLeft: 20,
					paddingBottom: 0,
				}}
			>
				<ActionButton
					imageName={'downloadPDF'}
					text={'report101ReportPage.101ReportDownload'}
					onPress={async () => {
						if (!pdfUrl) {
							downloadFile(pdfFile, pdfFileName);
						} else {
							downloadUrlApprovedManagerFile(data.id, data);
						}
					}}
					disabled={!data && !pdfUrl}
				/>

				<ActionButton
					imageName={'downloadPaycheck'}
					text={'report101ReportPage.101ReportAttachments'}
					width={18}
					onPress={() =>
						downloadDesktopManagerAttachmentsHandler(data.id, {
							values: data,
						})
					}
					disabled={!data.attachedFiles}
				/>

				{data.statusCode === 'PENDING_EMPLOYER' && (
					<>
						<View style={styles.separator} />
						<ActionButton
							imageName={'approveFile'}
							text={'report101ReportPage.approve101Report'}
							onPress={() =>
								openApproveModal({
									formId: data?.id,
									data,
									cancel: resetTablePage,
								})
							}
							disabled={canDoActions}
						/>
						<ActionButton
							imageName={'rejectFile'}
							text={'report101ReportPage.cancel101Report'}
							onPress={() =>
								reject({
									formId: data?.id,
									cancel: resetTablePage,
								})
							}
							disabled={canDoActions}
						/>
					</>
				)}
				<View style={styles.separator} />

				{['PENDING_EMPLOYER', 'APPROVED'].includes(data.statusCode) && (
					<ActionButton
						imageName={'file'}
						text={'report101ReportPage.addComments'}
						onPress={() =>
							onRowClick(data, { forceNavigate: true })
						}
						disabled={canDoActions}
					/>
				)}

				{data?.changed && (
					<>
						<View style={styles.separator} />
						<View style={styles.buttonContainer}>
							<Switch
								trackColor={{
									false: '#d8d8d8',
									true: '#d8d8d8',
								}}
								thumbColor={colors.lightPrimary}
								activeThumbColor={colors.lightPrimary}
								ios_backgroundColor='#d8d8d8'
								onValueChange={onShowDifference}
								value={showReportDifference}
								style={{
									marginBottom: 5,
									height: 15,
									width: 25,
								}}
							/>
							<I18n weight='normal' size={12}>
								report101ReportPage.showDifference101Report
							</I18n>
						</View>

						<ActionButton
							imageName={'compare'}
							text={'report101ReportPage.compare101Report'}
							onPress={openCompareModal}
						/>
					</>
				)}

				<View style={{ flex: 1 }} />
				{!disableNext && (
					<TouchableOpacity
						style={{
							marginHorizontal: 20,
							flexDirection: 'row-reverse',
							alignItems: 'center',
							...Platform.select({
								web: { color: colors.primary },
							}),
						}}
						onPress={moveToNextEmp}
					>
						<I18n color={'primary'} weight='normal' size={14}>
							report101ReportPage.nextEmp
						</I18n>
						<ArrowBackIos color='inherit' style={{ height: 20 }} />
					</TouchableOpacity>
				)}
				{selectedRowIndex ? (
					<TouchableOpacity
						style={{
							flexDirection: 'row-reverse',
							alignItems: 'center',
							...Platform.select({
								web: { color: colors.primary },
							}),
						}}
						onPress={moveToPrevEmp}
					>
						<ArrowBackIos
							style={{ transform: 'rotate(180deg)', height: 20 }}
							color='inherit'
						/>
						<I18n color={'primary'} weight='normal' size={14}>
							report101ReportPage.prevEmp
						</I18n>
					</TouchableOpacity>
				) : null}
			</View>

			<View
				style={{
					paddingHorizontal: 20,
					paddingBottom: 20,
					height: 'calc(100% - 91px)',
					width: '100%',
				}}
			>
				{(pdfUrl || pdfFile) && (
					<PDFViewer
						pdfUrl={
							data?.changed
								? showReportDifference
									? ''
									: pdfUrl
								: pdfUrl
						}
						file={
							data?.changed
								? showReportDifference
									? pdfMarkedFile
									: pdfFile
								: pdfFile
						}
					/>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	separator: {
		height: 40,
		width: 1,
		backgroundColor: '#d8d8d9',
		marginHorizontal: 10,
	},
	buttonContainer: {
		width: 80,
		height: 53,
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export default Report101PdfView;
