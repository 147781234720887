import React, { useEffect, useState } from 'react';
import FileViewer from 'react-native-file-viewer';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	Dimensions,
	Platform,
	PermissionsAndroid,
} from 'react-native';
import { WebView } from 'react-native-web-webview';
import RNHTMLtoPDF from 'react-native-html-to-pdf';
import { WebView as NativeWebView } from 'react-native-webview';
import colors from '../../../config/colors';
import { FontAwesome } from '@expo/vector-icons';
import I18n from '../../../components/shared/i18n/I18n.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import Typography from '../../../components/shared/Typography/Typography.component';
import moment from 'moment';
import httpService from '../../../services/http.service';
import { generateTemplate, paycheckGenerator } from '../PaycheckService';
import { dispatch } from '../../../store/store';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../../../store/actions/config.actions';
import { SCREEN_SIZES } from '../../../constants';
import { isSafari } from '../payCheckTemplate';
import { useSelector } from 'react-redux';
import { getEmployer } from '../../../store/selectors/login.selectors';
import { goBack } from '../../../utils/goBack.utils';
import { BusinessUnit } from '../PaycheckPage.utils';
import Select from '../../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { i18nService } from '../../../services/i18n.service';
import {
	EventName,
	logEvent,
} from '../../../services/analyticsAndLogs.service';

const monthsMap: any = {
	january: 'ינואר',
	february: 'פברואר',
	march: 'מרץ',
	april: 'אפריל',
	may: 'מאי',
	june: 'יוני',
	july: 'יולי',
	august: 'אוגוסט',
	september: 'ספטמבר',
	october: 'אוקטובר',
	november: 'נובמבר',
	december: 'דצמבר',
};

const PaycheckView = (props: any) => {
	const [paycheckServerData, setPaycheckServerData] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [template, setTemplate] = useState('');
	const [safariMargin, setSafariMargin] = useState(0);
	const [businessUnitsList, setBusinessUnitsList] = useState<BusinessUnit[]>(
		[],
	);
	const [selectedBusinessUnit, setSelectedBusinessUnit] =
		useState<BusinessUnit | null>(null);
	const employerData = useSelector(getEmployer);
	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;

	const isWeb = useDevicePlatform() === DeviceType.WEB;
	const isMobileWeb = useDevicePlatform() === DeviceType.MOBILE_WEB;
	const windowWidth = Dimensions.get('window').width;

	const selectedDate =
		props?.selectedOptions?.selectedPaycheckDate ||
		props?.selectedDate ||
		props?.route?.params?.selectedDate;
	const workerId = props?.workerId;

	const paycheckWebViewStyle = StyleSheet.flatten([
		styles.paycheckView,
		{ marginRight: isWeb && !workerId ? 30 : 0 },
	]);
	const shouldShowUnitSelect = businessUnitsList.length > 1 && !isWeb;

	useEffect(() => {
		onMount();
	}, []);

	const onMount = async () => {
		if (Platform.OS !== 'web') {
			const crashlytics = (
				await import('@react-native-firebase/crashlytics')
			).default;
			crashlytics().log('PaycheckView');
		}
	};

	const calculateViewSize = () => {
		const sideMenu = windowWidth > SCREEN_SIZES.MEDIUM ? 320 : 0;
		const paddingsWeb = isWeb ? 37 + 22 : 0;
		const spaceBeetwenCarouse = isWeb ? 30 : 0;
		const carouseWidth = isWeb ? 290 : 0; //here is a little bit more than actual width 263
		const size =
			windowWidth -
			sideMenu -
			paddingsWeb -
			spaceBeetwenCarouse -
			carouseWidth;

		return size;
	};

	const downloadBtnStyle = StyleSheet.flatten([
		styles.btn,
		props.isErrorPaycheck && styles.btnDisabled,
	]);

	const onChangeBusinessUnit = (id: string) => {
		const chosenUnit: BusinessUnit = businessUnitsList.filter(
			(unit: BusinessUnit) => unit.id === id,
		)[0];

		if (chosenUnit) {
			setSelectedBusinessUnit(chosenUnit);
		}
	};

	const getBussinesUnitsByDate = async (date: string) => {
		try {
			const res: {
				list: BusinessUnit[];
				data: any;
			} = await httpService.api({
				type: 'getPaychecksBusinessUnits',
				params: {
					year: moment(date).format('YYYY'),
					month: moment(date).format('MM'),
				},
			});

			setSelectedBusinessUnit(res?.list?.length ? res.list[0] : null);
			setBusinessUnitsList(res.list);
			if (!res?.list?.length) {
				isWeb && props?.setIsErrorPaycheck?.(true);
				setIsLoading(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const size = calculateViewSize();
	const isMobile = !isWeb && !isMobileWeb;
	let zoom = (size * 100) / 794 - 1;
	if (isMobile || isSafari()) {
		zoom = 100;
	}
	let zoomScale = 1;
	// if (!isMobile && isSafari()) {
	// 	zoomScale = size / 794;
	// }

	const getPaycheckFile = () => {
		httpService
			.api({
				type: workerId
					? 'getPeycheckDataWithWorker'
					: 'getPeycheckData',
				params: workerId
					? {}
					: {
							year: moment(selectedDate).format('YYYY'),
							month: moment(selectedDate).format('MM'),
							unitId:
								props?.selectedOptions?.selectedBusinessUnit
									.numericId ||
								selectedBusinessUnit?.numericId,
					  },
				query: workerId
					? {
							profileId: workerId,
							year: moment(selectedDate).format('YYYY'),
							month: moment(selectedDate).format('MM'),
							unitId: props?.unitId,
					  }
					: {},
			})
			.then(async (res: any) => {
				if (res) {
					const generatedTemplate = generateTemplate(
						res.data,
						calculateViewSize(),
						!isWeb && !isMobileWeb,
						safariMargin,
						isMobileWeb,
					);
					isWeb && props?.setIsErrorPaycheck?.(false);
					setPaycheckServerData(res);
					setTemplate(generatedTemplate);
					props?.onFetchPaycheckData &&
						props?.onFetchPaycheckData({
							fileName: `${moment(selectedDate).format(
								'MM',
							)}-${moment(selectedDate).format('YYYY')}-${
								res.teudatZeut
							}`,
							width: calculateViewSize(),
						});
				}
			})
			.catch(err => {
				props?.onFetchPaycheckData && props?.onFetchPaycheckData({});
				isWeb && props?.setIsErrorPaycheck?.(true);
				logEvent({
					eventName: EventName.paycheckGenerateFailure,
					data: {
						data: {
							profileId: workerId,
							year: moment(selectedDate).format('YYYY'),
							month: moment(selectedDate).format('MM'),
							unitId: props?.unitId,
						},
						err,
					},
				});
			})
			.finally(() => {
				setIsLoading(false);
				props?.setIsRequestCompleted &&
					props.setIsRequestCompleted(true);
			});
	};

	const downloadPaycheckHandler = async () => {
		try {
			if (isWeb || isMobileWeb) {
				console.log('downloadPaycheckHandler web');
				const iframes = document.getElementsByTagName('iframe');
				const arr = [...(iframes as any)].filter(f =>
					f.outerHTML.startsWith('<iframe style='),
				);
				const iframeEl: any = arr[arr.length - 1];

				const scrolableEl: any =
					iframeEl && iframeEl.contentDocument.documentElement;
				if (iframeEl && scrolableEl) {
					scrolableEl.scrollTo(0, 0); //fix bug with save pdf when paycheck view scroled to bottom
				}

				const d = await import('./PaycheckUtils.web');
				d.download(
					template,
					`${moment(selectedDate).format('MM')}-${moment(
						selectedDate,
					).format('YYYY')}-${paycheckServerData.teudatZeut}`,
					isSafari(),
					isMobileWeb,
				);
			} else {
				console.log('downloadPaycheckHandler mobile');
				let granted =
					Platform.Version >= 33
						? PermissionsAndroid.RESULTS.GRANTED
						: 'ios';
				if (Platform.OS === 'android' && Platform.Version < 33) {
					granted = await PermissionsAndroid.request(
						PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
					);
				}
				dispatch(incrementBusyIndicator());
				if (
					Platform.OS === 'ios' ||
					granted === PermissionsAndroid.RESULTS.GRANTED
				) {
					const fileName = `${moment(selectedDate).format(
						'MM',
					)}-${moment(selectedDate).format('YYYY')}-${
						paycheckServerData.teudatZeut
					}`;
					const options = {
						html: template,
						fileName,
						directory:
							Platform.OS === 'android' ? undefined : 'Documents',
						height: Platform.OS === 'android' ? 843.25 : 1010.7,
						width: Platform.OS === 'android' ? 596.5 : 714.6,
					};

					let file = await RNHTMLtoPDF.convert(options);
					dispatch(decrementBusyIndicator());
					const PushNotification = (await import(
						'react-native-push-notification'
					)) as any;

					const RNFS = (await import('react-native-fs')) as any;
					const newPath = `${
						RNFS.DownloadDirectoryPath
					}/${fileName}_${Date.now()}.pdf`;

					if (Platform.OS === 'android' && file?.filePath) {
						await RNFS.moveFile(file.filePath, newPath);
					}
					if (Platform.OS === 'ios') {
						file?.filePath &&
							(await FileViewer.open(file?.filePath));
					} else {
						newPath && (await FileViewer.open(newPath));
					}

					PushNotification.localNotification({
						channelId: 'michpal-paycheck',
						message: i18nService.translate(
							'general.documentDownloadSuccsessfully',
						),
						userInfo: {
							filePath:
								Platform.OS === 'ios'
									? file?.filePath
									: newPath,
						},
					});
				} else {
					alert('please allow to access files');
				}
			}
		} catch (err) {
			logEvent({
				eventName: EventName.paycheckDownloadFailure,
				data: {
					template,
					err,
				},
			});
			dispatch(decrementBusyIndicator());
		}
	};

	useEffect(() => {
		if ((isWeb || isMobileWeb) && isSafari() && template && !safariMargin) {
			setTimeout(() => {
				const iframe = document.getElementsByTagName('iframe')[0];
				const height =
					iframe?.contentDocument?.firstElementChild?.lastElementChild
						?.scrollHeight || 1123;
				const data = paycheckGenerator(paycheckServerData.data);
				const numberOfPages = Object.keys(data).length;
				const pageSize = calculateViewSize();
				const correctHeight = 1123 * numberOfPages * (pageSize / 794);
				if (height > correctHeight) {
					const margins = -(height - correctHeight) / 2;
					setSafariMargin(margins);
					const generatedTemplate = generateTemplate(
						paycheckServerData.data,
						pageSize,
						!isWeb && !isMobileWeb,
						margins,
						isMobileWeb,
					);
					setTemplate(generatedTemplate);
				}
			}, 2000);
		}
	}, [template]);

	useEffect(() => {
		if (selectedDate) {
			if (
				!props?.selectedOptions?.selectedBusinessUnit &&
				!props?.unitId &&
				isWeb
			) {
				props?.setIsErrorPaycheck?.(true);
				return;
			}
			setIsLoading(true);
			if (isWeb) getPaycheckFile();
			else getBussinesUnitsByDate(selectedDate);
		} else {
			props?.setIsRequestCompleted && props.setIsRequestCompleted(true);
		}
	}, [selectedDate, workerId, props?.selectedOptions]);

	useEffect(() => {
		if (selectedBusinessUnit) {
			getPaycheckFile();
		}
	}, [selectedBusinessUnit]);

	return (
		<>
			{!isWeb && (
				<View style={styles.header}>
					<View style={styles.headerTop}>
						<TouchableHighlight
							underlayColor='transparent'
							onPress={() => goBack(props.navigation)}
						>
							<View style={styles.goBack}>
								<FontAwesome
									name='angle-left'
									style={{
										fontSize: 26,
										color: colors.darkGrey,
									}}
								/>
								<I18n
									style={styles.title}
									size={20}
									weight='400'
									color='darkGrey'
								>
									paycheckView.goBack
								</I18n>
							</View>
						</TouchableHighlight>
						<View style={styles.actionBtns}>
							<TouchableHighlight
								disabled={isLoading || !template}
								style={[
									downloadBtnStyle,
									{
										opacity:
											isLoading || !template ? 0.5 : 1,
									},
								]}
								underlayColor='transparent'
								onPress={e => {
									e.persist();
									downloadPaycheckHandler();
								}}
							>
								<ImageIcon
									name='downloadPaycheck'
									height={20}
									width={20}
								/>
							</TouchableHighlight>
						</View>
					</View>
					<View style={styles.headerText}>
						{selectedDate && (
							<Typography size={20} weight={'normal'}>
								{
									monthsMap[
										moment(selectedDate)
											.format('MMMM')
											.toLowerCase()
									]
								}{' '}
								{moment(selectedDate).format('YYYY')}
							</Typography>
						)}
						<I18n
							style={styles.title}
							size={16}
							weight='400'
							color='darkGrey'
						>
							paycheckView.currPaycheck
						</I18n>
					</View>
					{shouldShowUnitSelect && (
						<View style={styles.filterText}>
							<Select
								options={businessUnitsList}
								onChange={onChangeBusinessUnit}
								value={
									props?.selectedOptions?.selectedBusinessUnit
										?.id || selectedBusinessUnit?.id
								}
								style={{ width: 200 }}
							/>
							<I18n
								size={16}
								weight='400'
								color='darkGrey'
								style={{ marginHorizontal: 3 }}
							>
								:
							</I18n>
							<I18n size={16} weight='400' color='darkGrey'>
								general.business
							</I18n>
						</View>
					)}
					<View style={styles.separator} />
				</View>
			)}
			{!isLoading ? (
				<View style={paycheckWebViewStyle} nativeID='paycheckWebView'>
					{props?.isErrorPaycheck || !template ? (
						<View style={styles.errorMessage}>
							<I18n weight='bold' size={24}>
								paychecks.noPaycheck
							</I18n>
						</View>
					) : isMobileWeb || isWeb ? (
						<WebView
							originWhitelist={['*']}
							source={{
								html: template,
								// tamplate(paycheckServerData),
							}}
							scrollEnabled={true}
							style={{
								zoom: `${zoom}%`,
								transform: `scale(${zoomScale})`,
							}}
						/>
					) : (
						<NativeWebView
							scalesPageToFit={true}
							automaticallyAdjustContentInsets={true}
							originWhitelist={['*']}
							containerStyle={{
								width: '120%',
								height: '120%',
								zoom: `${zoom}%`,
								//transform: `scale(${zoomScale})`
							}}
							source={{
								html: template,
								// tamplate(paycheckServerData),
							}}
						/>
					)}
				</View>
			) : (
				<View></View>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'column',
		zIndex: 1,
	},
	paycheckView: {
		// position: 'relative',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginRight: 30,
		backgroundColor: colors.white,
		zIndex: 0,
	},
	goBack: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: 21,
	},
	title: {
		marginLeft: 8,
	},
	btn: {
		height: 20,
		width: 40,
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	headerTop: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 35,
		zIndex: 1,
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		marginRight: 18,
	},
	headerText: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 22,
		marginRight: 18,
	},
	filterText: {
		zIndex: 1,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 22,
		marginRight: 18,
		alignSelf: 'flex-end',
	},
	separator: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
		marginBottom: 16.5,
		marginLeft: 20,
		marginRight: 18,
		marginTop: 22.5,
	},
	errorMessage: {
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	btnDisabled: {
		opacity: 0.5,
	},
});

export default PaycheckView;
