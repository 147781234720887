import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import colors from '../../../config/colors';

import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { useNavigation } from '@react-navigation/native';
import { FontSize } from '../../../interfaces/core.interface';
import { convertDateToLocal } from '../../../utils/convertDateToLocal.utils';
import Typography from '../../../components/shared/Typography/Typography.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import Button from '../../../components/shared/Buttons/Button.component';

import {
	HomePageReportContentElType,
	HomePageReportContentKeyType,
} from '../HomePage.container';
import { stringDataTransform } from './WageDataSummary.utils';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges } from '../../../store/selectors/login.selectors';
import { Privileges } from '../../../constants/roleTypes.constant';
import { onPressUpdate } from '../../WorkerCard/workerCard.utils';
import { getDefaultDisplayWageMode } from '../../../store/selectors/config.selectors';
import { InAppMessagingButton } from '../../../components/shared/InAppMessagingButton/InAppMessagingButton';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';

type WageDataSummaryProps = {
	reportDateYear: string;
	reportContentData: HomePageReportContentElType;
	report101warning: boolean;
	isPaycheckBlockShown: boolean;
	form101Id: number;
};

const WageDataSummary = (props: WageDataSummaryProps) => {
	const navigation = useNavigation();
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);
	const hideWageMode = useSelector(getDefaultDisplayWageMode);
	const [showDetails, setShowDetails] = useState(hideWageMode || false);
	const localDateTime = props.reportDateYear
		? convertDateToLocal(props.reportDateYear).format('MM/YYYY')
		: '';

	const isMobileRef = useRef<boolean>(useDevicePlatform() !== DeviceType.WEB);
	const textFontSizeRef = useRef<FontSize>(isMobileRef.current ? 16 : 18);

	const wageDataSummaryContent = [
		{ name: 'total', label: 'סה״כ תשלומים', style: 'normal' },
		{
			name: 'deductions',
			label: 'ניכויים לרשויות וקופ״ג',
			style: 'normal',
		},
		{ name: 'otherDeductions', label: 'ניכויים אחרים', style: 'normal' },
		{ name: 'paymentNet', label: 'נטו לתשלום', style: 'bold' },
		{ name: 'vacationBalance', label: 'יתרת חופשה', style: 'normal' },
	];

	const valueDisplayHandler = (value: HomePageReportContentKeyType) => {
		const res = stringDataTransform(
			value as HomePageReportContentKeyType,
			props.reportContentData,
		);
		return res ? res : <Typography size={10}>---- ----</Typography>;
	};

	useEffect(() => {
		if (hideWageMode) {
			setShowDetails(true);
		}
	}, [hideWageMode]);

	return (
		<View style={styles.container}>
			<View style={styles.titleContainer}>
				<View style={styles.dateYear}>
					<Typography
						size={textFontSizeRef.current}
						weight='400'
						color='darkGrey'
					>
						{localDateTime}
					</Typography>
				</View>
				<View style={styles.titleWrap}>
					{!hideWageMode && (
						<Button
							buttonStyle={[
								styles.showButton,
								showDetails && {
									backgroundColor: colors.primary,
								},
							]}
							onPress={() => 
								{
									if (!showDetails){
										logEvent({
											eventName: EventName.reportWageDataSummaryShowButton,
											data: {},
										});
									}
									setShowDetails(prev => !prev)
								}
							}
						>
							<I18n
								size={14}
								weight={'normal'}
								color={showDetails ? 'white' : 'primary'}
							>
								{showDetails
									? 'reportView.wageDataSummary.hideButton'
									: 'reportView.wageDataSummary.showButton'}
							</I18n>
						</Button>
					)}
					{/* <InAppMessagingButton campaignId="s5mVwxDZlBDaLfjaXvUO" /> */}
					<View style={styles.titleText}>
						<I18n size={36} weight='normal' color='darkGrey'>
							reportView.wageDataSummary.title
						</I18n>
					</View>
				</View>
			</View>
			<View style={styles.bottomLineWeb} />

			{showDetails && (
				<View style={styles.contentContainer}>
					{wageDataSummaryContent.map(
						(el: {
							name: string;
							label: string;
							style: string;
						}) => {
							return (
								<View key={el.name}>
									{el.name === 'vacationBalance' && (
										<View
											style={styles.bottomLineWebMargined}
										/>
									)}
									<View style={styles.contentRow}>
										<View style={styles.contentRowData}>
											<Typography
												size={20}
												weight={'bold'}
												color={
													el.style === 'normal'
														? 'darkGrey'
														: 'primary'
												}
											>
												{props.reportContentData &&
													valueDisplayHandler(
														el.name as HomePageReportContentKeyType,
													)}
											</Typography>
										</View>
										<View style={styles.contentRowText}>
											<Typography
												size={20}
												weight={
													el.style === 'normal'
														? '400'
														: 'normal'
												}
												color={
													el.style === 'normal'
														? 'darkGrey'
														: 'primary'
												}
											>
												{el.label}
											</Typography>
										</View>
									</View>
									{el.label === 'יתרת חופשה' && (
										<View style={styles.bottomLineWeb} />
									)}
								</View>
							);
						},
					)}
				</View>
			)}
			<Button
				buttonStyle={styles.titleButton}
				onPress={() => {
					logEvent({
						eventName: EventName.reportSalaryButton,
						data: {},
					});
					navigation.navigate('paycheck', {
						screen: 'employee',
					});
				}}
			>
				<I18n size={14} weight={'normal'} color={'warning'}>
					reportView.wageDataSummary.titleButton
				</I18n>
			</Button>

			{!!props.form101Id &&
				props.isPaycheckBlockShown &&
				employeeRolesPrivileges?.includes(
					Privileges.ROLE_EMPLOYEE_FORM_WRITE,
				) && (
					<View style={styles.report101Container}>
						<View style={styles.report101ActionsContainer}>
							<Button
								buttonStyle={StyleSheet.flatten([
									styles.report101Button,
									{
										backgroundColor: props.report101warning
											? 'red'
											: '#4aaeef',
									},
								])}
								onPress={() => 
									{
										logEvent({
											eventName: EventName.report101Button,
											data: {},
										});
										onPressUpdate(navigation, props.form101Id)
									}
								}
							>
								<I18n
									size={14}
									weight={'normal'}
									color={'white'}
								>
									reportView.wageDataSummary.report101Button
								</I18n>
							</Button>
						</View>
						<View style={styles.report101Text}>
							{props.report101warning ? (
								<I18n size={16} weight={'400'} color={'red'}>
									reportView.wageDataSummary.report101TextWarning
								</I18n>
							) : (
								<I18n
									size={16}
									weight={'400'}
									color={'darkGrey'}
								>
									reportView.wageDataSummary.report101TextUpdated
								</I18n>
							)}
						</View>
					</View>
				)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		width: '100%',
		flexDirection: 'column',
		paddingTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
	},
	dateYear: {
		flexDirection: 'row',
		paddingTop: 10,
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 22,
	},
	titleWrap: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	titleText: {
		display: 'flex',
	},
	titleActionsContainer: {
		justifyContent: 'space-between',
	},
	titleButton: {
		alignSelf: 'flex-start',
		marginTop: 20,
		height: 32,
		width: 148,
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.warning,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
	showButton: {
		height: 28,
		paddingHorizontal: 20,
		marginEnd: 15,
		marginTop: 6,
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.primary,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
	bottomLineWeb: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
	},
	bottomLineWebMargined: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
		marginBottom: 23,
	},
	contentContainer: {
		paddingTop: 21,
	},
	contentRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginBottom: 26,
	},
	contentRowText: {
		minWidth: 200,
	},
	contentRowData: {
		display: 'flex',
	},
	report101Container: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 20,
	},
	report101Text: {
		display: 'flex',
	},
	report101ActionsContainer: {},
	report101Button: {
		alignSelf: 'center',
		height: 40,
		width: 145,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
});

export default WageDataSummary;
