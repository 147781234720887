import React, { useState } from 'react';
import {
	ImageBackground,
	StyleSheet,
	View,
	Platform,
	KeyboardAvoidingView,
} from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import colors from '../../config/colors';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import { UserCredentials } from './SignIn.container';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { useSelector } from 'react-redux';
import { getIsMaintenance } from '../../store/selectors/config.selectors';
import { Maintenance } from './components/Maintenance';
import { SigninWithPassword } from './components/LoginWithPassword';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SigninWithCode } from './components/LoginWithCode/LoginWithCode';

const imageSrc = require('../../../assets/images/login/bg.png');

enum LoginMode {
	password = 'password',
	code = 'code',
}

const tabs = [
	{
		id: LoginMode.password,
		title: 'loginWithPass',
	},
	{ id: LoginMode.code, title: 'loginWithCode' },
];
export interface SignInProps {
	navigation: any;
	username: string;
	password: string;
	errorMessage: string;
	hasErrorLoginPassword: boolean;
	shouldShowPassword: boolean;
	setUsername: (val: string) => void;
	setPassword: (val: string) => void;
	signIn: (data: UserCredentials) => void;
	toggleShowPassword: () => void;
	lastClipboardText: React.MutableRefObject<string>;
}

const SignIn = ({
	navigation,
	username,
	password,
	hasErrorLoginPassword,
	shouldShowPassword,
	setUsername,
	setPassword,
	signIn,
	toggleShowPassword,
	lastClipboardText,
	errorMessage,
}: SignInProps) => {
	const [loginMode, setLoginMode] = useState<LoginMode>(LoginMode.password);
	const currPlatform = useDevicePlatform();
	const isMobile: boolean = currPlatform !== DeviceType.WEB;
	const isMaintenance = useSelector(getIsMaintenance);

	const error = (
		<View style={[styles.errorText]}>
			{hasErrorLoginPassword && (
				<I18n color='failure' weight='normal' size={12}>
					{errorMessage === 'NO_CONNECTION'
						? 'errors.noConnection'
						: errorMessage === 'WRONG_CODE'
						? 'login.wrongCode'
						: errorMessage === 'WRONG_MOBILE'
						? 'login.loginWithCode.err'
						: 'login.error'}
				</I18n>
			)}
		</View>
	);

	return (
		<View style={styles.wrapper}>
			<ImageBackground
				source={imageSrc}
				resizeMode='center'
				style={styles.image}
			>
				<KeyboardAvoidingView
					behavior='padding'
					enabled={Platform.OS === 'ios'}
					keyboardVerticalOffset={100}
				>
					<View
						style={[
							styles.outerContainer,
							{
								width: isMobile ? 334 : 448,
								paddingVertical: 32,
							},
						]}
					>
						<View style={styles.container}>
							<View style={styles.logoSection}>
								<ImageIcon
									name={'michpal'}
									width={83}
									height={59}
								></ImageIcon>
							</View>
							<View style={styles.separatorLine}></View>
							{!isMaintenance && (
								<>
									<View
										style={{
											flexDirection: 'row-reverse',
											marginTop: 10,
											marginBottom: 25,
										}}
									>
										{tabs.map(t => (
											<TouchableOpacity
												onPress={() =>
													setLoginMode(t.id)
												}
												style={{
													padding: 10,
													borderBottomWidth: 5,
													borderBottomColor:
														loginMode === t.id
															? colors.primary
															: colors.white,
												}}
											>
												<I18n>{`login.${t.title}`}</I18n>
											</TouchableOpacity>
										))}
									</View>

									{loginMode === LoginMode.password ? (
										<SigninWithPassword
											navigation={navigation}
											hasError={
												hasErrorLoginPassword &&
												errorMessage !== 'NO_CONNECTION'
											}
											username={username}
											password={password}
											shouldShowPassword={
												shouldShowPassword
											}
											error={error}
											setUsername={setUsername}
											setPassword={setPassword}
											signIn={signIn}
											toggleShowPassword={
												toggleShowPassword
											}
										/>
									) : (
										<SigninWithCode
											error={error}
											hasError={
												hasErrorLoginPassword &&
												errorMessage !== 'NO_CONNECTION'
											}
											username={username}
											password={password}
											setUsername={setUsername}
											setPassword={setPassword}
											lastClipboardText={
												lastClipboardText
											}
										/>
									)}
								</>
							)}
							{isMaintenance && <Maintenance />}
						</View>
					</View>
				</KeyboardAvoidingView>
			</ImageBackground>
		</View>
	);
};

export const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		height: '100%',
		width: '100%',
		backgroundColor: '#efefef',
	},
	image: {
		resizeMode: 'center',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: 500,
	},
	outerContainer: {
		backgroundColor: colors.white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		paddingHorizontal: 16,
		paddingTop: 18,
		paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
	},
	credentials: {
		height: 115,
		marginBottom: 53,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	inputContainer: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 300,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: 220,
		height: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
	errorInput: {
		borderWidth: 1,
		borderColor: colors.failure,
	},
	errorText: {
		alignItems: 'center',
		marginBottom: 16,
	},
});
export default SignIn;
