import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import AsyncMultiSelectContainer from '../../../components/shared/AsyncMultiSelect/AsyncMultiSelect.container';
import colors from '../../../config/colors';
import { Divider } from 'react-native-paper';
import OutlineButtonContainer from '../../../components/shared/Buttons/OutlineButton.container';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';

interface RequestFieldsProps {
	type: string;
	list: Array<object>;
	onCancel: Function;
	onSubmit: Function;
	submitBtnText: string;
	cancelBtnText: string;
	buttonsCustomStyle: Object;
	onNext: Function;
	flexTableData: Array<object>;
	error: string;
}

const FromMonthToMonthAddNewLine = ({
	type,
	list,
	onCancel,
	onSubmit,
	submitBtnText,
	cancelBtnText,
	buttonsCustomStyle,
	onNext,
	error,
}: RequestFieldsProps) => {
	const [newLine, setNewLine] = useState(null);

	const getComponentsList = (query: string) => {
		const tampfilterList = list
			.filter(
				(item: any) =>
					item.name?.includes(query) || item.code?.includes(query),
			)
			.sort((a: any, b: any) => {
				if (a.code < b.code) return -1;
				if (a.code > b.code) return 1;
				return 0;
			});

		return {
			options: tampfilterList?.map((el: any) => ({
				value: el.code,
				label: `${el.code} - ${el.name}`,
			})),
		};
	};

	const addComponent = (component: any) => {
		const newComponent = component?.value?.map((item: any) => {
			return {
				code: item.id,
				name: list.find((el: any) => el.code === item.id)?.name,
				componentType: type,
			};
		});
		setNewLine(newComponent);
	};
	return (
		<View style={styles.container}>
			<View style={styles.title}>
				<I18n size={30} weight='normal' color='darkGrey'>
					{`FromMonthToMonthReports.DataFormByEmployee.${type}`}
				</I18n>
			</View>
			<Divider
				style={{
					backgroundColor: colors.darkGrey,
					opacity: 1,
					marginVertical: 0,
				}}
			/>
			<View style={styles.body}>
				<AsyncMultiSelectContainer
					key={'wageComponents'}
					config={{
						name: 'wageComponents',
						loadOptions: getComponentsList,
						placeholder: 'wageComponents',
						styles: { width: 350 },
					}}
					styles={{ control: { height: 40 }, menuList: { height: 80 } }}
					onChange={addComponent}
				/>
			</View>
			{error && (
				<I18n
					size={16}
					style={{ minHeight: 40, textAlign: 'center' }}
					weight='normal'
					color='red'
				>
					{error}
				</I18n>
			)}
			<Divider
				style={{
					backgroundColor: colors.darkGrey,
					opacity: 0.2,
					marginVertical: 24,
				}}
			/>
			<View style={styles.actionButtons}>
				<SolidButtonContainer
					overrideStyle={[styles.buttonOk, buttonsCustomStyle]}
					onPress={() => onSubmit(onNext, newLine)}
					disabled={!!error}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='white'
					>
						{submitBtnText}
					</I18n>
				</SolidButtonContainer>
				<OutlineButtonContainer
					overrideStyle={[styles.buttonCancel, buttonsCustomStyle]}
					onPress={() => onCancel(onNext)}
				>
					<I18n
						size={16}
						style={{ minHeight: 40 }}
						weight='normal'
						color='primary'
					>
						{cancelBtnText}
					</I18n>
				</OutlineButtonContainer>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	title: {
		marginBottom: 20,
	},
	body: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1,
	},
	select: {
		width: '100%',
	},
	actionButtons: {
		flexDirection: 'row-reverse',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
	buttonOk: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		backgroundColor: colors.lightPrimary,
		paddingHorizontal: 15,
		padding: 10,
	},
	buttonCancel: {
		minWidth: 92,
		height: 40,
		justifyContent: 'center',
		padding: 10,
	},
});

export default FromMonthToMonthAddNewLine;
