import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
} from '../../../store/actions/config.actions';
import { dispatch } from '../../../store/store';

export const download = async (pay: any, fileName: string, isSafari?: boolean, isMobileWeb?: boolean) => {
	dispatch(incrementBusyIndicator());
	let pdf = new jsPDF({
		orientation: 'p',
		unit: 'px',
		format: 'a4',
		hotfixes: ['px_scaling'],
	});
	
	const contents = document.getElementById('paycheckWebView')?.firstChild
		?.contentDocument.activeElement.children

	contents[0].id = "unused"
	contents[0].style = "display: none"
	contents[0].style = "display: unset"

	let sections = contents[0].children;
	let wrapper = document.getElementById('paycheckWebView')?.firstChild
		?.contentDocument;

	if (wrapper?.scrollingElement?.scrollTop){
		wrapper.scrollingElement.scrollTop = 0
	}	

	if (isSafari){
		const iframe = document.getElementsByTagName('iframe')[0];
		contents[0].className = "wrapperDownloadSafari"
		wrapper = iframe?.contentDocument;
		sections = iframe?.contentDocument?.firstElementChild?.lastElementChild?.firstElementChild?.children
	}

	let index = -1;
	for (let section of sections) {
		index++;
		const canvas = await html2canvas(section
			, {
			scale: 4,
			// width: 794,
			// windowWidth: 794,
			// height: 1123,
			// windowHeight: 1123,
	//		windowWidth: wrapper.documentElement.offsetWidth,
	//		windowHeight: wrapper.documentElement.offsetHeight,
		}
	);
		index && pdf.addPage();
		pdf.addImage(
			canvas.toDataURL('image/jpeg', 1.0),
			'JPG',
			0,
			0,
			794,
			1123,
		);
	}
	pdf.save(fileName);
	dispatch(decrementBusyIndicator());
	if (isMobileWeb){
		contents[0].id = "usePanzoom"
	}
	contents[0].className = "wrapper"
};
