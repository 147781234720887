import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	Platform,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { ButtonsContainer, Required } from '../PersonalInformation.utils';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import httpService from '../../../services/http.service';
import { WorkerCardModel } from '../../../models/workerCard.model';
import moment from 'moment';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import Loader from '../../../components/shared/Loader/Loader.component';

interface Props {
	saveData: any;
	dataToServer: (
		values: any,
		initiaValues: any,
		duplicate: boolean,
		saveData?: any,
	) => Promise<any>;
	values: WorkerCardModel;
	onResponse(ans: any): void;
	onNext(val: any): void;
	onCancel: (callback: Function) => void;
}

const SubmitFormAuthModal = ({
	saveData,
	dataToServer,
	values,
	onResponse,
	onNext,
}: Props) => {
	const platform = useDevicePlatform();
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [showCurrentPassword, setShowCurrentPassword] = useState(false);

	const isWeb = platform === DeviceType.WEB;

	const errorString = "Failed to verify user password.401";

	const handleChange = (value: any) => {
		setPassword(value);
	};
	const onSubmitPassword = async () => {
		try {
			setIsLoading(true);
			const res = await httpService.api({
				type: 'submitWorkerCard',
				params: {
					formId: saveData.id,
				},
				isFormData: true,
				notGenerateFormData: true,
				data: await dataToServer(values, values, false, {
					...saveData,
					submitDate: moment().format('DD/MM/YYYY'),
					verifyPassword: password,
				}),
			});
			setIsLoading(false);
			if(Platform.OS !== "web" && res === errorString){
				await modalService.openModal(
                    null,
                    {
                        submitBtnText: 'general.close',
                        iconName: 'attention',
                    },
                    (props: any) => (
                        <AlertModal {...props}>
                            <I18n>workerCard.editor.submit.failureSubmitPassword</I18n>
                        </AlertModal>
                    ),
                );
                return;
			}
			onNext(onResponse({ res: res, resText: 'success' }));
		} catch (e: any) {
			if(e?.data === errorString){
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>workerCard.editor.submit.failureSubmitPassword</I18n>
						</AlertModal>
					),
				);
			}else{
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>workerCard.editor.submit.failureSubmitForm</I18n>
						</AlertModal>
					),
				);
			}
		}
	};

	return (
		<>
			<View style={[styles.modalContainer, { width: isWeb ? 476 : 350 }]}>
				<View style={{ paddingBottom: 20 }}>
					<I18n size={30} weight='normal'>
						workerCard.pull.validUserTitle
					</I18n>
					<View style={styles.divider} />
					<I18n size={14}>workerCard.pull.validUserDescription</I18n>
				</View>

				<View>
					<View style={{ height: 83 }}>
						<View style={{ flexDirection: 'row-reverse' }}>
							<I18n size={14} weight='normal'>
								personalInformation.updateEmailModal.fillPassword
							</I18n>
							<Required />
						</View>
						<View style={styles.inputContainer}>
							<TouchableOpacity
								onPress={() =>
									setShowCurrentPassword(!showCurrentPassword)
								}
							>
								<ImageIcon
									name={
										showCurrentPassword
											? 'showPassword'
											: 'hidePassword'
									}
									width={22}
									height={14}
								/>
							</TouchableOpacity>
							<TextInput
								style={[styles.textInput, { width: 220 }]}
								underlineColorAndroid='transparent'
								value={password}
								onChangeText={e => handleChange(e)}
								secureTextEntry={!showCurrentPassword}
							/>
						</View>
					</View>
				</View>

				<ButtonsContainer
					acceptText={
						'personalInformation.updateEmailModal.sendPassword'
					}
					onAccept={onSubmitPassword}
					onCancel={onNext}
				/>
			</View>
			{isLoading && <Loader />}
		</>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		padding: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		justifyContent: 'space-between',
	},
	divider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		height: 1,
		width: '100%',
		marginVertical: 20,
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		marginTop: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
});

export default SubmitFormAuthModal;
