import React, { useRef, useMemo, useState, useCallback } from 'react';
import {
	Dimensions,
	TouchableHighlight,
	View,
	Animated,
	useWindowDimensions,
} from 'react-native';

import FiltersContainer, {
	FilterData,
} from '../../../components/web/Filters/Filters.container';
import TableContainer from '../../../components/web/ReactTable/Table.container';
import ReportsPageWrapper from '../../../components/web/ReportsPageWrapper/ReportsPageWrapper.component';
import { Breadcrumbs, Link, makeStyles } from '@material-ui/core';
import { ScrollSync } from 'react-scroll-sync';
import Tooltip from 'rc-tooltip';

import colors from '../../../config/colors';
import I18n from '../../../components/shared/i18n/I18n.component';
import { i18nService } from '../../../services/i18n.service';
import {
	FromMonthToMonthReportsRowType,
	makeUndoActionModal,
	SelectReportsButtonMobileContainer,
} from '../FromMonthToMonthReports.utils';
import Header from '../../../components/shared/Header/Header.container';
import WebHeader from '../../../components/shared/Header/WebHeader.component';
import FromMonthToMonthReportsSalaryDataFormByEmployee from '../ByEmployeeform/FromMonthToMonthReportsSalaryDataFormByEmployee';
import Typography from '../../../components/shared/Typography/Typography.component';
import MonthYearFormat from '../ByEmployeeform/Formats/monthYearFormat';
import { DoubleButton } from '../../../components/shared/DoubleButton/DoubleButton.component';
import ComprehensiveForm from '../ComprehensiveForm/FromMonthToMonthReportsComprehensiveForm';
import { handleAddBWageModal } from '../ByEmployeeform/FromMonthToMonthReportsSalaryDataFormByEmployee.utils';
import { EditDeleteButton } from '../../web/LocationSettingsPage/LocationSettingsPage.utils';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import httpService from '../../../services/http.service';

const useStyles = makeStyles((theme: any) => ({
	linkMain: {
		color: ({ isRowSelected }: any) =>
			isRowSelected ? colors.primary : colors.darkGrey,
		cursor: ({ isRowSelected }: any) =>
			isRowSelected ? 'pointer' : 'auto',
	},
	linkSelected: {
		color: colors.darkGrey,
	},
	separator: {
		color: '#3b70a4',
		fontFamily: 'RubikRegular',
		fontSize: '23px',
		fontWeight: 600,
	},
	titleWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		flexDirection: 'row-reverse',
		marginBottom: '-28px',
		width: '100%',
		height: 70,
	},
	titleWrapRight: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row-reverse',
		width: '70%',
	},
	titleWrapLeft: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		alignItems: 'center',
		flexDirection: 'row-reverse',
	},
	switch: {
		position: 'absolute',
		right: '45%',
		top: 7,
	},
}));

interface FromMonthToMonthReportsPageProps {
	navigation: any;
	headers: any;
	data: any[];
	reportNotFound: boolean;
	filtersData: any;
	total: number;
	onSort: (field: any, direction: any) => void;
	onLoadMore: () => void;
	onFilter: (data: FilterData) => void;
	onRowClick: (dataItem: any) => void;
	isRowSelected: boolean;
	selectedRowData?: FromMonthToMonthReportsRowType | null;
	navigateBack: () => void;
	onSearchOptionSelected: (obj: {
		name: string;
		value: string | undefined;
		isMulti: false;
	}) => void;
	onSearchTextChange: (value: string) => void;
	onDisselectAllReports: () => void;
	onSelectAllReports: () => void;
	isSelectAllReportsCheckbox: boolean;
	onApproveSelectedButtonPressed: () => void;
	isChooseBtnSelected: boolean;
	setIsChooseBtnSelected: (state: boolean) => void;
	onSelectedCancelButtonPressed: () => void;
	noReports?: boolean;
	isPdfMode?: boolean;
	setIsPdfMode?: (value: boolean) => void;
	resetTablePage?: () => void;
	moveToNextEmp?: () => void;
	moveToPrevEmp?: () => void;
	exportExcel(): void;
	selectedRowIndex?: number;
	disableNext?: boolean;
	customerDraftData: Object;
	setCustomerDraftData: () => void;
	getDisplayComponents: Function;
	displayComponents: any;
	setDisplayComponents: Function;
	setShowLastsMonths: Function;
	showLastsMonths: boolean;
	makeUndoAction: Function;
}

const FromMonthToMonthReportsPage = (
	props: FromMonthToMonthReportsPageProps | any,
) => {
	const {
		navigation,
		data,
		reportNotFound,
		headers,
		filtersData,
		onSort,
		onSortComprehensive,
		onLoadMore,
		onFilter,
		onRowClick,
		isRowSelected,
		selectedRowData,
		navigateBack,
		onSearchOptionSelected,
		onSearchTextChange,
		onDisselectAllReports,
		onSelectAllReports,
		isSelectAllReportsCheckbox,
		onApproveSelectedButtonPressed,
		onSelectedCancelButtonPressed,
		noReports,
		isPdfMode,
		setIsPdfMode,
		resetTablePage,
		moveToNextEmp,
		moveToPrevEmp,
		setShowLastsMonths,
		showLastsMonths,
		onSendReport,
		selectedRowIndex,
		disableNext,
		customerWageComponents,
		onSendReminderButtonHandler,
		total,
		exportExcel,
		activeActionButtonType,
		setActiveActionButtonType,
		disabled,
		customerDraftData,
		setCustomerDraftData,
		yearMonth,
		setOnLineChange,
		getEmployeesFilter,
		getDepartmentsFilter,
		swithScreenBy,
		setSwithScreenBy,
		handleSelectLine,
		displayComponents,
		setDisplayComponents,
		getDisplayComponents,
		sortingComprehensiveMode,
		userData,
		pageData,
		maxParameter,
		getUndoAction,
		undoAction,
		triggerUndo,
		makeUndoAction,
		isLoadingData,
		removeOrAddNewComponent,
		setRemoveOrAddNewComponent,
		triggerRemoveOrAddNewComponent,
		setTriggerRemoveOrAddNewComponent,
		undoIds,
		handleUndoIds,
		selectUnitId,
		setComprehensiveAddAdditional,
		...restProps
	} = props;
	const { height: windowHeight, scale: windowScale } = useWindowDimensions();
	const isSmallDimensions = useMemo(
		() => window.innerWidth <= 1600,
		[window.innerWidth],
	);
	const classes = useStyles({ isRowSelected });

	const animatedWidth = useRef(new Animated.Value(1)).current;
	const [isOpen, setIsOpen] = useState(true);

	const handleReduceScreen = () => {
		const endValue = isOpen ? 0 : 1;
		Animated.timing(animatedWidth, {
			toValue: endValue,
			duration: 600,
			useNativeDriver: false,
		}).start();
		setIsOpen(prev => !prev);
	};
	const handleAddBWage = useCallback(
		(editMode?: any) => {
			handleAddBWageModal(
				customerWageComponents,
				getEmployeesFilter,
				getDepartmentsFilter,
				displayComponents,
				maxParameter,
				total,
				pageData.page ? resetTablePage : setDisplayComponents,
				getUndoAction,
				handleUndoIds,
				setRemoveOrAddNewComponent,
				setTriggerRemoveOrAddNewComponent,
				setComprehensiveAddAdditional,
				selectUnitId,
				editMode.componentType ? editMode : null,
			);
		},
		[
			customerWageComponents,
			getEmployeesFilter,
			getDepartmentsFilter,
			displayComponents,
			setDisplayComponents,
			pageData,
			resetTablePage,
		],
	);

	const handleMakeUndoAction = () => {
		makeUndoActionModal(undoAction, makeUndoAction);
	};

	const onPressChangeStatusButton = async () => {
		!isOpen && handleReduceScreen();
		setActiveActionButtonType("disapprove")
	}

	return (
		<View
			style={{
				backgroundColor: colors.tableBackground,
				height: '100%',
			}}
		>
			<Header
				navigation={navigation}
				containerStyle={{ backgroundColor: colors.white }}
				component={WebHeader}
			/>
			<ReportsPageWrapper
				navigation={navigation}
				title={
					<div className={classes.titleWrap}>
						<div className={classes.titleWrapRight}>
							<Breadcrumbs
								separator='<'
								className={classes.separator}
							>
								<Link
									onClick={
										isRowSelected ? navigateBack : undefined
									}
									color='inherit'
									className={classes.linkMain}
									underline={
										isRowSelected ? 'always' : 'none'
									}
								>
									<Typography
										size={36}
										weight='normal'
										style={{ flex: 1 }}
									>
										{yearMonth
											? `${i18nService.translate(
													'FromMonthToMonthReportsPage.title',
											  )} ${MonthYearFormat(yearMonth)}`
											: i18nService.translate(
													'FromMonthToMonthReportsPage.title.placeHolder',
											  )}
									</Typography>
								</Link>
							</Breadcrumbs>
							<div className={classes.switch}>
								<DoubleButton
									activeButton={swithScreenBy}
									onButtonClick={(buttonPressed: number) =>
										setSwithScreenBy(buttonPressed)
									}
									stylesConf={{
										width: 90,
										height: 32,
										textSize: 12,
										borderColor: colors.white,
									}}
									leftButtonText={
										'FromMonthToMonthReports.components.switch.comprehensive'
									}
									rightButtonText={
										'FromMonthToMonthReports.components.switch.byEmployee'
									}
								/>
							</div>
						</div>
						<div className={classes.titleWrapLeft}>
							{swithScreenBy === 1 && (
								<SelectReportsButtonMobileContainer
									setActiveActionButtonType={ 
										setActiveActionButtonType
									}
									disabled={disabled}
									activeActionButtonType={
										activeActionButtonType
									}
									onDisselectAll={onDisselectAllReports}
									onApproveAllSelectedButtonPressed={
										onApproveSelectedButtonPressed
									}
									onSelectedCancelButtonPressed={
										onSelectedCancelButtonPressed
									}
									onPressChangeStatusButton={onPressChangeStatusButton}
									onPressButton={() => {
										!isOpen && handleReduceScreen();
										setActiveActionButtonType("approve");
									}}
									unitId={selectUnitId?.[0]}
									stylesConf={{
										containerIncludesOneBtn: {
											width: 178,
											marginLeft: 10,
										},
										buttonApprove: { marginLeft: 10 },
									}}
								/>
							)}
							{swithScreenBy === 0 && (
								<div className='plusBtn'>
									<EditDeleteButton
										iconName='roundPlus'
										onPress={handleAddBWage}
										disabled={disabled}
										height={70}
										width={70}
									/>
								</div>
							)}
							<div className='export'>
									<TouchableHighlight
										underlayColor='transparent'
											disabled={false}
											onPress={exportExcel}
											style={{
												opacity: 1,
											}}
									>
										<ImageIcon name={'excel'} height={20} width={20} />
									</TouchableHighlight>
							</div>
						</div>
					</div>
				}
				noHeader={true}
			>
				{isRowSelected && !reportNotFound && selectedRowData && (
					<View
						style={{
							zIndex: 3000,
							height: windowHeight,
						}}
					></View>
				)}
				<ScrollSync>
					<View
						style={{
							height:
								windowHeight - (isSmallDimensions ? 139 : 193),
							flexDirection: 'row-reverse',
							width: '100%',
						}}
					>
						<Animated.View
							style={{
								zIndex: 10001,
								width: animatedWidth.interpolate({
									inputRange: [0, 1],
									outputRange: ['0%', '45%'],
								}),
								maxWidth: '45%',
								flexDirection: 'row-reverse',
							}}
						>
							<View
								style={{
									width: '100%',
								}}
							>
								<View
									style={{
										marginBottom: 20,
										zIndex: 10001,
										width: '100%',

										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<FiltersContainer
										config={filtersData}
										onChange={onFilter}
										onSearchTextChange={onSearchTextChange}
										resetActiveSortByProps={[userData.id]}
										onSelection={id =>
											onSearchOptionSelected(id!)
										}
										customStyles={{
											container: {
												width: '100%',
												display: 'flex',
												flexDirection: 'row-reverse',
												opacity: isOpen ? 1 : 0,
											},
											filterContainer: {
												marginLeft: 15,
												zIndex: 10001,
											},
											otherFiltersWrapper: {
												width: '100%',
											},
										}}
									/>
								</View>
								{!noReports && (
									<TableContainer
										keyName={''}
										headers={headers}
										data={isLoadingData ? [] : data || []}
										count={12}
										onSort={onSort}
										onRowClick={onRowClick}
										hasFooter={false}
										getNextData={onLoadMore}
										selectedRowId={
											isPdfMode && selectedRowData?.id
										}
										specialRowColor={
											colors.tableBackgroundOpacity
										}
										height={windowHeight - 245}
										scrollSyncMode={
											swithScreenBy === 1 ? false : true
										}
										windowScale={windowScale / 2}
										resetActiveSortByProps={[
											userData.id,
											sortingComprehensiveMode,
										]}
									/>
								)}
							</View>
							<View
								style={{
									width: 3,
									height: '100%',
									borderTopWidth: 62,
									zIndex: 10001,
									borderColor: colors.pageBackground,
									backgroundColor: colors.boxSeparator,
								}}
							>
								<Tooltip
									trigger={['hover', 'click']}
									overlayInnerStyle={{ minHeight: 14 }}
									placement='left'
									overlay={
										<I18n size={14}>
											FromMonthToMonthReportsReportPage.reduceScreen.toolTip
										</I18n>
									}
								>
									<TouchableHighlight
										onPress={handleReduceScreen}
										underlayColor={colors.lightPrimary}
										style={{
											position: 'absolute',
											top: 0,
											left: isOpen ? 0 : -30,
											backgroundColor: colors.primary,
											zIndex: 2,
											width: 30,
											height: 52,
											borderTopEndRadius: isOpen ? 26 : 0,
											borderBottomEndRadius: isOpen
												? 26
												: 0,
											borderTopStartRadius: isOpen
												? 0
												: 26,
											borderBottomStartRadius: isOpen
												? 0
												: 26,
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Typography
											size={18}
											color={colors.white}
											weight='bold'
										>
											{isOpen ? '>' : '<'}
										</Typography>
									</TouchableHighlight>
								</Tooltip>
							</View>
						</Animated.View>
						{noReports && (
							<View
								style={{
									flex: 1,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<I18n
									weight='bold'
									size={24}
									style={{
										marginTop: 36,
										marginRight: 26,
									}}
								>
									FromMonthToMonthReportsReportPage.noReports
								</I18n>
							</View>
						)}
						{swithScreenBy === 1 && isPdfMode && selectedRowData && (
							<FromMonthToMonthReportsSalaryDataFormByEmployee
								isFullScreen={!isOpen}
								disabled={disabled}
								// onRowClick={onRowClick}
								data={selectedRowData}
								// resetTablePage={resetTablePage}
								handleMakeUndoAction={handleMakeUndoAction}
								moveToNextEmp={moveToNextEmp}
								moveToPrevEmp={moveToPrevEmp}
								customerWageComponents={customerWageComponents}
								selectedRowIndex={selectedRowIndex}
								disableNext={disableNext}
								onSendReport={onSendReport}
								setShowLastsMonths={setShowLastsMonths}
								showLastsMonths={showLastsMonths}
								customerDraftData={customerDraftData}
								setCustomerDraftData={setCustomerDraftData}
								MonthYearFormat={MonthYearFormat}
								setOnLineChange={setOnLineChange}
								getUndoAction={getUndoAction}
								undoAction={undoAction}
								triggerUndo={triggerUndo}
								userData={userData}
								undoIds={undoIds}
								handleUndoIds={handleUndoIds}
								selectUnitId={selectUnitId}
								isSmallDimensions={isSmallDimensions}
							/>
						)}
						{swithScreenBy === 0 &&
							isPdfMode &&
							selectedRowData && (
								<ComprehensiveForm
									data={data}
									selectedRowData={selectedRowData}
									disabled={disabled}
									handleAddBWage={handleAddBWage}
									customerWageComponents={
										customerWageComponents
									}
									isFullScreen={!isOpen}
									displayComponents={displayComponents}
									setDisplayComponents={setDisplayComponents}
									getDisplayComponents={getDisplayComponents}
									customerDraftData={customerDraftData}
									setCustomerDraftData={setCustomerDraftData}
									setOnLineChange={setOnLineChange}
									handleSelectLine={handleSelectLine}
									tableRealTimeHight={windowHeight - 245}
									onSort={onSortComprehensive}
									selectedRowIndex={selectedRowIndex}
									pageData={pageData}
									getUndoAction={getUndoAction}
									undoAction={undoAction}
									triggerUndo={triggerUndo}
									removeOrAddNewComponent={
										removeOrAddNewComponent
									}
									setRemoveOrAddNewComponent={
										setRemoveOrAddNewComponent
									}
									triggerRemoveOrAddNewComponent={
										triggerRemoveOrAddNewComponent
									}
									setTriggerRemoveOrAddNewComponent={
										setTriggerRemoveOrAddNewComponent
									}
									userData={userData}
									undoIds={undoIds}
									handleUndoIds={handleUndoIds}
									selectUnitId={selectUnitId}
								/>
							)}
					</View>
				</ScrollSync>
			</ReportsPageWrapper>
		</View>
	);
};

export default FromMonthToMonthReportsPage;
