import React, { useEffect, useState } from 'react';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
	updateDoc,
	increment,
	setDoc,
} from 'firebase/firestore';
import { modalService } from '../../../services/modal.service';
import { UserCampaignModal } from '../../../pages/HomePage/HomePageModal/UserCampaignModal';
import { db } from '../../../services/analyticsAndLogs.service';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../store/selectors/login.selectors';

export const InAppMessages = () => {
	const userData = useSelector(getUserData);
	const [pagesGroupId, setPagesGroupId] = useState(""); 
	const [campaignPages, setCampaignPages] = useState<any>();
	const [enableUserDismissal, setEnableUserDismissal] = useState();
	console.log('userDismissal: ', enableUserDismissal);

	useEffect(() => {
		getCampaignData();
		// initializeInsertData();
	}, []);

	useEffect(() => {
		if (campaignPages) {
			updateUserActivity();
			onWorkerCampaign();
		}
	}, [campaignPages]);

	const getCampaignData = async () => {
		/**
		 * Get all active campaigns
		 */

			const inAppCol = collection(db, 'InAppMessages');
			// console.log('inAppCol: ', inAppCol);
			const q = query(inAppCol, where('state', '==', "active"));
			const docs = await getDocs(q);

			if (docs.empty) {
				console.log('No matching documents.');
				return;
			}		
			const firstCampaignRef = docs.docs[0];
			const messageData = firstCampaignRef.data();
			setPagesGroupId(firstCampaignRef.data().id);
			console.log('messageData: ', messageData);
			setEnableUserDismissal(messageData.enableUserDismissal);
			// const pagesData = await getPages(messageData?.contentPagesList);

			const userActivityDocs = doc(
				db,
				'UserActivity',
				userData.user_id.toString(),
			);

			const userActivityData = (await getDoc(userActivityDocs)).data();

			if (userActivityData?.dismissesByTheUser === true) {
				return;
			}

			if (userActivityData?.count >= messageData.numberOfExposures) {
				return;
			}

			// setCampaignPages(pagesData);
	};

	// const getPages = async (docs: any) => {
	// 	const docRefIds = docs.map((d: any) => d.id.toString());
	// 	const contentPagesCol = collection(db, 'content_pages');
	// 	const docsSnapshot = await getDocs(contentPagesCol);
	// 	let pagesDataDocs = docsSnapshot.docs;
	// 	return [...pagesDataDocs.filter(d => docRefIds.includes(d.data().id))]		
	// };

	// update user count when show popup
	const updateUserActivity = async () => {
		try {
			// const inAppCol = collection(db, 'InAppMessages');
			// const getAppDocs = (await getDocs(inAppCol)).docs[0];
			const docRef = doc(db, 'UserActivity', userData.user_id.toString());
			await updateDoc(docRef, {
				count: increment(1),
			});
		} catch (err) {
			console.log('failed updating user activity', err);
		}
	};

	const updateUserDismissed = async (isChecked: boolean) => {
		try {
			const docRef = doc(db, 'InAppMessages', pagesGroupId);
			await updateDoc(docRef,{
				state: !isChecked
			})
		} catch (err) {
			console.log('failed updating user dismissed', err);
		}
	};

	// const initializeInsertData = async () => {
	// add user activity collection
	// console.log(' in initializeInsertData');
	// const userActivityCollection = collection(db, 'UserActivity');
	// const docRef = doc(userActivityCollection, userData.user_id.toString());

	// await setDoc(docRef, {
	// 	userId: userData.user_id,
	// 	messageId: 123,
	// 	count: 0,
	// 	dismissesByTheUser: false,
	// 	campaignId: 1,
	// });
	// collection(db, 'UserActivity');
	// console.log('userActivityCollection: ', userActivityCollection);
	// const docRef = doc(userActivityCollection, userData.user_id.toString());
	// console.log('docRef: ', docRef);
	// console.log('userActivityCollection: ', userActivityCollection);
	// await setDoc(docRef, {
	// 	userId: userData.user_id,
	// 	messageId: 123,
	// 	count: 0,
	// 	dismissesByTheUser: false,
	// 	campaignId: 1,
	// });
	// add campaign collection
	// const InAppMessagesCol = collection(db, 'InAppMessages');
	// const docRef = doc(InAppMessagesCol, 'campaign1');
	// await setDoc(docRef, {
	// 	id: 123,
	// 	name: 'first campaign',
	// 	createdAt: new Date(),
	// 	state: 'active',
	// 	informationPackageId: 1,
	// 	numberOfExposures: 1,
	// 	enableUserDismissal: 0,
	// });
	// await setDoc(docRef, {
	// 	id: 2,
	// 	createdAt: new Date(),
	// 	title: 'second page',
	// 	subTitle: 'second page sub title',
	// });
	// add pages collection
	// console.log(' in initializeInsertData');
	// const userActivityCollection = collection(
	// 	db,
	// 	'InAppMessages/campaign1/Pages',
	// );
	// console.log('userActivityCollection: ', userActivityCollection);
	// const docRef = doc(userActivityCollection, 'pageThree');
	// console.log('docRef: ', docRef);
	// console.log('userActivityCollection: ', userActivityCollection);
	// 	await setDoc(docRef, {
	// 		id: 3,
	// 		createdAt: new Date(),
	// 		title: 'Third page',
	// 		subTitle: 'Third page sub title',
	// 	});
	// };

	const onWorkerCampaign = async () => {
		// await modalService.openModal(null, {}, (props: any) => (
		// 	<UserCampaignModal
		// 		campaignPages={campaignPages}
		// 		openWithButton={false}
		// 		enableUserDismissal={enableUserDismissal}
		// 		onResponse={(isChecked: boolean) => {
		// 			updateUserDismissed(isChecked);
		// 		}}
		// 		{...props}
		// 	/>
		// ));
	};

	return <></>;
};
