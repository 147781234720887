import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dimensions, Platform, StyleSheet, Text, View } from 'react-native';
import colors from '../../../config/colors';

import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FontSize } from '../../../interfaces/core.interface';
import { convertDateToLocal } from '../../../utils/convertDateToLocal.utils';
import Typography from '../../../components/shared/Typography/Typography.component';
import I18n from '../../../components/shared/i18n/I18n.component';
import Button from '../../../components/shared/Buttons/Button.component';
import {
	HomePageReportContentElType,
	HomePageReportContentKeyType,
} from '../HomePage.container';
import { stringDataTransform } from './WageDataSummary.utils';
import { useSelector } from 'react-redux';
import { getEmployeeRolesPrivileges } from '../../../store/selectors/login.selectors';
import { Privileges } from '../../../constants/roleTypes.constant';
import { ScrollView } from 'react-native-gesture-handler';
import { onPressUpdate } from '../../WorkerCard/workerCard.utils';
import { getDefaultDisplayWageMode } from '../../../store/selectors/config.selectors';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';

import { InAppMessagingButton } from '../../../components/shared/InAppMessagingButton/InAppMessagingButton';

type WageDataSummaryMobileProps = {
	reportDateYear: string;
	reportContentData: HomePageReportContentElType;
	report101warning: boolean;
	isPaycheckBlockShown: boolean;
	isSummaryOnly?: boolean;
	form101Id: number;
};

const WageDataSummaryMobile = ({
	reportDateYear,
	reportContentData,
	report101warning,
	isPaycheckBlockShown,
	isSummaryOnly,
	form101Id,
}: WageDataSummaryMobileProps) => {
	const navigation = useNavigation();
	const hideWageMode = useSelector(getDefaultDisplayWageMode);
	const [showDetails, setShowDetails] = useState(hideWageMode || false);
	const employeeRolesPrivileges = useSelector(getEmployeeRolesPrivileges);
	const localDateTime = reportDateYear
		? convertDateToLocal(reportDateYear).format('MM/YYYY')
		: '';

	const { height } = Dimensions.get('window');
	const isMobileRef = useRef<boolean>(useDevicePlatform() !== DeviceType.WEB);
	const textFontSizeRef = useRef<FontSize>(isMobileRef.current ? 16 : 18);

	const container = StyleSheet.flatten([
		styles.container,
		isSummaryOnly && {
			height: height - (Platform.OS === 'ios' ? 220 : 150),
		},
	]);

	const wageDataSummaryContent = [
		{ name: 'total', label: 'סה״כ תשלומים', style: 'normal' },
		{
			name: 'deductions',
			label: 'ניכויים לרשויות וקופ״ג',
			style: 'normal',
		},
		{ name: 'otherDeductions', label: 'ניכויים אחרים', style: 'normal' },
		{ name: 'paymentNet', label: 'נטו לתשלום', style: 'bold' },
		{ name: 'vacationBalance', label: 'יתרת חופשה', style: 'normal' },
	];

	const valueDisplayHandler = (value: HomePageReportContentKeyType) => {
		const res = stringDataTransform(
			value as HomePageReportContentKeyType,
			reportContentData,
		);
		return res ? res : <Typography size={10}>---- ----</Typography>;
	};

	return (
		<ScrollView style={container}>
			<View style={styles.titleContainer}>
				<Typography
					size={textFontSizeRef.current}
					weight='400'
					color='darkGrey'
				>
					{localDateTime}
				</Typography>
				<View style={styles.titleWrap}>
					{!hideWageMode && isSummaryOnly && (
						<Button
							buttonStyle={[
								styles.showButton,
								showDetails && {
									backgroundColor: colors.primary,
								},
							]}
							onPress={() => 
								{
									if (!showDetails){
										logEvent({
											eventName: EventName.reportWageDataSummaryShowButton,
											data: {},
										});
									}
									setShowDetails(prev => !prev)
								}
							}
						>
							<I18n
								size={12}
								weight={'normal'}
								color={showDetails ? 'white' : 'primary'}
							>
								{showDetails
									? 'reportView.wageDataSummary.hideButton'
									: 'reportView.wageDataSummary.showButton'}
							</I18n>
						</Button>
					)}
					{/* <InAppMessagingButton campaignId='campaign1' /> */}
					<View style={styles.titleText}>
						<I18n size={24} weight='normal' color='darkGrey'>
							reportView.wageDataSummary.title
						</I18n>
					</View>
				</View>
			</View>
			<View style={styles.bottomLineWeb} />

			{(showDetails || !isSummaryOnly) && (
				<View style={styles.contentContainer}>
					{wageDataSummaryContent.map(
						(el: {
							name: string;
							label: string;
							style: string;
						}) => {
							return (
								<View key={el.name}>
									{el.name === 'vacationBalance' && (
										<View
											style={styles.bottomLineWebMargined}
										/>
									)}
									<View style={styles.contentRow}>
										<View style={styles.contentRowData}>
											<Typography
												size={16}
												weight={'bold'}
												color={
													el.style === 'normal'
														? 'darkGrey'
														: 'primary'
												}
											>
												{reportContentData &&
													valueDisplayHandler(
														el.name as HomePageReportContentKeyType,
													)}
											</Typography>
										</View>
										<View style={styles.contentRowText}>
											<Typography
												size={16}
												weight={
													el.style === 'normal'
														? '400'
														: 'normal'
												}
												color={
													el.style === 'normal'
														? 'darkGrey'
														: 'primary'
												}
											>
												{el.label}
											</Typography>
										</View>
									</View>
									{el.label === 'יתרת חופשה' && (
										<View style={styles.bottomLineWeb} />
									)}
								</View>
							);
						},
					)}
				</View>
			)}
			<View style={styles.titleActionsContainer}>
				<Button
					buttonStyle={styles.titleButton}
					onPress={() => {
						logEvent({
							eventName: EventName.reportSalaryButton,
							data: {},
						});
						navigation.navigate('paycheck', {
							screen: 'employee',
						});
					}}
				>
					<I18n size={14} weight={'normal'} color={'warning'}>
						reportView.wageDataSummary.titleButton
					</I18n>
				</Button>
			</View>
			{!!form101Id &&
				isPaycheckBlockShown &&
				employeeRolesPrivileges?.includes(
					Privileges.ROLE_EMPLOYEE_FORM_WRITE,
				) && (
					<View style={styles.report101Container}>
						<View style={styles.report101ActionsContainer}>
							<Button
								buttonStyle={[
									styles.report101Button,
									{
										backgroundColor: report101warning
											? 'red'
											: '#4aaeef',
									},
								]}
								onPress={() => 
									{
										logEvent({
											eventName: EventName.report101Button,
											data: {},
										});
										onPressUpdate(navigation, form101Id)
									}
								}
							>
								<I18n
									size={14}
									weight={'normal'}
									color={'white'}
								>
									reportView.wageDataSummary.report101Button
								</I18n>
							</Button>
						</View>
						<View style={styles.report101Text}>
							{report101warning ? (
								<I18n size={12} weight={'400'} color={'red'}>
									reportView.wageDataSummary.report101TextWarning
								</I18n>
							) : (
								<I18n
									size={12}
									weight={'400'}
									color={'darkGrey'}
								>
									reportView.wageDataSummary.report101TextUpdated
								</I18n>
							)}
						</View>
					</View>
				)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: 'white',
		width: '100%',
		flexDirection: 'column',
		padding: 30,
	},
	titleContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'baseline',
		marginBottom: 22,
	},
	titleWrap: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	titleText: {
		display: 'flex',
	},
	titleActionsContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'flex-end',
		marginVertical: 20,
	},
	titleButton: {
		alignSelf: 'center',
		height: 32,
		width: 148,
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.warning,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
	showButton: {
		height: 22,
		paddingHorizontal: 15,
		marginEnd: 8,
		marginTop: 4,
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.primary,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
	bottomLineWeb: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
	},
	bottomLineWebMargined: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
		marginBottom: 23,
	},
	contentContainer: {
		paddingTop: 21,
	},
	contentRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginBottom: 26,
	},
	contentRowText: {
		minWidth: 200,
	},
	contentRowData: {
		display: 'flex',
	},
	report101Container: {
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		marginTop: 20,
		// marginBottom: 49,
	},
	report101Text: {
		display: 'flex',
		marginBottom: 5,
	},
	report101ActionsContainer: {},
	report101Button: {
		alignSelf: 'center',
		height: 40,
		width: 145,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
});

export default WageDataSummaryMobile;
