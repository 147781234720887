import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	Platform,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { ButtonsContainer, Required } from '../PersonalInformation.utils';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { UserDetails } from '../PersonalInformation.container';
import Typography from '../../../components/shared/Typography/Typography.component';
import httpService from '../../../services/http.service';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';

interface Props {
	userDetails: UserDetails;
	onNext(val: any): void;
	onResponse(ans: boolean): void;
}

const UpdateEmailModal = ({ onNext, userDetails, onResponse }: Props) => {
	const platform = useDevicePlatform();

	const [
		{ currentPassword, currentValid, showCurrentPassword },
		setCurrentPassword,
	] = useState({
		currentPassword: '',
		currentValid: true,
		showCurrentPassword: false,
	});
	const [newEmail, setNewEmail] = useState('');
	const [invalidEmail, setInvalidEmail] = useState(false);

	const isWeb = platform === DeviceType.WEB;

	const validateEmail = () => {
		const regExp =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isValid = regExp.test(newEmail);
		setInvalidEmail(!isValid);
		return isValid;
	};

	const onUpdateEmail = async () => {
		try {
			const isValid = validateEmail();
			if (isValid) {
				const res = await httpService.api<any>({
					type: 'updateUserEmail',
					data: {
						password: currentPassword,
						email: newEmail,
					},
				});
				logEvent({
					eventName: EventName.personalInformationChange,
					data: {change: 'UpdateEmail'},
					logDataToAnalytics: true,
				});
				onNext(onResponse(!!res));
			} else {
				setInvalidEmail(true);
			}
		} catch (e) {}
	};

	return (
		<View style={[styles.modalContainer, { width: isWeb ? 476 : 350 }]}>
			<View style={{ paddingBottom: 20 }}>
				<I18n size={30} weight='normal'>
					personalInformation.updateEmailModal.title
				</I18n>
				<View style={styles.divider} />
				<I18n size={14}>
					personalInformation.updateEmailModal.description
				</I18n>
			</View>

			<View>
				<View style={{ height: 83 }}>
					<View style={{ flexDirection: 'row-reverse' }}>
						<I18n size={14} weight='normal'>
							personalInformation.updatePasswordModal.pressPassword
						</I18n>
						<Required />
					</View>
					<View style={styles.inputContainer}>
						<TouchableOpacity
							onPress={() =>
								setCurrentPassword(prev => ({
									...prev,
									showCurrentPassword: !showCurrentPassword,
								}))
							}
						>
							<ImageIcon
								name={
									showCurrentPassword
										? 'showPassword'
										: 'hidePassword'
								}
								width={22}
								height={14}
							/>
						</TouchableOpacity>
						<TextInput
							style={[styles.textInput, { width: 220 }]}
							underlineColorAndroid='transparent'
							value={currentPassword}
							onChangeText={val =>
								setCurrentPassword(prev => ({
									...prev,
									currentPassword: val,
								}))
							}
							secureTextEntry={!showCurrentPassword}
						/>
					</View>
					{!currentValid && (
						<I18n color='failure' weight='normal' size={12}>
							personalInformation.updateEmailModal.wrongPassword
						</I18n>
					)}
				</View>

				<View style={{ height: 83 }}>
					<View>
						<I18n size={14} weight='normal'>
							personalInformation.updateEmailModal.currentEmail
						</I18n>
					</View>
					<View style={styles.inputContainer}>
						<Typography
							style={[styles.textInput, { height: undefined }]}
							size={14}
							color='darkGrey'
						>
							{userDetails?.email || ''}
						</Typography>
					</View>
				</View>

				<View style={{ height: 83 }}>
					<View style={{ flexDirection: 'row-reverse' }}>
						<I18n size={14} weight='normal'>
							personalInformation.updateEmailModal.newEmail
						</I18n>
						<Required />
					</View>
					<View style={styles.inputContainer}>
						<TextInput
							placeholder='example@example.com'
							style={styles.textInput}
							underlineColorAndroid='transparent'
							value={newEmail}
							onChangeText={val => setNewEmail(val)}
							keyboardType='email-address'
							// onBlur={validateEmail}
							onFocus={() => setInvalidEmail(false)}
						/>
					</View>
					{invalidEmail && (
						<I18n color='failure' weight='normal' size={12}>
							personalInformation.updateEmailModal.illegalEmail
						</I18n>
					)}
				</View>
			</View>

			<ButtonsContainer
				acceptText={'personalInformation.updateEmailModal.sendEmail'}
				onAccept={onUpdateEmail}
				onCancel={onNext}
				disabled={!currentPassword || !newEmail}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		padding: 39,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		minHeight: 500,
		justifyContent: 'space-between',
	},
	divider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		height: 1,
		width: '100%',
		marginVertical: 20,
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		marginTop: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
});

export default UpdateEmailModal;
