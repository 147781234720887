import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
// import PaycheckListContainer from './PaycheckList/PaycheckList.container'; //TODO: delete component
import HeaderContainer from '../../components/shared/Header/Header.container';
import colors from '../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import ReportRangeListContainer from '../../components/shared/ReportRangeList/ReportRangeList.container';
import routes from '../../config/routes';
import MobileHeader from '../../components/shared/Header/MobileHeader.component';
import I18n from '../../components/shared/i18n/I18n.component';

const PaycheckPage = ({
	navigation,
	paychecksRange,
}: {
	navigation: any;
	paychecksRange: { first: string; last: string };
}) => {
	const windowHeight = useWindowDimensions().height;
	const isInMobileView = useDevicePlatform() !== DeviceType.WEB;
	console.log('isInMobileView', isInMobileView);
	const containerStyle = StyleSheet.flatten([
		styles.container,
		{ height: windowHeight - 100 },
		{
			paddingRight: isInMobileView ? 0 : 22,
			paddingLeft: isInMobileView ? 0 : 37,
		},
	]);

	return (
		<>
			<HeaderContainer navigation={navigation} component={MobileHeader} />
			<View style={containerStyle} nativeID='PaycheckPageMobile'>
				{paychecksRange.first && paychecksRange.last ? (
					<ReportRangeListContainer
						navigation={navigation}
						minDate={`${paychecksRange.first}-01`}
						maxDate={`${paychecksRange.last}-01`}
						navigateToRoute={routes.paycheckView.name}
						listType='months'
						rangeTitle='paycheckList.choosePaycheck'
					/>
				) : (
					<>
						<View style={styles.titleSection}>
							<I18n
								size={36}
								weight='normal'
								style={{
									flex: 1,
									paddingTop: 20,
									paddingBottom: 10,
									paddingRight: 22,
								}}
							>
								paychecks
							</I18n>
						</View>
						<View style={styles.errorMessage}>
							<I18n weight='bold' size={24}>
								paychecks.noPaychecks
							</I18n>
						</View>
					</>
				)}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: colors.tableBackground,
		paddingRight: 22,
		paddingLeft: 37,
	},
	titleSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	errorMessage: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default PaycheckPage;
