import React, { useMemo } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import HomePageContainer from '../HomePage/HomePage.container';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { isLoggedIn, getEmployer } from '../../store/selectors/login.selectors';
import { Dimensions, Platform, useWindowDimensions } from 'react-native';
import routes from '../../config/routes';
import DrawerItemsListContainer from '../../components/shared/DrawerItems/DrawerItemsList.container';
import VacationReportsPage from '../web/VacationReportPage/VacationReportPage.container';
import CostingReportsPage from '../web/CostingReportsPage/CostingReportsPage.component';
import PaycheckPageContainer from '../PaycheckPage/PaycheckPage.container';
import PaycheckView from '../PaycheckPage/PaycheckView/PaycheckView.component';
import { SCREEN_SIZES } from '../../constants';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import ReportPDFPageContainer from '../ReportPDFPage/ReportPDFPage.container';
import PDFViewer from '../../components/shared/PDFViewer/PDFViewer.container';
import { Privileges } from '../../constants/roleTypes.constant';
import SalaryReportsPageContainer from '../web/SalaryReportPage/SalaryReportPage.container';
import AttendanceReportPageContainer from '../web/AttendanceReportPage/AttendaceReportPage.container';
import UsersManagmentPage from '../UsersManagmentPage/UsersManagmentPage.component';
import WorkerCard from '../WorkerCard/WorkerCard.component';
import CalendarAttendanceReports from '../CalendarAttendanceReports/CalendarAttendanceReports.container';
import WorkerCardPreview from '../WorkerCard/WorkerCardPreview/WorkerCardPreview.component';
import Report101Wrapper from '../Report101Page/Report101Wrapper.component';
import { getDocumentTitle } from '../../utils/globals.utils';
import DashboardEmployerContainer from '../DashboardEmployer/DashboardEmployer.container';
import EmptyPage from '../EmptyPage/EmptyPage.component';
import LogoutContainer from '../Logout/Logout.container';
import ApplicationsToEmployerContainer from '../ApplicationsToEmployer/ApplicationsToEmployer.container';
import EmplooyeeRequestsContainer from '../EmplooyeeRequests/EmplooyeeRequests.container';
import HoursReportSummaryContainer from '../HoursReportSummary/HoursReportSummary.container';
import RequestMobileWrapper from '../ApplicationsToEmployer/CreateNewRequest/RequestMobileWrapper';
import RequestDetailsMobile from '../EmplooyeeRequests/RequestDetails/RequestDetails.native';
import PersonalInformationContainer from '../PersonalInformation/PersonalInformation.container';
import WorkPaperPage from '../web/WorkPaperPage/WorkPaperPage.container';
import EmployeeHoursReportContainer from '../HoursReportSummary/EmployeeHoursReport/EmployeeHoursReport.container';
import BusinessSettingsContainer from '../BusinessSettings/BusinessSettings.container';
import LocationSettingsPageContainer from '../web/LocationSettingsPage/LocationSettingsPage.container';
import { useGetCurrentPrivilegs } from '../../hooks/useGetCurrentPrivilegs';
import RepHomePage from '../representative/RepHomePage/RepHomePage';
import RepCustomers from '../representative/RepCustomers/RepCustomers.container';
import RepCustomerWrapperMobile from '../representative/RepCustomers/RepCustomerWrapperMobile.component';
import { getIsEnterMode } from '../../store/selectors/repEnterMode.selector';
import colors from '../../config/colors';
import FromMonthToMonthReportsWrapper from '../FromMonthToMonthReports/FromMonthToMonthReportsWrapper.component';
import Documents from '../Documents/DocumentsWrapper.container';
import RepPaycheckReports from '../representative/RepReports/RepPaycheckReports/RepPaycheckReports.container';
import RepOrgReports from '../representative/RepReports/RepOrgReports/RepOrgReports.container';
import RepOrg126Reports from '../representative/RepReports/RepOrg126Reports/RepOrg126Reports.container';
import RepForm106Reports from '../representative/RepReports/RepForm106Reports/RepForm106Reports.container';
import RepForm101Reports from '../representative/RepReports/RepForm101Reports/RepForm101Reports.container';
import RepWorkPaperReports from '../representative/RepReports/RepWorkPaperReports/RepWorkPaperReports.container';
import WebviewPage from '../WebviewPage/WebviewPage';
import NavigationStateTracker from '../../components/shared/ExtraInfo/navigationStateTraker';

const isMobile =
	Dimensions.get('window').height > Dimensions.get('window').width;

const Drawer = createDrawerNavigator();
const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
	width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	// background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #d9e0ef;
	border-radius: 10px;
  }

  #root {
	border: ${(props: any) =>
		props.isRepEnterMode && !isMobile
			? `3px solid ${colors.warning}`
			: 'unset'};
  }
`;

const drawerRoutes: any = {
	home: {
		component: HomePageContainer,
		extraProps: { unmountOnBlur: true },
	},
	dashboardEmployer: {
		component: DashboardEmployerContainer,
		extraProps: { unmountOnBlur: true },
	},
	repHomePage: {
		component: RepHomePage,
		extraProps: { unmountOnBlur: true },
	},
	repCustomers: {
		component: isMobile ? RepCustomerWrapperMobile : RepCustomers,
		extraProps: { unmountOnBlur: true },
	},
	repPaycheckReports: {
		component: RepPaycheckReports,
		extraProps: { unmountOnBlur: true },
	},
	repOrgReports: {
		component: RepOrgReports,
		extraProps: { unmountOnBlur: true },
	},
	repOrg126Reports: {
		component: RepOrg126Reports,
		extraProps: { unmountOnBlur: true },
	},
	repForm106Reports: {
		component: RepForm106Reports,
		extraProps: { unmountOnBlur: true },
	},
	repForm101Reports: {
		component: RepForm101Reports,
		extraProps: { unmountOnBlur: true },
	},
	repWorkPaperReports: {
		component: RepWorkPaperReports,
		extraProps: { unmountOnBlur: true },
	},
	calendarAttendanceReports: {
		component: CalendarAttendanceReports,
		extraProps: { unmountOnBlur: true },
	},
	EmployeeWorkLogReports: {
		component: EmployeeHoursReportContainer,
		extraProps: { unmountOnBlur: true },
	},
	locationSettings: {
		component: LocationSettingsPageContainer,
		extraProps: { unmountOnBlur: true },
	},
	vacationReports: {
		component: VacationReportsPage,
		extraProps: { unmountOnBlur: true },
	},
	costingReports: {
		component: CostingReportsPage,
		extraProps: { unmountOnBlur: true },
	},
	salaryReports: {
		component: SalaryReportsPageContainer,
		extraProps: { unmountOnBlur: true },
	},
	attendanceReports: {
		component: AttendanceReportPageContainer,
		extraProps: { unmountOnBlur: true },
	},
	form101: {
		component: WorkerCard,
		extraProps: { unmountOnBlur: true },
	},
	attendance: {
		component: ReportPDFPageContainer,
		extraProps: { unmountOnBlur: true },
	},
	workPaper: {
		component: WorkPaperPage,
		extraProps: { unmountOnBlur: true },
	},
	fromMonthToMonthReports: {
		component: FromMonthToMonthReportsWrapper,
		extraProps: { unmountOnBlur: true },
	},
	paycheck: {
		component: PaycheckPageContainer,
		extraProps: { unmountOnBlur: true },
	},
	form106: {
		component: ReportPDFPageContainer,
		extraProps: { unmountOnBlur: true },
	},
	documents: {
		component: Documents,
		extraProps: { unmountOnBlur: true },
	},
	paycheckView: {
		component: PaycheckView,
		extraProps: { unmountOnBlur: true },
	},
	pdfReportView: {
		component: PDFViewer,
		extraProps: { unmountOnBlur: true },
	},
	report101View: {
		component: WorkerCardPreview,
		extraProps: { unmountOnBlur: true },
	},
	usersManagment: {
		component: UsersManagmentPage,
		extraProps: { unmountOnBlur: true },
	},
	documentsManagment: {
		component: Documents,
		extraProps: { unmountOnBlur: true },
	},
	reports101: {
		component: Report101Wrapper,
		extraProps: { unmountOnBlur: true },
	},
	applications: {
		component: ApplicationsToEmployerContainer,
		extraProps: { unmountOnBlur: true },
	},
	emploeeRequests: {
		component: EmplooyeeRequestsContainer,
		extraProps: { unmountOnBlur: true },
	},
	hoursReportSummary: {
		component: HoursReportSummaryContainer,
		extraProps: { unmountOnBlur: true },
	},
	emploeeRequestForm: {
		component: RequestMobileWrapper,
		extraProps: { unmountOnBlur: true },
	},
	requestDetailsMobile: {
		component: RequestDetailsMobile,
		extraProps: { unmountOnBlur: true },
	},
	personalInformation: {
		component: PersonalInformationContainer,
		extraProps: { unmountOnBlur: true },
	},
	businessSettings: {
		component: BusinessSettingsContainer,
		extraProps: { unmountOnBlur: true },
	},
	instructionsEmployee: {
		component: WebviewPage,
		extraProps: { unmountOnBlur: true },
	},
	// instructionsEmployer: {
	// 	component: WebviewPage,
	// 	extraProps: { unmountOnBlur: true },
	// },
	noPaymentVacation: {
		component: WebviewPage,
		extraProps: { unmountOnBlur: true },
	},
	noPaymentVacationEmployee: {
		component: WebviewPage,
		extraProps: { unmountOnBlur: true },
	},
	carInfo: {
		component: WebviewPage,
		extraProps: { unmountOnBlur: true },
	},
	//should aways be in the end.
	logout: {
		component: LogoutContainer,
		extraProps: { unmountOnBlur: true },
	},
};

const Router = () => {
	const loggedIn = useSelector(isLoggedIn);
	const navigation = useNavigation();
	const state = navigation.getState();

	const isRepEnterMode = useSelector(getIsEnterMode);
	const userPrivilegs = useGetCurrentPrivilegs();
	const employerData = useSelector(getEmployer);
	const employer =
		typeof employerData === 'string'
			? JSON.parse(employerData)
			: employerData;
	const platform = useDevicePlatform();
	const isWeb = useMemo(() => platform === DeviceType.WEB, [platform]);
	const isMobileWeb = useMemo(
		() => platform === DeviceType.MOBILE_WEB,
		[platform],
	);
	const filteredRoutes = useMemo(() => {
		return Object.keys(drawerRoutes).filter(r => {
			const routePrivilegs = routes[r] && routes[r].privileges;

			return isWeb
				? routes[r].notInUserRole !== state.routeNames[state?.index] &&
						userPrivilegs?.length &&
						userPrivilegs.some((role: Privileges) =>
							routePrivilegs.includes(role),
						)
				: routes[r].notInUserRole !== state.routeNames[state?.index] &&
						!routes[r].notInMobile &&
						userPrivilegs?.length &&
						userPrivilegs.some((role: Privileges) =>
							routePrivilegs.includes(role),
						);
		});
	}, [userPrivilegs]);

	const dimensions = useWindowDimensions();

	const drawerNavigator = (
		<Drawer.Navigator
			edgeWidth={-100}
			drawerPosition='right'
			initialRouteName={
				employer?.id &&
				isMobileWeb &&
				filteredRoutes.length === 1 &&
				state.routeNames[state?.index] === 'employer'
					? 'emptyPage'
					: routes.home.name
			}
			drawerStyle={{ justifyContent: 'center', opacity: 1 }}
			drawerType={
				!loggedIn || !employer?.id
					? 'front'
					: dimensions.width >= SCREEN_SIZES.MEDIUM
					? 'permanent'
					: 'front'
			}
			drawerContentOptions={{ activeBackgroundColor: '#333' }}
			drawerContent={props => <DrawerItemsListContainer {...props} />}
		>
			<>
				{filteredRoutes.map(route => (
					<Drawer.Screen
						name={routes[route].name}
						component={drawerRoutes[route].component}
						options={{
							...(drawerRoutes[route].extraProps || {}),
							title: getDocumentTitle(route),
						}}
					/>
				))}
				<Drawer.Screen name={'emptyPage'} component={EmptyPage} />
			</>
		</Drawer.Navigator>
	);

	if (employer?.id && isMobileWeb && filteredRoutes.length === 2) {
		if (state.routeNames[state?.index] !== 'employer') {
			navigation.navigate('employer');
		}
	} else if (employer?.id && !filteredRoutes.length) {
		return null;
	}

	return (
		<>
			<NavigationStateTracker></NavigationStateTracker>
			{drawerNavigator}
			<GlobalStyle isRepEnterMode={isRepEnterMode} />
		</>
	);
};

export default Router;
