import { debounce, stubTrue } from 'lodash';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { FilterData } from '../../components/web/Filters/Filters.container';
import httpService from '../../services/http.service';
import {
	getLoginData,
	getUserData,
} from '../../store/selectors/login.selectors';
import { dispatch } from '../../store/store';
import {
	Full101ReportList,
	Full101ReportListLike,
} from '../../models/full101ReportList.model';
import {
	createFilterOptionsForTable,
	createHeadersForTable,
	init101ReportFilters,
	init101ReportResponce,
	isFormReport101Outdated,
	Report101DataType,
	Report101FiltersDataType,
	Report101FiltersType,
	statusReport101Matcher,
} from './Report101Page.utils';
import reducer, {
	initialState,
	Report101Request,
} from './Report101Page.reducer';
import { useRoute } from '@react-navigation/native';
import {
	getDepartmentsFilterList,
	getReport101State,
} from '../../store/selectors/reportsFilters.selectors';
import {
	filter,
	incrementPage,
	sort,
	search,
	setAllFiltersSavedState,
	resetPage,
	updateUnitIds,
	resetFiltersAndSort,
} from './Report101Page.actions';
import { fetchDepartmentsFilterList } from '../../store/actions/reportsFilters.actions';
import Report101Page from './Report101Page.component';
import { modalService } from '../../services/modal.service';
import AlertModal from '../../modals/AlertModal/AlertModal.component';
import I18n from '../../components/shared/i18n/I18n.component';
import ModalConfirmActions from '../../modals/ModalConfirmActions/ModalConfirmActions.component';
import { setAllReport101FiltersState } from '../../store/reducers/report101Filter.reducer';
import {
	incrementBusyIndicator,
	decrementBusyIndicator,
} from '../../store/actions/config.actions';
import moment from 'moment';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import { getExcelFileFromResponse } from '../../utils/downloadFile.web.utils';
import useBusinessUnitsState from '../../hooks/useBusinessUnitsState';
import routes from '../../config/routes';
import { hasPermissionOnBusinessUnit } from '../../utils/globals.utils';
import { Privileges } from '../../constants/roleTypes.constant';
import { EventName, logEvent } from '../../services/analyticsAndLogs.service';

const Report101PageContainer = ({ navigation }: { navigation: any }) => {
	const platform = useDevicePlatform();

	const [isfirstComponentInit, setIsFirstComponentInit] =
		useState<boolean>(true);
	const [report101List, setReport101List] = useState<Full101ReportListLike>(
		init101ReportResponce,
	);
	const [report101Filters, setReport101Filters] =
		useState<Report101FiltersDataType>(init101ReportFilters);
	const [selected101Report, setSelected101Report] =
		useState<Report101DataType | null>(null);
	const selectedRowIndex = useMemo(
		() =>
			report101List.data.findIndex(
				(report: Report101DataType) =>
					report.id === selected101Report?.id,
			),
		[selected101Report],
	);
	const [isRowSelected, setIsRowSelected] = useState<boolean>(false);
	const [tableState, tableDispatch] = useReducer(reducer, initialState);

	const [activeActionButtonType, setActiveActionButtonType] = useState<
		boolean | 'cancelReports' | 'approveReports' | 'sendReminder'
	>(false);

	const [reportNotFound, setReportNotFound] = useState<boolean>(false);
	const [noReports, setNoReports] = useState<boolean>(false);

	const [isPdfMode, setIsPdfMode] = useState<boolean>(
		platform === DeviceType.WEB,
	);

	const [isSelectAllReportsCheckbox, setSelectAllReportsCheckbox] =
		useState<boolean>(false);
	const [checkboxedReports, setCheckboxedReports] = useState<number[]>([]);
	const route = useRoute() as any;
	const departmentsFilterList = useSelector(getDepartmentsFilterList);
	const userData = useSelector(getUserData);
	const stateReport101Saved = useSelector(getReport101State);
	const loginData = useSelector(getLoginData);
	const [form101ModuleActive, setForm101ModuleActive] =
		useState<boolean>(true);
	const isSelectAllReportsCheckboxRef = useRef<boolean>();
	const activeActionButtonTypeRef = useRef<
		boolean | 'cancelReports' | 'approveReports' | 'sendReminder'
	>();
	isSelectAllReportsCheckboxRef.current = isSelectAllReportsCheckbox;
	activeActionButtonTypeRef.current = activeActionButtonType;

	const handleFilter = useCallback((data: FilterData) => {
		setSelected101Report(null);
		if (data.name === 'unitIds') {
			tableDispatch(updateUnitIds(data));
			return;
		}
		if (data.name === 'search') {
			tableDispatch(search(data.value));
			return;
		} else if (Array.isArray(data?.value)) {
			if (data.name === 'status' && data?.value) {
				let mappedArray: any = [];

				data?.value.map(el => {
					const val = statusReport101Matcher[el.id]?.resolve;
					mappedArray.push({ id: val });
				});
				tableDispatch(filter(data.name, mappedArray, true));
			} else {
				tableDispatch(
					filter(data.name as Report101FiltersType, data.value, true),
				);
			}
		} else if (data.name === 'status') {
			const mapper = statusReport101Matcher[data?.value].resolve;
			tableDispatch(
				filter(data.name as Report101FiltersType, mapper, data.isMulti),
			);
		} else {
			tableDispatch(
				filter(
					data.name as Report101FiltersType,
					data.value,
					data.isMulti,
				),
			);
		}
	}, []);

	const {
		businessUnitsList,
		isBusinessUnitsMulti,
		multiValue,
		getSelectedUnits,
	} = useBusinessUnitsState({
		privileges: routes.reports101.privileges,
		onChangeFilter: handleFilter,
	});

	useEffect(() => {
		dispatch(incrementBusyIndicator());
		setIsFirstComponentInit(true);
		tableState.sortBy = '';
		tableState.search = '';
		tableState.sortDirection = 'DESC';
		tableState.filter = {
			dep: [],
			user_id: [],
			year: [],
			status: [],
		};
		if (tableState.filter.unitIds?.length) {
			tableDispatch(resetFiltersAndSort());
		}
		if (route.params?.screen === 'details') {
			setIsRowSelected(true);
		}
	}, [userData]);

	useEffect(() => {
		if (tableState.filter.unitIds?.length || multiValue) {
			dispatch(
				fetchDepartmentsFilterList(
					route.name,
					getSelectedUnits(tableState.filter.unitIds),
				),
			);
			loadReport101ListYearsFilter();
		}
	}, [tableState.filter.unitIds]);

	useEffect(() => {
		if (activeActionButtonType === 'approveReports') {
			tableDispatch(filter('status', 'PENDING_EMPLOYER', false));
		} else if (activeActionButtonType === 'cancelReports') {
			tableDispatch(
				filter(
					'status',
					[
						{ id: 'PENDING_EMPLOYER' },
						{ id: 'PENDING_EMPLOYEE_CORRECTION' },
						{ id: 'PENDING_EMPLOYEE_SUBMIT' },
					],
					true,
				),
			);
		} else if (activeActionButtonType === 'sendReminder') {
			tableDispatch(
				filter(
					'status',
					[
						{ id: 'PENDING_EMPLOYEE_CORRECTION' },
						{ id: 'PENDING_EMPLOYEE_SUBMIT' },
					],
					true,
				),
			);
		} else if (!activeActionButtonType) {
			tableDispatch(filter('status', '', false));
		}
	}, [activeActionButtonType]);

	useEffect(() => {
		let isSavedStateExists = false;
		Object.values(stateReport101Saved?.filter).forEach((item: any) => {
			if (item.length) {
				isSavedStateExists = true;
			}
		});
		if (isSavedStateExists && isfirstComponentInit) {
			tableDispatch(setAllFiltersSavedState(stateReport101Saved));
			setIsFirstComponentInit(false);
			return;
		}
		if (
			isfirstComponentInit &&
			(report101Filters.year as string[]).length &&
			!isSavedStateExists
		) {
			const currentYear = moment().year();
			const defaultYear =
				route?.params?.defaultYear ||
				report101Filters.year?.find(
					(year: number) =>
						currentYear.toString() === year.toString(),
				) ||
				report101Filters.year[0];
			navigation.setParams({ defaultYear: undefined });
			tableDispatch(filter('year', defaultYear as string, false));
			tableDispatch(
				filter('status', 'PENDING_EMPLOYER' as string, false),
			);
			setIsFirstComponentInit(false);
		}
	}, [report101Filters.year, stateReport101Saved]);

	useEffect(() => {
		if (!isfirstComponentInit) {
			debouncedUpdateData({
				...tableState,
				getSelectedUnits,
			});
			dispatch(decrementBusyIndicator());
		}
	}, [tableState]);

	useEffect(() => {
		setReport101Filters(state => ({
			...state,
			dep: departmentsFilterList,
		}));
	}, [departmentsFilterList]);

	const disabledLoadMore = useMemo(
		() => report101List.hasNext,
		[report101List, tableState.page],
	);

	const hasLocationModule = () => {
		const modules =
			typeof loginData?.modules === 'object'
				? loginData?.modules
				: JSON.parse(loginData?.modules);
		const modulesByUnits = modules?.filter((el: any) =>
			getSelectedUnits(tableState.filter.unitIds).includes(el.id),
		);
		let noModule113 = false;
		modulesByUnits.forEach(unit => {
			if (!noModule113) {
				noModule113 = !unit?.modules.find(
					(module: any) =>
						module.id === 113 && module.status === 'ACTIVE',
				);
			}
		});
		setForm101ModuleActive(!noModule113);
	};

	useEffect(() => {
		tableState.filter.unitIds && hasLocationModule();
	}, [tableState.filter.unitIds]);

	const onLoadMore = () => {
		if (disabledLoadMore) {
			tableDispatch(incrementPage());
		}
	};

	const debouncedUpdateData = useCallback(
		debounce((data: Report101Request) => {
			loadReport101List(data);
		}),
		[],
	);

	const loadReport101List = ({
		page,
		pageSize,
		sortBy,
		sortDirection,
		filter,
		search,
		getSelectedUnits,
	}: Report101Request) => {
		httpService
			.api<Full101ReportListLike>({
				type: 'getReport101List',
				data: {
					p: page,
					ps: pageSize,
					s: sortBy,
					sd: sortDirection,
					f: {
						...filter,
						unitIds: getSelectedUnits(filter?.unitIds),
					},
					search,
				},
			})
			.then(async (res: any) => {
				if (res) {
					const reqData = res.data.map(
						(fw: Full101ReportList) => new Full101ReportList(fw),
					);

					if (!res.data[0]) {
						setIsRowSelected(false);
						setSelected101Report(null);
						setReportNotFound(true);
					} else setReportNotFound(false);
					if (page === 0) {
						setReport101List(state => ({
							hasNext: res.hasNext,
							total: res.total,
							data: reqData,
						}));
						setSelected101Report(reqData[0]);
					} else {
						setReport101List(state => {
							if (isSelectAllReportsCheckboxRef.current) {
								setAllChecked([...state.data, ...reqData]);
							}
							return {
								hasNext: res.hasNext,
								total: res.total,
								data: [...state.data, ...reqData],
							};
						});
					}
					if (!res.total) {
						setNoReports(true);
					} else {
						setNoReports(false);
					}
				}
			})
			.catch(err => {
				setReport101List(init101ReportResponce);
			})
			.finally(() => dispatch(decrementBusyIndicator()));
	};

	const loadReport101ListYearsFilter = () => {
		httpService
			.api<number[]>({
				type: 'getReport101ListYearsFilter',
				query: {
					unitIds: getSelectedUnits(tableState.filter.unitIds).join(),
				},
			})
			.then(async (res: any) => {
				if (res.length) {
					setReport101Filters(state => ({ ...state, year: res }));
					setNoReports(false);
				} else {
					setNoReports(true);
					dispatch(decrementBusyIndicator());
				}
			})
			.catch(err =>
				setReport101Filters({ ...report101Filters, year: [] }),
			);
	};

	const handleSorting = useCallback((field: any, direction: any) => {
		tableDispatch(sort(field, direction));
	}, []);

	const updateStatusHandler = async (formId: string) => {
		try {
			const res: any = await httpService.api({
				type: 'updateReport101Status',
				params: {
					formId,
				},
			});

			navigation.navigate('reports101', {
				screen: 'viewer',
				params: {
					formId: formId,
					readOnly: 'false',
					// selectedEmplName: selected101Report?.fullName,
					screen: 'employee',
				},
			});
		} catch (e) {
			console.log(e);
		}
	};

	const selectedRowHandler = (rowData: Report101DataType, flags: any) => {
		if ((isPdfMode && !flags?.forceNavigate) || !form101ModuleActive) {
			setSelected101Report(rowData);
			return;
		}
		if (
			['PENDING_EMPLOYEE_CORRECTION', 'PENDING_EMPLOYEE_SUBMIT'].includes(
				rowData.statusCode as string,
			)
		) {
			//  'ממתין להגשה של עובד'
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>modal.reports101.pending_employee_submit</I18n>
					</AlertModal>
				),
			);
		} else if (
			rowData.statusCode === 'APPROVED' ||
			rowData.statusCode === 'PENDING_EMPLOYER'
		) {
			if (isFormReport101Outdated(rowData.taxYear as string)) {
				dispatch(setAllReport101FiltersState(tableState));
				navigation.navigate('reports101', {
					screen: 'viewer',
					params: {
						formId: rowData.id,
						readOnly: 'true',
						outdatedText: rowData.taxYear,
						screen: 'employee',
						selectedEmplName: rowData?.fullName,
					},
				});
			} else if (rowData.statusCode === 'PENDING_EMPLOYER') {
				//'בטיפול מעסיק'

				dispatch(setAllReport101FiltersState(tableState));
				navigation.navigate('reports101', {
					screen: 'viewer',
					params: {
						formId: rowData.id,
						outdatedText: undefined,
						readOnly: 'false',
						screen: 'employee',
						selectedEmplName: rowData?.fullName,
					},
				});
			} else if (rowData.statusCode === 'APPROVED') {
				//'אושר ע"י מעסיק'

				dispatch(setAllReport101FiltersState(tableState));
				navigation.navigate('reports101', {
					screen: 'viewer',
					params: {
						formId: rowData.id,
						readOnly: 'true',
						screen: 'employee',
						outdatedText: undefined,
						selectedEmplName: rowData?.fullName,
					},
				});
			}
		}
	};

	const navigateBack = () => {
		setSelected101Report(null);
		setIsRowSelected(false);
		navigation.navigate('reports101', { screen: 'table' });
	};

	const debouncedAutocompleteSearch = useCallback(
		debounce((query: string) => {
			tableDispatch(search(query));
		}, 300),
		[],
	);

	const onApproveSelectedButtonHandler = () => {
		if (checkboxedReports.length) {
			modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						callback();

						const res: any = await httpService.api({
							type: 'approveWorkerCard',
							data: {
								f: {
									...tableState.filter,
									unitIds: getSelectedUnits(
										tableState.filter.unitIds,
									).filter((unit: number) =>
										hasPermissionOnBusinessUnit(
											unit,
											Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
										),
									),
								},
								search: tableState.search,
								formId: isSelectAllReportsCheckbox
									? []
									: checkboxedReports,
							},
						});
						if (res?.length) {
							const errors = res.filter(
								(item: any) => !item.success,
							);
							modalService.openModal(
								null,
								{
									submitBtnText: 'general.close',
									iconName: 'attention',
								},
								(props: any) => (
									<AlertModal {...props}>
										<I18n>
											{`modal.reports101.selectAllReports.${
												!errors.length
													? 'success'
													: 'failure'
											}`}
										</I18n>
									</AlertModal>
								),
							);

							logEvent({
								eventName: EventName.managerApproved101,
								data: {},
							});

						}
						setActiveActionButtonType(false);
						setCheckboxedReports([]);
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>modal.reports101.selectAllReports</I18n>
					</ModalConfirmActions>
				),
			);
		} else {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>
							modal.reports101.selectAllReports.nonSelected
						</I18n>
					</AlertModal>
				),
			);
		}
	};

	const onSendReminderButtonHandler = () => {
		if (checkboxedReports.length) {
			modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						callback();

						const res: any = await httpService.api({
							type: 'sendUpdateReminderWorkerCard',
							data: {
								f: {
									...tableState.filter,
									unitIds: getSelectedUnits(
										tableState.filter.unitIds,
									).filter((unit: number) =>
										hasPermissionOnBusinessUnit(
											unit,
											Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
										),
									),
								},
								search: tableState.search,
								formId: isSelectAllReportsCheckbox
									? []
									: checkboxedReports,
							},
						});
						if (res) {
							modalService.openModal(
								null,
								{
									submitBtnText: 'general.close',
									iconName: 'attention',
								},
								(props: any) => (
									<AlertModal {...props}>
										<I18n>
											{`reportView.sendReminder.${
												!res.allFailed
													? 'success'
													: 'failure'
											}`}
										</I18n>
									</AlertModal>
								),
							);

							logEvent({
								eventName: EventName.sendEmployee101Reminder ,
								data: {},
							});

						}
						setActiveActionButtonType(false);
						setCheckboxedReports([]);
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<I18n>reportView.sendReminder.title</I18n>
					</ModalConfirmActions>
				),
			);
		} else {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>
							modal.reports101.selectAllReports.nonSelected
						</I18n>
					</AlertModal>
				),
			);
		}
	};

	const onSelectedCancelReportsButtonHandler = () => {
		if (checkboxedReports.length) {
			modalService.openModal(
				null,
				{
					onCancel: (callback: Function) => {
						callback();
					},
					onSubmit: async (callback: Function) => {
						callback();

						const res: any = await httpService.api({
							type: 'cancelWorkerCard',
							data: {
								f: {
									...tableState.filter,
									unitIds: getSelectedUnits(
										tableState.filter.unitIds,
									).filter((unit: number) =>
										hasPermissionOnBusinessUnit(
											unit,
											Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
										),
									),
								},
								search: tableState.search,
								formId: isSelectAllReportsCheckbox
									? []
									: checkboxedReports,
							},
						});

						if (res) {
							let message =
								'modal.reports101.selectAllReportsForCancel.failure';

							if (res.allSuccess) {
								message =
									'modal.reports101.selectAllReportsForCancel.success';
							} else if (res.partialsuccess) {
								message =
									'modal.reports101.selectAllReportsForCancel.partialSuccess';
							} else if (res.allFailed) {
								message =
									'modal.reports101.selectAllReportsForCancel.failure';
							}

							modalService.openModal(
								null,
								{
									submitBtnText: 'general.close',
									iconName: 'attention',
								},
								(props: any) => (
									<AlertModal {...props}>
										<I18n>{message}</I18n>
									</AlertModal>
								),
							);
						}
						setActiveActionButtonType(false);
						setCheckboxedReports([]);
					},
					submitBtnText: 'general.true',
					cancelBtnText: 'general.false',
					iconName: 'question',
				},
				(props: any) => (
					<ModalConfirmActions {...props}>
						<>
							<I18n weight='normal'>
								modal.reports101.selectAllReportsForCancel
							</I18n>
							<I18n>
								modal.reports101.selectAllReportsForCancel.sub
							</I18n>
						</>
					</ModalConfirmActions>
				),
			);
		} else {
			modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>
							modal.reports101.selectAllReports.nonSelected
						</I18n>
					</AlertModal>
				),
			);
		}
	};

	const exportExcel = async () => {
		const res: any = await httpService.api({
			type: 'export101List',
			data: {
				f: {
					...tableState.filter,
					unitIds: getSelectedUnits(tableState.filter.unitIds),
				},
				search: tableState.search,
			},
			responseType: 'blob',
			returnAllRes: true,
		});
		getExcelFileFromResponse(res);
		logEvent({
			eventName: EventName.export101ReportExcel,
			data: {},
		});
	};

	const setAllChecked = (list?: any) => {
		const data = list || report101List.data;
		const tempArr: number[] = [];
		if (activeActionButtonTypeRef.current === 'approveReports') {
			data.forEach(el => {
				if (el.statusCode === 'PENDING_EMPLOYER') {
					tempArr.push(el.id);
				}
			});
		}

		if (activeActionButtonTypeRef.current === 'cancelReports') {
			data.forEach(el => {
				if (el.statusCode !== 'APPROVED') {
					tempArr.push(el.id);
				}
			});
		}

		if (activeActionButtonTypeRef.current === 'sendReminder') {
			data.forEach(el => {
				if (
					[
						'PENDING_EMPLOYEE_CORRECTION',
						'PENDING_EMPLOYEE_SUBMIT',
					].includes(el.statusCode)
				) {
					tempArr.push(el.id);
				}
			});
		}
		setCheckboxedReports([...tempArr]);
	};

	const onSelectAllCheckboxHandler = () => {
		if (!isSelectAllReportsCheckbox) {
			setAllChecked();
			setSelectAllReportsCheckbox(true);
		} else onDisselectAllReportsHandler();
	};

	const onDisselectAllReportsHandler = () => {
		setCheckboxedReports([]);
		setSelectAllReportsCheckbox(false);
	};

	const onCheckboxChangeHandler = (row: any) => {
		const selectedId = row?.original?.id || row?.id;
		const includesEl = !!checkboxedReports.length
			? isCheckboxSelected(selectedId)
			: false;

		if (checkboxedReports.length && includesEl) {
			const arr = checkboxedReports.filter(el => el !== selectedId);
			setCheckboxedReports([...arr]);
			setSelectAllReportsCheckbox(false);
		} else setCheckboxedReports(state => [...state, selectedId]);
	};

	const isCheckboxSelected = (id: any) => {
		return checkboxedReports.includes(id);
	};

	const resetTablePage = () => {
		tableDispatch(resetPage());
	};

	const moveToNextEmp = () => {
		const nextEmp = report101List.data[selectedRowIndex + 1];
		setSelected101Report(nextEmp);
		if (
			report101List.data.length - selectedRowIndex <= 4 &&
			report101List.hasNext
		) {
			tableDispatch(incrementPage());
		}
	};

	const moveToPrevEmp = () => {
		const nextEmp = report101List.data[selectedRowIndex - 1];
		setSelected101Report(nextEmp);
	};

	const onSetIsPdfMode = (value: boolean) => {
		setIsPdfMode(value);
		report101List?.data && setSelected101Report(report101List?.data[0]);
	};

	const disabled = useMemo(() => {
		return (
			getSelectedUnits(tableState.filter.unitIds).filter((unit: number) =>
				hasPermissionOnBusinessUnit(
					unit,
					Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
				),
			)?.length === 0
		);
	}, [tableState.filter.unitIds]);

	return (
		<Report101Page
			navigation={navigation}
			data={report101List.data}
			total={report101List.total}
			reportNotFound={reportNotFound}
			headers={createHeadersForTable(
				activeActionButtonType,
				isCheckboxSelected,
				onCheckboxChangeHandler,
				onSelectAllCheckboxHandler,
				isSelectAllReportsCheckbox,
				isPdfMode,
				businessUnitsList,
			)}
			filtersData={createFilterOptionsForTable(
				report101Filters,
				tableState,
				businessUnitsList,
				isBusinessUnitsMulti,
			)}
			resetTablePage={resetTablePage}
			onSort={handleSorting}
			onLoadMore={onLoadMore}
			onFilter={handleFilter}
			onRowClick={selectedRowHandler}
			isRowSelected={isRowSelected}
			selectedRowData={selected101Report}
			navigateBack={navigateBack}
			// pageCurrent={currentPageselected}
			onSearchTextChange={(query: any) =>
				debouncedAutocompleteSearch(query)
			}
			onSearchOptionSelected={(query: any) => {
				tableDispatch(filter('user_id', query?.value as any));
			}}
			onDisselectAllReports={onDisselectAllReportsHandler}
			onSelectAllReports={onSelectAllCheckboxHandler}
			selectedFilters={tableState.filter}
			onSelectRowCheckboxPressed={onCheckboxChangeHandler}
			isCheckboxSelected={isCheckboxSelected}
			onApproveSelectedButtonPressed={onApproveSelectedButtonHandler}
			onSelectedCancelButtonPressed={onSelectedCancelReportsButtonHandler}
			isSelectAllReportsCheckbox={isSelectAllReportsCheckbox}
			onSelectAllCheckboxReports={onSelectAllCheckboxHandler}
			noReports={noReports}
			isPdfMode={isPdfMode}
			setIsPdfMode={onSetIsPdfMode}
			setActiveActionButtonType={setActiveActionButtonType}
			activeActionButtonType={activeActionButtonType}
			onSendReminderButtonHandler={onSendReminderButtonHandler}
			exportExcel={exportExcel}
			form101ModuleActive={form101ModuleActive}
			userData={userData}
			disabled={disabled}
			{...{
				moveToNextEmp,
				moveToPrevEmp,
				selectedRowIndex,
				disableNext:
					!disabledLoadMore &&
					selectedRowIndex === report101List.data.length - 1,
			}}
			businessUnitsList={businessUnitsList}
		/>
	);
};

export default Report101PageContainer;
