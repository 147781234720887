import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	Platform,
	Text,
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { ButtonsContainer, Required } from '../PersonalInformation.utils';
import httpService from '../../../services/http.service';
import { Tooltip } from '@material-ui/core';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';



interface Props {
	onNext(val: any): void;
	onResponse(ans: boolean): void;
}
const UpdatePassworrdModal = ({ onNext, onResponse }: Props) => {
	const [
		{ currentPassword, currentValid, showCurrentPassword },
		setCurrentPassword,
	] = useState({
		currentPassword: '',
		currentValid: true,
		showCurrentPassword: false,
	});
	const [{ newPassword, showNewPassword }, setNewPassword] = useState({
		newPassword: '',
		showNewPassword: false,
	});
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [displayTooltip, setDisplayTooltip] = useState(false);
	const [errorUpdatePassword, setErrorUpdatePassword] = useState('');
	const [{ matchPassword, matchValid, showMatchPassword }, setMatchPassword] =
		useState({
			matchPassword: '',
			matchValid: true,
			showMatchPassword: false,
		});

	const onUpdatePassword = async () => {
		try {
			const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
			const isNewPasswordValid = regExp.test(newPassword);

			if (!isNewPasswordValid) {
				setInvalidPassword(true);
			} else if (newPassword === matchPassword) {
				setInvalidPassword(false);
				let res = await httpService.api<any>({
					type: 'updatePassword',
					data: {
						oldPassword: currentPassword,
						newPassword: matchPassword,
					},
				});
				logEvent({
					eventName: EventName.personalInformationChange,
					data: {change: 'UpdatePassword'},
					logDataToAnalytics: true,
				});	
				switch (res) {
					case (res = 'SUCCESS'):
						break;
					case (res = 'PASSWORD_IN_HISTORY'):
						setErrorUpdatePassword(
							'personalInformation.updatePasswordModal.passwordInHistory',
						);
						return;
					case (res = 'INVALID_PASSWORD'):
						setErrorUpdatePassword(
							'personalInformation.updatePasswordModal.sendPasswordFailed',
						);
						return;
					case (res = 'FAILURE'):
						setErrorUpdatePassword(
							'personalInformation.updatePasswordModal.sendPasswordFailedRequest',
						);
						return;
				}

				onNext(onResponse(res));
			} else {
				setMatchPassword(prev => ({ ...prev, matchValid: false }));
			}
		} catch (e) {}
	};

	return (
		<View style={styles.modalContainer}>
			<View>
				<View style={styles.flexRow}>
					<I18n size={30} weight='normal'>
						personalInformation.updatePasswordModal.title
					</I18n>
					<TouchableOpacity
						onPress={() => {
							setDisplayTooltip(!displayTooltip);
						}}
					>
						<ImageIcon
							name='questionMark'
							width={15}
							height={15}
							style={{ marginRight: 5, marginLeft: 5 }}
						/>
					</TouchableOpacity>
				</View>
				<View style={styles.divider} />
			</View>
			{displayTooltip && (
				<View style={styles.tooltip}>
					<View>
						<I18n color={'primary'} weight='normal' size={12}>
							personalInformation.updatePasswordModal.passwordRules
						</I18n>
						<I18n color={'primary'} weight='normal' size={12}>
							personalInformation.updatePasswordModal.passwordTerms1
						</I18n>
						<I18n color={'primary'} weight='normal' size={12}>
							personalInformation.updatePasswordModal.passwordTerms2
						</I18n>
						<I18n color={'primary'} weight='normal' size={12}>
							personalInformation.updatePasswordModal.passwordTerms3
						</I18n>
						<I18n color={'primary'} weight='normal' size={12}>
							personalInformation.updatePasswordModal.passwordTerms4
						</I18n>
						<I18n color={'primary'} weight='normal' size={12}>
							personalInformation.updatePasswordModal.passwordTerms5
						</I18n>
					</View>
				</View>
			)}

			<View style={{ height: 83 }}>
				<View style={{ flexDirection: 'row-reverse' }}>
					<I18n size={14} weight='normal'>
						personalInformation.updatePasswordModal.pressPassword
					</I18n>
					<Required />
				</View>
				<View style={styles.inputContainer}>
					<TouchableOpacity
						onPress={() =>
							setCurrentPassword(prev => ({
								...prev,
								showCurrentPassword: !showCurrentPassword,
							}))
						}
					>
						<ImageIcon
							name={
								showCurrentPassword
									? 'showPassword'
									: 'hidePassword'
							}
							width={22}
							height={14}
						/>
					</TouchableOpacity>
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						value={currentPassword}
						onChangeText={val =>
							setCurrentPassword(prev => ({
								...prev,
								currentPassword: val,
							}))
						}
						secureTextEntry={!showCurrentPassword}
						onBlur={e => {}}
						onFocus={() => {}}
					/>
				</View>
				{!currentValid && (
					<I18n color='failure' weight='normal' size={12}>
						personalInformation.updateEmailModal.wrongPassword
					</I18n>
				)}
			</View>

			<View style={{ height: 83 }}>
				<View style={{ flexDirection: 'row-reverse' }}>
					<I18n size={14} weight='normal'>
						personalInformation.updatePasswordModal.newPassword
					</I18n>
					<Required />
				</View>
				<View style={styles.inputContainer}>
					<TouchableOpacity
						onPress={() =>
							setNewPassword(prev => ({
								...prev,
								showNewPassword: !showNewPassword,
							}))
						}
					>
						<ImageIcon
							name={
								showNewPassword
									? 'showPassword'
									: 'hidePassword'
							}
							width={22}
							height={14}
						/>
					</TouchableOpacity>
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						value={newPassword}
						onChangeText={val =>
							setNewPassword(prev => ({
								...prev,
								newPassword: val,
							}))
						}
						secureTextEntry={!showNewPassword}
						onFocus={() =>
							setMatchPassword(prev => ({
								...prev,
								matchValid: true,
							}))
						}
					/>
				</View>
			</View>

			<View style={{ height: 83 }}>
				<View style={{ flexDirection: 'row-reverse' }}>
					<I18n size={14} weight='normal'>
						personalInformation.updatePasswordModal.newPasswordAgain
					</I18n>
					<Required />
				</View>
				<View style={styles.inputContainer}>
					<TouchableOpacity
						onPress={() =>
							setMatchPassword(prev => ({
								...prev,
								showMatchPassword: !showMatchPassword,
							}))
						}
					>
						<ImageIcon
							name={
								showMatchPassword
									? 'showPassword'
									: 'hidePassword'
							}
							width={22}
							height={14}
						/>
					</TouchableOpacity>
					<TextInput
						style={styles.textInput}
						underlineColorAndroid='transparent'
						value={matchPassword}
						onChangeText={val =>
							setMatchPassword(prev => ({
								...prev,
								matchPassword: val,
							}))
						}
						secureTextEntry={!showMatchPassword}
						onFocus={() =>
							setMatchPassword(prev => ({
								...prev,
								matchValid: true,
							}))
						}
					/>
				</View>
				{!matchValid && (
					<I18n color='failure' weight='normal' size={12}>
						login.notEqualPsw
					</I18n>
				)}
			</View>
			{invalidPassword && (
				<View>
					<I18n color={'primary'} weight='normal' size={12}>
						personalInformation.updatePasswordModal.invalidPassword
					</I18n>
					<I18n color={'primary'} weight='normal' size={12}>
						personalInformation.updatePasswordModal.passwordTerms1
					</I18n>
					<I18n color={'primary'} weight='normal' size={12}>
						personalInformation.updatePasswordModal.passwordTerms2
					</I18n>
					<I18n color={'primary'} weight='normal' size={12}>
						personalInformation.updatePasswordModal.passwordTerms3
					</I18n>
					<I18n color={'primary'} weight='normal' size={12}>
						personalInformation.updatePasswordModal.passwordTerms4
					</I18n>
					<I18n color={'primary'} weight='normal' size={12}>
						personalInformation.updatePasswordModal.passwordTerms5
					</I18n>
				</View>
			)}
			{errorUpdatePassword ? (
				<View>
					<I18n color={'primary'} weight='normal' size={12}>
						{errorUpdatePassword}
					</I18n>
				</View>
			) :  null}
			<ButtonsContainer
				acceptText={
					'personalInformation.updatePasswordModal.sendPassword'
				}
				onAccept={onUpdatePassword}
				onCancel={onNext}
				disabled={!currentPassword || !newPassword || !matchPassword}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		padding: 35,
		flexDirection: 'column',
		shadowColor: 'rgba(0, 0, 0, 0)',
		shadowOpacity: 0.1,
		shadowOffset: { width: 0, height: 2 },
		backgroundColor: colors.white,
		borderRadius: 20,
		minHeight: 500,
		width: 350,
		justifyContent: 'space-between',
		position: 'relative',
	},
	flexRow: {
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	tooltip: {
		position: 'absolute',
		width: 200,
		shadowOpacity: 0.1,
		borderRadius: 20,
		backgroundColor: '#e4e4e4',
		top: 70,
		right: 70,
		padding: 10,
		zIndex: 100,
	},
	divider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		height: 1,
		width: '100%',
		marginVertical: 20,
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 280,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
		marginTop: 5,
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: 220,
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
});

export default UpdatePassworrdModal;
