import React, { useState } from 'react';
import {
	View,
	StyleSheet,
	useWindowDimensions,
	TouchableHighlight,
} from 'react-native';
// import PaycheckListContainer from './PaycheckList/PaycheckList.container'; //TODO: delete component
import HeaderContainer from '../../components/shared/Header/Header.container';
import colors from '../../config/colors';
import WebHeader from '../../components/shared/Header/WebHeader.component';
import ReportRangeListContainer from '../../components/shared/ReportRangeList/ReportRangeList.container';
import PDFViewer from '../../components/shared/PDFViewer/PDFViewer.container';
import { ListType } from '../../interfaces/core.interface';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import I18n from '../../components/shared/i18n/I18n.component';
import { downloadFile as urlDownloadFile } from '../../utils/downloadFile.web.utils';
import { generatePdfName } from '../../utils/generatePdfName.utlis';
import {
	BusinessUnit,
	Employee101Data,
	Employee101YearStatus,
	PDFDateRange,
} from './ReportPDFPage.utils';
import WorkerCardHeader from './WorkerCardHeader.component';
import WorkerCardPreview from '../WorkerCard/WorkerCardPreview/WorkerCardPreview.component';
import { downloadFile } from '../WorkerCard/WorkerCardPreview/utils';
import { onPressCreate } from '../WorkerCard/workerCard.utils';
import Select from '../../components/shared/FieldBuilder/Select/ReactSelect.component';
import { 
	EventName,
	 logEvent 
} from '../../services/analyticsAndLogs.service';



const ReportPDFPageWeb = ({
	navigation,
	dateRange,
	innerHeaderData,
	onSelectDate,
	selectedDate,
	listType,
	title,
	rangeTitle,
	noReports,
	isLoading,
	displayHeader = true,
	is101Page,
	setIsRequestCompleted,
	enableCreate,
	businessUnitsList,
	selectedOptions,
	route,
	onChangeBusinessUnit,
	...restProps
}: {
	navigation: any;
	dateRange: PDFDateRange | Employee101YearStatus[];
	innerHeaderData: Employee101Data;
	onSelectDate: (date: string | Employee101YearStatus) => void;
	selectedDate: string | Employee101YearStatus;
	listType: ListType;
	title: string;
	rangeTitle: string;
	noReports: string;
	isLoading: boolean;
	displayHeader: boolean;
	setIsRequestCompleted: any;
	is101Page: boolean;
	enableCreate: boolean;
	selectedOptions: {
		selectedBusinessUnit: BusinessUnit;
		selectedPaycheckDate: string;
	} | null;
	route: any;
	businessUnitsList: BusinessUnit[];
	onChangeBusinessUnit: (id: string) => void;
}) => {
	const shouldShowUnitSelect = businessUnitsList.length > 1 && !is101Page;
	const windowHeight = useWindowDimensions().height;
	const [fileUrl, setFileUrl] = useState<any>();
	const [fileData, setFileData] = useState();

	const containerStyle = StyleSheet.flatten([
		styles.container,
		{ height: windowHeight - 200 },
	]);

	const downloadBtnStyle = StyleSheet.flatten([
		styles.btn,
		!fileUrl && !fileData && styles.btnDisabled,
	]);

	const download = () => {
		if (fileUrl) {
			const fileName = generatePdfName(fileUrl?.url || fileUrl, listType);
			urlDownloadFile(fileUrl?.url || fileUrl, fileUrl?.name || fileName);
		} else if (fileData && fileData.file) {
			downloadFile(fileData?.file, fileData?.fileName);
		};
		logEvent({
			eventName: EventName.downloadPdf,
			data: {
				data: fileUrl? fileUrl : fileData?.fileName,
			},
			logDataToAnalytics: true,
		});
	};

	return (
		<>
			{displayHeader && (
				<HeaderContainer
					navigation={navigation}
					containerStyle={{ backgroundColor: colors.white }}
					component={WebHeader}
				/>
			)}
			{isLoading ? null : (dateRange as PDFDateRange).first ||
			  (dateRange as PDFDateRange).last ||
			  (dateRange as Employee101YearStatus[]).length ? (
				<View style={containerStyle} nativeID='ReportPDFPageWeb'>
					<View style={styles.titleSection}>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								...(shouldShowUnitSelect && {
									width: 'calc(100% - 295px)',
								}),
							}}
						>
							<TouchableHighlight
								disabled={!fileUrl && !fileData}
								style={downloadBtnStyle}
								underlayColor='transparent'
								onPress={download}
							>
								<ImageIcon
									name={
										innerHeaderData
											? 'downloadPDF'
											: 'downloadPaycheck'
									}
									height={innerHeaderData ? 23 : 20}
									width={innerHeaderData ? 18 : 20}
								/>
							</TouchableHighlight>
							{shouldShowUnitSelect && (
								<View
									style={{
										zIndex: 1,
										width: 200,
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Select
										options={businessUnitsList}
										onChange={onChangeBusinessUnit}
										value={
											selectedOptions
												?.selectedBusinessUnit?.id
										}
									/>
									<I18n
										size={16}
										weight='400'
										color='darkGrey'
										style={{ marginHorizontal: 3 }}
									>
										:
									</I18n>
									<I18n
										size={16}
										weight='400'
										color='darkGrey'
									>
										general.business
									</I18n>
								</View>
							)}
						</View>

						{innerHeaderData && (
							<WorkerCardHeader
								{...restProps}
								innerHeaderData={innerHeaderData}
								enableCreate={enableCreate}
							/>
						)}

						<View style={styles.innerHeaderContentContainer}>
							<View style={{ width: 295 }}>
								<I18n
									size={36}
									weight='normal'
									style={{
										flex: 1,
										paddingTop: 20,
										paddingBottom: 10,
									}}
								>
									{title}
								</I18n>
							</View>
						</View>
					</View>
					<View style={styles.content}>
						{restProps.is101 ? (
							<WorkerCardPreview
								setFileUrl={setFileUrl}
								selectedDate={{
									[selectedDate.taxYear]: selectedDate.status,
								}}
								innerHeaderData={innerHeaderData}
								setFileData={setFileData}
								setIsRequestCompleted={setIsRequestCompleted}
								{...restProps}
							/>
						) : (
							<PDFViewer
								setFileUrl={setFileUrl}
								selectedOptions={selectedOptions}
								setIsRequestCompleted={setIsRequestCompleted}
								route={route}
								{...restProps}
							/>
						)}

						<View
							nativeID='ReportPDFPageWebWrapper'
							style={styles.listWrapper}
						>
							{listType === 'status' ? (
								<ReportRangeListContainer
									dateList={
										dateRange as Employee101YearStatus[]
									}
									onSelectDate={onSelectDate}
									listType={listType}
									rangeTitle={rangeTitle}
									is101Page={is101Page}
								/>
							) : (
								<ReportRangeListContainer
									minDate={`${
										(dateRange as PDFDateRange).first
									}-01`}
									maxDate={`${
										(dateRange as PDFDateRange).last
									}-01`}
									onSelectDate={onSelectDate}
									listType={listType}
									rangeTitle={rangeTitle}
									is101Page={is101Page}
								/>
							)}
						</View>
					</View>
				</View>
			) : (
				<>
					<View style={styles.titleSection}>
						<View style={styles.actionBtns}>
							{enableCreate && (
								<TouchableHighlight
									style={styles.btn}
									underlayColor='transparent'
									onPress={() => onPressCreate(navigation)}
								>
									<View style={styles.innerButtonItem}>
										<I18n
											color='white'
											weight='normal'
											size={16}
										>
											report101ReportPage.add
										</I18n>
									</View>
								</TouchableHighlight>
							)}
						</View>
						<I18n
							size={36}
							weight='normal'
							style={{
								flex: 1,
								paddingTop: 20,
								paddingBottom: 10,
								paddingRight: 22,
							}}
						>
							{title}
						</I18n>
					</View>
					<View style={styles.errorMessage}>
						<I18n weight='bold' size={24}>
							{noReports}
						</I18n>
					</View>
				</>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		backgroundColor: colors.tableBackground,
		paddingRight: 22,
		paddingLeft: 37,
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		height: '100%',
		zIndex: 0,
	},
	titleSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		zIndex: 1,
	},

	innerHeaderContentContainer: {
		display: 'flex',
		flexDirection: 'row',
	},

	listWrapper: {
		height: '100%',
	},
	paycheckView: {
		position: 'relative',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginRight: 30,
		backgroundColor: colors.white,
	},
	btn: {
		height: 20,
		width: 20,
		justifyContent: 'center',
	},
	actionBtns: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	btnDisabled: {
		opacity: 0.5,
	},
	errorMessage: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	innerButtonItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,
		width: 176,
		backgroundColor: '#4aaeef',
		borderRadius: 25,
		marginLeft: 20,
	},
});

export default ReportPDFPageWeb;
