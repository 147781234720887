import React, { useMemo, useState, useEffect } from 'react';
import { RECAPTCHA_SITE_KEY } from '@env';
import ReCAPTCHA from 'react-google-recaptcha';
import { View, StyleSheet, ImageBackground, Platform } from 'react-native';
import { TextInput, TouchableHighlight } from 'react-native';
import SolidButtonContainer from '../../components/shared/Buttons/SolidButton.container';
import LinkButtonContainer from '../../components/shared/Buttons/LinkButton.container';
import I18n from '../../components/shared/i18n/I18n.component';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
import colors from '../../config/colors';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';
import routes from '../../config/routes';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/login.actions';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
const imageSrc = require('../../../assets/images/login/bg.png');

interface ResetPasswordProps {
	navigation: any;
	newPassword: string;
	repeatPassword: string;
	shouldShowNewPassword: boolean;
	shouldShowRepeatPassword: boolean;
	onSetNewPassword: (psw: string) => void;
	onSetRepeatPassword: (psw: string) => void;
	onToggleShowNewPassword: () => void;
	onToggleShowRepeatPassword: () => void;
	onResetPassword: () => void;
	onCaptchaChange: (value: any) => void;
	onExpiredCaptcha: () => void;
	backToLogin: () => void;
	captchaVerified: boolean;
	submitted: boolean;
	resetPasswordError: boolean;
	errorMessage: string;
	isPasswordGotReset: boolean;
}

const ResetPassword = React.forwardRef(
	(
		{
			navigation,
			newPassword,
			repeatPassword,
			shouldShowNewPassword,
			shouldShowRepeatPassword,
			onSetNewPassword,
			onSetRepeatPassword,
			onToggleShowNewPassword,
			onToggleShowRepeatPassword,
			onResetPassword,
			onCaptchaChange,
			onExpiredCaptcha,
			captchaVerified,
			submitted,
			resetPasswordError,
			errorMessage,
			backToLogin,
			isPasswordGotReset,
		}: ResetPasswordProps,
		ref: any,
	) => {
		const [hasError, setHasError] = useState(false);
		const [validPasswordError , setValidPasswordError] = useState(false)
		const [matchPasswordError , setMatchPasswordError] = useState(false)
		const dispatch = useDispatch();
		const isMobile: boolean = useDevicePlatform() !== DeviceType.WEB;
		const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

		const imageS = StyleSheet.flatten([
			styles.image,
			isMobile
				? { height: 300, width: 430, bottom: -50 }
				: { height: 700, width: 700, bottom: 0 },
		]);
		const outerContainerS = StyleSheet.flatten([
			styles.outerContainer,
			isMobile ? { width: 334 } : { width: 448 },
		]);

	
		

		const isShowError = () => {
			const isNewPasswordValid = regExp.test(newPassword);
			const passwordMatch = newPassword === repeatPassword;

			if(!passwordMatch){
				setMatchPasswordError(true)
				setHasError(true)
			}else if(!isNewPasswordValid){
				setValidPasswordError(true)
				setHasError(true)
			}else{
                setValidPasswordError(false)
                setMatchPasswordError(false)
				setHasError(false)
			}
		};

		const errorInputStyle = useMemo(
			() => (hasError ? styles.errorInput : {}),
			[hasError],
		);

		return (
			<View style={styles.wrapper} nativeID='#ResetPassword'>
				<ImageBackground
					source={imageSrc}
					style={imageS}
				></ImageBackground>
				<View style={outerContainerS}>
					<View style={styles.container}>
						<View style={styles.logoSection}>
							<ImageIcon
								name={'michpal'}
								width={83}
								height={59}
							></ImageIcon>
						</View>

						<View style={styles.separatorLine}></View>

						{isPasswordGotReset ? (
							<View style={styles.viewConainer}>
								<View
									style={{
										height: 150,
										justifyContent: 'center',
									}}
								>
									<ImageIcon
										name='tick'
										width={100}
										height={100}
									/>
								</View>
								<I18n
									size={16}
									weight='bold'
									style={styles.userChangedPasswordMesagge}
								>
									login.resetPasswordSuccess
								</I18n>
								<SolidButtonContainer
									overrideStyle={{ width: 200 }}
									onPress={() => backToLogin()}
								>
									<I18n
										size={14}
										weight='normal'
										color='white'
									>
										login.backToLogin
									</I18n>
								</SolidButtonContainer>
							</View>
						) : (
							<>
								{resetPasswordError ? (
									<View style={styles.viewConainer}>
										<I18n
											size={16}
											weight='normal'
											color='darkGrey'
											style={styles.userMesagge}
										>
											{errorMessage}
										</I18n>
										<SolidButtonContainer
											overrideStyle={{ width: 200 }}
											onPress={() => backToLogin()}
										>
											<I18n
												size={16}
												weight='normal'
												color='white'
											>
												login.backToLogin
											</I18n>
										</SolidButtonContainer>
										<LinkButtonContainer
											onPress={async () => {
												navigation.navigate(
													routes.forgotPassword,
												);
												await dispatch(logout());
											}}
											overrideStyle={{ width: 110 }}
										>
											<I18n
												size={14}
												weight='400'
												color='lightPrimary'
											>
												login.forgotPassword
											</I18n>
										</LinkButtonContainer>
									</View>
								) : (
									<>
										<View style={styles.titleSection}>
											<I18n
												size={20}
												weight='normal'
												color='darkGrey'
											>
												login.changePassword
											</I18n>
										</View>
										<View style={styles.subTitle}>
											<I18n
												size={14}
												weight='400'
												color='darkGrey'
											>
												login.resetPasswordInfo
											</I18n>
										</View>

										<View style={styles.inputSection}>
											<View
												style={[
													styles.inputContainer,
													errorInputStyle,
												]}
											>
												<TouchableHighlight
													underlayColor='transparent'
													onPress={() =>
														onToggleShowNewPassword()
													}
												>
													<ImageIcon
														name={
															shouldShowNewPassword
																? 'hidePassword'
																: 'showPassword'
														}
														width={22}
														height={14}
													/>
												</TouchableHighlight>
												<TextInput
													style={styles.textInput}
													underlineColorAndroid='transparent'
													placeholder='סיסמה חדשה'
													value={newPassword}
													onChangeText={
														onSetNewPassword
													}
													secureTextEntry={
														!shouldShowNewPassword
													}
													onBlur={e =>
														isShowError()
													}
													onFocus={() =>
														isShowError()
													}
												/>
											</View>
											<View
												style={[
													styles.inputContainer,
													errorInputStyle,
												]}
											>
												<TouchableHighlight
													underlayColor='transparent'
													onPress={() =>
														onToggleShowRepeatPassword()
													}
													onBlur={() =>
														isShowError()
													}
													onFocus={() =>
														isShowError()
													}
												>
													<ImageIcon
														name={
															shouldShowRepeatPassword
																? 'hidePassword'
																: 'showPassword'
														}
														width={22}
														height={14}
													/>
												</TouchableHighlight>
												<TextInput
													style={styles.textInput}
													underlineColorAndroid='transparent'
													placeholder='אשר/י סיסמה'
													value={repeatPassword}
													onChangeText={
														onSetRepeatPassword
													}
													secureTextEntry={
														!shouldShowRepeatPassword
													}
													onBlur={e =>
														isShowError()
													}
													onFocus={() =>
														isShowError()
													}
												/>
											</View>
											{Platform.OS === 'web' ? (
												<View
													style={{
														marginTop: 15,
													}}
												>
													<ReCAPTCHA
														ref={ref}
														onChange={
															onCaptchaChange
														}
														onExpired={
															onExpiredCaptcha
														}
														sitekey={
															RECAPTCHA_SITE_KEY
														}
													/>
												</View>
											) : null}
										</View>
										{Platform.OS === 'web'
											? !captchaVerified &&
											  submitted && (
													<View
														style={styles.errorInfo}
													>
														<I18n
															color='failure'
															weight='normal'
															size={12}
														>
															forgetPassword.invalidCaptcha
														</I18n>
													</View>
											  )
											: null}
										<View style={styles.errorInfo}>
											{matchPasswordError && (
												<I18n
													color='failure'
													weight='normal'
													size={12}
												>
													login.notEqualPsw
												</I18n>
											)}
											{validPasswordError && (
												<I18n
												color='failure'
												weight='normal'
												size={12}
											>
												login.notValidPsw
											</I18n>
											)}
										</View>
										<SolidButtonContainer
											disabled={
												hasError ||
												(Platform.OS === 'web' &&
													!captchaVerified)
											}
											overrideStyle={{
												height: 61,
												alignItems: 'center',
												justifyContent: 'center',
											}}
											onPress={() => onResetPassword()}
										>
											<I18n
												size={16}
												weight='normal'
												color='white'
											>
												login.changePassword
											</I18n>
										</SolidButtonContainer>
									</>
								)}
							</>
						)}
					</View>
				</View>
			</View>
		);
	},
);

const styles = StyleSheet.create({
	wrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		flex: 1,
		height: 435,
		backgroundColor: '#efefef',
	},
	image: {
		resizeMode: 'cover',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
	},
	outerContainer: {
		backgroundColor: colors.white,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		paddingHorizontal: 16,
		paddingTop: 18,
		paddingBottom: 47,
	},
	container: {
		flexDirection: 'column',
		width: 301,
	},
	logoSection: {
		height: 70,
		paddingBottom: 10,
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	separatorLine: {
		backgroundColor: colors.primary,
		height: 1,
		opacity: 0.38,
	},
	viewConainer: {
		alignSelf: 'center',
		alignItems: 'center',
		justifyContent: 'center',
	},

	userMesagge: {
		height: 200,
		textAlign: 'center',
		paddingVertical: 100,
		alignContent: 'center',
		justifyContent: 'center',
	},
	userChangedPasswordMesagge: {
		height: 50,
		marginTop: 10,
		paddingBottom: 90,
		alignSelf: 'center',
		alignContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
	titleSection: {
		marginTop: 19,
		textAlign: 'center',
		alignItems: 'center',
	},
	subTitle: {
		marginTop: 25,
	},
	inputSection: {
		height: 220,
		marginTop: 10,
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 300,
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: 220,
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
	errorInput: {
		borderWidth: 1,
		borderColor: colors.failure,
	},
	errorText: {
		alignItems: 'center',
		marginBottom: 16,
	},
	errorInfo: {
		height: 41,
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export default ResetPassword;
