import React, { useMemo, useState } from 'react';
import { VERSION } from '@env';
import Config from 'react-native-config';
import {
	DrawerContentComponentProps,
	DrawerContentOptions,
} from '@react-navigation/drawer';
import {
	ScrollView,
	StyleSheet,
	View,
	TouchableHighlight,
	useWindowDimensions,
	TouchableOpacity,
} from 'react-native';
import DrawerItem from './DrawerItem.component';
import ImageIcon from '../Icon/ImageIcon.component';
import routes from '../../../config/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
	getUserPrivilegesStatus,
	getUserTypes,
} from '../../../store/selectors/login.selectors';
import { Privileges } from '../../../constants/roleTypes.constant';
import Typography from '../Typography/Typography.component';
import colors from '../../../config/colors';
import { SCREEN_SIZES } from '../../../constants';
import SubDrawerItem from './SubDrawerItem.component';
import { i18nService } from '../../../services/i18n.service';
import I18n from '../i18n/I18n.component';
import { DoubleButtonContainer } from './DoubleButton/DoubleButton.container';
import { useRoute } from '@react-navigation/native';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import { updateRequestsFilters } from '../../../store/actions/reportsFilters.actions';
import { useGetCurrentPrivilegs } from '../../../hooks/useGetCurrentPrivilegs';
import {
	getEnterModeName,
	getIsEnterMode,
} from '../../../store/selectors/repEnterMode.selector';

interface DrawerItemsList
	extends DrawerContentComponentProps<DrawerContentOptions> {
	navigate: (routeName: string) => void;
	closeDrawer: () => void;
}

const extraRoutes = ['reports', 'repReports', 'management', 'proInfo'];

const DrawerItemsList = ({
	navigate,
	closeDrawer,
	...props
}: DrawerItemsList) => {
	const dimensions = useWindowDimensions();
	const dispatch = useDispatch();

	const userPrivilegesStatus = useSelector(getUserPrivilegesStatus);
	const userPrivileges = useGetCurrentPrivilegs();
	const isRepEnterMode = useSelector(getIsEnterMode);
	const repEnterModeName = useSelector(getEnterModeName);
	const { isRep } = useSelector(getUserTypes);
	const isMobileWeb =
		useDevicePlatform() === DeviceType.MOBILE ||
		useDevicePlatform() === DeviceType.MOBILE_WEB;

	const roles =
		typeof userPrivileges === 'string'
			? JSON.parse(userPrivileges)
			: userPrivileges;

	const filteredRoutes = useMemo(() => {
		const routs = props.state.routeNames.filter(
			r => r !== 'personalInformation' && r !== 'businessSettings',
		);
		const showBusinessSettings =
			(userPrivileges?.includes(Privileges.ROLE_CUSTOMER_SETTING_WRITE) ||
				userPrivileges?.includes(
					Privileges.ROLE_CUSTOMER_SETTING_READ,
				)) &&
			isMobileWeb;
		return [
			...[...routs, ...extraRoutes].filter((route: any) => {
				const isNotHidden = !routes[route]?.isHidden;

				const allowedRole =
					roles?.length &&
					roles.some(
						(role: Privileges) =>
							routes[route] &&
							routes[route].privileges?.includes(role),
					);

				return isNotHidden && allowedRole;
			}),
			routs.length < props.state.routeNames.length &&
				!isRepEnterMode &&
				'personalInformation',
			showBusinessSettings && 'businessSettings',
			!!isMobileWeb && 'logout',
		];
	}, [props.state.routeNames, roles]);

	const subRoutes = useMemo(
		() =>
			filteredRoutes.reduce(
				(final, route) =>
					!routes[route]?.subRoute
						? final
						: {
								...final,
								[routes[route]?.subRoute]: final[
									routes[route]?.subRoute
								]
									? [...final[routes[route]?.subRoute], route]
									: [route],
						  },
				{},
			),
		[filteredRoutes],
	);

	const getRouterName = (prop: string) => {
		return routes[prop]?.label;
	};

	const bottomLineStyle = StyleSheet.flatten([styles.bottomLineWeb]);

	const isActiveRoute = (r1: string, r2: string): boolean => {
		if (
			routes[r2]?.hasSubRoute &&
			routes[r1]?.subRoute === routes[r2]?.hasSubRoute
		) {
			return true; //reports menu should be selected when inner menu tab selected
		}
		return r1 === r2;
	};

	const onNavigate = route => {
		dispatch(
			updateRequestsFilters({ requestsFilters: {}, requestsSearch: '' }),
		);
		let nextRoute = route;
		if (
			routes[route]?.hasSubRoute &&
			subRoutes[routes[route]?.hasSubRoute]
		) {
			nextRoute = subRoutes[routes[route]?.hasSubRoute][0];
		}
		navigate(nextRoute);
	};

	if (!userPrivileges) {
		return null;
	}
	return (
		<View style={styles.root}>
			{dimensions.width < SCREEN_SIZES.MEDIUM ? (
				<TouchableHighlight
					style={styles.hamburger}
					underlayColor='transparent'
					onPress={closeDrawer}
				>
					<ImageIcon name='hamburger' />
				</TouchableHighlight>
			) : null}

			<View
				style={
					dimensions.width < SCREEN_SIZES.MEDIUM
						? styles.title
						: styles.titleAlone
				}
			>
				<Typography>
					<I18n size={30} weight='normal' color='darkGrey'>
						{i18nService.translate(`drawerItemsList.title`)}
					</I18n>
				</Typography>
			</View>
			{userPrivilegesStatus && !isRepEnterMode && (
				<>
					<View style={bottomLineStyle} />
					<View style={styles.roleButtonContainer}>
						<DoubleButtonContainer
							styleConf={
								dimensions.width > 600
									? {
											width: isRep ? 90 : 130,
											height: 26,
											textSize: 12,
									  }
									: {
											width: isRep ? 90 : 135,
											height: 39,
											textSize: 18,
									  }
							}
						/>
					</View>
					<View style={bottomLineStyle} />
				</>
			)}

			{isRepEnterMode && (
				<View
					style={{
						backgroundColor: colors.warning,
						width: '100%',
						height: 64,
						paddingHorizontal: 21,
						paddingVertical: isMobileWeb ? 4 : 11,
					}}
				>
					<I18n size={14} weight='normal' color='white'>
						enterCustomer.details
					</I18n>
					<View
						style={{
							flexDirection: isMobileWeb
								? 'column'
								: 'row-reverse',
							alignItems: isMobileWeb ? 'flex-end' : 'baseline',
						}}
					>
						<Typography
							size={isMobileWeb ? 20 : 22}
							numberOfLines={1}
							weight='normal'
							color='white'
						>
							{repEnterModeName}
						</Typography>
						<Typography
							style={{ marginRight: isMobileWeb ? 0 : 4 }}
							size={isMobileWeb ? 14 : 16}
							weight='400'
							color='white'
						>
							{isRepEnterMode}
						</Typography>
					</View>
				</View>
			)}

			<ScrollView>
				{/* TODO: Check users role by comparing with redux store to see if we should show route */}
				{filteredRoutes
					.filter(route =>
						routes[route]?.hasSubRoute &&
						!subRoutes[routes[route]?.hasSubRoute]
							? false
							: !routes[route]?.subRoute,
					)
					.map((route, id) => {
						return route === 'businessSettings' ? (
							<TouchableOpacity
								onPress={() => onNavigate('businessSettings')}
								style={{
									flexDirection: 'row-reverse',
									alignItems: 'center',
									paddingVertical: 15,
								}}
							>
								<ImageIcon
									name='settings'
									width={20}
									height={20}
									style={{ marginRight: 5 }}
								/>
								<I18n
									size={14}
									style={{ marginRight: 15 }}
									weight='normal'
								>
									businessSettings.title
								</I18n>
							</TouchableOpacity>
						) : (
							<View key={id}>
								<DrawerItem
									key={`drwItem_${id}`}
									index={id}
									routeName={getRouterName(route)}
									isActive={isActiveRoute(
										props.state.routeNames[
											props.state.index
										],
										route,
									)}
									onPress={() => onNavigate(route)}
								/>
								{routes[route]?.hasSubRoute &&
									subRoutes[routes[route]?.hasSubRoute] &&
									subRoutes[routes[route]?.hasSubRoute].map(
										(subRoute: string, index: number) => (
											<SubDrawerItem
												index={index}
												key={`drwItem_${subRoute}`}
												routeName={getRouterName(
													subRoute,
												)}
												route={subRoute}
												isActive={isActiveRoute(
													props.state.routeNames[
														props.state.index
													],
													subRoute,
												)}
												isParentActive={
													// true
													isActiveRoute(
														props.state.routeNames[
															props.state.index
														],
														route,
													)
												}
												onPress={() => {
													navigate(subRoute);
													dispatch(
														updateRequestsFilters({
															requestsFilters: {},
															requestsSearch: '',
														}),
													);
												}}
											/>
										),
									)}
								{/* paycheck & attendance main routes for reports menu  */}
							</View>
						);
					})}
				{/* {(userPrivileges?.includes(Privileges.ROLE_CUSTOMER_SETTING_WRITE) || userPrivileges?.includes(Privileges.ROLE_CUSTOMER_SETTING_READ)) && isMobileWeb && (
					
				)} */}
			</ScrollView>
			<View style={styles.version}>
				<Typography>{Config.VERSION || VERSION}</Typography>
			</View>
			<View style={styles.footer}>
				<ImageIcon width={44} height={30} name='michpal' />
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
	},
	title: {
		paddingRight: 22,
		marginBottom: 10,
	},
	titleAlone: {
		paddingRight: 22,
		marginBottom: 10,
		paddingTop: 20,
	},
	hamburger: {
		margin: 28,
	},
	roleButtonContainer: {
		marginBottom: 10,
		marginTop: 10,
	},
	footer: {
		justifyContent: 'space-evenly',
		flexDirection: 'row',
		paddingVertical: 12,
	},
	version: {
		flexDirection: 'row',
		marginLeft: 25,
		padding: 12,
	},
	paycheckDrillMenu: {
		width: '100%',
		paddingRight: 50,
		position: 'relative',
	},
	paycheckMenuWeb: {
		paddingRight: 53,
		paddingTop: 14,
		paddingBottom: 14,
	},
	paycheckMenuMobile: {
		paddingRight: 53,
		paddingTop: 14,
		paddingBottom: 14,
	},
	paycheckMenuSelected: {
		backgroundColor: 'rgba(59, 112, 164, 0.1)',
	},
	bottomLineWeb: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
	},
	bottomLineMobile: {
		height: 2,
		opacity: 0.2,
		backgroundColor: colors.darkGrey,
	},
	verticalLine: {
		width: 3,
		height: 35,
		borderRadius: 1.5,
		backgroundColor: colors.primary,
		position: 'absolute',
		right: 1,
		top: 5,
	},
	paycheckMenuWrapper: {
		display: 'flex',
		flexDirection: 'row',
	},
});

export default DrawerItemsList;
