import React, { useMemo, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableHighlight,
	FlatList,
	useWindowDimensions,
	Platform,
} from 'react-native';

import HeaderContainer from '../../../components/shared/Header/Header.container';
import I18n from '../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../components/shared/Icon/ImageIcon.component';
import { SingleConfig } from '../../../components/web/Filters/Filters.component';
import FiltersContainer, {
	FilterData,
} from '../../../components/web/Filters/Filters.container';
import SearchInputContainer from '../../../components/shared/SearchInput/SearchInput.container';
import MobileHeader from '../../../components/shared/Header/MobileHeader.component';
import SolidButtonContainer from '../../../components/shared/Buttons/SolidButton.container';
import getShadowStyle from '../../../config/shadow';
import colors from '../../../config/colors';
import UserTableMobileRow from './UserTableMobileRow/UserTableMobileRow.component';
import FiltersDrawer from '../../../components/shared/FiltersDrawer/FiltersDrawer';
import { dispatch } from '../../../store/store';
import { setHideChat } from '../../../store/actions/config.actions';
import { debounce } from 'lodash';
import Checkbox from '../../../components/shared/FieldBuilder/Checkbox/Checkbox';
import { i18nService } from '../../../services/i18n.service';
import { Portal } from 'react-native-paper';
import ModalContainer from '../../../components/shared/Modal/Modal.container';
import SendingDocument from '../../Documents/SendingDocument/SendingDocument.container';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';

const UsersTablePage = ({
	data,
	navigation,
	onFilter,
	filtersData,
	selectedFilters,
	getNextData,
	noReports,
	selectedFiltersForMobile,
	businessUnitsList,
	docPermission,
	showCreateUserBtn,
	modules,
}: {
	data: any[];
	navigation: any;
	onFilter: (data: FilterData) => void;
	filtersData: any;
	selectedFilters: any;
	getNextData: Function;
	noReports?: boolean;
	userData: any;
	businessUnitsList: any[];
	selectedFiltersForMobile: any;
	docPermission: boolean;
	showCreateUserBtn: boolean;
	modules: any;
}) => {
	const dimentions = useWindowDimensions();
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<
		{ name: string; id: number } | false
	>(false);

	const onRowClick = (dataItem: any) => {
		dispatch(setHideChat(true));

		if (Platform.OS === 'web') {
			navigation.navigate('users', {
				screen: 'editor',
				params: {
					screen: 'home',
					params: {
						id: dataItem.id,
						businessUnitId: dataItem.businessUnitId,
					},
				},
			});
			return;
		}
		navigation.navigate('usersManagment', {
			screen: 'editor',
			params: {
				screen: 'home',
				params: {
					id: dataItem.id,
					businessUnitId: dataItem.businessUnitId,
				},
			},
		});
	};

	const onCreateUserButtonClick = () => {
		dispatch(setHideChat(true));
		logEvent({
			eventName: EventName.addNewUserFromUsersTable,
			data: {},
		});
		if (Platform.OS === 'web') {
			navigation.navigate('users', { screen: 'editor', params: {} });
			return;
		}
		navigation.navigate('usersManagment', { screen: 'editor', params: {} });
	};

	const mobileItemHandler = (item: any) => {
		const showMenu =
			modules[item?.item?.businessUnitId]?.find(
				(module: any) =>
					module.id === 114 && module?.status === 'ACTIVE',
			) && docPermission
				? item.item.identityType === 'ID'
					? true
					: false
				: false;
		return (
			<UserTableMobileRow
				dataRow={item}
				businessUnitsList={businessUnitsList}
				showMenu={showMenu}
				onRowClick={onRowClick}
				setShowModal={setShowModal}
			/>
		);
	};

	const filterStatusIsActive = useMemo(
		() =>
			Object.keys(selectedFilters).find(
				(key: string) =>
					selectedFilters[key].length &&
					!['employees', 'finish_work'].includes(key),
			),
		[selectedFilters],
	);

	const onSearch = debounce(onFilter, 700);

	return (
		<FiltersDrawer
			isOpen={isSubMenuOpen}
			onToggleSubMenu={setIsSubMenuOpen}
			config={filtersData.filter(
				(d: any) => !['employees', 'finish_work'].includes(d.name),
			)}
			onFilter={onFilter}
			selectedFilters={selectedFiltersForMobile}
		>
			<View>
				<HeaderContainer
					navigation={navigation}
					component={MobileHeader}
				/>
			</View>

			<View style={{ marginRight: 16, marginBottom: 20 }}>
				<I18n weight='normal' size={28}>
					{'usersManagement.table.title'}
				</I18n>
			</View>

			<View nativeID='#searchWrapper' style={styles.searchWrapper}>
				<SearchInputContainer
					onChange={onSearch}
					placeholder={i18nService.translate('filter.search')}
					config={
						filtersData.find(
							(singleConf: SingleConfig) =>
								singleConf.type === 'searchInput',
						)!
					}
					styleConf={{ container: { flex: 1 } }}
				/>
				<View style={styles.filterSortingSelectButtons}>
					<TouchableHighlight
						underlayColor='transparent'
						onPress={() => {
							setIsSubMenuOpen(true);
						}}
					>
						{!filterStatusIsActive ? (
							<ImageIcon name='filter' width={20} height={20} />
						) : (
							<ImageIcon
								name='filterActive'
								width={20}
								height={20}
							/>
						)}
					</TouchableHighlight>
				</View>
			</View>

			<View style={styles.buttonsLayerContainer}>
				<View>
					{showCreateUserBtn && (
						<SolidButtonContainer
							overrideStyle={styles.selectReportButton}
							onPress={onCreateUserButtonClick}
						>
							<I18n color='white'>createUserButton.title</I18n>
						</SolidButtonContainer>
					)}
				</View>
				<View
					style={{
						flexDirection: 'row-reverse',
						alignItems: 'center',
						marginTop: -5,
					}}
				>
					<Checkbox
						onChange={(value: boolean) =>
							onFilter({ name: 'finish_work', value })
						}
						value={selectedFilters?.finish_work?.[0] === 'true'}
					/>
					<I18n>usersManagement.filter.finishWork</I18n>
				</View>
			</View>
			{noReports ? (
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<I18n
						weight='bold'
						size={24}
						style={{ marginTop: 36, marginRight: 26 }}
					>
						report101ReportPage.noReports
					</I18n>
				</View>
			) : (
				<View style={styles.dataContainer}>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: 10,
							paddingBottom: 5,
						}}
					>
						<View
							style={{
								flex: 1.5,
							}}
						>
							<I18n weight='bold' size={16}>
								usersManagement.table.emp_name
							</I18n>
						</View>
						<View
							style={{
								flex: 1,
								marginLeft: 10,
								alignItems: 'flex-end',
							}}
						>
							<I18n weight='bold' size={16}>
								usersManagement.table.roles
							</I18n>
						</View>
						<View
							style={{
								flex: 0.5,
								alignItems: 'center',
								minWidth: 20,
							}}
						>
							<I18n weight='bold' size={16}>
								usersManagement.table.status
							</I18n>
						</View>
						{docPermission && <View style={{ width: 20 }}></View>}
					</View>
					<FlatList
						data={data}
						renderItem={mobileItemHandler}
						style={{
							height:
								dimentions.height -
								(Platform.OS === 'web' ? 250 : 260),
						}}
						contentContainerStyle={{ paddingBottom: 90 }}
						onEndReached={() => getNextData()}
						keyExtractor={item => `${item.id}-${item.profileId}`}
					/>
				</View>
			)}
			<Portal>
				<ModalContainer
					disabledKeyboard
					visible={showModal}
					component={SendingDocument}
					notDisplayModal
					{...{
						onNext: (callback: Function) => {
							setShowModal(false);
						},
						...(showModal || {}),
					}}
				/>
			</Portal>
		</FiltersDrawer>
	);
};

const styles = StyleSheet.create({
	selectReportButton: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 40,

		backgroundColor: colors.lightPrimary,
		borderRadius: 45,
		...getShadowStyle(20, 'rgb(59, 112, 164)'),
	},
	title: {
		marginLeft: 8,
	},
	searchWrapper: {
		display: 'flex',
		// alignItems: 'flex-end',
		paddingRight: 10,
		paddingLeft: 10,
		flexDirection: 'row-reverse',
	},
	buttonsLayerContainer: {
		paddingHorizontal: 10,
		marginTop: 16,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	filterSortingSelectButtons: {
		marginHorizontal: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	dataContainer: {
		marginTop: 10,
	},
});

export default UsersTablePage;
