import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ScrollSyncPane } from 'react-scroll-sync';
import colors from '../../../config/colors';
import FlexTableComponent from './FlexTable.component';
import ImageIcon from '../../shared/Icon/ImageIcon.component';
import I18n from '../../shared/i18n/I18n.component';

interface FlexTableProps {
	data: any;
	tableName: string;
	index: string;
	disabled: boolean;
	headerBackgroundColor: string;
	headerColor: string;
	color: string;
	onBlur: Function;
	onChange: Function;
	onPreesAdd: Function;
	onPreesEdit: Function;
	onRemove: Function;
	onPress: Function;
	onPreesRemove: Function;
	maxLength: number;
	keyboardType: [string, string] | string;
	toFixed: number | [number, Object?];
	type: string;
	userId: any;
	next: () => void;
	allDataFetched?: boolean;
	tableRealTimeHight: number;
	scrollSyncMode?: boolean;
	onSort: Function;
	forceMin: boolean;
	boxNumber: number;
	nextUserId: any;
	editCurrentLineOnlyMode: boolean;
}

interface FlexTableStyles {
	numberOfRows: number;
	headerBackgroundColor: string;
	headerColor: string;
	color: string;
	type: string;
	tableRealTimeHight: number;
	tableName: string;
}

const useStyles = makeStyles((theme: any) => ({
	superHeader: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontFamily: 'RubikMedium',
		width: 'auto',
		whiteSpace: 'nowrap',
		padding: '8px',
		height: '36px',
		backgroundColor: colors.white,
		color: ({ headerColor }: any) => headerColor,
	},
	superHeaderTitle: {
		maxWidth: 'calc(100% - 60px)',
		width: '100%',
		clear: 'both',
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	superHeaderIcons: {
		width: '50px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 5px',
	},
	editable: {
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		padding: 0,
		margin: 0,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': { opacity: 0.8 },
	},
	tableHeader: {
		height: '100%',
		width: '100%',
		position: 'relative',
		zIndex: 100,
	},
	tableBody: {
		marginTop: '-60px',
		height: ({ tableRealTimeHight }) => tableRealTimeHight,
		width: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	container: {
		display: 'grid',
		borderRight: `1px solid ${colors.filterBorder}`,
		gridTemplateColumns: ({ numberOfRows }: FlexTableStyles) =>
			`repeat(${numberOfRows}, auto)`,
		fontSize: '14px',
		marginLeft: ({ type }) => (type === 'table' ? 6 : 0),
		minWidth: ({ tableName }) => (tableName === 'header' ? 130 : 256),
	},
	bodyContainer: {
		display: 'grid',
		borderRight: `1px solid ${colors.filterBorder}`,
		gridTemplateColumns: ({ numberOfRows }: FlexTableStyles) =>
			`repeat(${numberOfRows}, auto)`,
		fontSize: '14px',
		paddingTop: ({ type }) => (type === 'table' ? 60 : 0),
		minWidth: ({ tableName }) => (tableName === 'header' ? 130 : 256),
	},
}));

const FlexTable = ({
	data,
	tableName,
	disabled,
	headerBackgroundColor = colors.primary,
	headerColor = colors.white,
	color = colors.darkGrey,
	onBlur,
	onChange,
	onPreesAdd,
	onPreesEdit,
	onRemove,
	boxNumber,
	onPress,
	onPreesRemove,
	maxLength,
	keyboardType,
	index,
	toFixed,
	type,
	userId,
	tableRealTimeHight,
	scrollSyncMode,
	onSort,
	forceMin,
	nextUserId,
	editCurrentLineOnlyMode,
}: FlexTableProps) => {
	const numberOfRowsFun = (
		type: string,
		data: any,
		tableName: string,
		index?: string,
		boxNumber?: number,
	): number => {
		if (type === 'table') {
			return data.header.length;
		}
		if (type === 'box') {
			return Array.isArray(
				data[index as any][tableName][boxNumber as any],
			)
				? data[index as any][tableName][boxNumber as any].length
				: data[index as any][tableName].length;
		}
		return 1;
	};
	const numberOfRows = numberOfRowsFun(
		type,
		data,
		tableName,
		index,
		boxNumber,
	);
	const [activeSort, setActiveSort] = useState({ name: '', direction: '' });

	const classes = useStyles({
		numberOfRows,
		headerBackgroundColor,
		headerColor,
		color,
		type,
		tableRealTimeHight,
		tableName,
	});
	const useSortList = () => {
		if (type === 'box') {
			const sortListArr = Object.entries(data).sort((a: any, b: any) => {
				if (a[0] === 'wageComponent') {
					return -1;
				} else if (a[0] === 'optionalDeductionsComponent') {
					return -1;
				}
				return a[0].split('-')[0] - b[0].split('-')[0];
			});

			const sList = sortListArr.map((line: any) => line[0]);
			return sList;
		}
	};
	const sortList = useSortList();

	const showDataKeys = useMemo(() => {
		const d =
			index === 'HeaderAdditionalData' || type === 'table'
				? Object.keys({ ...data } || {})
				: sortList;
		if (type === 'table') {
			delete d.data;
		}
		return d;
	}, [sortList]);

	return (
		<div className={classes.tableContainer}>
			{type === 'table' && (
				<div className={classes.superHeader}>
					<div></div>
					<div className={classes.superHeaderTitle}>
						<I18n color={'darkGrey'} weight='normal' size={18}>
							{tableName}
						</I18n>
					</div>
					{!disabled && (
						<div className={classes.superHeaderIcons}>
							<button
								className={classes.editable}
								onClick={() => onPreesEdit(data.data)}
								tabIndex={-1}
							>
								<ImageIcon
									width={16}
									height={16}
									name={'edit'}
								/>
							</button>
							<button
								className={classes.editable}
								onClick={() => onPreesRemove(data.data)}
								tabIndex={-1}
							>
								<ImageIcon
									width={16}
									height={16}
									name={'remove'}
								/>
							</button>
						</div>
					)}
				</div>
			)}
			{type === 'table' ? (
				<>
					<div className={classes.tableHeader}>
						<div className={classes.container}>
							{data &&
								showDataKeys.map((key, index) => (
									<FlexTableComponent
										key={key}
										index={index}
										header={data[key]}
										activeSort={activeSort}
										setActiveSort={setActiveSort}
										type={type}
										onSort={onSort}
										componentType={data.data?.componentType}
										comType={Object.entries(data)[0][0]}
										tableName={tableName}
										boxNumber={boxNumber}
										disabled={disabled}
										headerBackgroundColor={
											headerBackgroundColor
										}
										headerColor={headerColor}
										color={color}
										onBlur={onBlur}
										onPress={onPress}
										onRemove={onRemove}
										onChange={onChange}
										onPreesAdd={onPreesAdd}
										maxLength={maxLength}
										keyboardType={keyboardType}
										toFixed={toFixed}
										userId={userId}
										forceMin={forceMin}
										editCurrentLineOnlyMode={
											editCurrentLineOnlyMode
										}
										nextUserId={nextUserId}
									/>
								))}
						</div>
					</div>
					{scrollSyncMode ? (
						<ScrollSyncPane>
							<div className={classes.tableBody}>
								<div className={classes.bodyContainer}>
									{data &&
										showDataKeys.map((key, index) => (
											<FlexTableComponent
												key={key}
												index={index}
												data={data[key]}
												type={type}
												componentType={
													data.data?.componentType
												}
												comType={Object.entries(data)[0][0]}
												tableName={tableName}
												boxNumber={boxNumber}
												disabled={disabled}
												headerBackgroundColor={
													headerBackgroundColor
												}
												headerColor={headerColor}
												color={color}
												onBlur={onBlur}
												onPress={onPress}
												onChange={onChange}
												onPreesAdd={onPreesAdd}
												onRemove={onRemove}
												maxLength={maxLength}
												keyboardType={keyboardType}
												toFixed={toFixed}
												userId={userId}
												forceMin={forceMin}
												editCurrentLineOnlyMode={
													editCurrentLineOnlyMode
												}
												nextUserId={nextUserId}
											/>
										))}
								</div>
							</div>
						</ScrollSyncPane>
					) : (
						<div className={classes.tableBody}>
							<div className={classes.bodyContainer}>
								{data &&
									showDataKeys.map((key, index) => (
										<FlexTableComponent
											key={key}
											index={index}
											data={data[key]}
											type={type}
											componentType={
												data.data?.componentType
											}
											tableName={tableName}
											boxNumber={boxNumber}
											disabled={disabled}
											headerBackgroundColor={
												headerBackgroundColor
											}
											headerColor={headerColor}
											color={color}
											comType={Object.entries(data)[0][0]}
											onBlur={onBlur}
											onPress={onPress}
											onChange={onChange}
											onPreesAdd={onPreesAdd}
											onRemove={onRemove}
											maxLength={maxLength}
											keyboardType={keyboardType}
											toFixed={toFixed}
											userId={userId}
											forceMin={forceMin}
											editCurrentLineOnlyMode={
												editCurrentLineOnlyMode
											}
											nextUserId={nextUserId}
										/>
									))}
							</div>
						</div>
					)}
				</>
			) : (
				<div className={classes.container}>
					{data &&
						showDataKeys.map((key, index) => (
							<FlexTableComponent
								key={key}
								index={index}
								data={data[key]}
								type={type}
								componentType={data.data?.componentType}
								tableName={tableName}
								boxNumber={boxNumber}
								disabled={disabled}
								comType={Object.entries(data)[0][0]}
								headerBackgroundColor={headerBackgroundColor}
								headerColor={headerColor}
								color={color}
								onBlur={onBlur}
								onPress={onPress}
								onChange={onChange}
								onRemove={onRemove}
								onPreesAdd={onPreesAdd}
								maxLength={maxLength}
								keyboardType={keyboardType}
								toFixed={toFixed}
								userId={userId}
								forceMin={forceMin}
							/>
						))}
				</div>
			)}
		</div>
	);
};

export default React.memo(FlexTable);
