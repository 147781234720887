import React, { useState } from 'react';
import { View, StyleSheet, TouchableHighlight, Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { convertFromUtc, Props } from './Clock.utils';
import I18n from '../i18n/I18n.component';
import SelectFilter from './EventsAndProjectsSelect/EventsAndProjectsSelect.container';
import Timer from '../Timer/Timer.container';
import Typography from '../Typography/Typography.component';
import moment from 'moment';
import { getServerDateTime } from '../../../store/selectors/dateTime.selectors';
import { i18nService } from '../../../services/i18n.service';
import SolidButtonContainer from '../Buttons/SolidButton.container';
import Button from '../../../components/shared/Buttons/Button.component';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { 
	EventName,
	 logEvent 
} from '../../../services/analyticsAndLogs.service';

const ClockReportMobileComponent = ({
	initialTime,
	shiftTime,
	totalReported,
	reachedMax,
	onPressEntry,
	onPressExit,
	projectsOptions,
	eventsOptions,
	onSelectProjectOrEvent,
	selectedItem,
	defaultProjectOption,
	defaultEventOption,
	label,
	canReportFullDay,
	disableButtons,
	disableEntryButton,
	report101warning,
	selectedItemType,
	handleOpenOptions,
	eventPlaceholdar,
	currentRunnigWorkLog,
	handleAddNewLog
}: Props) => {
	const navigation = useNavigation();
	const currentServerTime = useSelector(getServerDateTime);

	return (
		<ScrollView
			contentContainerStyle={styles.contentBlock}
			style={{ width: '100%' }}
			nativeID='clockReport'
			showsVerticalScrollIndicator
		>
			<View nativeID='detailsHeader' style={styles.detailsHeader}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'center',
						width: '100%',
						marginBottom: 30,
					}}
				>
					<Typography size={18} color={'darkGrey'} weight='400'>
						{`${convertFromUtc(
							currentServerTime,
							false,
							true,
							'YYYY-MM-DDTHH:mm',
							'DD/MM/YY',
						)}  |  ${convertFromUtc(
							currentServerTime,
							true,
							false,
							'YYYY-MM-DDTHH:mm',
						)}`}
					</Typography>
				</View>
				{!disableButtons.disable ? (
					<View nativeID='FiltersHolder' style={styles.FiltersHolder}>
						{eventsOptions.length ? (
							<SelectFilter //events select
								canReportFullDay={canReportFullDay}
								options={eventsOptions}
								isProject={false}
								placeHolder={currentRunnigWorkLog?.eventName ? currentRunnigWorkLog?.eventName : eventPlaceholdar}
								onSubmit={onSelectProjectOrEvent}
								disabled={selectedItemType === 'projects'}
								onOpen={isOpen =>
									handleOpenOptions(isOpen, 'events')
								}
								defaultOption={defaultEventOption}
								currentRunnigWorkLog={currentRunnigWorkLog}
								handleAddNewLog={handleAddNewLog}
							/>
						) : null}
						{projectsOptions.length ? (
							<SelectFilter //projects select
								canReportFullDay={canReportFullDay}
								options={projectsOptions}
								isProject={true}
								placeHolder={
									currentRunnigWorkLog?.projectName ? currentRunnigWorkLog?.projectName :
									defaultProjectOption.name
										? defaultProjectOption.name
										: 'menu.projects'
								}
								onSubmit={onSelectProjectOrEvent}
								disabled={selectedItemType === 'events'}
								onOpen={isOpen =>
									handleOpenOptions(isOpen, 'projects')
								}
								defaultOption={defaultProjectOption}
								currentRunnigWorkLog={currentRunnigWorkLog}
							/>
						) : null}
					</View>
				) : null}
				{label ? (
					<Typography
						size={22}
						color={'darkGrey'}
						weight='bold'
						style={{ margin: 15, textAlign: 'center' }}
					>
						{label}
					</Typography>
				) : null}
			</View>
			{!disableButtons.disable ? (
				<View nativeID='ButtonsHolder' style={styles.ButtonsHolder}>
					{initialTime ? (
						<Timer
							max={shiftTime - totalReported}
							maxAlert={reachedMax}
							initialTime={initialTime}
							textColor='primary'
							fontSize={36}
						/>
					) : (
						<SolidButtonContainer
							overrideStyle={styles.EntryButton}
							onPress={onPressEntry}
							disabled={disableEntryButton}
							buttonStyle={{
								opacity: disableEntryButton ? 0.3 : 1,
							}}
						>
							<I18n size={22} weight={'normal'} color='white'>
								general.enterence
							</I18n>
						</SolidButtonContainer>
					)}
					<SolidButtonContainer
						onPress={onPressExit}
						overrideStyle={styles.ExitButton}
					>
						<I18n size={22} weight={'normal'} color='white'>
							general.exit
						</I18n>
					</SolidButtonContainer>
				</View>
			) : (
				<View
					style={{
						flexDirection: 'row-reverse',
						justifyContent: 'center',
						alignItems: 'center',
						height: 200,
					}}
				>
					<I18n weight='bold'>{`${i18nService.translate(
						'general.youAreNowReportingEvent',
					)} `}</I18n>
					<I18n weight='bold' color='warning'>
						{i18nService.translate(disableButtons.eventName)}
					</I18n>
				</View>
			)}
			{report101warning && (
				<View style={styles.report101Container}>
					<View style={styles.report101ActionsContainer}>
						<Button
							buttonStyle={StyleSheet.flatten([
								styles.report101Button,
								{
									backgroundColor: 'red',
								},
							])}
							onPress={() => {
								logEvent({
									eventName: EventName.report101Button,
									data: {},
								});
								navigation.navigate('form101');
							}}
						>
							<I18n size={14} weight={'normal'} color={'white'}>
								reportView.wageDataSummary.report101Button
							</I18n>
						</Button>
					</View>
					<View style={styles.report101Text}>
						<I18n
							style={{ textAlign: 'center' }}
							size={16}
							weight={'normal'}
							color={'red'}
						>
							reportView.wageDataSummary.report101TextWarning
						</I18n>
					</View>
				</View>
			)}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	contentBlock: {
		marginTop: 28,
		marginBottom: 25,
		alignItems: 'center',
		justifyContent: 'space-evenly',
		width: '100%',
	},
	detailsHeader: {
		width: '100%',
		marginBottom: 20,
		alignSelf: 'flex-start',
	},
	FiltersHolder: {
		zIndex: 11111,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: 42,
		paddingHorizontal: 50,
		alignSelf: 'center',
		width: '100%',
	},
	ButtonsHolder: {
		backgroundColor: 'rgba(255,255,255,0.8)',
		justifyContent: 'center',
		alignItems: 'center',
		width: '105%',
		paddingVertical: 25,
		borderRadius: 58,
	},
	EntryButton: {
		width: 200,
		height: 60,
		marginVertical: 10,
		borderRadius: 40, //fix box shadow
		shadowColor: 'rgb(59, 112, 164)',
		shadowOpacity: 0.5,
		backgroundColor: '#4aaeef',
		justifyContent: 'center',
		alignItems: 'center',
	},
	ExitButton: {
		width: 200,
		height: 60,
		borderRadius: 40,
		marginVertical: 10,
		shadowColor: 'rgb(82, 89, 98)',
		shadowRadius: 30,
		shadowOpacity: 0.4,
		shadowOffset: { width: 0, height: 10 },
		backgroundColor: '#eb8b2d',
		justifyContent: 'center',
		alignItems: 'center',
	},
	report101Container: {
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		marginTop: 30,
	},
	report101Text: {
		display: 'flex',
		marginBottom: 5,
	},
	report101ActionsContainer: {},
	report101Button: {
		alignSelf: 'center',
		height: 40,
		width: 196,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
	},
});
export default ClockReportMobileComponent;
