import {
	Privileges,
	RepresentativePrivileges,
	EmployeePrivileges,
	EmployerPrivileges,
} from '../constants/roleTypes.constant';
import { Dimensions, Platform } from 'react-native';

export enum UserRolesNames {
	EMPLOYEE = 'employee',
	EMPLOYER = 'employer',
	REPRESENTATIVE = 'representative',
}

const isMobile =
	Platform.OS === 'android' ||
	Platform.OS === 'ios' ||
	Dimensions.get('window').height > Dimensions.get('window').width;

interface CustomRoutes {
	[routeName: string]: {
		name: string;
		label: string;
		isHidden: boolean;
		privileges: Privileges[];
		subRoute?: string;
		hasSubRoute?: string;
		navigateObj?: any;
		notInMobile?: boolean;
		notInUserRole?: UserRolesNames;
	};
}

// TODO: add roles per route where needed
const routes: CustomRoutes = {
	home: {
		name: 'home',
		label: 'מסך הבית',
		isHidden: false,
		privileges: isMobile
			? [
					Privileges.ROLE_MY_PAYCHECKS_READ,
					Privileges.ROLE_MY_PAYCHECKS_WRITE,
					Privileges.ROLE_LOG_WORK_READ,
					Privileges.ROLE_LOG_WORK_WRITE,
			  ]
			: [
					Privileges.ROLE_MY_PAYCHECKS_READ,
					Privileges.ROLE_MY_PAYCHECKS_WRITE,
			  ],
	},
	dashboardEmployer: {
		name: 'dashboardEmployer',
		label: 'מסך הבית',
		isHidden: false,
		notInMobile: true,
		notInUserRole: UserRolesNames.REPRESENTATIVE,
		privileges: [
			Privileges.ROLE_CREATE_USER_WRITE,
			Privileges.ROLE_EMP_PAYCHECKS_READ,
			Privileges.ROLE_EMP_PAYCHECKS_WRITE,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_READ,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
			Privileges.ROLE_EMP_PAYCHECKS_READ,
			Privileges.ROLE_EMP_PAYCHECKS_WRITE,
			Privileges.ROLE_EMP_10001_READ,
			Privileges.ROLE_EMP_10001_WRITE,
			Privileges.ROLE_COSTING_REPORT_READ,
			Privileges.ROLE_COSTING_REPORT_WRITE,
			Privileges.ROLE_VACATION_REPORT_READ,
			Privileges.ROLE_VACATION_REPORT_WRITE,
		],
	},
	repHomePage: {
		name: 'repHomePage',
		label: 'מסך הבית',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_PAYCHECK_REPORT_READ,
			Privileges.ROLE_REP_PAYCHECK_REPORT_WRITE,
			Privileges.ROLE_REP_ORG_REPORT_READ,
			Privileges.ROLE_REP_ORG_REPORT_WRITE,
			Privileges.ROLE_REP_ORG_126_REPORT_READ,
			Privileges.ROLE_REP_ORG_126_REPORT_WRITE,
			Privileges.ROLE_REP_FORM_106_REPORT_READ,
			Privileges.ROLE_REP_FORM_106_REPORT_WRITE,
			Privileges.ROLE_REP_FORM_101_REPORT_READ,
			Privileges.ROLE_REP_FORM_101_REPORT_WRITE,
			Privileges.ROLE_REP_WORK_PAPER_REPORT_READ,
			Privileges.ROLE_REP_WORK_PAPER_REPORT_WRITE,
		],
	},
	repCustomers: {
		name: 'repCustomers',
		label: 'לקוחות',
		isHidden: false,
		notInMobile: false,
		privileges: [
			Privileges.ROLE_REP_CUSTOMER_READ,
			Privileges.ROLE_REP_CUSTOMER_WRITE,
		],
	},
	repReports: {
		name: 'repReports',
		label: 'דוחות',
		isHidden: false,
		notInMobile: true,
		hasSubRoute: 'repReports',
		privileges: [
			Privileges.ROLE_REP_PAYCHECK_REPORT_READ,
			Privileges.ROLE_REP_PAYCHECK_REPORT_WRITE,
			Privileges.ROLE_REP_ORG_REPORT_READ,
			Privileges.ROLE_REP_ORG_REPORT_WRITE,
			Privileges.ROLE_REP_ORG_126_REPORT_READ,
			Privileges.ROLE_REP_ORG_126_REPORT_WRITE,
			Privileges.ROLE_REP_FORM_106_REPORT_READ,
			Privileges.ROLE_REP_FORM_106_REPORT_WRITE,
			Privileges.ROLE_REP_FORM_101_REPORT_READ,
			Privileges.ROLE_REP_FORM_101_REPORT_WRITE,
			Privileges.ROLE_REP_WORK_PAPER_REPORT_READ,
			Privileges.ROLE_REP_WORK_PAPER_REPORT_WRITE,
		],
	},
	repPaycheckReports: {
		name: 'repPaycheckReports',
		label: 'סטטוס הפקת תלושי שכר',
		subRoute: 'repReports',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_PAYCHECK_REPORT_READ,
			Privileges.ROLE_REP_PAYCHECK_REPORT_WRITE,
		],
	},
	repOrgReports: {
		name: 'repOrgReports',
		label: 'דיווחי 100 ו 102 לרשויות',
		subRoute: 'repReports',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_ORG_REPORT_READ,
			Privileges.ROLE_REP_ORG_REPORT_WRITE,
		],
	},
	repOrg126Reports: {
		name: 'repOrg126Reports',
		label: 'סטטוס טפסי 126 לרשויות',
		subRoute: 'repReports',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_ORG_126_REPORT_READ,
			Privileges.ROLE_REP_ORG_126_REPORT_WRITE,
		],
	},
	repForm106Reports: {
		name: 'repForm106Reports',
		label: 'סטטוס טפסי 106',
		subRoute: 'repReports',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_FORM_106_REPORT_READ,
			Privileges.ROLE_REP_FORM_106_REPORT_WRITE,
		],
	},
	repForm101Reports: {
		name: 'repForm101Reports',
		label: 'סטטוס טפסי 101',
		subRoute: 'repReports',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_FORM_101_REPORT_READ,
			Privileges.ROLE_REP_FORM_101_REPORT_WRITE,
		],
	},
	repWorkPaperReports: {
		name: 'repWorkPaperReports',
		label: 'דיווחי שכר מחודש לחודש',
		subRoute: 'repReports',
		isHidden: false,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_REP_WORK_PAPER_REPORT_READ,
			Privileges.ROLE_REP_WORK_PAPER_REPORT_WRITE,
		],
	},
	calendarAttendanceReports: {
		name: 'calendarAttendanceReports',
		label: 'דיווחי נוכחות',
		isHidden: false,
		privileges: [
			Privileges.ROLE_LOG_WORK_READ,
			Privileges.ROLE_LOG_WORK_WRITE,
		],
	},
	signIn: {
		name: 'signIn',
		label: 'התחברות',
		isHidden: true,
		privileges: [],
	},
	forgotPassword: {
		name: 'forgotPassword',
		label: 'שחזור סיסמה',
		isHidden: false,
		privileges: [],
	},
	vacationReports: {
		name: 'vacationReports',
		label: 'דוח יתרות חופש',
		subRoute: 'reports',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_VACATION_REPORT_READ,
			Privileges.ROLE_VACATION_REPORT_WRITE,
		],
	},
	costingReports: {
		name: 'costingReports',
		label: 'דוחות תמחיר',
		subRoute: 'reports',
		navigateObj: { screen: 'detailed' },
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_COSTING_REPORT_READ,
			Privileges.ROLE_COSTING_REPORT_WRITE,
		],
	},
	workPaper: {
		name: 'workPaper',
		label: 'פירוט נתוני שכר',
		subRoute: 'reports',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_EMPLOYER_WAGE_REPORT_READ,
			Privileges.ROLE_EMPLOYER_WAGE_REPORT_WRITE,
		],
	},
	fromMonthToMonthReports: {
		name: 'fromMonthToMonthReports',
		label: 'דוח שכר מחודש לחודש',
		subRoute: 'reports',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_DRAFT_WAGE_REPORT_READ,
			Privileges.ROLE_DRAFT_WAGE_REPORT_WRITE,
		],
	},
	salaryReports: {
		name: 'salaryReports',
		label: 'תלושי עובדים',
		subRoute: 'reports',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_EMP_PAYCHECKS_READ,
			Privileges.ROLE_EMP_PAYCHECKS_WRITE,
		],
	},
	attendanceReports: {
		name: 'attendanceReports',
		label: 'דוחות נוכחות',
		subRoute: 'reports',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_EMP_10001_READ,
			Privileges.ROLE_EMP_10001_WRITE,
		],
	},
	reports101: {
		name: 'reports101',
		label: 'טופסי 101',
		subRoute: 'reports',
		navigateObj: { screen: 'table101' },
		isHidden: false,
		privileges: [
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_READ,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
		],
	},
	management: {
		name: 'management',
		label: 'הנהלה',
		hasSubRoute: 'management',
		isHidden: false,
		privileges: [
			Privileges.ROLE_MGM_USER_READ,
			Privileges.ROLE_MGM_USER_WRITE,
			Privileges.ROLE_CREATE_USER_READ,
			Privileges.ROLE_CREATE_USER_WRITE,
			Privileges.ROLE_MNG_DOCS_READ,
			Privileges.ROLE_MNG_DOCS_WRITE,
		],
	},
	usersManagment: {
		name: 'usersManagment',
		label: 'משתמשים',
		subRoute: 'management',
		navigateObj: {
			screen: 'users',
			params: { isFromDashboard: undefined },
		},
		isHidden: false,
		// notInMobile: true,
		privileges: [
			Privileges.ROLE_MGM_USER_READ,
			Privileges.ROLE_MGM_USER_WRITE,
			Privileges.ROLE_CREATE_USER_READ,
			Privileges.ROLE_CREATE_USER_WRITE,
		],
	},
	documentsManagment: {
		name: 'documentsManagment',
		label: 'מסמכים',
		subRoute: 'management',
		isHidden: false,
		privileges: [
			Privileges.ROLE_MNG_DOCS_READ,
			Privileges.ROLE_MNG_DOCS_WRITE,
		],
	},
	locationSettings: {
		name: 'locationSettings',
		label: 'הגדרת מיקומים',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_LOCATION_READ,
			Privileges.ROLE_LOCATION_WRITE,
			Privileges.ROLE_LOCATION_ASSOCIATION_READ,
			Privileges.ROLE_LOCATION_ASSOCIATION_WRITE,
		],
	},
	reports: {
		name: 'routes',
		label: 'דוחות',
		hasSubRoute: 'reports',
		isHidden: false,
		privileges: [
			Privileges.ROLE_MY_106_WRITE,
			Privileges.ROLE_MY_106_WRITE,
			Privileges.ROLE_EMP_10001_READ,
			Privileges.ROLE_EMP_10001_WRITE,
			Privileges.ROLE_COSTING_REPORT_READ,
			Privileges.ROLE_COSTING_REPORT_WRITE,
			Privileges.ROLE_VACATION_REPORT_READ,
			Privileges.ROLE_VACATION_REPORT_WRITE,
			Privileges.ROLE_EMP_PAYCHECKS_READ,
			Privileges.ROLE_EMP_PAYCHECKS_WRITE,
			Privileges.ROLE_MY_PAYCHECKS_WRITE,
			Privileges.ROLE_MY_PAYCHECKS_READ,
			Privileges.ROLE_EMPLOYEE_FORM_READ,
			Privileges.ROLE_EMPLOYEE_FORM_WRITE,
			Privileges.ROLE_MY_10001_READ,
			Privileges.ROLE_MY_10001_WRITE,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_READ,
			Privileges.ROLE_EMPLOYEE_FORM_REPORT_WRITE,
		],
	},
	paycheck: {
		name: 'paycheck',
		label: 'תלושים',
		subRoute: 'reports',
		isHidden: false,
		privileges: [
			Privileges.ROLE_MY_PAYCHECKS_READ,
			Privileges.ROLE_MY_PAYCHECKS_WRITE,
		],
	},
	form106: {
		name: 'form106',
		label: 'טופס 106',
		subRoute: 'reports',
		isHidden: false,
		privileges: [Privileges.ROLE_MY_106_READ, Privileges.ROLE_MY_106_WRITE],
	},
	documents: {
		name: 'documents',
		label: 'מסמכים',
		subRoute: 'reports',
		isHidden: false,
		privileges: [
			Privileges.ROLE_MY_DOCS_READ,
			Privileges.ROLE_MY_DOCS_WRITE,
		],
	},
	form101: {
		name: 'form101',
		label: 'טופס 101',
		subRoute: 'reports',
		isHidden: false,
		navigateObj: { screen: '101table' },
		privileges: [
			Privileges.ROLE_EMPLOYEE_FORM_READ,
			Privileges.ROLE_EMPLOYEE_FORM_WRITE,
		], //TODO Change to appropriate Permissions
	},
	paycheckView: {
		name: 'paycheckView',
		label: 'paycheckView',
		isHidden: true,
		privileges: [
			Privileges.ROLE_MY_10001_READ,
			Privileges.ROLE_MY_10001_WRITE,
			Privileges.ROLE_MY_106_READ,
			Privileges.ROLE_MY_106_WRITE,
			Privileges.ROLE_MY_PAYCHECKS_WRITE,
			Privileges.ROLE_MY_PAYCHECKS_READ,
		],
	},
	attendance: {
		name: 'attendance',
		label: 'דוחות נוכחות',
		isHidden: false,
		subRoute: 'reports',
		privileges: [
			Privileges.ROLE_MY_10001_READ,
			Privileges.ROLE_MY_10001_WRITE,
		],
	},
	logout: {
		name: 'logout',
		label: 'יציאה',
		isHidden: true,
		privileges: [
			...EmployeePrivileges,
			...EmployerPrivileges,
			...RepresentativePrivileges,
		], // [Privileges.ROLE_LOGOUT],
	},
	pdfReportView: {
		name: 'pdfReportView',
		label: 'pdfReportView',
		isHidden: true,
		privileges: [
			Privileges.ROLE_MY_106_READ,
			Privileges.ROLE_MY_106_WRITE,
			Privileges.ROLE_MY_10001_READ,
			Privileges.ROLE_MY_10001_WRITE,
		],
	},
	report101View: {
		name: 'report101View',
		label: 'report101View',
		isHidden: true,
		privileges: [
			Privileges.ROLE_EMPLOYEE_FORM_READ,
			Privileges.ROLE_EMPLOYEE_FORM_WRITE,
		],
	},
	fp: {
		//actually it's "reset password" (strange path name from BE)
		name: 'fp',
		label: 'resetPassword',
		isHidden: true,
		privileges: [],
	},
	mc: {
		name: 'mc',
		label: 'updateEmail',
		isHidden: true,
		privileges: [],
	},
	employeeList: {
		name: 'employeeList',
		label: 'employeeList',
		isHidden: true,
		privileges: [],
	},
	applications: {
		name: 'applications',
		label: 'פניות למעסיק',
		isHidden: true,
		privileges: [
			Privileges.ROLE_VACATION_REQUEST_READ,
			Privileges.ROLE_VACATION_REQUEST_WRITE,
			Privileges.ROLE_SICKNESS_REQUEST_READ,
			Privileges.ROLE_SICKNESS_REQUEST_WRITE,
		],
	},
	emploeeRequests: {
		name: 'emploeeRequests',
		label: 'פניות למעסיק',
		isHidden: true,
		privileges: [
			Privileges.ROLE_MNG_VAC_REQUEST_READ,
			Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
			Privileges.ROLE_MNG_SICK_REQUEST_READ,
			Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
		],
	},
	emploeeRequestForm: {
		name: 'emploeeRequestForm',
		label: 'פניות למעסיק',
		isHidden: true,
		privileges: [
			Privileges.ROLE_VACATION_REQUEST_READ,
			Privileges.ROLE_VACATION_REQUEST_WRITE,
			Privileges.ROLE_SICKNESS_REQUEST_READ,
			Privileges.ROLE_SICKNESS_REQUEST_WRITE,
		],
	},
	requestDetailsMobile: {
		name: 'requestDetailsMobile',
		label: 'פניות למעסיק',
		isHidden: true,
		privileges: [
			Privileges.ROLE_MNG_VAC_REQUEST_READ,
			Privileges.ROLE_MNG_VAC_REQUEST_WRITE,
			Privileges.ROLE_MNG_SICK_REQUEST_READ,
			Privileges.ROLE_MNG_SICK_REQUEST_WRITE,
		],
	},
	hoursReportSummary: {
		name: 'hoursReportSummary',
		label: 'דוח דיווח שעות',
		isHidden: isMobile,
		notInMobile: true,
		privileges: [
			Privileges.ROLE_LOG_WORK_REPORT_READ,
			Privileges.ROLE_LOG_WORK_REPORT_WRITE,
		],
		navigateObj: { singleEmployee: false },
	},
	EmployeeWorkLogReports: {
		name: 'EmployeeWorkLogReports',
		label: 'דוח דיווח שעות',
		isHidden: isMobile,
		privileges: [
			Privileges.ROLE_LOG_WORK_WRITE,
			Privileges.ROLE_LOG_WORK_READ,
		],
	},
	personalInformation: {
		name: 'personalInformation',
		label: 'פרטים אישיים',
		isHidden: false,
		privileges: [
			...EmployeePrivileges,
			...EmployerPrivileges,
			...RepresentativePrivileges,
			Privileges.ROLE_PERSONAL_INFORMATION_ONLY,
		],
	},
	businessSettings: {
		name: 'businessSettings',
		label: 'הגדרות עסק',
		isHidden: !isMobile,
		privileges: [
			Privileges.ROLE_CUSTOMER_SETTING_WRITE,
			Privileges.ROLE_CUSTOMER_SETTING_READ,
		],
	},
	proInfo: {
		name: 'proInfo',
		label: 'מידע מקצועי',
		hasSubRoute: 'proInfo',
		isHidden: false,
		privileges: [...EmployeePrivileges, ...EmployerPrivileges],
	},
	instructionsEmployee: {
		name: 'instructionsEmployee',
		label: 'חוק שירות עבודה בשעת חירום',
		subRoute: 'proInfo',
		isHidden: false,
		privileges: [...EmployeePrivileges],
	},
	// instructionsEmployer: {
	// 	name: 'instructionsEmployer',
	// 	label: 'הנחיות למעסיק',
	// 	subRoute: 'proInfo',
	// 	isHidden: false,
	// 	privileges: [...EmployerPrivileges],
	// },
	noPaymentVacation: {
		name: 'noPaymentVacation',
		label: 'חל״ת חרבות ברזל',
		subRoute: 'proInfo',
		isHidden: false,
		privileges: [...EmployerPrivileges],
	},
	noPaymentVacationEmployee: {
		name: 'noPaymentVacationEmployee',
		label: 'חל״ת חרבות ברזל',
		subRoute: 'proInfo',
		isHidden: false,
		privileges: [...EmployeePrivileges],
	},
	carInfo: {
		name: 'carInfo',
		label: 'שווי רכב חרבות ברזל',
		subRoute: 'proInfo',
		isHidden: false,
		privileges: [...EmployerPrivileges],
	},
};

export default routes;
